<template>
  <main class="app-view billing billing-rewards">
    <header>
      <div class="title">
        <ul class="crumbs">
          <li>
            <router-link :to="{name: 'billing'}">Billing</router-link>
          </li>
        </ul>
        <h1>Rewards</h1>
      </div>

      <div class="btns">
        <a href="https://help.hithrive.com/hc/en-us/articles/360058955171-Paying-for-rewards-points-and-top-ups" target="_blank" class="help-link">Paying for rewards</a>
        <template v-if="team.reward_payment_type === 0">
          <button class="btn btn-green" @click="topupPoints"><i class="fas fa-coins"></i> Top-up points</button>
        </template>
        <template v-else>
          <span class="sync-indicator">
            Postpay rewards enabled
          </span>
        </template>
      </div>
    </header>

    <section class="main-section">
      <header>
        <h2>Current Balance</h2>
      </header>

      <div class="content">
        <ul class="stats" v-if="point_stats">

          <li>
            <div>
              <h4>Purchased points</h4>
              <span>{{$formatPoints(point_stats.point_balance)}}</span>
            </div>
          </li>

          <li>
            <div>
              <h4>Unredeemed points</h4>
              <span>{{$formatPoints(point_stats.outstanding_points)}}</span>
            </div>
          </li>

          <li>
            <div>
              <h4>Redemptions this month</h4>
              <span>{{$formatPoints(point_stats.redeemed_this_month)}}</span>
            </div>
          </li>

          <li>
            <div>
              <h4>Remaining allowances</h4>
              <span>{{$formatPoints(point_stats.giveable_points)}}</span>
            </div>
          </li>

        </ul>
      </div>
    </section>

    <section class="main-section" v-if="has_pending_topup || (topups && topups.length > 0)">
      <header>
        <h2>Recent Top-ups</h2>
      </header>

      <div class="content">
        <ul class="topups">
          <li class="loading-placeholder" v-if="has_pending_topup"></li>
          <li v-for="(topup, index) in topups" :key="`topup_history_${index}`" :class="{'success': topup.transaction ? topup.transaction.pi_id === recent_topup : false}">
            <span class="points">
              {{$formatPoints(topup.points)}}
            </span>
            <span class="timestamp">
              on <strong>{{$formatDate(topup.created_at)}}</strong>
            </span>

            <div class="actions">
              <a href="#" @click.prevent="viewReceipt(topup)" class="btn btn-light btn-xs">View receipt</a>
            </div>
          </li>
        </ul>
      </div>
    </section>

    <section class="main-section" v-if="payments !== null && payments.length > 0">
      <header>
        <h2>Postpay Receipts</h2>
      </header>

      <div class="content">
        <ul class="payments">
          <li v-for="payment in payments" :key="`reward_payment_${payment.payment_id}`">
            <div class="amount">
              <span class="total-cost">{{$formatCurrency(payment.total_amount/100)}}</span>
            </div>
            <div class="info">
              on <span class="timestamp">{{$formatDate(payment.payment_time)}}</span> ({{payment.payment_id}})
            </div>

            <div class="actions">
              <a href="#" @click.prevent="viewPostpayReceipt(payment)" class="btn btn-light btn-xs">View receipt</a>
            </div>
          </li>
        </ul>
      </div>
    </section>

  </main>
</template>

<script>
export default {
  computed: {
    has_pending_topup() {
      if(this.recent_topup === null) return false;
      if(this.topups === null || this.topups.length === 0) return false;

      return _.findIndex(this.topups, t => (t.transaction ? t.transaction.pi_id === this.recent_topup : false)) === -1;
    },
    team() {
      return this.$store.state.team;
    }
  },
  data() {
    return {
      point_stats: null,
      topups: null,
      recent_topup: null,
      poll_timeout: null,
      payments: null,
      use_postpay: false
    }
  },
  watch: {
    topups(val, prev) {
      if(prev === null || val.length === 0) return;
      if(prev[0].id === val[0].id) return;

      this.populatePointStats();
    }
  },
  beforeDestroy() {
    clearTimeout(this.poll_timeout)
  },
  created() {
    this.populatePointStats();
    this.populateTopupHistory();
    this.populatePayments()

    this.use_postpay = this.team.reward_payment_type === 1;
  },
  methods: {
    async populatePayments() {
      const resp = await this.$api.Billing.get_reward_payments()

      this.payments = resp;
    },
    async populateTopupHistory() {
      const resp = await this.$api.Billing.get_topups(10);
      this.topups = resp;

      this.poll_timeout = setTimeout( this.populateTopupHistory, 2000 );
    },
    async populatePointStats() {
      this.point_stats = null;
      const resp = await this.$api.Team.get_point_stats();
      this.point_stats = resp;
    },
    enablePostpay() {
      this.$root.$emit(
        'openModal',
        'Billing/EnablePostpay',
        {},
        () => {
          
        }
      )
    },
    viewReceipt(topup) {
      this.$root.$emit(
        'openModal',
        'Billing/TopupReceipt',
        {
          topup
        },
        () => {
          
        }
      )
    },
    viewPostpayReceipt(postpay) {
      this.$root.$emit(
        'openModal',
        'Billing/PostpayReceipt',
        {
          payment: postpay
        },
        () => {
          
        }
      )
    },
    topupPoints() {
      this.$root.$emit(
        'openModal',
        'Billing/PointTopup',
        {},
        (action, payment_intent_id) => {
          if(action === 'TOPUP_SUCCESS') {
            this.recent_topup = payment_intent_id;
            this.populatePointStats()
          }
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.btns {
  align-items: center !important;
}
.sync-indicator {
  color: #659e09;
  font-size: 12px;
  position: relative;
  padding: 0 0 0 12px;
  display: block;
  margin: 0 0 0 25px;

  &:before {
    content: '';
    display: block;
    background-color: #659e09;
    border-radius: 100%;
    width: 6px;
    height: 6px;
    animation: blip 1s linear infinite;
    position: absolute;
    left: 0;
    top: 6px;
  }
}

ul.payments {
  display: block;
  padding: 0;
  margin: 0;

  > li {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 10px;
    background: $light;
    border-radius: 10px;
    font-size: 14px;

    .info {
      padding: 0 10px 0 0;
      flex: 0 0 auto;

      .timestamp {
        font-weight: 700;
      }
    }
    .user {
      padding: 0 10px;
      flex: 0 0 auto;
    }

    .reward {
      padding: 0 10px;
      flex: 0 0 auto;
    }

    .amount {
      padding: 0 5px 0 0;
      flex: 0 0 auto;
    }

    .actions {
      margin: 0 0 0 auto;
    }

    .amount {
      > span {
        background: $green;
        display: inline-block;
        line-height: 1em;
        font-size: 12px;
        border-radius: 3px;
        padding: 3px 4px;
        color: #fff;
      }
    }

    +li {
      margin-top: 15px;
    }
  }
}

.stats {
  display: flex;
  padding: 0;
  margin: 0 -10px;

  > li {
    display: block;
    padding: 0 10px;

    > div {
      background: $light;
      padding: 15px;
      border-radius: 10px;

      > h4 {
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        color: $muted-text;
        margin: 0 0 5px;
      }

      > span {
        text-align: center;
        display: block;
      }
    }
  }
}

.topups {
  display: block;
  padding: 0;
  margin: 0;

  > li {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 10px;
    border-radius: 10px;
    background: $light;

    + li {
      margin: 15px 0 0;
    }

    .timestamp {
      display: block;
      font-size: 14px;
    }

    .actions {
      margin: 0 0 0 auto;
    }

    .points {
      background: $green;
      font-size: 12px;
      line-height: 12px;
      padding: 2px 4px;
      border-radius: 3px;
      display: block;
      color: #fff;
      margin: 0 5px 0 0;
    }

    &.success {
      box-shadow: $green 0 0 0 1px, rgba($green, 0.3) 0 0 0 4px;

      &:before {
        content: '\f00c';
        font-family: 'Font Awesome 5 Pro';
        font-weight: 700;
        display: block;
        border-radius: 100%;
        background: $green;
        color: $light;
        font-size: 12px;
        text-align: center;
        line-height: 24px;
        width: 22px;
        height: 22px;
        margin: 0 10px 0 0;
      }
    }

    &.loading-placeholder {
      height: 44px;
    }
  }
}

@keyframes blip {
  0% {
    box-shadow: rgba(#659e09, 0) 0 0 0 0;
  }
  50% {
    box-shadow: rgba(#659e09, 0.3) 0 0 0 3px;
  }
  100% {
    box-shadow: rgba(#659e09, 0) 0 0 0 6px;
  }
}
</style>