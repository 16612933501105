<template>
  <div class="profile-edit">

    <section class="main-section point-balance">
      <header>
        <h2>Point Balance</h2>
      </header>

      <div class="content">
        <div>
          <label>Redeemable points</label>
          <span>{{$formatPoints(user.redeemable_points)}}</span>
        </div>
        <div>
          <label>Shoutout allowance</label>
          <span>{{$formatPoints(user.shoutout_allowance_remaining)}}<small>/ {{$formatPoints(user.shoutout_allowance)}}</small></span>
        </div>
        <div>
          <label>Award allowance</label>
          <span>{{$formatPoints(user.award_allowance_remaining)}}<small>/ {{$formatPoints(user.award_allowance)}}</small></span>
        </div>
      </div>

    </section>

    <section class="main-section basic-details">
      <header>
        <h2>Basic Details</h2>
      </header>

      <div class="content settings">


        <div class="setting-row birthday-setting">
          <i class="fas fa-birthday-cake"></i>
          <div class="title">
            <h4>Birthday</h4>
            <p>Date used for birthday celebrations.</p>
          </div>
          <div class="fields">
            <div class="d-flex">
              <select class="form-control form-control-sm" v-model="birthday_month">
                <option :value="-1" disabled>Month...</option>

                <option v-for="(month, index) in months" :value="index+1" :key="`birthday_month_${index}`">{{month}}</option>
              </select>

              <select class="form-control form-control-sm" v-model="birthday_day" :disabled="birthday_month === -1">
                <option :value="-1" disabled>Day...</option>

                <option v-for="(day, index) in birthday_days" :value="day" :key="`birthday_day_${index}`">{{day}}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="setting-row birthday-setting">
          <i class="fas fa-glass-cheers"></i>
          <div class="title">
            <h4>Anniversary</h4>
            <p>Date used for anniversary celebrations.</p>
          </div>
          <div class="fields">
            <div class="d-flex">
              <select class="form-control form-control-sm" v-model="anniversary_month">
                <option :value="-1" disabled>Month...</option>

                <option v-for="(month, index) in months" :value="index+1" :key="`annv_month_${index}`">{{month}}</option>
              </select>

              <select class="form-control form-control-sm" v-model="anniversary_day" :disabled="anniversary_month === -1">
                <option :value="-1" disabled>Day...</option>

                <option v-for="(day, index) in annv_days" :value="day" :key="`annv_day_${index}`">{{day}}</option>
              </select>
              <select class="form-control form-control-sm" v-model="anniversary_year" :disabled="anniversary_month === -1">
                <option :value="-1" disabled>Year...</option>

                <option v-for="(year, index) in annv_years" :value="year" :key="`annv_day_${index}`">{{year}}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="setting-row newhire-setting">
          <i class="fas fa-handshake-alt"></i>
          <div class="title">
            <h4>New Hire Message</h4>
            <p>Optional message to include on new hire award.</p>
          </div>
          <div class="fields">
            <textarea class="form-control" v-model="newhire_message" rows="5" placeholder="(optional)"></textarea>
          </div>
        </div>


      </div>
    </section>

    <section class="main-section account-settings">
      <header>
        <h2>Settings</h2>
      </header>

      <div class="content settings">


        <div class="setting-row eligiblity-setting">
          <i class="fas fa-coins"></i>
          <div class="title">
            <h4>Point Eligibility</h4>
            <p>Set if this user can receive points, or is eligible for a point allowance.</p>
          </div>
          <div class="fields">
            <select class="form-control" v-model="point_eligibility">
              <option :value="0">User can send and receive points</option>
              <option :value="1">User can only send points</option>
              <option :value="2">User can only receive points</option>
              <option :value="3">User can't send or receive points</option>
            </select>
          </div>
        </div>

        <div class="setting-row override-setting">
          <i class="fas fa-sliders-v-square"></i>
          <div class="title">
            <h4>Allowance Override</h4>
            <p>Set a specific monthly allowance for this user. Overrides recognition channel settings.</p>
          </div>
          <div class="fields">
            <div class="d-flex">

              <div class="form-group">
                <label>Shoutout Allowance</label>
                <div class="input-group input-group-sm" :class="{'is-invalid': $v.shoutout_allowance_override.$invalid}">
                  <input type="text" v-model.number="$v.shoutout_allowance_override.$model" class="form-control">
                  <div class="input-group-append">
                    <label class="input-group-text">points/month</label>
                  </div>
                </div>
                <div class="invalid-feedback">
                  Set to 0 to clear override.
                </div>
              </div>

              <div class="form-group">
                <label>Award Allowance</label>
                <div class="input-group input-group-sm" :class="{'is-invalid': $v.award_allowance_override.$invalid}">
                  <input type="text" v-model.number="$v.award_allowance_override.$model" class="form-control">
                  <div class="input-group-append">
                    <label class="input-group-text">points/month</label>
                  </div>
                </div>
                <div class="invalid-feedback">
                  Set to 0 to clear override.
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-if="user.custom_fields && user.custom_fields.length > 0" class="main-section user-fields">
      <header>
        <h2>User Fields</h2>
      </header>

      <div class="content">
        <table>
          <tbody>
            <tr v-for="([label, value], index) in custom_fields" :key="`cf_${index}`">
              <th>{{label}}</th>
              <td>{{value}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>

    <footer>
      <button class="btn btn-green btn-sm" @click="saveUser">Save</button>
    </footer>

  </div>
</template>

<script>
import {
  required,
  minValue
} from 'vuelidate/lib/validators'
export default {
  props: ['user'],
  computed: {
    months() {
      return _.map(
        _.range(1, 13),
        (m) => {
          return this.$DateTime.fromObject({month: m}).toFormat('MMMM')
        }
      )
    },
    birthday_days() {
      const {daysInMonth} = this.$DateTime.fromObject( {month: this.birthday_month, year: 2019} );

      return _.range(1, daysInMonth + 1);
    },
    annv_days() {
      const {daysInMonth} = this.$DateTime.fromObject( {month: this.anniversary_month, year: 2019} );

      return _.range(1, daysInMonth + 1);
    },
    annv_years() {
      const {year} = this.$DateTime.local()
      return _.range(2021 - 100, year + 1).reverse();
    },
    custom_fields() {
      const {custom_fields} = this.$store.state.team;
      const slack_fields = custom_fields.slack_fields || {};
      const profile_fields = custom_fields.profile_fields || {};
      const integrations = Object.values(custom_fields.integrations || {}).reduce((agg, val) => (Object.assign(agg, val)), {})

      const fields = Object.entries(
        Object.assign(
          {},
          integrations,
          slack_fields,
          profile_fields
        )
      ).map(([key, val]) => ({
        label: val,
        id: key
      }))

      return fields.map(field => {
        const ucf = this.user.custom_fields.find(f => f.field_id === field.id)
        
        return [
          field.label,
          ucf ? ucf.value : ''
        ];
      })

    }
  },
  validations: {
    shoutout_allowance_override: {
      required,
      minValue: minValue(0)
    },
    award_allowance_override: {
      required,
      minValue: minValue(0)
    }
  },
  data() {
    return {
      birthday_month: -1,
      birthday_day: -1,
      anniversary_month: -1,
      anniversary_day: -1,
      anniversary_year: -1,
      point_eligibility: 0,
      shoutout_allowance_override: 0,
      award_allowance_override: 0,
      newhire_message: ''
    }
  },
  created() {
    this.birthday_month = this.user.birthday_month !== null ? this.user.birthday_month + 1 : -1;
    this.birthday_day = this.user.birthday_day !== null ? this.user.birthday_day : -1;

    this.anniversary_month = this.user.anniversary_month !== null ? this.user.anniversary_month + 1 : -1;
    this.anniversary_day = this.user.anniversary_day !== null ? this.user.anniversary_day : -1;
    this.anniversary_year = this.user.anniversary_year !== null ? this.user.anniversary_year : -1;

    this.point_eligibility = this.user.point_eligibility;

    this.shoutout_allowance_override = this.user.shoutout_allowance_override;
    this.award_allowance_override = this.user.award_allowance_override;

    this.newhire_message = this.user.newhire_message || '';
  },
  methods: {
    async saveUser() {
      this.$store.commit('loading', true)

      const update_data = {
        birthday_month: this.birthday_month === -1 ? null : this.birthday_month - 1,
        birthday_day: this.birthday_day === -1 ? null : this.birthday_day,
        anniversary_month: this.anniversary_month === -1 ? null : this.anniversary_month - 1,
        anniversary_day: this.anniversary_day === -1 ? null : this.anniversary_day,
        anniversary_year: this.anniversary_year === -1 ? null : this.anniversary_year,
        point_eligibility: this.point_eligibility,
        shoutout_allowance_override: this.shoutout_allowance_override,
        award_allowance_override: this.award_allowance_override,
        newhire_message: this.newhire_message.trim().length === 0 ? null : this.newhire_message
      };

      await this.$api.Team.update_user(this.user.id, update_data)
      this.$emit('refresh')

      this.$toast.success('User has been updated.');

      this.$store.commit('loading', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.birthday-setting {
  .d-flex {
    margin: 0 -5px;
    > select {
      margin: 0 5px;
      flex: 0 0 auto;
      width: auto;
    }
  }
}

.user-fields {
  table {
    width: 100%;

    tr {
      font-size: 12px;

      th,td {
        border-bottom: 1px solid $light;
        padding: 5px 0;
      }

      th {
        white-space: nowrap;
        color: $muted-text;
        width: 1px;
        padding-right: 15px;
        text-align: right;
      }

      td {
        white-space: nowrap;
        flex: 0 0 auto;
      }
    }
  }
}

.override-setting {
  .d-flex {
    margin: 0 -5px;

    > div {
      padding: 0 5px;
    }
  }

  .form-group {
    > label {
      font-size: 12px;
      margin: 0;
      font-weight: 700;
    }
  }
}

.point-balance {
  .content {
    display: flex;
    margin: 0 -5px;

    > div {
      flex: 0 0 auto;
      text-align: center;
      display: block;
      background: $light;
      border-radius: 10px;
      padding: 10px 15px;
      margin: 0 5px;

      > label {
        font-weight: 700;
        color: $muted-text;
        margin: 0;
        font-size: 14px;
        line-height: 14px;
      }

      > span {
        display: block;
        font-size: 14px;

        > small {
          margin: 0 0 0 3px;
          display: inline-block;
        }
      }
    }
  }
}
</style>