<template>
  <main class="app-view new-hires new-hires-dashboard">
    <header>
      <div class="title">
        <h1>New Hires</h1>
      </div>

      <div class="btns">
        <router-link class="btn btn-light" :to="{name: 'reporting-activity', query: {type: 'new-hires'}}"><i class="fas fa-chart-pie"></i> Latest activity</router-link>
      </div>
    </header>
    
    <template v-if="!award">
      <div class="empty-state">
        <h4>Setup your new hire award</h4>
        <p>Before you can celebrate your new hires, you'll need to <router-link :to="{name: 'new-hires-settings'}">configure the new hire award</router-link>.</p>
      </div>
    </template>
    <template v-else>
      <section class="main-section">
        <header>
          <h2>Insights</h2>
        </header>

        <div class="content">
          <ul class="stats" v-if="stats">

            <li>
              <div>
                <h4>Celebrated this month</h4>
                <span>{{$formatNumber(stats.newhires_celebrated)}}</span>
              </div>
            </li>

            <li>
              <div>
                <h4>Upcoming this month</h4>
                <span>{{$formatNumber(stats.newhires_upcoming)}}</span>
              </div>
            </li>

            <li>
              <div>
                <h4>Estimated point cost</h4>
                <span>{{$formatPoints(estimated_points)}}</span>
              </div>
            </li>

          </ul>
        </div>

      </section>

      <section class="main-section" v-if="upcoming">
        <header>
          <h2>Upcoming New Hires</h2>
        </header>

        <div class="content">

          <ul v-if="Object.keys(upcoming).length > 0" class="upcoming-dates">
            <li v-for="(month, monthIndex) in Object.keys(upcoming)" :key="`upcoming_month_${monthIndex}`">
              <h4>{{month}}</h4>
              <ul class="dates">
                <li v-for="(date, dateIndex) in upcoming[month]" :key="`upcoming_date_${dateIndex}`">
                  <user-card :user="date" :height="28">
                    <template #under-name>
                      <div class="date">
                        {{month}} {{$formatOrdinal(date.anniversary_day)}}
                      </div>
                    </template>
                  </user-card>
                </li>
              </ul>
            </li>
          </ul>
          <div class="empty-text" v-else>
            No new hires in the next 6 months.
          </div>

        </div>

      </section>
    </template>
  </main>
</template>

<script>
export default {
  name: 'NewHiresDashboard',
  computed: {
    award() {
      return _.find(this.$store.state.awards, {bot_type: 'NEW_HIRE'});
    },
    estimated_points() {
      return (this.stats.newhires_upcoming + this.stats.newhires_celebrated) * this.award.points;
    }
  },
  data() {
    return {
      upcoming: null,
      stats: null
    }
  },
  created() {
    this.populateUpcoming()
    this.populateStats()
  },
  methods: {
    async populateStats() {
      const resp = await this.$api.Awards.get_newhire_stats();
      this.stats = resp;
    },
    async populateUpcoming() {
      const resp = await this.$api.Awards.get_upcoming_newhires()
      //group by month
      this.upcoming = _.groupBy(resp, r => this.$DateInfo.months()[r.anniversary_month]);
    },
    year_diff(date) {
      const today = this.$DateTime.local();
      const year = (date.anniversary_month + 1) < today.month ? today.year + 1 : today.year;
      const diff = year - date.anniversary_year;
      return `${diff} year${diff === 1 ? '' : 's'}`;
    }
  }
}
</script>

<style lang="scss" scoped>
.empty-text {
  color: $muted-text;
}
.empty-state {
  margin: 25px 0 0;

  > h4 {
    font-weight: 700;
    font-size: 16px;
    color: $muted-text;
    margin: 0 0 5px;
  }

  > p {
    
  }
}
.stats {
  display: flex;
  padding: 0;
  margin: 0 -10px;

  > li {
    display: block;
    padding: 0 10px;

    > div {
      background: $light;
      padding: 15px;
      border-radius: 10px;

      > h4 {
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        color: $muted-text;
        margin: 0 0 5px;
      }

      > span {
        text-align: center;
        display: block;
      }
    }
  }
}

.upcoming-dates {
  display: flex;
  padding: 0;
  margin: 0 -10px;

  > li {
    display: block;
    flex: 0 0 auto;
    background: $light;
    border-radius: 10px;
    margin: 0 10px;
    padding: 15px;

    > h4 {
      font-weight: 700;
      font-size: 14px;
    }

    > ul {
      display: block;
      padding: 0;
      margin: 0;

      > li {
        display: block;

        + li {
          margin-top: 10px;
        }

        .user--card {
          .date {
            font-size: 12px;
            color: $muted-text;
            display: block;
            line-height: 12px;
            font-weight: 700;
            margin: 2px 0 0;
          }
        }
      }
    }
  }
}
</style>