<template>
  <main class="app-view billing billing-dashboard">
    <header>
      <div class="title">
        <h1>Billing</h1>
      </div>
    </header>

    <div class="alert alert-primary locked-feature-alert" v-if="locked_feature">
      <strong>Time to upgrade!</strong> That feature is only available on HiThrive Pro, take a look at your upgrade options below.
    </div>

    <section class="main-section">
      <header>
        <h2>Payment Method</h2>
      </header>

      <div class="content">
        <payment-method v-model="payment_method" :key="payment_method_component_key" />
      </div>
    </section>

    <section class="main-section">
      <header>
        <h2>Subscription</h2>
      </header>

      <div class="content">

        <plan-selector @refresh="handleRefresh" :paymentMethod="payment_method" />

      </div>
    </section>
  </main>
</template>

<script>
import PlanSelector from '@/components/Billing/PlanSelector.vue';

export default {
  components: {
    PlanSelector
  },
  computed: {
    team() {
      return this.$store.state.team;
    },
    locked_feature() {
      return this.$route.params.locked_feature === true;
    }
  },
  data() {
    return {
      payment_method: null,
      payment_method_component_key: `pmcomp_0`
    }
  },
  created() {
    
  },
  methods: {
    handleRefresh() {
      this.payment_method_component_key = `pmcomp_${this.$rand()}`;
      this.$store.dispatch('populateUser')
    }
  }
}
</script>

<style lang="scss" scoped>
.locked-feature-alert {
  margin-top: 25px;
}
</style>