<template>
  <div id="app">
    <div class="app-loader" v-if="app_loading">
      <img src="@/assets/hithrive-logo-dark.svg" height="18" />

      <loading-indicator />
    </div>

    <div class="session-expired" v-if="ses_exp">
      <div>
        <h4>Your session has expired.</h4>
        <p>For security purposes, your current session has expired. Please reload this page and login again.</p>

        <a href="#" @click.prevent="refreshPage" class="btn btn-dark btn-rounded btn-sm">Reload page</a>
      </div>
    </div>

    <router-view/>

    <div id="modal-stack" v-if="modal_stack.length > 0">
      <component
        v-for="(modal, index) in modal_stack"
        :key="`modal_${modal.id}`"
        :style="{'z-index': (modal_stack.length - index), '--modalIndex': index}"
        :is="modal.component"
        :class="['ht-modal', {'modalOffcanvas': modal.show === false}]"
        :data="modal.data"
        @done="modal.callback" />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    app_loading() {
      return this.$store.state.app_loading;
    },
    user() {
      return this.$store.state.user;
    }
  },
  data() {
    return {
      modal_stack: [],
      ses_exp: false
    }
  },
  watch: {
    user(val) {
      try {
        zE('webWidget', 'identify', {
          name: val.name,
          email: val.email
        });
        zE('webWidget', 'prefill', {
          name: {
            value: val.name,
            readOnly: true // optional
          },
          email: {
            value: val.email,
            readOnly: true // optional
          }
        });

        FS.setUserVars({
          hithrive_id: val.id,
          email: val.email,
          displayName: val.name
        })
      } catch(e) {
        console.warn('Unable to ID user to Zendesk')
      }
    },
    'modal_stack.length': (length) => {
      if(length > 0) {
        if(document.body.classList.contains('has-modal')) return;

        document.body.classList.add('has-modal')
      }
      else {
        document.body.classList.remove('has-modal')
      }
    }
  },
  created() {
    document.body.classList.remove('has-modal')
    this.$root.$on('openModal', this.openModal)

    this.$store.dispatch('populateGeoIP')

    window.addEventListener('scroll', function() {
      const {scrollY} = window;
      if(scrollY > 15) {
        if(document.body.classList.contains('is-scrolled')) return;

        document.body.classList.add('is-scrolled');
      }
      else {
        document.body.classList.remove('is-scrolled');
      }
    })

    this.initHealthCheck()
  },
  methods: {
    refreshPage() {
      window.location.reload();
    },
    initHealthCheck() {
      setInterval(async () => {
        if(!this.user) return;

        const res = await this.$api.User.ping()
          .catch((e) => {
            if(e.response.status === 400) {
              this.$store.dispatch('logout')
              this.ses_exp = true;
            }
          })
      }, 30000)
    },
    openModal(component_path, data, callback) {
      this.modal_stack.unshift({
        id: this.$rand(),
        show: false,
        component: () => ({
          component: import(`@/components/Modals/${component_path}`)
        }),
        data,
        callback: function() {
          this.modal_stack.shift();
          if( typeof callback === 'function' ) callback(...arguments);
        }.bind(this)
      })

      setTimeout( () => {
        this.modal_stack[0].show = true;
      }, 50 )
    }
  }
}
</script>

<style lang="scss">
.app-loader {
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  
}

.session-expired {
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba($purple, 0.5);
  backdrop-filter: blur(2px);
  z-index: 9999999;

  > div {
    background: $light;
    border-radius: 10px;
    padding: 35px;
    max-width: 512px;
    box-shadow: rgba($body-color, 0.2) 0 10px 20px;

    h4 {
      font-weight: 700;
      font-size: 20px;
    }
  }
}
</style>
