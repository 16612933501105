<template>
  <main class="app-view birthdays birthday-dashboard">
    <header>
      <div class="title">
        <h1>Birthdays</h1>
      </div>

      <div class="btns">
        <router-link
          class="btn btn-light"
          :to="{ name: 'reporting-activity', query: { type: 'birthdays' } }"
          ><i class="fas fa-chart-pie"></i> Latest activity</router-link
        >
      </div>
    </header>

    <template v-if="!award">
      <div class="empty-state">
        <h4>Setup your birthday award</h4>
        <p>
          Before you can celebrate birthdays, you'll need to
          <router-link :to="{ name: 'birthdays-settings' }"
            >configure the birthday award</router-link
          >.
        </p>
      </div>
    </template>
    <template v-else>
      <section class="main-section">
        <header>
          <h2>Insights</h2>
        </header>

        <div class="content">
          <ul class="stats" v-if="stats">
            <li>
              <div>
                <h4>Celebrated this month</h4>
                <span>{{ $formatNumber(stats.birthdays_celebrated) }}</span>
              </div>
            </li>

            <li>
              <div>
                <h4>Upcoming this month</h4>
                <span>{{ $formatNumber(stats.birthdays_upcoming) }}</span>
              </div>
            </li>

            <li>
              <div>
                <h4>Estimated point cost</h4>
                <span>{{ $formatPoints(estimated_points) }}</span>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <section class="main-section" v-if="upcoming">
        <header>
          <h2>Upcoming Birthdays</h2>
        </header>

        <div class="content settings">
          <ul v-if="Object.keys(upcoming).length > 0" class="upcoming-dates">
            <li
              v-for="(month, monthIndex) in Object.keys(upcoming).sort(
                (a, b) => a - b
              )"
              :key="`upcoming_month_${monthIndex}`"
            >
              <h4>{{ $DateInfo.months()[month] }}</h4>
              <ul class="dates">
                <li
                  v-for="(date, dateIndex) in upcoming[month]"
                  :key="`upcoming_date_${dateIndex}`"
                >
                  <user-card :user="date" :height="28">
                    <template #under-name>
                      <div class="date">
                        {{ $DateInfo.months()[month] }}
                        {{ $formatOrdinal(date.birthday_day) }}
                      </div>
                      <span v-if="date.metadata.birthday_public === 'PRIVATE'"
                        >(Private)</span
                      >
                    </template>
                  </user-card>
                </li>
              </ul>
            </li>
          </ul>

          <div class="empty-text" v-else>
            No birthdays in the next 6 months.
          </div>
        </div>
      </section>
    </template>
  </main>
</template>

<script>
export default {
  name: 'BirthdayDashboard',
  computed: {
    groups() {
      return this.$store.state.groups;
    },
    award() {
      return _.find(this.$store.state.awards, { bot_type: 'BIRTHDAY' });
    },
    estimated_points() {
      return (
        (this.stats.birthdays_upcoming + this.stats.birthdays_celebrated) *
        this.award.points
      );
    },
  },
  data() {
    return {
      upcoming: null,
      stats: null,
    };
  },
  created() {
    this.populateUpcoming();
    this.populateStats();
  },
  methods: {
    async populateUpcoming() {
      const resp = await this.$api.Awards.get_upcoming_birthdays();
      //group by month
      this.upcoming = _.groupBy(resp, (r) => r.birthday_month);
    },
    async populateStats() {
      const resp = await this.$api.Awards.get_birthday_stats();
      //group by month
      this.stats = resp;
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-text {
  color: $muted-text;
}
.empty-state {
  margin: 25px 0 0;

  > h4 {
    font-weight: 700;
    font-size: 16px;
    color: $muted-text;
    margin: 0 0 5px;
  }

  > p {
  }
}
.stats {
  display: flex;
  padding: 0;
  margin: 0 -10px;

  > li {
    display: block;
    padding: 0 10px;

    > div {
      background: $light;
      padding: 15px;
      border-radius: 10px;

      > h4 {
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        color: $muted-text;
        margin: 0 0 5px;
      }

      > span {
        text-align: center;
        display: block;
      }
    }
  }
}

.upcoming-dates {
  display: flex;
  padding: 0;
  margin: 0 -10px;

  > li {
    display: block;
    flex: 0 0 auto;
    background: $light;
    border-radius: 10px;
    margin: 0 10px;
    padding: 15px;

    > h4 {
      font-weight: 700;
      font-size: 14px;
    }

    > ul {
      display: block;
      padding: 0;
      margin: 0;

      > li {
        display: block;

        + li {
          margin-top: 10px;
        }

        .user--card {
          .date {
            font-size: 12px;
            color: $muted-text;
            display: block;
            line-height: 12px;
            font-weight: 700;
            margin: 2px 0 0;
          }
        }
      }
    }
  }
}
</style>
