<template>
  <div class="donation-redemption">
    <div class="top-info">
      <div class="thumbnail">
        <img :src="`https://hithrive-giving.herokuapp.com/nonprofits/${charity.ein}/logo`" @load="has_logo = true;" class="logotest" />
        <div class="thumb" :style="{'background-image': charity_logo}"></div>
      </div>

      <div class="status ml-auto">
        <span :class="[status_class]">{{status_text}}</span>
      </div>
      <div class="point-cost">
        <span>{{$formatPoints(points)}}</span>
      </div>
    </div>

    <div class="d-flex">
      <div class="title">
        <div class="timestamp">{{$formatDate(date)}}</div>
        <h4>Donation to {{charity.name}} <small>{{$formatCurrency(points/100, 'USD')}}</small></h4>
      </div>
      <div class="order-actions">
        <a class="btn btn-light btn-kinda-sm" :href="support_email">Get redemption help</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['redemption'],
  computed: {
    user() {
      return this.$store.state.user;
    },
    team() {
      return this.$store.state.team;
    },
    status() {
      return this.redemption[0].status;
    },
    status_text() {
      switch( this.status ) {
        case 'PENDING':
          return 'Processing';
        case 'PROCESSING':
          return 'Sending funds';
        case 'COMPLETE':
          return 'Funds disbursed';
      }
    },
    status_class() {
      return 'status-' + this.status.toLowerCase();
    },
    date() {
      return this.redemption[0].created_at;
    },
    points() {
      return this.redemption[0].points;
    },
    charity() {
      return this.redemption[0].redemption;
    },
    charity_logo() {
      if(this.has_logo === true) return `url('https://hithrive-giving.herokuapp.com/nonprofits/${this.charity.ein}/logo')`;
      return `url('${require('@/assets/logo-placeholder.jpg')}')`;
    },
    support_email() {
      return `mailto:help@hithrive.com?subject=Redemption%20Support%3A%20%23${this.team.id}-${this.user.id}-${this.redemption[0].id}&body=Please%20describe%20how%20we%20can%20assist%20you%20with%20your%20redemption%20below%3A%0D%0A%0D%0A`;
    }
  },
  data() {
    return {
      has_logo: false
    }
  }
}
</script>

<style lang="scss" scoped>
.logotest {
  width: 0;
  height: 0;
  position: absolute;
}

.thumb {
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;

  &:before {
    padding: 0 0 62%;
    display: block;
    content: '';
  }
}
</style>