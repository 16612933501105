<template>
  <main class="app-view awards awards-awards" v-if="award">
    <header>
      <div class="title">
        <ul class="crumbs">
          <li>
            <router-link :to="{name: 'awards'}">Awards</router-link>
          </li>
        </ul>
        <h1>{{award.name}}</h1>
      </div>

      <div class="btns">
        <router-link class="btn btn-light" :to="{name: 'reporting-activity', query: {type: 'awards', award_id: award.id}}"><i class="fas fa-chart-pie"></i> Latest activity</router-link>
      </div>
    </header>

    <section class="main-section">
      <header>
        <h2>All Awards</h2>

        <div class="btns">
          <button class="btn btn-secondary btn-circle" @click="newAward"><i class="fas fa-plus"></i></button>
        </div>
      </header>

    </section>
  </main>
</template>

<script>
export default {
  name: 'Awards',
  computed: {
    awards() {
      return this.$store.state.awards;
    }
  },
  data() {
    return {
      award: null
    }
  },
  created() {
    this.populateAward(this.$route.params.award_id)
  },
  methods: {
    async populateAward(award_id) {
      const _cloneAward = () => {
        this.award = _.cloneDeep(
          _.find(this.awards, (a) => a.id.toString() === award_id.toString())
        );
      };
      
      if(this.awards) {
        _cloneAward();
      }
      else {
        const unwatch = this.$watch('awards', (val) => {
          _cloneAward();
          unwatch();
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.content.award-list {
  .empty-state {
    position: relative;
    
    > span {
      display: block;
      background: $light;
      border-radius: 10px;
      padding: 15px;
      position: absolute;
      right: 70px;
      top: 30px;
    }

    &:after {
      content: '';
      display: block;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='81.18' height='52.88' viewBox='0 0 81.18 52.88'%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Elements'%3E%3Cpath d='M78.11,21.23c-1.9-1.5-3.81-3-5.63-4.56-.3-.25-3.8-3.45-3.89-3.07C67.49,18.5,66.9,22.52,64.2,27a47.05,47.05,0,0,1-9.91,11.38A63,63,0,0,1,30.05,51c-7.63,2.09-17,3.06-24.44-.4-1.39-.65-7.51-4.91-5-7,1.16-1,2.1.62,3,1.29A15.84,15.84,0,0,0,10,47.36c17,3.47,37.07-9.27,46.25-22.64A25.67,25.67,0,0,0,61,12.8c.1-1,.6-4.47-.24-4.9s-4.7,3.43-5.53,4c-3,2.14-6.59,5.27-10.11,6.37-1.59.5-3.18.48-3.88-1.26-.85-2.08,1.09-3.39,2.43-4.55,3.78-3.28,8.15-5.9,12.28-8.72,3.77-2.56,7-5.84,10.83-2A160.57,160.57,0,0,1,77.51,14.28c1,1.17,3.92,4,3.65,5.92-.39,2.78-2.35.14-3,1C76.42,19.89,78.15,21.18,78.11,21.23Z' style='fill:%23796f69'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      width: 81px;
      height: 52px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  > ul {
    display: flex;
    flex-wrap: wrap;

    padding: 0;
    margin: -10px -10px;

    > li {
      display: block;
      flex: 0 0 (100%/3);
      width: (100%/3);
      padding: 10px 10px;

      > a {
        height: 100%;

        h4 {
          font-size: 14px;
          font-weight: 700;
        }

        .point-allowance {
          font-size: 12px;
          color: $muted-text;
          margin: 0 0 10px;
          > span {
            display: inline-block;
            background: $green;
            color: #fff;
            border-radius: 3px;
            padding: 2px 4px;
            
            line-height: 1.2em;
          }
        }
      }
    }
  }

  .loading-placeholder {
    display: flex;
    margin: 0 -10px;
    > i {
      margin: 0 10px;
      flex: 1 0 auto;
      display: block;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      background-image: url("data:image/svg+xml,%3Csvg width='178' height='125' viewBox='0 0 178 125' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='178' height='125' rx='4' fill='%23E4E1DF'/%3E%3C/svg%3E%0A");
      
      &:before {
        content: '';
        display: block;
        padding: 0 0 70%;
      }
    }
  }
}
</style>