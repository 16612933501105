<template>
  <multiselect
    v-model="selected_channels"
    label="name"
    :track-by="'id'"
    placeholder="Search for channels..."
    :options="channels"
    :multiple="true"
    :searchable="true"
    :internal-search="false"
    :clear-on-select="true"
    :close-on-select="false"
    :hide-selected="true"
    @search-change="searchChannels"
  />
</template>

<script>
import {debounce} from 'lodash'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },
  props: {
    value: {
      type: null,
      required: false
    }
  },
  data() {
    return {
      selected_channels: [],
      channels: [],
      initial_channels: []
    }
  },
  watch: {
    selected_channels(val) {
      this.$nextTick( () => {
        this.$emit('input', this.selected_channels)
      } );
    }
  },
  mounted() {
    if(this.value !== null) {
      this.selected_channels = this.value;
      this.populateChannelInfo();
    }
    else {
      this.prepopulateChannels()
    }
  },
  methods: {
    async prepopulateChannels() {
      const resp = await this.$api.Slack.get_channels('');
      this.channels = resp;
      this.initial_channels = this.channels;
    },
    formatChannelLabel(option) {
      return `#${option.name}`;
    },
    fetchChannelOptions(query, loading) {
      if(query.length > 0) {
        this.searchChannels(loading, query, this);
      }
      else {
        this.channels = this.initial_channels || [];
      }
    },
    handleChannelSelect(opt) {
      console.log(opt)
      this.selected_channels = opt;
      this.$nextTick( () => {
        this.channels = [];
        //this.initial_channels = this.channels;
      } );
    },
    searchChannels: debounce(
      async function (query) {
        const resp = await this.$api.Slack.get_channels(query);
        this.channels = resp;
      },
      250
    ),
    async populateChannelInfo() {
      const resp = await this.$api.Slack.get_channel_by_id(this.selected_channels.join(','))
      this.channels = resp;
      this.selected_channels = resp;
      this.initial_channels = resp;
    },
  }
}
</script>