<template>
  <div class="award-card">
    <div class="header">
      <div class="users recognized">
        <template v-if="!award.award.is_bot">
          <user-card :user="award.created_by" :height="user_size" />

          <i class="fas fa-caret-right"></i>
        </template>

        <span class="for" v-for="(for_user, index) in award.for_users" :key="`award_for_${award.id}_${index}`">
          <user-card :user="for_user" :height="user_size" />
          <span class="separator" v-html="for_separator(index)"></span>
        </span>
      </div>

      <div class="timestamp">
        {{$timeAgo(award.created_at)}}
      </div>
    </div>

    <img :src="award.card_image" v-if="award.card_image" class="card-image" />

    <p v-if="!award.award.is_bot">
      <strong>{{award.created_by.name}}</strong> gave the <strong>{{award.award.name}}</strong> award to <strong>{{for_text}}</strong>
    </p>
    <p v-else>
      <strong>{{for_text}}</strong> earned the <strong>{{award.award.name}}</strong> award.
    </p>

    <p class="message" v-if="award.message">{{award.message}}</p>

    <slot name="bottom"></slot>

    <ul class="reactions" v-if="has_reactions">
      <li v-for="(reaction, index) in award.reactions" :key="`emoji_reaction_${award.id}_${index}`">
        <i>{{$emoji(`:${reaction.name}:`)}}</i><span class="count">{{$formatNumber(reaction.count)}}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'award-card',
  props: ['data', 'full'],
  computed: {
    has_reactions() {
      return this.award.reactions.length > 0;
    },
    is_full() {
      if( typeof this.full != 'undefined' ) return true;

      return false;
    },
    user_size() {
      return 20;
    },
    for_text() {
      const forMentions = _.map(this.award.for_users, f => f.name);
      const forText = `${forMentions.length > 1 ? `${forMentions.slice(0, -1).join(', ')} and ${forMentions[ forMentions.length - 1 ]}` : forMentions[0]}`;

      return forText;
    }
  },
  data() {
    return {
      award: null
    }
  },
  created() {
    this.award = this.data;
  },
  methods: {
    for_separator(index) {
      if( (index + 2) < this.award.for_users.length ) return ',&nbsp;';
      else if( (index + 2) == this.award.for_users.length ) return '&nbsp;and&nbsp;';

      return '';
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
