<template>
  <div>
    <img src="@/assets/hithrive-logo-dark.svg" height="22" class="logo" />

    <loading-indicator />
  </div>
</template>

<script>
export default {
  name: 'SlackLogin',
  components: {},
  computed: {},
  data() {
    return {};
  },
  created() {
    const code = this.$route.query.code;
    const state = this.$route.query.state;

    this.handleSlackAuth(code, state);
  },
  methods: {
    async handleSlackAuth(code, state) {
      const hasMigrateQS = Object.keys(this.$route.query).includes('migrate');
      const redirect = localStorage.getItem('hithrive_continue_uri')
        ? decodeURIComponent(localStorage.getItem('hithrive_continue_uri'))
        : '/';

      const saved_state = localStorage.getItem('hithrive_login_state');
      if ((!saved_state || saved_state !== state) && !hasMigrateQS) {
        this.$toast.error('Something went wrong, please try again.');
        this.$router.replace({
          name: 'login',
          query: {
            redirect,
          },
        });
        return;
      }

      //exchange for jwt
      try {
        const resp = await this.$api.Auth.exchange_slack_token(code, {
          migrate: hasMigrateQS,
        });
        if (hasMigrateQS) {
          console.log('post message');
          window.opener.postMessage(
            {
              event: 'legacy_auth',
              token: resp.token,
            },
            'https://app.hithrive.com'
          );
          return;
        }
        this.$store.commit('user_token', resp.token);
        await this.$store.dispatch('populateUser');
        this.$router.replace(redirect);
      } catch (e) {
        console.error(e);
        this.$toast.error(
          'We had trouble logging in with Slack, please try again.'
        );
        this.$router.replace({
          name: 'login',
          params: {
            error: `The workspace you tried logging into isn't using HiThrive, you may need to install the app.`,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
img.logo {
  display: block;
  margin: 0 auto 25px;
}

img.doodle {
  width: 100%;
  height: auto;
  max-width: 242px;
  margin: 50px auto 0;
  display: block;
}

.signin-with-slack {
  margin: 50px auto 0;
  display: block;
  width: 172px;
  height: 40px;
}

h1 {
  font-weight: 700;
  margin: 25px 0 0;
  font-size: 22px;

  > small {
    font-size: 16px;
    display: block;
    margin: 5px 0 0;
    color: $muted-text;
  }
}
</style>
