<template>
  <main class="app-view settings settings-integrations">
    <header>
      <div class="title">
        <ul class="crumbs">
          <li>
            <router-link :to="{name: 'settings'}">Settings</router-link>
          </li>
        </ul>
        <h1>Integrations</h1>
      </div>
    </header>

    <section class="main-section">
      <header>
        <h2>Data Sync Integrations</h2>
      </header>

      <div class="content integrations">
        <ul>
          <li>
            <a href="#" class="content-card" @click.prevent="openSettingsModal('BambooHR')">
              <img src="@/assets/bamboo-hr.svg" alt="Bamboo HR Integration">
              <h4>BambooHR</h4>
              <p>One-way data sync</p>
            </a>
          </li>
          <li>
            <a href="#" class="content-card" @click.prevent="openSettingsModal('Dayforce')">
              <img src="@/assets/dayforce.png" alt="Ceridian Dayforce Integration">
              <h4>Ceridian Dayforce</h4>
              <p>One-way data sync</p>
            </a>
          </li>
          <li>
            <a href="#" class="content-card" @click.prevent="openSettingsModal('Gusto')">
              <img src="@/assets/gusto-icon.svg" alt="Gusto Integration">
              <h4>Gusto</h4>
              <p>One-way data sync</p>
            </a>
          </li>
          <li>
            <a href="#" class="content-card" @click.prevent="openSettingsModal('HiBob')">
              <img src="@/assets/hibob-icon.svg" alt="HiBob Integration">
              <h4>HiBob</h4>
              <p>One-way data sync</p>
            </a>
          </li>
          <li>
            <a href="#" class="content-card" @click.prevent="openSettingsModal('FTP')">
              <img src="@/assets/ftp-upload-icon.svg" alt="FTP Upload">
              <h4>Inbound FTP</h4>
              <p>One-way data sync</p>
            </a>
          </li>
          <li>
            <a href="#" class="content-card" @click.prevent="openSettingsModal('Namely')">
              <img src="@/assets/namely-icon.svg" alt="Namely Integration">
              <h4>Namely</h4>
              <p>One-way data sync</p>
            </a>
          </li>
          <li>
            <a href="#" class="content-card" @click.prevent="openSettingsModal('Paylocity')">
              <img src="@/assets/paylocity-icon.svg" alt="Paylocity Integration">
              <h4>Paylocity</h4>
              <p>One-way data sync</p>
            </a>
          </li>
          <li>
            <a href="#" class="content-card" @click.prevent="openSettingsModal('SageHR')">
              <img src="@/assets/sagehr-icon.png" alt="SageHR Integration">
              <h4>Sage HR</h4>
              <p>One-way data sync</p>
            </a>
          </li>
          <li>
            <a href="#" class="content-card" @click.prevent="openSettingsModal('SagePeople')">
              <img src="@/assets/sagepeople-icon.png" alt="Sage People Integration">
              <h4>Sage People</h4>
              <p>One-way data sync</p>
            </a>
          </li>
          <li>
            <a href="#" class="content-card" @click.prevent="openSettingsModal('TriNet')">
              <img src="@/assets/trinet-logo.svg" alt="TriNet Integration">
              <h4>TriNet</h4>
              <p>One-way data sync</p>
            </a>
          </li>
          <li>
            <a href="#" class="content-card" @click.prevent="openSettingsModal('UltiPro')">
              <img src="@/assets/ukg.svg" alt="UltiPro Integration">
              <h4>UKG (UltiPro)</h4>
              <p>One-way data sync</p>
            </a>
          </li>
          <li>
            <a href="#" class="content-card" @click.prevent="openSettingsModal('Workday')">
              <img src="@/assets/workday.svg" alt="Workday Integration">
              <h4>Workday</h4>
              <p>One-way data sync</p>
            </a>
          </li>
          <li>
            <a href="#" class="content-card" @click.prevent="openSettingsModal('Zenefits')">
              <img src="@/assets/zenefits.svg" alt="Zenefits Integration">
              <h4>Zenefits</h4>
              <p>One-way data sync</p>
            </a>
          </li>
        </ul>
      </div>
    </section>

    <section class="main-section">
      <header>
        <h2>Custom Catalog Integrations</h2>
      </header>

      <div class="content integrations">
        <ul>
          <li>
            <a href="#" class="content-card" @click.prevent="openSettingsModal('Printful')">
              <img src="@/assets/printful.svg" alt="Printful Integration">
              <h4>Printful</h4>
              <p>Custom reward catalog</p>
            </a>
          </li>
          <li>
            <a href="#" class="content-card" @click.prevent="openSettingsModal('Sendoso')">
              <img src="@/assets/sendoso.svg" alt="Sendoso Integration">
              <h4>Sendoso</h4>
              <p>Custom reward catalog</p>
            </a>
          </li>
          <li v-if="show_beta_features">
            <a href="#" class="content-card" @click.prevent="openSettingsModal('SendosoSandbox')">
              <img src="@/assets/sendoso.svg" alt="Sendoso Integration">
              <h4>Sendoso (Sandbox)</h4>
              <p>Custom reward catalog</p>
            </a>
          </li>
        </ul>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  computed: {
    show_beta_features() {
      return this.$store.state.team.metadata.show_beta_features === true;
    }
  },
  mounted() {
    if(this.$route.params.open) {
      this.openSettingsModal(this.$route.params.open)
    }
  },
  methods: {
    openSettingsModal(integration) {
      this.$root.$emit(
        'openModal',
        `Integrations/${integration}`,
        {},
        () => {}
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.integrations {
  ul {
    display: flex;
    flex-wrap: wrap;

    padding: 0;
    margin: -10px -10px;

    > li {
      display: block;
      flex: 0 0 (100%/3);
      width: (100%/3);
      padding: 10px 10px;

      > a {
        height: 100%;
        display: flex;
        flex-direction: column;

        align-items: flex-start;

        img {
          display: block;
          max-height: 40px;
        }

        h4 {
          font-weight: 700;
          margin: 10px 0 0;
          font-size: 14px;
        }
        
        p {
          margin: 0;
          font-size: 12px;
          color: $muted-text;
        }
      }
    }
  }
}
</style>