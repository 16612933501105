<template>
  <div class="user-picker">
    <ul v-if="users.length > 0">
      <li v-for="(user, index) in users" :key="`user-picker-${index}-${user.id}`" :class="{'can-delete': !requiredUsersIds.includes(user.id)}" @click.prevent="removeUser(index)">
        <user-card :user="user" :height="22" />
        <slot name="afterName" v-bind="user"></slot>
      </li>
    </ul>
    
    <div class="search-wrap" v-if="canAdd === true">
      <v-select @search="fetchUserOptions" :value="autocomplete_value" :selectable="canSelect" label="name" @option:selected="handleUserSelect" :placeholder="placeholder" :clearable="false" :filterable="false" :options="user_results" :reduce="opt => opt.id" :append-to-body="true">
        <template #open-indicator><span></span></template>

        <template #option="option">
          <user-card :user="option" :height="26" />
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';

export default {
  props: {
    value: Array,
    placeholder: {
      type: String,
      default: 'search for a user...'
    },
    canAdd: {
      type: Boolean,
      default: true
    },
    group: null,
    segment: null,
    requiredUsers: {
      type: Array,
      default() { return [] }
    }
  },
  computed: {
    requiredUsersIds() {
      return _.map(this.requiredUsers, 'id');
    }
  },
  data() {
    return {
      users: [],
      user_results: [],
      autocomplete_value: null
    }
  },
  watch: {
    value(val) {
      this.users = val;
    },
    users(val) {
      this.$emit('input', val);
    }
  },
  mounted() {
    this.users = this.value || [];
  },
  methods: {
    removeUser(index) {
      if(this.requiredUsersIds.includes(this.users[index].id)) return;
      
      this.users.splice(index, 1);
    },
    canSelect(opt) {
      return _.findIndex(this.users, {id: opt.id}) === -1;
    },
    handleUserSelect(user) {
      if(_.findIndex(this.users, {id: user.id}) === -1) this.users.push(user);

      this.user_results = [];
      this.autocomplete_value = null;
    },
    fetchUserOptions(query, loading) {
      if(query.length > 0) {
        this.searchUsers(loading, query, this);
      }
      else {
        this.user_results = [];
      }
    },
    searchUsers: debounce(
      async (loading, search, vm) => {
        loading(true);

        const resp = await vm.$api.Team.search_users(search, vm.group, vm.segment);
        vm.user_results = resp;

        loading(false);
      },
      250
    ),
  }
}
</script>