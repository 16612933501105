var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reward-view container-fluid",attrs:{"id":"gift-cards"}},[_c('main',[_c('header',[_c('div',{staticClass:"title"},[_c('ul',{staticClass:"crumbs"},[_c('li',[_c('router-link',{attrs:{"to":{name: 'rewards_home'}}},[_vm._v("Rewards")])],1)]),_vm._m(0)]),_c('form',{staticClass:"search-wrap",on:{"submit":function($event){$event.preventDefault();return _vm.searchAmazon.apply(null, arguments)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"type and press enter to search..."},domProps:{"value":(_vm.query)},on:{"input":function($event){if($event.target.composing)return;_vm.query=$event.target.value}}}),_vm._m(1)])]),_c('section',{staticClass:"main-section"},[_vm._m(2),_c('featured-amazon',{attrs:{"category":"HOME_KITCHEN"}})],1),_c('section',{staticClass:"main-section"},[_vm._m(3),_c('featured-amazon',{attrs:{"category":"WOMENS_CLOTHING"}}),_c('featured-amazon',{attrs:{"category":"MENS_CLOTHING"}})],1),_c('section',{staticClass:"main-section"},[_vm._m(4),_c('featured-amazon',{attrs:{"category":"PETS"}})],1),_c('section',{staticClass:"main-section"},[_vm._m(5),_c('featured-amazon',{attrs:{"category":"WOMENS_SHOES"}}),_c('featured-amazon',{attrs:{"category":"MENS_SHOES"}})],1),_c('section',{staticClass:"main-section"},[_vm._m(6),_c('featured-amazon',{attrs:{"category":"ELECTRONICS"}})],1),_c('section',{staticClass:"main-section"},[_vm._m(7),_c('featured-amazon',{attrs:{"category":"TOYS_GAMES"}})],1),_c('section',{staticClass:"main-section"},[_vm._m(8),_c('featured-amazon',{attrs:{"category":"BOOKS"}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',[_c('img',{attrs:{"src":require("@/assets/amazon-logo.svg") + "#amazon-color","height":"22"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn btn-sm btn-light btn-circle",attrs:{"type":"submit"}},[_c('i',{staticClass:"fas fa-search"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('header',[_c('h2',[_vm._v("Trending Home & Kitchen")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('header',[_c('h2',[_vm._v("Trending Clothing")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('header',[_c('h2',[_vm._v("Trending Pet Supplies")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('header',[_c('h2',[_vm._v("Trending Shoes")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('header',[_c('h2',[_vm._v("Trending Electronics")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('header',[_c('h2',[_vm._v("Trending Toys & Games")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('header',[_c('h2',[_vm._v("Trending Books")])])
}]

export { render, staticRenderFns }