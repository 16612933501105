<template>
  <div id="trial-ended">
    <header>
      <img src="@/assets/hithrive-logo-dark.svg" height="20" />
    </header>

    <section class="intro">
      <h1>Sorry, your team is currently inactive.</h1>
      <p>Unfortunately, HiThrive has been disabled for your team, so you won't be able to access your account. Please contact an administrator for help.</p>
    </section>
  </div>
</template>

<script>
export default {
  computed: {
    team() {
      return this.$store.state.team;
    }
  },
  mounted() {
    if(this.team.plan_type !== -2) {
      this.$router.replace({
        name: 'recognition'
      })
    }
  },
  methods: {
   
  }
}
</script>

<style lang="scss" 
PlanSelectorscoped>
#trial-ended {
  max-width: 600px;
  margin: 35px auto;

  header {
    padding-bottom: 50px;
  }

  h1 {
    font-weight: 700;
    font-size: 24px;
  }

  .choose-plan {
    margin: 50px 0 0;
  }
}
</style>