<template>
  <router-view />
</template>

<script>
export default {
  created() {
    this.populateAwards();
  },
  methods: {
    async populateAwards() {
      this.$store.dispatch('populateAwards')
    }
  }
}
</script>