<template>
  <main class="app-view rewards rewards-dashboard">
    <header>
      <div class="title">
        <ul class="crumbs">
          <li>
            <router-link :to="{name: 'rewards'}">Rewards</router-link>
          </li>
        </ul>
        <h1>Custom Catalog</h1>
      </div>
    </header>

    <section class="main-section" id="catalog-items">
      <header>
        <h2>Current Items</h2>

        <div class="btns">
          <button class="btn btn-secondary btn-circle" @click.prevent="addItem"><i class="fas fa-plus"></i></button>
        </div>
      </header>

      <div class="content">
        <template v-if="items !== null">
          <ul>
            <li v-for="item in items" :key="`customcatalog_${item.id}`">
              <a href="#" @click.prevent="editItem(item)">
                <div class="thumbnail" :style="{'background-image': `url('${$catalogThumbnail(item.images[0])}')`}"></div>
                <h4>{{item.name}}</h4>
                <div class="point-cost"><span>{{$formatPoints(item.point_cost)}}</span></div>
              </a>
            </li>
          </ul>
        </template>
        <div class="loading-placeholder" v-else><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i></div>
      </div>

    </section>

  </main>
</template>

<script>
export default {
  name: 'RewardsCustomCatalog',
  computed: {
    team() {
      return this.$store.state.team;
    },
    loading() {
      return this.$store.state.loading;
    },
  },
  data() {
    return {
      items: null
    }
  },
  validations: {
  },
  mounted() {
    this.populateItems()
  },
  watch: {
    
  },
  methods: {
    async populateItems() {
      const resp = await this.$api.Rewards.get_custom_rewards()
      this.items = resp;
    },
    addItem() {
      this.$root.$emit(
        'openModal',
        'Rewards/AddCustomItem',
        {
          
        },
        (result) => {
          if(result === 'ADDED_ITEM') {
            this.populateItems()
          }
        }
      )
    },
    editItem(item) {
      this.$root.$emit(
        'openModal',
        'Rewards/EditCustomItem',
        _.cloneDeep(item),
        (result) => {
          if(result === 'UPDATED_ITEM') {
            this.populateItems()
          }
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
#catalog-items {
  >.content {
    > ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px 0;
      padding: 0;

      > li {
        flex: 0 0 33.333%;
        width: 33.333%;
        @include media-breakpoint-up(md) {
          flex: 0 0 25%;
          width: 25%;
        }
        display: block;
        padding: 15px 10px;
        margin: 0;
        text-align: center;

        > a {
          display: block;
          text-decoration: none;
          color: $body-color;

          > .point-cost {
            margin: 3px 0 0;
            > span {
              display: inline-block;
              background: $green;
              color: #fff;
              border-radius: 3px;
              line-height: 14px;
              font-size: 12px;
              padding: 2px 5px;
            }
          }

          > .thumbnail {
            display: block;
            padding: 0 0 100%;
            background-size: cover;
            background-position: center center;
            border-radius: 6px;
            overflow: hidden;
            box-shadow: rgba($body-color, 0.05) 0 3px 5px;
            transition: box-shadow 0.2s $curve, transform 0.2s $curve;
            background-color: #fff;
          }

          > h4 {
            //font-weight: 700;
            font-size: 14px;
            margin: 10px 0 0;
          }

          &:hover {
            > .thumbnail {
              box-shadow: rgba($body-color, 0.08) 0 8px 15px;
              transform: translateY(-2px);
            }
          }
        }
      }
    }
  }

  .loading-placeholder {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    > i {
      margin: 10px 10px;
      flex: 0 0 calc(25% - 20px);
      display: block;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center center;
      background-image: url("data:image/svg+xml,%3Csvg width='178' height='125' preserveAspectRatio='none' viewBox='0 0 178 125' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='178' height='125' rx='4' fill='%23E4E1DF'/%3E%3C/svg%3E%0A");
      
      &:before {
        content: '';
        display: block;
        padding: 0 0 100%;
      }
    }
  }
}
</style>