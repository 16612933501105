<template>
  <main class="app-view recognition recognition-dashboard">
    <header>
      <div class="title">
        <h1>Recognition</h1>
      </div>
    </header>

    <section class="main-section">
      <header>
        <h2>All Channels</h2>
        <div class="btns">
          <button class="btn btn-secondary btn-circle" @click="addChannel" v-if="team.metadata.use_all_channels !== true"><i class="fas fa-plus"></i></button>
        </div>
      </header>

      <div class="content groups">
        <ul v-if="groups && groups.length > 0">
          <li v-for="group in groups" :key="`group_${group.id}`">
            <router-link :to="{name: 'recognition-group-edit', params: {group_id: group.id}}" class="content-card">
              <h4>{{group.title}}</h4>

              <div class="point-allowance">
                <strong>Shoutouts:</strong> <span>{{$formatPoints(group.shoutout_allowance)}}</span> per {{team.allowance_interval}}
              </div>
              <div class="point-allowance" v-if="team.plan_type === 1">
                <strong>Awards:</strong> <span>{{$formatPoints(group.point_allowance)}}</span> per {{team.allowance_interval}}
              </div>

              <div class="user-count">
                <ul v-if="group.users.length > 0">
                  <li v-for="user in group.users" :key="`groupuserpreview_${user.id}`">
                    <user-profile-picture :user="user" height="22" />
                  </li>
                </ul>
                <span>{{$formatNumber(group.user_count)}} user{{group.user_count == 1 ? '' : 's'}}</span>
              </div>
            </router-link>
          </li>
        </ul>
        <div class="empty-state" v-else-if="groups && groups.length == 0">
          <span>Get started by adding a new recognition channel.</span>
        </div>
        <div class="loading-placeholder" v-else-if="!groups"><i></i><i></i><i></i></div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: 'RecognitionDashboard',
  computed: {
    groups() {
      return this.$store.state.groups;
    },
    team() {
      return this.$store.state.team;
    }
  },
  data() {
    return {
      
    }
  },
  created() {
    
  },
  methods: {
    addChannel() {
      this.$root.$emit(
        'openModal',
        'Recognition/NewGroup',
        {},
        (result) => {

        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.content.groups {
  .empty-state {
    position: relative;
    
    > span {
      display: block;
      background: $light;
      border-radius: 10px;
      padding: 15px;
      position: absolute;
      right: 70px;
      top: 30px;
    }

    &:after {
      content: '';
      display: block;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='81.18' height='52.88' viewBox='0 0 81.18 52.88'%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Elements'%3E%3Cpath d='M78.11,21.23c-1.9-1.5-3.81-3-5.63-4.56-.3-.25-3.8-3.45-3.89-3.07C67.49,18.5,66.9,22.52,64.2,27a47.05,47.05,0,0,1-9.91,11.38A63,63,0,0,1,30.05,51c-7.63,2.09-17,3.06-24.44-.4-1.39-.65-7.51-4.91-5-7,1.16-1,2.1.62,3,1.29A15.84,15.84,0,0,0,10,47.36c17,3.47,37.07-9.27,46.25-22.64A25.67,25.67,0,0,0,61,12.8c.1-1,.6-4.47-.24-4.9s-4.7,3.43-5.53,4c-3,2.14-6.59,5.27-10.11,6.37-1.59.5-3.18.48-3.88-1.26-.85-2.08,1.09-3.39,2.43-4.55,3.78-3.28,8.15-5.9,12.28-8.72,3.77-2.56,7-5.84,10.83-2A160.57,160.57,0,0,1,77.51,14.28c1,1.17,3.92,4,3.65,5.92-.39,2.78-2.35.14-3,1C76.42,19.89,78.15,21.18,78.11,21.23Z' style='fill:%23796f69'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      width: 81px;
      height: 52px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  > ul {
    display: flex;
    flex-wrap: wrap;

    padding: 0;
    margin: -10px -10px;

    > li {
      display: block;
      flex: 0 0 (100%/3);
      width: (100%/3);
      padding: 10px 10px;

      > a {
        height: 100%;
        display: flex;
        flex-direction: column;

        h4 {
          font-size: 14px;
          font-weight: 700;
        }

        .point-allowance {
          font-size: 12px;
          color: $muted-text;
          margin: 0 0 10px;
          > span {
            display: inline-block;
            background: $green;
            color: #fff;
            border-radius: 3px;
            padding: 2px 4px;
            
            line-height: 1.2em;
          }
        }

        .user-count {
          display: flex;
          align-items: center;
          justify-self: flex-end;
          height: 26px;
          margin: auto 0 0;

          > ul {
            display: flex;
            flex: 0 0 auto;
            padding: 0 5px;
            margin: 0 5px 0 0;

            > li {
              display: block;
              margin: 0 0 0 -5px;
              
              ::v-deep >.user--profile-picture  {
                box-shadow: #fff 0 0 0 2px;
              }
            }
          }

          > span {
            display: block;
            font-size: 12px;
            margin: 0 0;
            color: $muted-text;
          }
        }
      }
    }
  }

  .loading-placeholder {
    display: flex;
    margin: 0 -10px;
    > i {
      margin: 0 10px;
      flex: 1 0 auto;
      display: block;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      background-image: url("data:image/svg+xml,%3Csvg width='178' height='125' viewBox='0 0 178 125' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='178' height='125' rx='4' fill='%23E4E1DF'/%3E%3C/svg%3E%0A");
      
      &:before {
        content: '';
        display: block;
        padding: 0 0 70%;
      }
    }
  }
}
</style>