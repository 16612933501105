<template>
  <main class="app-view reporting reporting-dashboard">
    <header>
      <div class="title">
        <h1>
          Insights
          <span>
            for
            <div class="dropdown" v-if="me.is_admin">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown"><span>{{group_filter_label}}</span></a>
              <div class="dropdown-menu">
                <a class="dropdown-item" href="#" @click.prevent="group_filter = 'TEAM';">Entire team</a>
                <a v-for="group in groups" :key="`activity_group_filter_${group.id}`" @click.prevent="group_filter = group.id;" class="dropdown-item" href="#">{{group.title}}</a>
              </div>
            </div>
            <div class="dropdown" v-else>
              <a href="#" class="dropdown-toggle" data-toggle="dropdown"><span>{{segment_filter_label}}</span></a>
              <div class="dropdown-menu">
                <a class="dropdown-item" href="#" @click.prevent="segment_filter = 'ALL';">All teams</a>
                <a v-for="segment in segments" :key="`activity_segment_filter_${segment.id}`" @click.prevent="segment_filter = segment.id;" class="dropdown-item" href="#">{{segment.name}}</a>
              </div>
            </div>
          </span>
        </h1>
      </div>

      <div class="filters">
        <date-range-picker
          ref="datePicker"
          v-model="date_range"
          :max-date="$DateTime.local().toJSDate()"
          opens="left"
          :ranges="date_options"
        >
        </date-range-picker>
      </div>
    </header>

    <section class="main-section">
      <div class="content widgets" v-if="stat_data">
        <div class="widget-grid" ref="widget-grid">
          <widget class="col-7" widget="MonthlyActivity" title="Activity by Month" :data="stat_data.monthly_activity.main_stats" @loaded="renderLayout" />
          <widget class="col-5" widget="Engagement" title="Team Engagement" :data="{engagement: stat_data.engagement, shoutouts: stat_data.shoutouts_given, awards: stat_data.awards_given}" @loaded="renderLayout" />
          <widget class="col-6" widget="TopPoints" title="Top Point Earners" :data="stat_data.top_point_earners" @loaded="renderLayout" />
          <widget class="col-6" widget="TopPoints" title="Top Point Givers" :data="stat_data.top_point_givers" @loaded="renderLayout" />
          <widget class="col-6" widget="TopValues" title="Top Values" :data="stat_data.top_values" @loaded="renderLayout" />
          <widget class="col-6" widget="SegmentActivity" title="Segment Activity" v-if="segments.length > 0" :data="segment_activity" @loaded="renderLayout" />
          
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Widget from '@/components/Reporting/Widget';
import Muuri from 'muuri'
import DateRangePicker from 'vue2-daterange-picker'
import {debounce} from 'lodash'

export default {
  name: 'ReportingDashboard',
  components: {
    Widget,
    DateRangePicker
  },
  computed: {
    me() {
      return this.$store.state.user;
    },
    groups() {
      return this.$store.state.groups;
    },
    segments() {
      return this.$store.state.segments;
    },
    group_filter_label() {
      if(this.group_filter === 'TEAM') return 'entire team';
      return _.find(this.groups, {id: this.group_filter}).title;
    },
    segment_filter_label() {
      if(this.segment_filter === 'ALL') return 'all of your teams';
      return _.find(this.segments, {id: this.segment_filter}).name;
    }
  },
  watch: {
    stat_data(val) {
      if(val === null) this.grid = null;
      else this.$nextTick( this.renderLayout );
    },
    date_range(val) {
      this.populateStatData()
    },
    group_filter(val) {
      this.populateStatData()
    },
    segment_filter(val) {
      this.populateStatData()
    }
  },
  data() {
    return {
      group_filter: 'TEAM',
      segment_filter: 'ALL',
      stat_data: null,
      connection_data: null,
      segment_activity: null,
      grid: null,
      date_options: {
        'Last 7 days': [ this.$today.minus({days: 7}).toJSDate(), this.$today.toJSDate() ],
        'This week': [ this.$today.startOf('week').toJSDate(), this.$today.endOf('week').toJSDate() ],
        'Last week': [ this.$today.minus({days: 7}).startOf('week').toJSDate(), this.$today.minus({days: 7}).endOf('week').toJSDate() ],
        'This month': [ this.$today.startOf('month').toJSDate(), this.$today.toJSDate() ],
        'Last 30 days': [ this.$today.minus({days: 30}).toJSDate(), this.$today.toJSDate() ],
        'Last month': [ this.$today.minus({months: 1}).startOf('month').toJSDate(), this.$today.minus({months: 1}).endOf('month').toJSDate() ],
        'Last 6 months': [ this.$today.minus({months: 6}).startOf('month').toJSDate(), this.$today.toJSDate() ]
      },
      date_range: {
        startDate: this.$today.startOf('month').toJSDate(),
        endDate: this.$today.toJSDate()
      }
    }
  },
  created() {
    this.populateStatData();
  },
  methods: {
    populateStatData: debounce( async function() {
      this.stat_data = null;

      let _loadStats;
      if(this.me.is_admin) {
        _loadStats = this.$api.Reporting.get_stats(
        this.date_range.startDate,
        this.date_range.endDate,
        this.group_filter,
        this.segment_filter
      )
        .then(d => {
          this.stat_data = d;
        })
      }
      else {
        _loadStats = this.$api.Reporting.get_dr_stats(
        this.date_range.startDate,
        this.date_range.endDate,
      )
        .then(d => {
          this.stat_data = d;
        })
      }

      const _loadConnections = this.$api.Reporting.get_connections(
        this.date_range.startDate,
        this.date_range.endDate,
        this.group_filter
      )
        .then(d => {
          this.connection_data = d;
        })

      const _loadSegmentActivity = this.$api.Reporting.get_segment_activity(
        this.date_range.startDate,
        this.date_range.endDate
      )
      .then(d => {
        this.segment_activity = d;
      })

      const promises = [
        _loadStats,
        //_loadConnections
      ];

      if(this.segments.length > 0) {
        promises.push(_loadSegmentActivity)
      }

      await Promise.all(promises)
    }, 250 ),
    renderGrid() {
      this.grid = new Muuri('.widget-grid', {
        layout: {
          fillGaps: true,
        },
        layoutDuration: 0,
        layoutOnResize: 0
      });
    },
    renderLayout() {
      if(this.grid === null) return this.renderGrid();

      this.grid.refreshItems();
      this.grid.layout(true);
    }
  }
}
</script>

<style lang="scss" scoped>
.app-view.reporting {
  > header {
    > .filters {
      margin: 0 0 0 auto;
    }
    > .title {
      > h1 {
        > span {
          display: inline-block;
          font-size: 0.7em;
          color: $muted-text;

          > .dropdown {
            display: inline-block;

            > a {
              text-decoration: none;
              color: $muted-text;

              > span {
                border-bottom: 1px $muted-text dashed;
              }
            }
          }
        }
      }
    }
  }
}
.content.widgets {
  padding: 20px 0 0;

  > .widget-grid {
    position: relative;
    margin: 0 -15px;

    > .reporting-widget {
      display: block;
      position: absolute;
      z-index: 1;
      margin: 15px 0;
    }
  }
}
</style>