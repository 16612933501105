<template>
  <main class="app-view users users-home">
    <header>
      <div class="title">
        <h1>Users</h1>
      </div>

      <span class="sync-indicator">
        Synced with Slack
      </span>
      <div class="btns">
        <button class="btn btn-light" type="button" @click="openFieldImporter"><i class="fas fa-file-import"></i>Import user fields</button>
      </div>
    </header>

    <section class="main-section list-section">
      <header>
        <div class="search-filters">
          <div class="search-wrap">
            <input type="text" placeholder="type to search by name..." v-model="query" />
          </div>

          <div class="filter-wrap">
            <label>Filter by</label>
            <ul>
              <li>
                <user-groups-filter v-model="filters.user_groups" :label="filter_label('groups')" />
              </li>
              <li>
                <user-segment-filter v-model="filters.user_segments" :label="filter_label('segments')" />
              </li>
              <li>
                <user-type-filter v-model="filters.user_types" :label="filter_label('user_types')" />
              </li>
            </ul>
          </div>
        </div>
      </header>

      <template v-if="users">
        <div class="table-header">
          <span class="name">User</span>
          <span class="user-types"></span>
          <span class="last-activity">Latest activity</span>
          <span class="point-balance">Redeemable points</span>
        </div>
        <ul v-if="users" class="user-list">
          <li v-for="user in users" :key="`user_result_${user.id}`">
            <router-link :to="{name: 'users-profile', params: {id: user.id, user}}">
              <div class="name">
                <user-card :user="user">
                  <template #under-name>
                    <div class="dates">
                      <span v-if="user.birthday_month !== null">
                        <i class="fas fa-birthday-cake"></i>
                        {{$DateInfo.months('short')[user.birthday_month]}} {{user.birthday_day}}
                      </span>
                      <span v-if="user.anniversary_month !== null">
                        <i class="fas fa-glass-cheers"></i>
                        {{$formatDate(`${user.anniversary_year}-${(user.anniversary_month+1).toString().padStart(2, '0')}-${(user.anniversary_day).toString().padStart(2, '0')}`, $DateTime.DATE_SHORT)}}
                      </span>
                    </div>
                  </template>
                </user-card>
              </div>

              <div class="user-types">
                <span class="admin" v-if="user.is_admin">Administrator</span>
                <span class="owner" v-if="user.is_owner">Team Owner</span>
                <span class="no-group" v-if="!user.has_group">No Channel</span>
              </div>

              <div class="last-activity">
                {{lastActivity(user)}}
              </div>

              <div class="point-balance">
                <span>{{$formatPoints(user.redeemable_points)}}</span>
              </div>
            </router-link>
          </li>
        </ul>

        <div class="load-more" ref="load_more" v-if="users.length > 0">
          <loading-indicator v-if="loading" />
        </div>
      </template>
      <div class="loading-placeholder" v-else><i></i><i></i><i></i><i></i><i></i><i></i></div>
    </section>
  </main>
</template>

<script>
import {debounce} from 'lodash'
import UserGroupsFilter from '@/components/Reporting/Filters/UserGroups'
import UserSegmentFilter from '@/components/Reporting/Filters/UserSegments'
import UserTypeFilter from '@/components/Users/Filters/UserTypes'

export default {
  components: {
    UserGroupsFilter,
    UserSegmentFilter,
    UserTypeFilter
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    }
  },
  data() {
    return {
      users: null,
      page: 1,
      has_more_pages: true,
      query: '',
      filters: {
        user_groups: [],
        user_segments: [],
        user_types: []
      }
    }
  },
  watch: {
    query(val) {
      this.page = 1;
      this.has_more_users = true;
      this.users = null;
      this.populateUserPage();
    },
    filters: {
      deep: true,
      handler: function() {
        this.page = 1;
        this.has_more_users = true;
        this.users = null;
        this.populateUserPage()
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scroll_handler);
  },
  mounted() {
    window.addEventListener('scroll', this.scroll_handler);
  },
  created() {
    this.populateUserPage()
  },
  methods: {
    openFieldImporter() {
      this.$root.$emit(
        'openModal',
        'Users/FieldImporter',
        {},
        (result) => {
          if(result === 'IMPORTED_FIELDS') {
            this.page = 1;
            this.has_more_users = true;
            this.users = null;
            this.populateUserPage()
          }
        }
      )
    },
    filter_label(filter) {
      switch(filter) {
        case "segments":
          return this.filters.user_segments.length > 0 ? `Segments (${this.filters.user_segments.length})` : 'Segments';
        case "groups":
          return this.filters.user_groups.length > 0 ? `Channels (${this.filters.user_groups.length})` : 'Channels';
        case "user_types":
        return this.filters.user_types.length > 0 ? `User Types (${this.filters.user_types.length})` : 'User Types';
      }
    },
    scroll_handler() {
      if(this.loading) return;
      if(!this.has_more_pages) return;
      
      const el = this.$refs.load_more;
      if(!el) return;

      const rect = el.getBoundingClientRect();
      
      if(rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
        this.$store.commit('loading', true)
        this.page ++;
        this.populateUserPage();
      }
    },
    populateUserPage: debounce(async function() {
      this.$store.commit('loading', true)
      const res = await this.$api.Team.list_users(this.page, this.query, this.filters)
      this.$store.commit('loading', false)
      
      if(res.length === 100) this.has_more_pages = true;
      else if(res.length < 100) this.has_more_pages = false;

      if(this.users === null) this.users = [];

      this.users.push(...res)
    }, 250),
    editUser(user) {
      this.$root.$emit(
        'openModal',
        'Users/EditProfile',
        user,
        (action) => {

        }
      )
    },
    lastActivity(user) {
      const dates = [
        user.shoutout_last_sent,
        user.award_last_sent
      ];

      if(_.every(dates, d => d === null)) return 'never';

      dates.sort((a, b) => this.$DateTime.fromISO(b) - this.$DateTime.fromISO(a))

      const most_recent = this.$DateTime.fromISO(dates[0]);
      if(this.$today - most_recent < 86400000) return 'today';
      return most_recent.toRelative({unit: 'days'})
    }
  }
}
</script>

<style lang="scss" scoped>
.loading-placeholder {
  display: block;
  margin: 0 0;
  > i {
    margin: 0 0;
    +i { 
      margin-top: 15px;
    }
    //flex: 1 0 auto;
    display: block;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    background-image: url("data:image/svg+xml,%3Csvg width='951' height='50' preserveAspectRatio='none' viewBox='0 0 951 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='951' height='50' rx='4' fill='%23E4E1DF'/%3E%3C/svg%3E%0A");
    
    &:before {
      content: '';
      display: block;
      height: 50px;
    }
  }
}

.app-view {
  > header {
    .btns {
      margin: 0 !important;
    }
  }
}

.sync-indicator {
  color: #659e09;
  font-size: 12px;
  position: relative;
  padding: 0 0 0 12px;
  display: block;
  margin: 0 15px 9px auto;

  &:before {
    content: '';
    display: block;
    background-color: #659e09;
    border-radius: 100%;
    width: 6px;
    height: 6px;
    animation: blip 1s linear infinite;
    position: absolute;
    left: 0;
    top: 6px;
  }
}

.list-section {
  > header {
    height: 80px !important;
    margin-bottom: 10px;
    .search-filters {
      flex: 0 0 100%;
      display: flex;
      align-items: center;
    }
    .search-wrap {
      flex: 1 0 auto;
      border-radius: 10px;
      background: #fff;
      box-shadow: rgba($light, 0.7) 0 5px 10px;
      > input {
        background: none;
        display: block;
        width: 100%;
        height: 40px;
        border: none;
        appearance: none;
        padding: 0 15px;
        outline: none;
      }
    }

    .filter-wrap {
      flex: 0 0 auto;
      display: flex;
      padding: 0 0 0 15px;
      
      > label {
        display: block;
        font-weight: 700;
        color: $muted-text;
        margin: 2px 0 0;
        font-size: 14px;
      }

      > ul {
        display: flex;
        padding: 0 0 0 15px;
        margin: 0 -5px;

        > li {
          padding: 0 5px;
          display: block;
          
          ::v-deep .dropdown-toggle {
            background: darken($light, 5%);
            color: $body-color;
            text-decoration: none;
            border-radius: 100em;
            font-size: 14px;
            line-height: 14px;
            padding: 3px 7px;

            &:after {
              vertical-align: 2px;
            }
            &:hover {
              background: darken($light, 8%);
            }
          }
        }
      }
    }
  }

  .table-header {
    display: flex;
    margin: 0 0;

    > span {
      padding: 0 10px;
      font-size: 12px;
      font-weight: 700;
      color: $muted-text;
    }
  }

  .table-header,
  .user-list {
    .name {
      flex: 0 0 auto;
    }
    
    .user-types {
      margin: 0 auto 0 0;
    }

    .last-activity {
      flex: 0 0 20%;
    }

    .point-balance {
      flex: 0 0 20%;
    }
  }
}
.user-list {
  display: block;
  padding: 0;
  margin: 0;
  

  > li {
    display: block;

    > a {
      display: flex;
      padding: 10px 0;
      color: $body-color;
      align-items: center;
      text-decoration: none;
      font-size: 14px;

      &:hover {
        background: $light;
      }

      ::v-deep .user--card {
        flex: 0 0 auto;

        .dates {
          display: flex;
          margin: 2px -4px 0;

          > span {
            color: $muted-text;
            display: block;
            font-size: 11px;
            padding: 0 4px;
          }
        }
      }

      > div {
        padding: 0 10px;
      }

      >.point-balance {
        > span {
          background: $green;
          font-size: 12px;
          line-height: 12px;
          padding: 2px 4px;
          border-radius: 3px;
          color: #fff;
        }
      }

      >.user-types {
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        margin: 0 -3px;

        > span {
          display: block;
          font-size: 10px;
          background: $body-color;
          color: #fff;
          border-radius: 100em;
          line-height: 10px;
          padding: 2px 5px;
          margin: 0 3px;

          &.no-group {
            background: $red;
          }
        }
      }
    }

    border-bottom: 1px solid $input-border-color;

    ::v-deep .user--card {
      .user--name {
        font-weight: 700;
        font-size: 16px;
      }
    }
  }
}

@keyframes blip {
  0% {
    box-shadow: rgba(#659e09, 0) 0 0 0 0;
  }
  50% {
    box-shadow: rgba(#659e09, 0.3) 0 0 0 3px;
  }
  100% {
    box-shadow: rgba(#659e09, 0) 0 0 0 6px;
  }
}
</style>