<template>
  <main class="setup setup-slack">
    <section>
      <div class="left">
        <img src="@/assets/people-doodle.png" class="bg" />
        <img src="@/assets/hithrive-logo-dark.svg" height="22" />
      </div>

      <div class="right">
        <template v-if="error">
          <div v-if="error === 'access_denied'">
            <p>Uh oh! HiThrive can't be installed unless you approve the permissions we request.</p>
            <p>HiThrive doesn't have access to messages, files or other sensitive data in Slack. Learn more about <a href="#" target="_blank">our security</a>.</p>
            <router-link class="btn btn-green btn-sm" :to="{name: 'install'}">Start over</router-link>
          </div>
        </template>
        <template v-else>
          <h1>Just a moment</h1>
          <p>We're installing HiThrive to your Slack workspace.</p>

          <loading-indicator small />
        </template>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  computed: {
    
  },
  data() {
    return {
      error: null
    }
  },
  created() {
    //validate state
    const state = this.$route.query.state;

    const saved_state = localStorage.getItem('hithrive_install_state');
    if( !saved_state || saved_state !== state ) {
      this.$toast.error('Something went wrong, please try again.');
      this.$router.replace({
        name: 'install'
      })
      return;
    }

    const error = this.$route.query.error;
    if(!!error) {
      this.error = error;
      return;
    }

    //successful
    //try install
    this.tryInstall(this.$route.query.code)
  },
  methods: {
    async tryInstall(code) {
      try {
        const res = await this.$api.Team.install_slack(code);

        if( res.result == 'NEWLY_CREATED' ) {
          try {
            window.dataLayer.push({
              event: 'slack_signup'
            })
          } catch(e) {}

          //save auth details
          this.$store.commit('user_token', res.token);
          await this.$store.dispatch('populateUser');
          
          this.$router.replace({name: 'get-started'})
        }

        if( res.result == 'REINSTALLED' ) {
          try {
            window.dataLayer.push({
              event: 'slack_reinstall'
            })
          } catch(e) {}

          //save auth details
          this.$store.commit('user_token', res.token);
          await this.$store.dispatch('populateUser');

          this.$router.replace({name: 'recognition'})
        }
      } catch(e) {
        this.$toast.error('An error occured, please try again.');
        this.$router.replace({name: 'install'});
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.setup {
  min-height: 100vh;
  background: linear-gradient(45deg, rgba(45,31,64,1) 0%, rgba(71,52,97,1) 100%);
  display: flex;
  justify-content: center;
  align-items: center;

  > section {
    background: #F9F6F4;
    border-radius: 10px;
    width: 100%;
    max-width: 780px;
    position: relative;
    box-shadow: rgba(0,0,0,0.2) 0 10px 20px;
    padding: 50px 0 50px 0;
    display: flex;
    //justify-content: center;
    flex-wrap: nowrap;
    overflow-x: hidden;
    overflow-y: auto;
    align-items: center;

    > .left {
      flex: 0 0 40%;
      min-width: 0;
      padding: 0 35px;
      position: relative;

      > .bg {
        width: 100%;
        height: auto;
        opacity: 0.2;

        + img {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    > .right {
      border-left: 1px solid $light;
      padding: 35px 35px;

      .app-loader--spinner {
        margin: 25px 0 0;
      }

      > h1 {
        font-weight: 700;
        font-size: 20px;
        margin: 0 0 5px;
      }
      > p {
        color: $muted-text;
      }
    }
  }
}
</style>