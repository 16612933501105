<template>
  <div class="dropdown">
    <a href="#" class="dropdown-toggle" data-toggle="dropdown">{{label}}</a>

    <div class="dropdown-menu">
      <a class="dropdown-item" @click.prevent.stop="selectItem(user_type)" :class="{'active': value.indexOf(user_type) > -1}" href="#" v-for="user_type in Object.keys(user_types)" :key="`user_type_filter_${user_type}`">{{user_types[user_type]}}</a>
    </div>
  </div>
</template>

<script>
export default {
  props: ['label', 'value'],
  data() {
    return {
      user_types: {
        'admins': 'Administrators',
        'no_group': 'No Channel',
        'managers': 'Managers'
      }
    }
  },
  methods: {
    selectItem(obj) {
      if(this.value.indexOf(obj) > -1) {
        const newVal = [...this.value];
        newVal.splice(
          newVal.indexOf(obj),
          1
        )
        this.$emit('input', newVal)
      }
      else {
        this.$emit('input', [
          ...this.value,
          obj
        ])
      }
    }
  }
}
</script>