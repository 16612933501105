<template>
  <router-view />
</template>

<script>
export default {
  created() {
    
  },
  methods: {
    
  }
}
</script>