<template>
  <div class="user--card">
    <user-profile-picture :height="height" :user="user" />
    <div class="name-wrap">
      <user-name :user="user" />
      <slot name="under-name"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserCard',
  props: {
    user: Object,
    height: {
      default: 35,
      type: Number
    }
  }
}
</script>