<template>
  <main class="app-view user-segments user-segment-edit" v-if="segment">
    <header>
      <div class="title">
        <ul class="crumbs">
          <li>
            <router-link :to="{name: 'users'}">Users</router-link>
          </li>
          <li>
            <router-link :to="{name: 'users-user-segments'}">User Segments</router-link>
          </li>
        </ul>
        <h1>{{segment.name}}</h1>
      </div>

      <a href="#" @click.prevent="showUserList" class="user-count">
        <ul v-if="segment.users.length > 0">
          <li v-for="user in segment.users.slice(0, 3)" :key="`segmentuserpreview_${user.id}`">
            <user-profile-picture :user="user" height="22" />
          </li>
        </ul>
        <span>{{$formatNumber(segment.user_count)}} user{{segment.user_count == 1 ? '' : 's'}}</span>
      </a>
    </header>

    <section class="main-section">
      <header>
        <h2>Segment Settings</h2>
      </header>

      <div class="content settings">
        
        <div class="setting-row">
          <i class="fas fa-tag"></i>
          <div class="title">
            <h4>Segment Name</h4>
            <p>A name for this user segment.</p>
          </div>
          <div class="fields">
            <input type="text" class="form-control" v-model="segment.name" />
          </div>
        </div>

        <div class="setting-row segment-type">
          <i class="fas fa-sync"></i>
          <div class="title">
            <h4>Segment Sync</h4>
            <p>Should this segment sync automatically?</p>
          </div>
          <div class="fields">
            <select class="form-control" v-model="sync_type">
              <option value="manually">Choose members manually</option>
              <option value="field_sync">Sync by profile fields</option>
            </select>
          </div>
        </div>

        <div class="setting-row conditions" v-if="sync_type === 'field_sync'">
          <i class="fas fa-project-diagram"></i>
          <div class="title">
            <h4>Segment Conditions</h4>
            <p>Conditions used to build this segment.</p>
          </div>
          <div class="fields">
            <segment-condition-builder v-model="$v.conditions" />
          </div>
        </div>

        <div class="setting-row budget-adjustments" v-else-if="sync_type === 'manually'">
          <i class="fas fa-users"></i>
          <div class="title">
            <h4>Members</h4>
            <p>Users who are members of this segment.</p>
          </div>
          <div class="fields">
            <user-picker v-model="members" placeholder="search to add a member..." />
          </div>
        </div>

        <div class="setting-row budget-adjustments">
          <i class="fas fa-user-crown"></i>
          <div class="title">
            <h4>Managers</h4>
            <p>Users who have reporting access to this segment.</p>
          </div>
          <div class="fields">
            <user-picker v-model="segment.managers" placeholder="search to add a manager..." />
          </div>
        </div>

        <div class="setting-row budget-adjustments">
          <i class="fas fa-coins"></i>
          <div class="title">
            <h4>Budget Adjustments</h4>
            <p>Adjust the award and shoutout budgets for users in this segment.</p>
          </div>
          <div class="fields">
            <div class="field-group">
              <h5>User Adjustments</h5>
              <p class="help-text">These adjustments affect every user in this segment.</p>
              <div class="d-flex">
                <div class="form-group">
                  <label>Shoutout Budget</label>
                  <div class="input-group input-group-sm" :class="{'is-invalid': $v.shoutout_adjustment.$invalid}">
                    <div class="input-group-prepend">
                      <button class="btn btn-outline-secondary dropdown-toggle" data-toggle="dropdown" type="button">{{shoutout_adjustment_mode === 'increase' ? 'Increase' : 'Decrease'}} by</button>
                      <div class="dropdown-menu">
                        <a href="#" class="dropdown-item" @click.prevent="shoutout_adjustment_mode='increase';">Increase by</a>
                        <a href="#" class="dropdown-item" @click.prevent="shoutout_adjustment_mode='decrease';">Decrease by</a>
                      </div>
                    </div>
                    <input type="text" class="form-control" :class="{'is-invalid': $v.shoutout_adjustment.$invalid}" v-model.number="$v.shoutout_adjustment.$model">
                    <div class="input-group-append">
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                  <div class="invalid-feedback">
                    Must be between 0% to 99,999%
                  </div>
                </div>
                <div class="form-group">
                  <label>Award Budget</label>
                  <div class="input-group input-group-sm" :class="{'is-invalid': $v.award_adjustment.$invalid}">
                    <div class="input-group-prepend">
                      <button class="btn btn-outline-secondary dropdown-toggle" data-toggle="dropdown" type="button">{{award_adjustment_mode === 'increase' ? 'Increase' : 'Decrease'}} by</button>
                      <div class="dropdown-menu">
                        <a href="#" class="dropdown-item" @click.prevent="award_adjustment_mode='increase';">Increase by</a>
                        <a href="#" class="dropdown-item" @click.prevent="award_adjustment_mode='decrease';">Decrease by</a>
                      </div>
                    </div>
                    <input type="text" class="form-control" :class="{'is-invalid': $v.award_adjustment.$invalid}" v-model.number="$v.award_adjustment.$model">
                    <div class="input-group-append">
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                  <div class="invalid-feedback">
                    Must be between 0% to 99,999%
                  </div>
                </div>
              </div>
            </div>

            <div class="field-group">
              <h5>Manager Adjustments</h5>
              <p class="help-text">These adjustments only affect managers of this segment.</p>
              <div class="d-flex">
                <div class="form-group">
                  <label>Shoutout Budget</label>
                  <div class="input-group input-group-sm" :class="{'is-invalid': $v.manager_shoutout_adjustment.$invalid}">
                    <div class="input-group-prepend">
                      <button class="btn btn-outline-secondary dropdown-toggle" data-toggle="dropdown" type="button">{{manager_shoutout_adjustment_mode === 'increase' ? 'Increase' : 'Decrease'}} by</button>
                      <div class="dropdown-menu">
                        <a href="#" class="dropdown-item" @click.prevent="manager_shoutout_adjustment_mode='increase';">Increase by</a>
                        <a href="#" class="dropdown-item" @click.prevent="manager_shoutout_adjustment_mode='decrease';">Decrease by</a>
                      </div>
                    </div>
                    <input type="text" class="form-control" :class="{'is-invalid': $v.manager_shoutout_adjustment.$invalid}" v-model.number="$v.manager_shoutout_adjustment.$model">
                    <div class="input-group-append">
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                  <div class="invalid-feedback">
                    Must be between 0% to 99,999%
                  </div>
                </div>
                <div class="form-group">
                  <label>Award Budget</label>
                  <div class="input-group input-group-sm" :class="{'is-invalid': $v.manager_award_adjustment.$invalid}">
                    <div class="input-group-prepend">
                      <button class="btn btn-outline-secondary dropdown-toggle" data-toggle="dropdown" type="button">{{manager_award_adjustment_mode === 'increase' ? 'Increase' : 'Decrease'}} by</button>
                      <div class="dropdown-menu">
                        <a href="#" class="dropdown-item" @click.prevent="manager_award_adjustment_mode='increase';">Increase by</a>
                        <a href="#" class="dropdown-item" @click.prevent="manager_award_adjustment_mode='decrease';">Decrease by</a>
                      </div>
                    </div>
                    <input type="text" class="form-control" :class="{'is-invalid': $v.manager_award_adjustment.$invalid}" v-model.number="$v.manager_award_adjustment.$model">
                    <div class="input-group-append">
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                  <div class="invalid-feedback">
                    Must be between 0% to 99,999%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>

    <footer>
      <button class="btn btn-muted-link btn-sm" @click="deleteSegment">Delete</button>
      <button class="btn btn-green btn-sm" @click="saveSegment">Save</button>
    </footer>
  </main>
</template>

<script>
import { debounce } from 'lodash';
import {required, minLength, minValue, maxValue} from 'vuelidate/lib/validators'
import SegmentConditionBuilder from '@/components/Users/SegmentConditionBuilder'
import UserPicker from '@/components/UserPicker';

export default {
  components: {
    SegmentConditionBuilder,
    UserPicker
  },
  computed: {
    segments() {
      return this.$store.state.segments;
    }
  },
  data() {
    return {
      segment: null,
      conditions: [],
      members: [],
      sync_type: 'manually',
      profile_fields: null,
      shoutout_adjustment_mode: 'increase',
      award_adjustment_mode: 'increase',
      manager_shoutout_adjustment_mode: 'increase',
      manager_award_adjustment_mode: 'increase',
      shoutout_adjustment: 0,
      award_adjustment: 0,
      manager_shoutout_adjustment: 0,
      manager_award_adjustment: 0
    }
  },
  watch: {
    members(val) {
      return this.segment.user_count = val.length;
    }
  },
  validations: {
    conditions: {
      minLength: function(model) {
        if(this.sync_type === 'manually') return true;

        return model.length >= 1;
      },
      $each: {
        $each: {
          field_id: {
            required
          },
          field_value: {
            required
          },
          operator: {
            required
          }
        }
      }
    },
    shoutout_adjustment: {
      required,
      minValue: minValue(0),
      maxValue: maxValue(99999)
    },
    award_adjustment: {
      required,
      minValue: minValue(0),
      maxValue: maxValue(99999)
    },
    manager_shoutout_adjustment: {
      required,
      minValue: minValue(0),
      maxValue: maxValue(99999)
    },
    manager_award_adjustment: {
      required,
      minValue: minValue(0),
      maxValue: maxValue(99999)
    }
  },
  created() {
    this.populateSegment(this.$route.params.id);
    this.populateProfileFields()
  },
  methods: {
    async showUserList() {
      const resp = await this.$api.Segments.list_members(this.segment.id, '', true)
      this.$root.$emit(
        'openModal',
        'UserList/index',
        {
          modal_title: `${this.segment.name} Members`,
          list: resp
        },
        () => {
          
        }
      )
    },
    available_fields(field) {
      const used_fields = _.map(this.conditions, 'field_id')
      return _.filter(this.profile_fields, (f) => f.id === field || !used_fields.includes(f.id))
    },
    addCondition() {
      this.$v.conditions.$model.push({
        field_id: '',
        field_value: ''
      })
    },
    removeCondition(index) {
      this.$v.conditions.$model.splice(index, 1);
    },
    async populateProfileFields() {
      const resp = await this.$api.Team.get_profile_fields()
      this.profile_fields = resp;
    },
    async populateSegment(id) {
      const _cloneSegment = async () => {
        // this.segment = _.cloneDeep(
        //   _.find(this.segments, (g) => g.id.toString() === id.toString())
        // );

        this.segment = await this.$api.Segments.get(id);

        this.shoutout_adjustment = Math.abs(this.segment.shoutout_budget_adjustment);
        this.award_adjustment = Math.abs(this.segment.award_budget_adjustment);
        this.manager_shoutout_adjustment = Math.abs(this.segment.manager_shoutout_budget_adjustment);
        this.manager_award_adjustment = Math.abs(this.segment.manager_award_budget_adjustment);

        this.shoutout_adjustment_mode = this.segment.shoutout_budget_adjustment < 0 ? 'decrease' : 'increase';
        this.award_adjustment_mode = this.segment.award_budget_adjustment < 0 ? 'decrease' : 'increase';
        this.manager_shoutout_adjustment_mode = this.segment.manager_shoutout_budget_adjustment < 0 ? 'decrease' : 'increase';
        this.manager_award_adjustment_mode = this.segment.manager_award_budget_adjustment < 0 ? 'decrease' : 'increase';

        this.sync_type = this.segment.filters.length === 0 ? 'manually' : 'field_sync';
        if(this.sync_type === 'manually') {
          this.members = this.segment.users;
        }

        this.conditions = this.segment.filters.map( (group) => group.reduce( (arr, val) => {
          arr.push({
            field_id: val.field,
            field_value: val.value,
            operator: val.operator
          })
          return arr;
        }, [] ) );
      };
      
      if(this.segments) {
        _cloneSegment();
      }
      else {
        const unwatch = this.$watch('segments', (val) => {
          _cloneSegment();
          unwatch();
        })
      }
    },
    async saveSegment() {
      this.$v.$touch()
      if(this.$v.$invalid) return;
      
      this.$store.commit('loading', true)

      const update_data = _.cloneDeep(this.segment)

      if(this.sync_type === 'field_sync') {
        delete update_data.users;
        update_data.filters = 
          this.conditions.map(
            group => group.reduce((agg, val) => {
              agg.push({
                field: val.field_id,
                value: val.field_value,
                operator: val.operator
              })
              return agg;
            }, []
          )
        );
      } else {
        update_data.filters = [];
      }
      update_data.managers = _.map(this.segment.managers, 'id');

      update_data.shoutout_adjustment = Math.max(-100, this.shoutout_adjustment_mode === 'increase' ? this.shoutout_adjustment : (this.shoutout_adjustment * -1));
      update_data.award_adjustment = Math.max(-100, this.award_adjustment_mode === 'increase' ? this.award_adjustment : (this.award_adjustment * -1));
      update_data.manager_shoutout_adjustment = Math.max(-100, this.manager_shoutout_adjustment_mode === 'increase' ? this.manager_shoutout_adjustment : (this.manager_shoutout_adjustment * -1));
      update_data.manager_award_adjustment = Math.max(-100, this.manager_award_adjustment_mode === 'increase' ? this.manager_award_adjustment : (this.manager_award_adjustment * -1));

      await this.$api.Segments.update(this.segment.id, update_data)

      if(this.sync_type === 'manually') {
        await this.$api.Segments.update_members(this.segment.id, _.map(this.members, 'id'));
      }

      this.$store.dispatch('populateSegments')

      this.$toast.success('Segment settings have been saved.');

      this.$store.commit('loading', false)
    },
    async deleteSegment() {
      if(!confirm('Are you sure you want to delete this segment?')) return;

      this.$store.commit('loading', true)
      await this.$api.Segments.delete(this.segment.id)
      await this.$store.dispatch('populateSegments')
      this.$store.commit('loading', false)
      this.$toast.success('User segment has been deleted.');
      this.$router.replace({name: 'users-user-segments'});
    }
  }
}
</script>

<style lang="scss" scoped>
.budget-adjustments {
  .field-group {
    +.field-group {
      margin-top: 10px;
    }
    h5 {
      margin: 0;
      font-size: 14px;
      font-weight: 700;

      +.help-text {
        margin: 0 0 5px;
      }
    }

    .d-flex {
      margin: 0 -5px;

      > div {
        padding: 0 5px;
      }
    }

    .form-group {
      label {
        font-size: 12px;
        margin: 0;
        font-weight: 700;
      }
    }
  }
}
.conditions {
  ul {
    display: block;
    padding: 0;
    margin: -15px 0 0;

    > li {
      display: flex;
      margin: 0 -5px 0 -30px;
      position: relative;
      padding: 10px 0 0 25px; 

      > button {
        display: none;
        position: absolute;
        bottom: 22px;
        left: 0;
      }

      &:hover {
        > button {
          display: block;
        }
      }

      > div {
        flex: 1;
        padding: 0 5px 0;

        > label {
          font-size: 12px;
          font-weight: 700;
          color: $muted-text;
        }
      }

      &:before {
        content: 'AND';
        display: block;
        position: absolute;
        left: 5px;
        top: -6px;
        font-size: 10px;
        color: $muted-text;
      }

      &:first-child {
        &:before {
          content: none;
        }
      }
    }
  }
}

.app-view {
  > header {
    .user-count {
      display: flex;
      align-items: center;
      height: 26px;
      margin: 0 0 0 15px;

      > ul {
        display: flex;
        flex: 0 0 auto;
        padding: 0 5px;
        margin: 0 5px 0 0;

        > li {
          display: block;
          margin: 0 0 0 -5px;
          
          ::v-deep >.user--profile-picture  {
            box-shadow: #fff 0 0 0 2px;
          }
        }
      }

      > span {
        display: block;
        font-size: 12px;
        margin: 0 0;
        color: $muted-text;
      }
    }
  }
}
</style>