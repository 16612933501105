<template>
  <div class="reward-view" id="reward-home">
    <main>
      <div
        class="alert alert-info d-sm-flex align-items-center"
        v-if="claims.length > 0"
      >
        <div>
          <strong>You have a gift to claim!</strong>
          Head on over to your
          <router-link :to="{ name: 'rewards_claims' }" class="alert-link"
            >pending gifts</router-link
          >
          to claim your gift.
        </div>
        <router-link
          :to="{ name: 'rewards_claims' }"
          class="btn btn-info btn-sm ml-auto"
          >Claim my gift</router-link
        >
      </div>
      <section
        class="featured-carousel"
        v-if="$hasCatalog('AMAZON') && article_list.length > 0"
      >
        <vue-slick-carousel :dots="true" :adaptiveHeight="true">
          <router-link :to="{ name: 'rewards_amazon' }">
            <img src="@/assets/amazon-banner.jpg" />
          </router-link>
          <router-link
            v-for="(article, index) in article_list"
            :key="`featured_article_${index}`"
            :to="{
              name: 'rewards_editorial_post',
              params: { id: article.uid },
            }"
          >
            <img :src="article.data.post_image.url" />
          </router-link>
        </vue-slick-carousel>
      </section>

      <section
        class="featured-gift-cards main-section"
        v-if="$hasCatalog('GIFT_CARDS')"
      >
        <header>
          <h2>Featured gift cards</h2>

          <div class="btns">
            <router-link
              class="btn btn-dark btn-xs btn-rounded"
              :to="{ name: 'rewards_gift_cards' }"
              >View all gift cards <i class="fal fa-long-arrow-right"></i
            ></router-link>
          </div>
        </header>

        <div class="content">
          <featured-gift-cards />
        </div>
      </section>

      <section
        class="featured-amazon main-section"
        v-if="$hasCatalog('AMAZON')"
      >
        <header>
          <h2>Trending on <img src="@/assets/amazon.svg" height="16" /></h2>

          <div class="btns">
            <router-link
              class="btn btn-dark btn-xs btn-rounded"
              :to="{ name: 'rewards_amazon' }"
              >Shop more categories <i class="fal fa-long-arrow-right"></i
            ></router-link>
          </div>
        </header>

        <div class="content">
          <featured-amazon />
        </div>
      </section>

      <section
        class="company-rewards main-section"
        v-if="
          company_rewards === null ||
            (company_rewards !== null && company_rewards.length > 0)
        "
      >
        <header>
          <h2>Company rewards</h2>
        </header>

        <div class="content">
          <template v-if="company_rewards !== null">
            <ul v-if="company_rewards.length > 0">
              <li v-for="reward in company_rewards" :key="`cr_${reward.id}`">
                <div class="icon">
                  <i v-html="$emoji(reward.emoji)"></i>
                </div>
                <div class="details">
                  <div class="title">
                    <h4>{{ reward.name }}</h4>
                  </div>
                  <div class="description">
                    {{ reward.description }}
                  </div>
                </div>
                <div class="redeem">
                  <button
                    class="btn btn-xs btn-green"
                    :class="{ loading: reward.loading === true }"
                    :disabled="point_balance < reward.point_cost"
                    @click="redeemCompanyReward(reward)"
                  >
                    Redeem for
                    <strong>{{ $formatPoints(reward.point_cost) }}</strong>
                  </button>
                </div>
              </li>
            </ul>
          </template>
          <div v-else class="loading-placeholder"><i></i><i></i><i></i></div>
        </div>
      </section>
      <section v-else-if="!$hasCatalog('GIFT_CARDS') && !$hasCatalog('AMAZON')">
        <header>
          <h2>No rewards yet!</h2>
        </header>
        <div class="empty-state">
          Your team hasn't setup any rewards yet, check back soon!
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import FeaturedGiftCards from '@/components/RewardsPortal/FeaturedGiftCards';
import FeaturedAmazon from '@/components/RewardsPortal/FeaturedAmazon';

import axios from 'redaxios';

export default {
  components: {
    VueSlickCarousel,
    FeaturedGiftCards,
    FeaturedAmazon,
  },
  computed: {
    claims() {
      return this.$store.state.reward_claims;
    },
    point_balance() {
      return this.$store.state.user.redeemable_points;
    },
    prismic_ref() {
      return this.$store.state.prismic_ref;
    },
  },
  data() {
    return {
      company_rewards: null,
      article_list: [],
    };
  },
  created() {
    this.populateCompanyRewards();
    this.populateFeaturedArticles();
    this.populateClaims();
  },
  methods: {
    async populateClaims() {
      await this.$store.dispatch('populateClaims');
    },
    async populateFeaturedArticles() {
      if (this.prismic_ref === null) {
        await this.$store.dispatch('populatePrismicRef');
      }

      const resp = await axios.get(
        `https://hithrive.cdn.prismic.io/api/v2/documents/search?ref=${this.prismic_ref}&q=[[at(document.type,"reward_content")][at(document.tags,["rewards"])]]&orderings=[document.last_publication_date desc]`
      );

      const results = resp.data.results;
      this.article_list = results;
    },
    async populateCompanyRewards() {
      const resp = await this.$api.RewardPortal.CompanyRewards.list();
      this.company_rewards = resp;
    },
    async redeemCompanyReward(reward) {
      this.setRewardLoadingStatus(reward.id, true);
      const resp = await this.$api.RewardPortal.CompanyRewards.redeem(
        reward.id
      );
      this.setRewardLoadingStatus(reward.id, false);

      if (resp.error === 'INSUFFICIENT_POINTS') {
        this.$toast.error(`You don't have enough points for this reward!`);
        return;
      }

      this.$store.dispatch(
        'adjustRedeemablePoints',
        -Math.abs(reward.point_cost)
      );
      this.$router.push({
        name: 'rewards_redemptions',
        params: {
          redemption_id: resp.redemption_id,
        },
      });
    },
    setRewardLoadingStatus(id, status = false) {
      this.company_rewards = _.map(this.company_rewards, (r) => {
        if (r.id === id) r.loading = status;
        return r;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.featured-amazon {
  > header {
    > h2 {
      > img {
        margin-bottom: -5px;
      }
    }
  }
}

.company-rewards {
  .loading-placeholder {
    //display: flex;
    margin: -10px 0;
    > i {
      margin: 10px 0;
      //flex: 1 0 auto;
      display: block;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center center;
      background-image: url("data:image/svg+xml,%3Csvg width='951' height='50' preserveAspectRatio='none' viewBox='0 0 951 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='951' height='50' rx='4' fill='%23E4E1DF'/%3E%3C/svg%3E%0A");

      &:before {
        content: '';
        display: block;
        height: 50px;
      }
    }
  }

  ul {
    display: block;
    padding: 0;
    margin: 0;

    > li {
      display: flex;
      padding: 15px;
      align-items: center;
      background: $light;
      border-radius: 10px;

      + li {
        margin-top: 15px;
      }

      .icon {
        padding: 0 15px 0 0;
        > i {
          font-size: 26px;
          line-height: 1em;
          font-style: normal;
          vertical-align: -3px;
        }
      }

      .details {
        display: block;
        flex: 0 1 auto;
        min-width: 0;
        padding: 0 7px 0 0;

        @include media-breakpoint-up(md) {
          display: flex;
        }
      }

      .title {
        flex: 0 0 auto;
        min-width: 0;
        h4 {
          white-space: nowrap;
          font-weight: 700;
          margin: 0;
          font-size: 16px;
          line-height: 1.25em;
        }
      }
      .description {
        min-width: 0;
        margin: 0;

        @include media-breakpoint-up(md) {
          margin: 0 5px 0 7px;
        }

        white-space: nowrap;
        flex: 0 1 auto;
        overflow: hidden;
        line-height: 1.25em;
        text-overflow: ellipsis;
      }
      .redeem {
        flex: 0 0 auto;
        margin: 0 0 0 auto;
      }
    }
  }
}

.featured-carousel {
  margin: 20px 0 0;

  ::v-deep .slick-slider {
    .slick-dots {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex !important;
      padding: 0 0 10px;
      margin: 0;
      justify-content: center;

      > li {
        display: block;
        padding: 0 5px;
        margin: 0;

        > button {
          appearance: none;
          border: none;
          background: rgba(255, 255, 255, 0.5);
          text-indent: -999px;
          overflow: hidden;
          border-radius: 100%;
          height: 10px;
          width: 10px;
          padding: 0;
        }

        &.slick-active {
          > button {
            background: #ffffff;
          }
        }
      }
    }

    > button {
      display: block;
      position: absolute;
      top: 50%;
      height: 40px;
      z-index: 9;
      margin-top: -20px;
      text-indent: -999px;
      overflow: hidden;
      appearance: none;
      border: none;
      padding: 0;
      border-radius: 100%;
      width: 40px;
      background: #fff;
      box-shadow: rgba(0, 0, 0, 0.05) 0 5px 10px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        color: $muted-text;
        text-indent: 0;
        left: 50%;
        margin-left: -6px;
        padding: 0;
        width: 14px;
        top: 0;
        bottom: 0;
        font-size: 14px;
        font-family: 'Font Awesome 5 Pro';
        line-height: 42px;
        text-align: center;
      }

      &.slick-prev {
        left: 15px;

        &:before {
          content: '\f053';
          margin-left: -8px;
        }
      }

      &.slick-next {
        right: 15px;

        &:before {
          content: '\f054';
        }
      }
    }

    .slick-list {
      overflow: hidden;
      border-radius: 12px;

      .slick-slide {
        > div {
          > a {
            display: block !important;
            text-decoration: none;

            > img {
              display: block;
              width: 100%;
              height: auto;
              user-select: none;
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}
</style>
