<template>
  <div class="shoutout-card">
    <div class="external-banner" v-if="shoutout.type == 'EXTERNAL'">
      OUTSIDE SHOUTOUT
    </div>
    <div class="header">
      <div class="recognized external" v-if="shoutout.type == 'EXTERNAL'">
        <user-card :user="shoutout.external_creator" :height="user_size" />

        <i class="fas fa-caret-right"></i>

        <span class="for" v-for="(for_user, index) in shoutout.for_users" :key="`shoutout_for_${shoutout.id}_${index}`">
          <user-card :user="for_user" :height="user_size" />
          <span class="separator" v-html="for_separator(index)"></span>
        </span>
      </div>
      <div class="recognized" v-else>
        <user-card :user="shoutout.created_by" :height="user_size" />

        <i class="fas fa-caret-right"></i>

        <span class="for" v-for="(for_user, index) in shoutout.for_users" :key="`shoutout_for_${shoutout.id}_${index}`">
          <user-card :user="for_user" :height="user_size" />
          <span class="separator" v-html="for_separator(index)"></span>
        </span>
      </div>

      <div class="timestamp">
        {{$timeAgo(shoutout.created_at)}}
      </div>
    </div>

    <p>{{shoutout.message_text}}</p>

    <div v-if="shoutout.attachment && is_full" class="attachment">
      <div class="gif" v-if="shoutout.attachment.attachment_type == 'TENOR_GIF'">
        <video :src="shoutout.attachment.media[0].webm.url" autoplay loop muted></video>
      </div>
    </div>

    <div class="tagged-value" v-if="shoutout.value_text">
      <i>{{$emoji(':bookmark:')}}</i><span class="tag">{{shoutout.value_text}}</span>
    </div>

    <slot name="bottom"></slot>

    <ul class="reactions" v-if="has_reactions">
      <li v-for="(reaction, index) in shoutout.reactions" :key="`emoji_reaction_${shoutout.id}_${index}`">
        <i>{{$emoji(`:${reaction.name}:`)}}</i><span class="count">{{$formatNumber(reaction.count)}}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'shoutout-card',
  props: ['data', 'full'],
  computed: {
    has_reactions() {
      return this.shoutout.reactions.length > 0;
    },
    is_full() {
      if( typeof this.full != 'undefined' ) return true;

      return false;
    },
    user_size() {
      return 20;
    }
  },
  data() {
    return {
      shoutout: null
    }
  },
  created() {
    this.shoutout = this.data;
  },
  methods: {
    for_separator(index) {
      if( (index + 2) < this.shoutout.for_users.length ) return ',&nbsp;';
      else if( (index + 2) == this.shoutout.for_users.length ) return '&nbsp;and&nbsp;';

      return '';
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
