var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reward-view",attrs:{"id":"charity-details"}},[_c('main',[_c('header',[_c('div',{staticClass:"title"},[_c('ul',{staticClass:"crumbs"},[_c('li',[_c('router-link',{attrs:{"to":{ name: 'rewards_home' }}},[_vm._v("Rewards")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'rewards_charities_home' }}},[_vm._v("Charities")])],1)]),(_vm.charity !== null)?_c('h1',[_vm._v(_vm._s(_vm.charity.name))]):_c('div',{staticClass:"loading-placeholder"},[_c('i')])])]),_c('section',{staticClass:"main-section",attrs:{"id":"details-section"}},[(_vm.charity !== null)?[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"thumbnail"},[_c('img',{staticClass:"logotest",attrs:{"src":`https://hithrive-giving.herokuapp.com/nonprofits/${_vm.charity.ein}/logo`},on:{"load":function($event){return _vm.$set(_vm.charity, 'has_logo', true)}}}),_c('div',{staticClass:"thumb",style:({ 'background-image': _vm.charity_logo })})])]),_c('div',{staticClass:"col-md-5"},[_c('h4',[_vm._v("Redeem your "+_vm._s(_vm.team.points_name))]),_c('div',{staticClass:"form-group"},[_c('div',{class:[
                  'input-group',
                  'input-group-lg',
                  { 'is-invalid': !_vm.allow_redemption },
                ]},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.points),expression:"points",modifiers:{"number":true}}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.points)},on:{"input":function($event){if($event.target.composing)return;_vm.points=_vm._n($event.target.value)},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_vm._v(_vm._s(_vm.team.points_name))])])]),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.warning))]),_c('div',{staticClass:"point-cost"},[_vm._v(" "+_vm._s(_vm.$formatPoints(_vm.points))+" = "+_vm._s(_vm.$formatCurrency(_vm.points / 100))+" ")])]),_c('button',{class:['btn', 'btn-block', 'btn-green', { loading: _vm.loading }],attrs:{"disabled":!_vm.allow_redemption},on:{"click":_vm.donatePoints}},[_vm._v(" Donate "),_c('strong',[_vm._v(_vm._s(_vm.$formatPoints(_vm.points)))])])])]),_c('div',{staticClass:"info-section"},[(_vm.charity.mission)?_c('div',{staticClass:"d-flex info-row"},[_c('div',{staticClass:"title"},[_vm._v(" Mission ")]),_c('div',{staticClass:"content"},[_c('p',{staticClass:"mission"},[_vm._v(" "+_vm._s(_vm.charity.mission)+" ")])])]):_vm._e(),_c('div',{staticClass:"d-flex info-row"},[_c('div',{staticClass:"title"},[_vm._v(" Address ")]),_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.address)+" ")])]),(_vm.charity.url)?_c('div',{staticClass:"d-flex info-row"},[_c('div',{staticClass:"title"},[_vm._v(" Website ")]),_c('div',{staticClass:"content"},[_c('a',{attrs:{"href":_vm.url,"target":"_blank"}},[_vm._v(_vm._s(_vm.url))])])]):_vm._e(),(_vm.charity.receipts)?_c('div',{staticClass:"d-flex info-row"},[_c('div',{staticClass:"title"},[_vm._v(" Annual Donations ")]),_c('div',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.$formatCurrency(_vm.charity.receipts))+" ")])]):_vm._e()])]:[_vm._m(0),_vm._m(1)]],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row align-items-end"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"loading-placeholder image"},[_c('i')])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"loading-placeholder redeem"},[_c('i')])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-10"},[_c('div',{staticClass:"loading-placeholder description"},[_c('i')])])])
}]

export { render, staticRenderFns }