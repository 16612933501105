<template>
  <div class="user--profile-picture" v-bind:style="[{'width': size.width, 'height': size.height, 'font-size': size.width}]">
    <img :src="profilePictureSrc" @load="handleLoad" />
    <span>{{initials}}</span>
  </div>
</template>

<script>
export default {
  name: 'UserProfilePicture',
  props: [
    'user',
    'width',
    'height'
  ],
  computed: {
    profilePictureSrc() {
      let user = null;
      if( typeof this.user == 'undefined' ) {
        //use current user
        user = this.$store.state.user;
      }
      else if( typeof this.user == 'object' ) {
        user = this.user;
      }

      let src = null;
      if( user ) {
        src = user.profile_picture;
      }

      return `${src || `https://img-cdn.hithrive.com/100x100/default_cat.png`}`;
    },
    initials() {
      let user = null;
      if( typeof this.user == 'undefined' ) {
        //use current user
        user = this.$store.state.user;
      }
      else if( typeof this.user == 'object' ) {
        user = this.user;
      }

      const name = user.name;
      const firstInitial = name.split(' ')[0][0];
      const lastInitial = name.split(' ')[1] ? name.split(' ')[1][0] : '';

      return `${firstInitial}${lastInitial}`;
    },
    size() {
      if( this.width && !this.height ) { //No height, just width
        return {
          width: this.width + 'px',
          height: this.width + 'px',
        }
      }
      if( this.height && !this.width ) { //No width, just height
        return {
          width: this.height + 'px',
          height: this.height + 'px',
        }
      }
      if( this.width && this.height ) { //Both height & width
        return {
          width: this.width + 'px',
          height: this.height + 'px',
        }
      }

      return { //Nothing set
        width: '35px',
        height: '35px'
      }
    }
  },
  methods: {
    handleLoad(e) {
      e.srcElement.className = 'loaded';
    }
  }
}
</script>