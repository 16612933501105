<template>
  <div id="redemption-portal" :style="brand_colors">
    <div id="locale-modal" class="ht-popup" v-if="need_locale">
      <div class="window">
        <header>
          <h2>Select Your Country</h2>
        </header>

        <div class="content">
          <p>Please select your country to see available reward options.</p>

          <v-select class="f16" v-model="country" :options="country_options" :reduce="country => country.country_code" :clearable="false">
            <template #option="{label, country_code}">
              <i :class="['flag', country_code.toLowerCase()]"></i> <span>{{label}}</span>
            </template>
            <template #selected-option="{label, country_code}">
              <i :class="['flag', country_code.toLowerCase()]"></i> <span>{{label}}</span>
            </template>
          </v-select>
        </div>

        <footer>
          <button class="btn btn-success btn-sm" :class="{'loading': loading}" @click="setCountry">Continue</button>
        </footer>
      </div>
    </div>

    <header>
      <div class="container-fluid">
        <a href="#" class="menu-toggle" @click.prevent="show_catalog_menu = !show_catalog_menu;"><i class="far fa-bars" :class="{'fa-times': show_catalog_menu}"></i></a>

        <div class="left-nav">
          <router-link :to="{name: 'recognition'}" v-if="me.is_admin || me.is_manager">Back to admin</router-link>
        </div>

        <div class="logo-wrap">
          <router-link :to="{name: 'rewards_home'}">
            <img :src="custom_logo" v-if="custom_logo !== false" height="30" />
            <img src="@/assets/hithrive-logo.svg" v-else height="16" />

            <h2>Rewards</h2>
          </router-link>
        </div>

        <div class="right-nav">
          <a @click.prevent="openHelpWidget" href="#"><i class="fas fa-question"></i></a>
          <router-link :to="{name: 'rewards_redemptions'}"><i class="fas fa-gift"></i></router-link>
          <div class="dropdown">
            <a href="#" class="dropdown-toggle" data-display="static" role="button" data-toggle="dropdown">
              <user-profile-picture />
            </a>

            <div class="dropdown-menu dropdown-menu-right">
              <h6 class="dropdown-header">Hey, {{me.name}}</h6>
              <a class="dropdown-item" href="#" @click.prevent="logout">Logout</a>
              <a class="dropdown-item" href="#" @click.prevent="change_locale=true;">Change country</a>
            </div>
          </div>
        </div>
      </div>
    </header>
    
    <div class="container-fluid">
      <div class="view-sidebar-wrap">
        <aside class="nav-wrap">
          <nav class="header-nav">
            <div class="point-wrap">
              You have <span class="points">{{point_balance}}</span> to redeem.
            </div>

            <div class="catalog-links" :class="{'open': show_catalog_menu}">
              <a href="#" @click.prevent="show_catalog_menu = false;" class="mobile-close"><i class="fas fa-times"></i></a>
              <ul>
                <router-link exact :to="{name: 'rewards_home'}" tag="li" v-if="showHomeNav">
                  <a>
                    <i class="fas fa-home"></i> Rewards
                  </a>
                </router-link>
                <router-link :to="{name: 'rewards_custom_catalog'}" tag="li" v-if="$hasCatalog('CUSTOM_CATALOG')">
                  <a>
                    {{custom_catalog_name}}
                  </a>
                </router-link>
                <router-link :to="{name: 'rewards_amazon'}" tag="li" v-if="$hasCatalog('AMAZON')">
                  <a>
                    <span class="amazon-logo"></span>
                  </a>
                </router-link>
                <router-link :to="{name: 'rewards_gift_cards'}" tag="li" v-if="$hasCatalog('GIFT_CARDS')">
                  <a>
                    Gift Cards
                  </a>
                </router-link>
                <router-link :to="{name: 'rewards_charities_home'}" tag="li" v-if="$hasCatalog('CHARITIES')">
                  <a>
                    Charities
                  </a>
                </router-link>
                <router-link :to="{name: 'rewards_paypal'}" tag="li" v-if="$hasCatalog('PAYPAL')">
                  <a>
                    <span class="paypal-logo"></span>
                  </a>
                </router-link>
                <router-link :to="{name: 'rewards_venmo'}" tag="li" v-if="$hasCatalog('VENMO')">
                  <a>
                    <span class="venmo-logo"></span>
                  </a>
                </router-link>
              </ul>
            </div>
          </nav>
        </aside>

        <router-view  />
      </div>
    </div>

    <div class="container-fluid">
      <footer id="redemption-footer">
        <portal-target name="reward-footer-disclaimers"></portal-target>
        <p>The merchants represented are not sponsors of the rewards or otherwise affiliated with Tango Card, Inc. The logos and other identifying marks attached are trademarks of and owned by each represented company and/or its affiliates. Please visit each company's website for additional terms and conditions.</p>

        <p>The Starbucks word mark and the Starbucks Logo are trademarks of Starbucks Corporation. Starbucks is also the owner of the Copyrights in the Starbucks Logo and the Starbucks Card designs. All rights reserved. Starbucks is not a participating partner or sponsor in this offer.</p>

        <p>This company is not affiliated with Wal-Mart Stores, Inc., Wal-Mart Stores Arkansas, LLC, or Walmart.com or any of their affiliates. Wal-Mart Stores, Inc., Wal-Mart Stores Arkansas, LLC, Walmart.com and their affiliates do not endorse or sponsor this company's services, products, or activities.
        See www.walmart.com/giftcardtermsandconditions for complete gift card terms and conditions.</p>

        <p>The Dunkin’ Donuts trademarks/trade dress are owned by DD IP Holder LLC and used under license. Dunkin’ Donuts is not a sponsor of this promotion.</p>

        <p>&copy; 2019 Apple Distribution International. All rights reserved. Apple is not a participant in or sponsor of this promotion.</p>

        <p>*This company is not affiliated with The Home Depot®. The Home Depot® is not a sponsor of this promotion. The Home Depot® is a registered trademark of Home Depot Product Authority, LLC.</p>

        <p>The merchants represented are not sponsors of the rewards or otherwise affiliated with this company. The logos and other identifying marks attached are trademarks of and owned by each represented company and/or its affiliates. Please visit each company's website for additional terms and conditions.</p>

        <p>Notice for Texas-Based Enterprise Customers ONLY: If you have a complaint about the Visa/Mastercard rewards and/or the non-profit organization (NPO) donation option and services, please first contact Tango Card, Inc. Support at: 877-55-tango (877-558-2646) or via E-mail: success@tangocard.com. If you still have an unresolved complaint regarding the Company’s activity and your organization is based in the state of Texas, please direct your complaint to: Texas Department of Banking, 2601 North Lamar Boulevard, Austin, Texas 78705, 1-877-276-5554 (toll free), www.dob.texas.gov. (For questions or concerns regarding all other products and services that Tango Card provides, please only contact Tango Card directly at the information provided above.)</p>
      </footer>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap';
import { CustomEase } from'gsap/CustomEase';
gsap.registerPlugin(CustomEase)

export default {
  computed: {
    me() {
      return this.$store.state.user;
    },
    team() {
      return this.$store.state.team;
    },
    point_balance() {
      return this.$formatPoints(this.tweened_point_balance);
    },
    country_options() {
      return Object.entries(this.$geoData.all_countries).map(([code, name]) => ({label: name, country_code: code}))
    },
    loading() {
      return this.$store.state.loading;
    },
    need_locale() {
      return this.change_locale === true || this.me.metadata.set_locale !== true;
    },
    custom_logo() {
      return this.team.metadata.logo ? this.team.metadata.logo : false;
    },
    brand_colors() {
      if(this.team.metadata.primary_color) {
        return {
          '--brand-color': `#${this.team.metadata.primary_color}`,
          '--brand-color-2': `#${this.$lightenColor(this.team.metadata.primary_color, 2)}`,
          '--brand-color-3': `#${this.$lightenColor(this.team.metadata.primary_color, 10)}`
        };
      }

      return {
        '--brand-color': '#2D1F40',
        '--brand-color-2': `#${this.$lightenColor('2D1F40', 2)}`,
        '--brand-color-3': `#${this.$lightenColor('2D1F40', 10)}`
      };
    },
    custom_catalog_name() {
      return this.$store.getters.custom_catalog_name;
    },
    showHomeNav() {
      if(
        this.$hasCatalog('AMAZON') ||
        this.$hasCatalog('GIFT_CARDS')
      ) return true;

      return false;
    }
  },
  data() {
    return {
      tweened_point_balance: 0,
      show_catalog_menu: false,
      country: 'US',
      change_locale: false
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      console.log(vm.showHomeNav)
      if(to.name === 'rewards_home' && !vm.showHomeNav && vm.$hasCatalog('CUSTOM_CATALOG')) {
        vm.$router.replace({
          name: 'rewards_custom_catalog'
        })
      }
    })
  },
  mounted() {
    this.tweened_point_balance = this.me.redeemable_points;
  },
  watch: {
    '$route': function() {
      this.show_catalog_menu = false;
    },
    'me.redeemable_points': function(val) {
      gsap.to(this.$data, {duration: 0.5, tweened_point_balance: val, ease: CustomEase.create("custom", "M0,0 C0.11,0.93 0.2,0.96 1,1")})
    }
  },
  methods: {
    openHelpWidget() {
      zE('webWidget', 'open');
    },
    logout() {
      this.$store.dispatch('logout')
      this.$router.replace({name: 'login'})
    },
    async setCountry() {
      this.$store.commit('loading', true)
      await this.$api.User.update_locale(this.country);
      window.location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
#locale-modal {
  ::v-deep .vs--searching {
    .vs__selected {
      display: none;
    }
  }
  ::v-deep .vs__selected,
  ::v-deep .vs__dropdown-option {
    display: flex;
    align-items: center;

    > .flag {
      width: 16px;
      flex: 0 0 16px;
      height: 16px;
      display: block;
      background-repeat: no-repeat;
    }

    > span {
      display: block;
      padding: 0 0 0 10px;
    }
  }
}

.menu-toggle {
  display: block;
  @include media-breakpoint-up(md) {
    display: none;
  }
  position: absolute;
  left: 15px;
  top: 26px;
  font-size: 24px;
  color: #fff;
  text-decoration: none;
}

#redemption-portal {
  .view-sidebar-wrap {
    @include media-breakpoint-up(md) {
      display: flex;

      > aside {
        flex: 0 0 256px;
        min-width: 0;
      }

      > .reward-view {
        flex: 1 1 auto;
        min-width: 0;
        padding: 10px 0 50px 30px;
      }
    }
  }

  .header-nav {

    .point-wrap {
      font-size: 14px;
      color: $muted-text;
      background: $light;
      border-radius: 10px;
      padding: 7px 5px;
      text-align: center;
      margin: 0 0;
      > .points {
        font-size: 12px;
        display: inline-block;
        background: $green;
        color: #fff;
        border-radius: 3px;
        padding: 2px 4px;
        
        line-height: 1.2em;
      }
    }

    .catalog-links {
      position: relative;
      align-items: center;
      margin: 15px 0 0 auto;

      >label {
        display: block;
        margin: 0 5px 0 0;
        font-size: 14px;
        color: $muted-text;
      }
      > ul {
        padding: 0;
        margin: 0;

        > li {
          display: block;
          padding: 3px 0;

          > a {
            display: block;
            font-size: 16px;
            padding: 7px 15px;
            text-decoration: none;
            border-radius: 100em;
            color: $body-color;

            >.amazon-logo {
              $height: 15px;
              vertical-align: -4px;
              height: $height;
              width: $height*3.35;
              background-image: url('../../assets/amazon-logo.svg#amazon-color');
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
              display: inline-block;
            }

            >.paypal-logo {
              $height: 15px;
              vertical-align: -4px;
              height: $height;
              width: $height*3.35;
              background-image: url('../../assets/paypal-logo.svg#paypal-color');
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
              display: inline-block;
            }
            >.venmo-logo {
              $height: 15px;
              vertical-align: -4px;
              height: $height;
              width: $height*3.4;
              background-image: url('../../assets/venmo-logo.svg#venmo-color');
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
              display: inline-block;
            }

            &:hover {
              background: rgba($light, 1);
            }
          }

          &.router-link-active {
            > a {
              background: var(--brand-color);
              color: #fff;

              >.amazon-logo {
                background-image: url('../../assets/amazon-logo.svg#amazon-white');
              }
              >.paypal-logo {
                background-image: url('../../assets/paypal-logo.svg#paypal-white');
              }
              >.venmo-logo {
                background-image: url('../../assets/venmo-logo.svg#venmo-white');
              }
            }
          }
        }
      }

      .mobile-close {
        display: none;

        @include media-breakpoint-down(sm) {
          display: block;
          border-radius: 100%;
          background: $light;
          color: $muted-text;
          text-align: center;
          font-size: 16px;
          width: 34px;
          height: 34px;
          line-height: 34px;
          text-decoration: none;
          position: absolute;
          left: 15px;
          top: 15px;
        }
      }

      @include media-breakpoint-down(sm) {
        position: fixed;
        transform: translateX( calc(-100% - 30px) );
        left: 0;
        right: 75px;
        top: 0;
        bottom: 0;
        background: #fff;
        box-shadow: rgba(0,0,0,0.1) 10px 0 20px;
        z-index: 9999;
        padding: 60px 15px 15px;
        margin: 0;
        transition: transform 0.3s $curve;

        &.open {
          transform: translateX(0);
        }

        > ul {
          display: block;

          > li {
            padding: 5px 0;
            > a {
              display: inline-block;
              height: 50px;
              line-height: 50px;
              border-radius: 10px;
              padding: 0 15px;
            }
          }
        }
      }
    }
  }
}
</style>