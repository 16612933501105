import { render, staticRenderFns } from "./MultiChannelSelector.vue?vue&type=template&id=da06bac6"
import script from "./MultiChannelSelector.vue?vue&type=script&lang=js"
export * from "./MultiChannelSelector.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports