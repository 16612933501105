<template>
  <div class="login">
    <aside>
      <router-view />
    </aside>
  </div>
</template>

<script>
export default {
  name: 'Login',
  components: {
    
  },
  computed: {
  },
  data() {
    return {
    }
  },
  created() {
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.login {
  min-height: 100vh;
  background-image: url('../../assets/login-bg-v2.jpg');
  background-size: cover;
  background-position: center center;

  > aside {
    max-width: 512px;
    padding: 50px;
    position: fixed;
    width: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > div {
      background: #F9F6F4;
      border-radius: 10px;
      position: relative;
      box-shadow: rgba(0,0,0,0.2) 0 10px 20px;
      padding: 50px 35px;
      display: block;
      flex-direction: column;
      justify-content: center;
      flex-wrap: nowrap;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}
</style>