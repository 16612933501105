<template>
  <div class="custom-redemption">
      <div class="thumbnail"><i v-html="$emoji(emoji)"></i></div>

      <div class="title">
        <div class="timestamp">{{$formatDate(date)}}</div>
        <h4>{{title}}</h4>
      </div>

      <div class="status ml-auto">
        <span :class="[status_class]">{{status_text}}</span>
      </div>

      <div class="point-cost">
        <span>{{$formatPoints(points)}}</span>
      </div>
  </div>
</template>

<script>
export default {
  props: ['redemption'],
  computed: {
    status() {
      return this.redemption[0].status;
    },
    title() {
      return this.redemption[0].redemption.name;
    },
    emoji() {
      return this.redemption[0].redemption.emoji;
    },
    status_text() {
      switch( this.status ) {
        case 'PENDING':
          return 'Pending';
        case 'COMPLETE':
          return 'Complete';
      }
    },
    status_class() {
      return 'status-' + this.status.toLowerCase();
    },
    date() {
      return this.redemption[0].created_at;
    },
    points() {
      return this.redemption[0].points;
    }
  }
}
</script>