<template>
  <main class="app-view recognition recognition-dashboard">
    <header>
      <div class="title">
        <ul class="crumbs">
          <li>
            <router-link :to="{name: 'recognition'}">Recognition</router-link>
          </li>
        </ul>
        <h1>Settings</h1>
      </div>
    </header>

    <section class="main-section">
      <header>
        <h2>Shoutout Settings</h2>
      </header>

      <div class="content">

        <div class="setting-row taggable-values">
          <i class="fas fa-tag"></i>
          <div class="title">
            <h4>Taggable Values</h4>
            <p>Company values or statements that shoutouts can be tagged with.</p>
          </div>
          <div class="fields">
            <ul class="values">
              <li v-for="value in taggable_values" @click="deleteTaggableValue(value.hash)" :class="['value-field', {'adding-row':value.hash==='new'}, {'saved-value':value.hash!=='new'}]" :key="`value_${value.hash}`">
                <template v-if="value.hash === 'new'">
                  <input type="text" placeholder="type value and hit enter..." ref="addTaggableValueField" v-model="value.text" class="form-control form-control-sm" @keypress.enter="addTaggableValue" @blur="cancelAddTaggableValue" />
                  <button class="btn btn-primary btn-circle" @click.stop="addTaggableValue"><i class="fas fa-check"></i></button>
                </template>
                <template v-else>
                  <span>{{value.text}}</span>
                </template>
              </li>
              <li class="add-row" v-if="can_add_value">
                <button class="btn btn-light btn-circle" @click="newTaggableValue"><i class="fas fa-plus"></i></button>
              </li>
            </ul>
          </div>
        </div>

        <div class="setting-row taggable-values">
          <i class="fas fa-asterisk"></i>
          <div class="title">
            <h4>Require Tagged Value</h4>
            <p>Set if tagged values should be required when sending a shoutout.</p>
          </div>
          <div class="fields">
            <ht-switch v-model="require_values" />
          </div>
        </div>

      </div>
    </section>

    <footer>
      <button class="btn btn-green btn-sm" :class="{'loading': loading}" @click="save">Save</button>
    </footer>
  </main>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'RecognitionSettings',
  computed: {
    can_add_value() {
      const last_value = this.taggable_values[ this.taggable_values.length - 1 ]
      return last_value ? last_value.hash !== 'new' : true;
    },
    team() {
      return this.$store.state.team;
    },
    loading() {
      return this.$store.state.loading;
    }
  },
  data() {
    return {
      taggable_values: [],
      require_values: false
    }
  },
  validations: {
    taggable_values: {
      $each: {
        //check for dupes
        no_dupe: function (value) {
          if( value.hash !== 'new' || value.text.trim().length === 0 ) return true;
          
          const has_dupe = this.taggable_values.findIndex( (v) => v.hash === this.$md5(value.text) )
          if( has_dupe > -1 ) return false;
          
          return true;
        }
      }
    }
  },
  created() {
    this.taggable_values = this.team.shoutout_values;
    this.require_values = this.team.metadata.require_values === true ? true : false;
  },
  methods: {
    deleteTaggableValue(value_hash) {
      if(value_hash === 'new') return;
      
      this.taggable_values.splice(
        this.taggable_values.findIndex((v) => v.hash === value_hash),
        1
      )
    },
    addTaggableValue() {
      this.$v.$touch();

      if( this.$v.$invalid ) {
        console.log('invalid');
        return;
      }

      this.taggable_values = this.taggable_values.map((v) => {
        if(v.hash === 'new') v.hash = this.$md5(v.text.trim());
        return v;
      })
    },
    cancelAddTaggableValue() {
      const last_value = this.taggable_values[ this.taggable_values.length - 1 ]
      if(last_value.text.trim().length > 0) return;

      this.taggable_values.splice(this.taggable_values.length - 1, 1)
    },
    newTaggableValue() {
      this.$v.$reset();

      this.taggable_values.push(
        {
          hash: 'new',
          text: ''
        }
      )

      this.$nextTick( () => this.$refs.addTaggableValueField[0].focus() );
    },
    async save() {
      this.$store.commit('loading', true);
      await Promise.all([
        this.$store.dispatch('teamMetadata', {require_values: this.require_values}),
        this.$api.Team.update_values(
          _.map(this.taggable_values, 'text')
        )
      ])

      if(this.taggable_values.length > 0) {
        this.$store.dispatch('teamMetadata', {added_tag_values: true});
      }

      this.$toast.success('Recognition settings have been saved.')
      this.$store.commit('loading', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.setting-row.taggable-values {
  ul.values {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    margin: -3px -3px 7px;
    padding: 0;

    > li {
      display: block;
      flex: 0 0 auto;
      white-space: nowrap;
      margin: 3px 3px;

      > input {
        margin: 0 10px 0 0;
        flex: 1 1 auto;
        display: block;

        &+button {
          display: block;
          flex: 0 0 auto;
          font-size: 14px;
        }
      }

      &.adding-row {
        width: 100%;
        flex: 0 0 100%;
        display: flex;
      }

      &.saved-value {
        padding: 3px 5px;
        border-radius: 3px;
        background: $light;
        cursor: pointer;
        user-select: none;
        position: relative;
        overflow: hidden;

        &:after {
          content: '\f1f8';
          display: block;
          background: linear-gradient(270deg, rgba(239,236,234,1) 0%, rgba(239,236,234,0) 100%);
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          padding: 0 5px 0 0;
          width: 60px;
          font-family: 'Font Awesome 5 Pro';
          color: $red;
          font-weight: 700;
          text-align: right;
          line-height: 28px;
          font-size: 12px;
          opacity: 0;
          transform: translateX(100%);
          transition: opacity 0.2s $curve, transform 0.2s $curve;
        }

        &:hover {
          background: darken($light, 3%);

          &:after {
            opacity: 1;
            transform: none;
          }
        }
      }
    }
  }
}
</style>