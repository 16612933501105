<template>
  <label :class="['ht-switch', {'disabled': disabled, 'loading': loading}]">
    <input type="checkbox" :disabled="disabled" @change.stop="$e => $emit('input', $e.currentTarget.checked)" :checked="value" />
    <div>
      <span></span>
    </div>
  </label>
</template>

<script>
export default {
  props: {
    'value': Boolean,
    'disabled': Boolean,
    'loading': Boolean
  }
}
</script>