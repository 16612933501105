<template>
  <main class="app-view settings settings-integrations-gusto">
    <div class="loading-window">
      <header>
        <i class="ht">
          <img src="@/assets/hithrive-icon.png" />
        </i>
        <i class="tp">
          <img src="@/assets/gusto-icon.svg" />
        </i>
      </header>

      <h1>Just a moment...</h1>
      <p>We're connecting to your Gusto account, this should only take a few moments.</p>
    </div>
  </main>
</template>

<script>
export default {
  mounted() {
    this.handleAuth()
  },
  methods: {
    async handleAuth() {
      const {
        code
      } = this.$route.query;

      //exchange code for api key
      const resp = await this.$api.Integrations.Gusto.exchange_code(code)
      .catch((e) => false)
      //HANDLE ERROR

      if(resp === false) {
        // throw error
        this.$toast.error('An error has occurred.')
        return;
      }

      await this.$store.dispatch('populateIntegrations')

      this.$toast.success('Gusto successfully connected.')

      this.$router.replace({
        name: 'integrations-home',
        params: {
          open: 'Gusto'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.loading-window {
  background: $light;
  border-radius: 10px;
  padding: 25px;
  max-width: 512px;
  margin: 100px auto 0;

  > header {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: -50px;

    > i {
      display: block;
      background: #fff;
      border-radius: 100%;
      overflow: hidden;
      height: 60px;
      width: 60px;
      position: relative;
      pointer-events: none;
      box-shadow: #fff 0 0 0 5px, rgba(0,0,0,0.1) 0 10px 15px;
      z-index: 2;

      &.ht {
        animation: loadinght 2s $curve infinite;
      }

      &.tp {
        animation: loading 2s $curve infinite;
      }

      > img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

      }
    }
  }

  h1 {
    font-weight: 700;
    text-align: center;
    font-size: 22px;
    margin: 35px 0 10px;
  }

  p {
    text-align: center;
  }
}

@keyframes loading {
  0% {
    transform: translateX(0);
    z-index: 0;
  }
  50% {
    transform: translateX(-100%);
    z-index: 0;
  }
  51% {
    transform: translateX(-100%);
    z-index: 1;
  }
  100% {
    transform: translateX(0);
    z-index: 1;
  }
}

@keyframes loadinght {
  0% {
    transform: translateX(0);
    z-index: 1;
  }
  50% {
    transform: translateX(100%);
    z-index: 1;
  }
  51% {
    transform: translateX(100%);
    z-index: 0;
  }
  100% {
    transform: translateX(0);
    z-index: 0;
  }
}
</style>