<template>
  <div class="reward-view container-fluid" id="venmo-details">
    <div id="dest-verification" class="ht-popup" v-if="verification">
      <div class="window">
        <header>
          <h2>Verify Your Phone Number</h2>
        </header>

        <div class="content">
          <p>
            This looks like a new phone number. Before you can continue, please
            enter the 4-digit verification code that was just texted to you.
          </p>

          <input
            type="text"
            class="form-control form-control-lg"
            v-model="verification_code"
            placeholder="0000"
            maxlength="4"
          />
        </div>

        <footer>
          <button
            class="btn btn-sm btn-light"
            @click.prevent="verification = false"
          >
            Cancel
          </button>
          <button
            class="btn btn-success btn-sm"
            :class="{ loading: loading }"
            :disabled="loading"
            @click="verifyCode"
          >
            Continue
          </button>
        </footer>
      </div>
    </div>
    <main>
      <header>
        <div class="title">
          <ul class="crumbs">
            <li>
              <router-link :to="{ name: 'rewards_home' }">Rewards</router-link>
            </li>
          </ul>

          <h1><img src="@/assets/venmo-logo.svg#venmo-color" height="16" /></h1>
        </div>
      </header>

      <section class="main-section" id="details-section">
        <div class="steps-wrap">
          <div id="point-amt">
            <h4>
              <i>1</i>
              Amount of {{ team.points_name }} to cash out
            </h4>
            <p class="desc">
              Enter amount of {{ team.points_name }} to convert to local
              currency.
            </p>

            <div
              class="field-wrap"
              :class="{ 'is-invalid': $v.amount.$invalid }"
            >
              <div>
                <div class="input-group input-group-sm">
                  <input
                    type="text"
                    v-model.number="$v.amount.$model"
                    class="form-control"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">{{ team.points_name }}</span>
                  </div>
                </div>
              </div>
              <i class="fas fa-arrow-right"></i>
              <div>
                <span class="currency-amount"
                  >{{ currency_amount
                  }}<sup v-if="currency_code !== 'USD'">*</sup></span
                >
              </div>
            </div>
            <div class="invalid-feedback">
              Must be at least 100 {{ team.points_name }}.
            </div>

            <p class="help-text" v-if="currency_code !== 'USD'">
              *This is an estimated amount due to currency conversion rates.
            </p>
          </div>

          <div id="destination">
            <h4>
              <i>2</i>
              Where to send money
            </h4>
            <p class="desc">
              Enter the phone number of the Venmo account you're sending money
              to.
            </p>

            <div :class="{ 'is-invalid': $v.destination.$error }">
              <input
                type="text"
                placeholder="(555) 123-1234"
                v-model="$v.destination.$model"
                class="form-control form-control-sm"
              />
            </div>
            <div class="invalid-feedback">Must be a valid phone number.</div>
          </div>

          <div id="confirm">
            <h4>
              <i>3</i>
              Confirm transaction
            </h4>
            <p class="desc">
              Verify the amount of {{ team.points_name }} and Venmo phone number
              is correct.
            </p>

            <button
              class="btn btn-block btn-success"
              :class="{ loading: loading }"
              :disabled="loading || $v.$invalid"
              @click.prevent="attemptTransaction"
            >
              Cash out to Venmo
            </button>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import { required, minValue, helpers } from 'vuelidate/lib/validators';

export default {
  computed: {
    team() {
      return this.$store.state.team;
    },
    loading() {
      return this.$store.state.loading;
    },
    currency_rates() {
      return this.$store.state.currency_rates;
    },
    currency_code() {
      const locale = this.$store.state.user.locale;
      const separator = locale.indexOf('-') > -1 ? '-' : '_';
      const splitLocale = locale.split(separator);

      const country = splitLocale[1].toUpperCase();

      return this.$paypalCurrencies[country] || 'USD';
    },
    fee() {
      return this.$paypalFees[this.currency_code];
    },
    currency_amount() {
      const conversion_rate = this.currency_rates[this.currency_code];
      const currency_amount = Math.max(
        0,
        (this.amount / 100) * conversion_rate - this.fee
      );

      return this.$formatCurrency(currency_amount, this.currency_code, true);
    },
  },
  validations: {
    amount: {
      required,
      minValue: minValue(100),
    },
    destination: {
      required,
      phone: (number) => {
        number = number.replace(/[^\d+]+/g, '');
        number = number.replace(/^00/, '+');
        if (number.match(/^1/)) number = '+' + number;
        if (!number.match(/^\+/)) number = '+1' + number;

        return /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/g.test(
          number
        );
      },
    },
  },
  data() {
    return {
      amount: 100,
      destination: '',
      verification_code: '',
      verification_error: null,
      verification: false,
    };
  },
  watch: {
    verification() {
      this.verification_code = '';
    },
  },
  methods: {
    async verifyCode() {
      this.verification_error = null;
      const resp = await this.$api.RewardPortal.PayPal.verify_destination(
        this.destination,
        this.verification_code
      );
      if (resp.error === 'INVALID_CODE') {
        this.verification_error = 'The code you entered is invalid.';
      } else if (resp.ok === true) {
        this.verification = false;
        this.attemptTransaction();
      }
    },
    async attemptTransaction() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.$store.commit('loading', true);
      const resp = await this.$api.RewardPortal.PayPal.redeem_venmo(
        this.amount,
        this.destination
      ).catch((e) => e.data);
      this.$store.commit('loading', false);

      if (resp.error) {
        if (resp.error === 'VERIFY_DESTINATION') {
          this.verification = true;
        }

        if (resp.error === 'INSUFFICIENT_FUNDS') {
          this.$toast.error(
            'PayPal and Venmo redemptions are temporarily unavailable, an administrator has been notified.'
          );
        }
        if (resp.error === 'INSUFFICIENT_POINTS') {
          this.$toast.error(
            `You don't have enough ${this.team.points_name} for this redemption.`
          );
        }

        return;
      }

      //success
      this.$store.dispatch('adjustRedeemablePoints', -Math.abs(this.amount));
      //this.$toast.success('Your gift card redemption is processing.')
      this.$router.push({
        name: 'rewards_redemptions',
        params: {
          redemption_id: resp.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.help-text {
  font-size: 10px;
  color: $muted-text;
  margin: 10px 0 0;
}

.steps-wrap {
  @include media-breakpoint-up(lg) {
    display: flex;
  }

  background: $light;
  padding: 5px;
  border-radius: 10px;
  margin: 25px 0 0;

  > div {
    display: block;
    flex: 1;
    padding: 15px;

    &#point-amt {
      .field-wrap {
        display: flex;

        > div {
          flex: 1;
        }

        > i {
          flex: 0 0 auto;
          margin: 10px 10px 0;
          font-size: 14px;
          color: $muted-text;
        }
      }

      .currency-amount {
        background: $green;
        border-radius: 4px;
        color: #fff;
        padding: 0 10px;
        display: inline-block;
        font-size: 14px;
        height: 31px;
        line-height: 31px;
      }
    }

    > h4 {
      font-size: 16px;
      font-weight: 700;
      margin: 0 0 5px;

      + p {
        font-size: 12px;
        margin: 0 0 15px;
        color: $muted-text;
      }

      > i {
        background: $muted-text;
        color: #fff;
        border-radius: 100%;
        width: 20px;
        height: 20px;
        display: inline-block;
        text-align: center;
        line-height: 20px;
        font-size: 12px;
        font-style: normal;
        text-shadow: rgba(0, 0, 0, 0.2) 0 -1px 0;
        vertical-align: 2px;
        margin: 0 3px 0 0;
      }
    }
  }
}
</style>
