<template>
  <main class="app-view users users-profile" v-if="user">
    <header>
      <div class="title">
        <ul class="crumbs">
          <li>
            <router-link :to="{name: 'users'}">Users</router-link>
          </li>
        </ul>
        <h1><user-card :user="user" /></h1>
      </div>
    </header>
    
    <router-view :user="user" @refresh="populateUser(user.id)" />
  </main>
</template>

<script>
export default {
  data() {
    return {
      user: null
    }
  },
  created() {
    if(this.$route.params.user) this.populateUser(this.$route.params.user);
    else this.populateUser(this.$route.params.id);
    
  },
  methods: {
    async populateUser(user) {
      if(typeof user === 'object') {
        this.user = _.cloneDeep(user);
      }
      else {
        //populate from id
        const resp = await this.$api.Team.get_user_by_id(user)
        this.user = resp;
      }
    },
  }
}
</script>