<template>
  <main class="app-view competitions awards-competitions">
    <header>
      <div class="title">
        <ul class="crumbs">
          <li>
            <router-link :to="{name: 'awards'}">Awards</router-link>
          </li>
        </ul>
        <h1>Competitions</h1>
      </div>
    </header>

    <section class="main-section">
      <header>
        <h2>All Competitions</h2>

        <div class="btns">
          <button class="btn btn-secondary btn-circle" @click="newCompetition"><i class="fas fa-plus"></i></button>
        </div>
      </header>

      <div class="content active-competitions">
        <template v-if="active_competitions && active_competitions.length > 0">
          <div class="table-header">
            <span class="title">Competition</span>
            <span class="timestamps">Start / end date</span>
            <span class="group">Channel</span>
            <span class="award">Award</span>
          </div>
          <ul>
            <li v-for="competition in active_competitions" :class="{'active': competition.status === 'STARTED'}" :key="`competition_${competition.id}`">
              <div class="title">
                <h4><a href="#" @click.prevent="viewCompetition(competition)">{{competition.title}}</a> <small>{{competition.type === 'PVP' ? 'PvP' : 'GvG'}}</small></h4>
                <span class="type">{{competition_type(competition)}}</span>
              </div>
              <div class="dates">
                <span><strong>Start: </strong>{{$formatDate(competition.start_time)}}</span>
                <span><strong>End: </strong>{{$formatDate(competition.end_reset_time)}}</span>
              </div>
              <div class="group">
                <span v-if="competition.user_group"><i class="fas fa-users"></i> {{competition.user_group.title}}</span>
              </div>
              <div class="award">
                <span><i class="fas fa-trophy"></i> {{competition.award.name}}</span>
              </div>

              <div class="actions">
                <a href="#" class="btn btn-light btn-xs" @click.prevent="viewLeaderboard(competition)">View leaderboard</a>
              </div>
            </li>
          </ul>
        </template>
        <div class="empty-state" v-else-if="active_competitions && active_competitions.length == 0">
          <p>No active competitions.</p>
        </div>
        <div class="loading-placeholder" v-else-if="!active_competitions"><i></i><i></i><i></i></div>
      </div>
    </section>

    <section class="main-section" v-if="competition_results === null || (competition_results && competition_results.length > 0)">
      <header>
        <h2>Competition History</h2>
      </header>

      <div class="content recent-results">
        <template>
          <div class="table-header">
            <span class="title">Competition</span>
            <span class="winner">Winner</span>
            <span class="group">Channel</span>
          </div>

          <ul>
            <li v-for="result in competition_results" :key="`result_${result.id}`">
              <div class="title">
                <h4>{{result.title}}</h4>
              </div>

              <div class="winner">
                <span v-if="result.reward.winners.length === 0">No winner</span>
                <span v-if="result.competition.type === 'PVP' && result.reward.winners.length > 0"><user-preview :users="$_.map(result.reward.winners, 'user')" /></span>
                <span v-if="result.competition.type === 'TVT' && result.reward.winners.length > 0">{{$_.map(result.reward.winners, 'group.title').join(', ')}}</span>
              </div>

              <div class="group">
                <span v-if="result.competition.user_group"><i class="fas fa-users"></i> {{result.competition.user_group.title}}</span>
              </div>

              <div class="actions">
                <a href="#" class="btn btn-light btn-xs" @click.prevent="viewResults(result)">View results</a>
              </div>
            </li>
          </ul>
        </template>

        <div class="loading-placeholder" v-if="!competition_results"><i></i><i></i><i></i></div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: 'Competitions',
  computed: {
    
  },
  data() {
    return {
      active_competitions: null,
      competition_results: null
    }
  },
  created() {
    this.populateCompetitions();
  },
  methods: {
    populateCompetitions() {
      this.active_competitions = null;
      this.$api.Competitions.get_active().then((resp) => {
        this.active_competitions = resp;
      })

      this.competition_results = null;
      this.$api.Competitions.get_results().then((resp) => {
        this.competition_results = resp;
      })
    },
    competition_type(competition) {
      if(competition.type === 'PVP') return competition.metric === 'SHOUTOUT_COUNT' ? `User who receives the most shoutouts wins.` : `User who earns the most points wins.`;
      return competition.metric === 'SHOUTOUT_COUNT' ? `Group who receives the most shoutouts wins.` : `Group who earns the most points wins.`;
    },
    newCompetition() {
      this.$root.$emit(
        'openModal',
        'Competitions/CreateCompetition',
        {},
        (result) => {
          if(result === 'COMPETITION_CREATED') {
            this.populateCompetitions();
          }
        }
      )
    },
    viewCompetition(competition) {
      this.$root.$emit(
        'openModal',
        'Competitions/ViewCompetition',
        competition,
        (result) => {
          if(result === 'ARCHIVED_COMPETITION') {
            this.populateCompetitions();
          }
        }
      )
    },
    viewLeaderboard(competition) {
      this.$root.$emit(
        'openModal',
        'Competitions/Leaderboard',
        competition,
        () => {

        }
      )
    },
    viewResults({results, competition}) {
      this.$root.$emit(
        'openModal',
        'Competitions/Results',
        {
          leaderboard: results,
          competition
        },
        () => {

        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.empty-state {
  p {
    margin: 0 0 0;
    color: $muted-text;
  }
}
.content.recent-results {
  .table-header {
    margin: 0 0;
    padding: 0 10px 10px 16px;
    display: flex;
    align-items: flex-end;

    > span {
      padding: 0 15px;
      min-width: 0;
      font-size: 12px;
      color: $muted-text;
      font-weight: 700;
      display: block;
      line-height: 1.2em;

      &.title {
        flex: 0 1 28%;
        padding-left: 0;
      }

      &.timestamps {
        flex: 0 1 150px;
      }

      &.winner, &.group {
        flex: 0 1 25%;
      }
    }
  }

  > ul {
    padding: 0;
    margin: 0;
    display: block;

    > li {
      display: flex;
      padding: 10px 10px 10px 16px;
      background: $light;
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      align-items: center;

      + li {
        margin-top: 15px;
      }

      .group, .winner {
        flex: 0 1 25%;
        padding: 0 15px;
        white-space: nowrap;

        > span {
          font-size: 14px;
          > i {
            font-size: 12px;
            color: $muted-text;
            vertical-align: 0;
            margin: 0 2px 0 0;
          }
        }
      }

      .title {
        flex: 0 1 28%;
        padding-left: 0;
      }

      .user--preview {
        display: inline-flex;
      }

      .actions {
        margin: 0 0 0 auto;
      }

      h4 {
        font-size: 14px;
        font-weight: 700;
        margin: 0 0 2px;

        > small {
          display: inline-block;
          font-size: 10px;
          color: $body-color;
          font-weight: 700;
          background: darken($light, 10%);
          border-radius: 3px;
          padding: 2px 3px;
          line-height: 10px;
          vertical-align: 1px;
          margin: 0 0 0 3px;
        }
      }
    }
  }
}

.content.active-competitions {
  .table-header {
    margin: 0 0;
    padding: 0 10px 10px 16px;
    display: flex;
    align-items: flex-end;

    > span {
      padding: 0 10px;
      min-width: 0;
      font-size: 12px;
      color: $muted-text;
      font-weight: 700;
      display: block;
      line-height: 1.2em;

      &.title {
        flex: 0 1 28%;
        padding-left: 0;
      }

      &.timestamps {
        flex: 0 1 150px;
      }

      &.award, &.group {
        flex: 0 1 18%;
      }
    }
  }

  > ul {
    padding: 0;
    margin: 0;

    > li {
      display: flex;
      padding: 10px 10px 10px 16px;
      background: $light;
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      align-items: center;

      + li {
        margin-top: 15px;
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 6px;
        background: $secondary;
      }

      &.active {
        &:before {
          background: $green;
        }
      }

      .title {
        padding: 0 10px 0 0;
        flex: 0 1 28%;

        .type {
          font-size: 11px;
          color: $muted-text;
          margin: 0;
          line-height: 1.2em;
          display: block;
        }
      }

      h4 {
        font-size: 14px;
        font-weight: 700;
        margin: 0 0 2px;

        > small {
          display: inline-block;
          font-size: 10px;
          color: $body-color;
          font-weight: 700;
          background: darken($light, 10%);
          border-radius: 3px;
          padding: 2px 3px;
          line-height: 10px;
          vertical-align: 1px;
          margin: 0 0 0 3px;
        }
      }

      .award, .group {
        flex: 0 1 18%;
        padding: 0 10px;

        > span {
          font-size: 12px;
          white-space: nowrap;
          > i {
            font-size: 12px;
            color: $muted-text;
            vertical-align: 0px;
            margin: 0 2px 0 0;
          }
        }
      }

      .dates {
        font-size: 12px;
        padding: 0 10px;
        flex: 0 1 150px;
        > span {
          display: block;
          line-height: 1.35em;

          > strong {
            color: $muted-text;
          }
        }
      }

      .actions {
        margin: 0 0 0 auto;

        >.btn {
          display: block;

          &+.btn {
            margin: 2px 0 0;
          }
        }
      }
    }
  }

  .loading-placeholder {
    display: block;
    margin: 0 0;
    > i {
      margin: 0 0;
      +i { 
        margin-top: 15px;
      }
      //flex: 1 0 auto;
      display: block;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center center;
      background-image: url("data:image/svg+xml,%3Csvg width='951' height='50' preserveAspectRatio='none' viewBox='0 0 951 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='951' height='50' rx='4' fill='%23E4E1DF'/%3E%3C/svg%3E%0A");
      
      &:before {
        content: '';
        display: block;
        height: 50px;
      }
    }
  }
}
</style>