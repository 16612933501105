<template>
  <div class="reward-view" id="charities">
    <main>
      <header>
        <div class="title">
          <ul class="crumbs">
            <li>
              <router-link :to="{name: 'rewards_home'}">Rewards</router-link>
            </li>
          </ul>
          <h1>Charities</h1>
        </div>

        <div class="search-wrap" v-if="!whitelist_override">
          <input type="text" class="form-control" placeholder="start typing to search..." v-model="query" />
        </div>
      </header>

      <template v-if="whitelist_override">
        <section class="main-section" id="whitelist-charities" v-if="!!whitelist_page">
          <header>
            <h2>All Charities</h2>
          </header>
          <ul>
            <li v-for="(charity, index) in whitelist_page" :key="`whitelist_charity_${index}`">
              <router-link v-bind:to="{name: 'rewards_charities_charity', params: {ein: charity.charity_ein.replace('-', '')}}">
                <div class="thumbnail">
                  <div class="thumb" :style="{'background-image': `url(${charity.charity_logo.url})`}"></div>
                </div>
                <h4>{{charity.charity_name[0].text}}</h4>
              </router-link>
            </li>
          </ul>
        </section>
      </template>
      <template v-else>
        <section class="main-section" id="charity-categories" v-if="charities === null">

          <div class="category nearby" v-if="featured_charities !== null">
            <header>
              <h2>{{featured_charities_title}}</h2>
            </header>
            <ul>
              <li v-for="(charity, index) in featured_charities" :key="`charity_${index}_${charity.charity_ein}`">
                <router-link v-bind:to="{name: 'rewards_charities_charity', params: {ein: charity.charity_ein.replace('-', '')}}">
                  <div class="thumbnail">
                    <div class="thumb" :style="{'background-image': `url(${charity.charity_logo.url})`}"></div>
                  </div>
                  <h4>{{charity.charity_name[0].text}}</h4>
                </router-link>
              </li>
            </ul>
          </div>
          <div class="category nearby" v-else-if="nearby_charities === null || nearby_charities.length > 0">
            <header>
              <h2>Nearby Non-profits</h2>
            </header>
            <ul v-if="nearby_charities !== null">
              <li v-for="charity in nearby_charities" :key="`charity_nearby_${charity.ein}`">
                <router-link :to="{name: 'rewards_charities_charity', params: {ein: charity.ein}}">
                  <div class="thumbnail">
                    <img :src="`https://hithrive-giving.herokuapp.com/nonprofits/${charity.ein}/logo`" @load="$set(charity, 'has_logo', true)" class="logotest" />
                    <div class="thumb" :style="{'background-image': charityLogo(charity)}"></div>
                  </div>
                  <h4>{{charity.name}}</h4>
                </router-link>
              </li>
            </ul>
            <div v-else class="loading-placeholder"><i></i><i></i><i></i><i></i></div>
          </div>
          
          <div class="category" v-for="(category, index) in categories" :key="`category_${index}`">
            <header>
              <h2>{{category.primary.category_title[0].text}}</h2>
            </header>
            <ul>
              <li v-for="charity in category.items" :key="`charity_${index}_${charity.charity_ein}`">
                <router-link v-bind:to="{name: 'rewards_charities_charity', params: {ein: charity.charity_ein.replace('-', '')}}">
                  <div class="thumbnail">
                    <img v-bind:src="charity.charity_logo.url" />
                  </div>
                  <h4>{{charity.charity_title[0].text}}</h4>
                </router-link>
              </li>
            </ul>
          </div>
        </section>
        <section class="main-section" id="charity-results" v-else>
          <template v-if="charities !== -1 && charities.length > 0">
            <ul>
              <router-link v-for="c in charities" :key="`charity_${c.id}`" tag="li" :to="{name: 'rewards_charities_charity', params: {ein: c.ein}}">
                <a>
                  <div class="icon" v-if="c.logo != null" :style="{'background-image': `url('https://img-cdn.hithrive.com/nonprofit-logos/${c.logo}')`}"></div>
                  <h4>{{c.dba || c.name}}<small v-if="!!c.dba">{{c.name}}</small></h4>

                  <div class="tags">
                    <span>{{c.city}}, {{c.state}}</span>
                    <span v-if="$nonprofitTaxonomy(c.classification_code)">{{$nonprofitTaxonomy(c.classification_code)}}</span>
                  </div>
                </a>
              </router-link>
            </ul>
          </template>
          <template v-else-if="charities === -1">
            <loading-indicator />
          </template>
          <div class="empty-state" v-else>
            No charities were found that match your search.
          </div>
        </section>
      </template>
    </main>
  </div>
</template>

<script>
import axios from 'redaxios'

export default {
  computed: {
    prismic_ref() {
      return this.$store.state.prismic_ref;
    },
    user() {
      return this.$store.state.user;
    },
    geo_ip() {
      if(this.user.metadata.force_geo) return {
        latitude: this.user.metadata.force_geo.split(',')[0],
        longitude: this.user.metadata.force_geo.split(',')[1]
      };

      return this.$store.state.geo_ip;
    },
    whitelist_override() {
      return this.$store.state.team.metadata.charity_whitelist || null;
    },
    team() {
      return this.$store.state.team;
    }
  },
  watch: {
    query(val) {
      if(val.trim().length === 0) {
        this.charities = null;
      }
      else {
        this.charities = -1;
        this.populateCharities()
      }
    }
  },
  data() {
    return {
      categories: null,
      query: '',
      nearby_charities: null,
      charities: null,
      whitelist_page: null,
      featured_charities: null,
      featured_charities_title: ''
    }
  },
  created() {
    if(!this.whitelist_override) {
      this.populateCategories()
      if(this.team.metadata.featured_charities) {
        this.populateFeaturedCharities()
      }
      else {
        this.populateNearbyCharities()
      }
    }
    else {
      this.populateWhitelist()
    }
  },
  methods: {
    charityLogo(charity) {
      if(charity.has_logo === true) return `url('https://hithrive-giving.herokuapp.com/nonprofits/${charity.ein}/logo')`;
      return `url('${require('@/assets/logo-placeholder.jpg')}')`;
    },
    async populateFeaturedCharities() {
      if(this.prismic_ref === null) {
        await this.$store.dispatch('populatePrismicRef')
      }

      const id = this.team.metadata.featured_charities;

      const resp = await axios.get(`https://hithrive.cdn.prismic.io/api/v2/documents/search?ref=${this.prismic_ref}&q=[[at(my.whitelisted_charities.uid,"${id}")]]`).then(d => d.data)
      const page = resp.results[0].data;

      this.featured_charities_title = page.title[0].text;
      this.featured_charities = page.charities;
    },
    async populateWhitelist() {
      if(this.prismic_ref === null) {
        await this.$store.dispatch('populatePrismicRef')
      }

      const id = this.whitelist_override;

      const resp = await axios.get(`https://hithrive.cdn.prismic.io/api/v2/documents/search?ref=${this.prismic_ref}&q=[[at(my.whitelisted_charities.uid,"${id}")]]`).then(d => d.data)
      const page = resp.results[0].data;

      this.whitelist_page = page.charities;

      //this.categories = _.filter(page, p => p.slice_type == 'post_category');
    },
    populateNearbyCharities() {
      const _loadCharities = async () => {
        const resp = await axios.get(`https://hithrive-giving.herokuapp.com/nonprofits/nearby/${this.geo_ip.latitude},${this.geo_ip.longitude}`)

        this.nearby_charities = resp.data;
      };

      if(this.geo_ip === null) {
        const unwatch = this.$watch('geo_ip', (val) => {
          _loadCharities();
          unwatch();
        })
      }
      else {
        _loadCharities()
      }
    },
    async populateCategories() {
      if(this.prismic_ref === null) {
        await this.$store.dispatch('populatePrismicRef')
      }

      const resp = await axios.get(`https://hithrive.cdn.prismic.io/api/v2/documents/search?ref=${this.prismic_ref}&q=[[at(document.type,"charity_page")]]&orderings=[document.last_publication_date desc]`);

      const page = resp.data.results[0].data.body;

      this.categories = _.filter(page, p => p.slice_type == 'post_category');
    },
    populateCharities: _.debounce( async function() {
      this.charities = -1;
      const resp = await this.$api.RewardPortal.Charities.search(this.query);
      this.charities = resp;
    }, 500 )
  }
}
</script>

<style lang="scss" scoped>
#charity-results {
  ul {
    padding: 0;
    margin: 35px 0 0 ;
    display: block;
    > li {
      padding: 0;
      margin: 0;
      display: block;

      +li {
        border-top: 1px solid $light;
      }
      > a {
        display: block;
        @include media-breakpoint-up(lg) {
          display: flex;
        }
        text-decoration: none;
        padding: 15px 40px 15px 5px;
        position: relative;
        align-items: center;
        color: $body-color;

        &:hover {
          background: rgba($light, 0.5);
        }

        &:after {
          content: '\f054';
          display: block;
          position: absolute;
          right: 0;
          @include media-breakpoint-up(lg) {
            right: 5px;
          }
          top: 50%;
          transform: translateY(-50%);
          font-family: 'Font Awesome 5 Pro';
          color: rgb(181, 181, 181);
          margin-top: 1px;
        }

        .icon {
          width: 40px;
          height: 40px;
          background-size: cover;
          background-position: center center;
          background-color: #fff;
          box-shadow: rgba($body-color, 0.05) 0 5px 10px;
          margin: 0 10px 0 0;
          border-radius: 10px;
        }

        h4 {
          font-size: 14px;
          @include media-breakpoint-up(lg) {
            font-size: 16px;
          }
          font-weight: 700;
          margin: 0;

          > small {
            display: block;
            line-height: 16px;
            font-size: 12px;
            color: $muted-text;
            font-style: italic;
          }
        }

        .tags {
          display: flex;
          margin: 5px -3px 0;
          @include media-breakpoint-up(lg) {
            margin: 0 0 0 10px;
          }

          > span {
            display: block;
            height: 16px;
            border-radius: 100em;
            background: $light;
            font-size: 11px;
            padding: 0 8px;
            line-height: 16px;
            margin: 0 3px;
            color: $muted-text;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

.search-wrap {
  margin: 0 0 4px auto;

  > input {
    height: 28px;
    font-size: 14px;
    border-radius: 100em;
    width: 256px;
    max-width: 100%;
  }
}

.loading-placeholder {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  > i {
    margin: 10px 10px;
    flex: 0 0 calc(20% + 20px);
    display: block;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    background-image: url("data:image/svg+xml,%3Csvg width='178' height='125' preserveAspectRatio='none' viewBox='0 0 178 125' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='178' height='125' rx='4' fill='%23E4E1DF'/%3E%3C/svg%3E%0A");
    
    &:before {
      content: '';
      display: block;
      padding: 0 0 60%;
    }
  }
}

#whitelist-charities {
  > ul {
    display: flex;
    padding: 0;
    margin: -10px -10px;
    flex-wrap: wrap;

    > li {
      flex: 0 0 (100% / 4);
      display: block;
      padding: 10px 10px;

      > a {
        display: block;
        text-decoration: none;

        > .thumbnail {
          padding: 15px;
          background: #fff;
          border-radius: 10px;
          box-shadow: rgba($body-color, 0.05) 0 3px 5px;
          transition: box-shadow 0.2s $curve, transform 0.2s $curve;

          > img {
            display: block;
            width: 100%;
            height: auto;
          }

          .logotest {
            width: 0;
            height: 0;
            position: absolute;
          }

          .thumb {
            display: block;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            border-radius: 12px;
            overflow: hidden;

            &:before {
              padding: 0 0 63%;
              display: block;
              content: '';
            }
          }
        }

        > h4 {
          font-size: 16px;
          text-align: center;
          color: $body-color;
          margin: 15px 0 0;
        }

        &:hover {
          > .thumbnail {
            box-shadow: rgba($body-color, 0.08) 0 8px 15px;
            transform: translateY(-2px);
          }
        }
      }
    }
  }
}

#charity-categories {
  > .category {

    +.category {
      margin-top: 25px;
    }

    > header {
      height: 60px;
      padding: 0 0 15px;
      display: flex;
      align-items: flex-end;

      > h2 {
        font-size: 16px;
        font-weight: 700;
        margin: 0;
      }
    }
    > ul {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 20px;
      padding: 0;
      margin: 0 0 -25px;
      flex-wrap: wrap;

      > li {
        display: block;
        padding: 0;
        margin: 0 0 25px;

        > a {
          display: block;
          text-decoration: none;

          > .thumbnail {
            padding: 15px;
            background: #fff;
            border-radius: 10px;
            box-shadow: rgba($body-color, 0.05) 0 3px 5px;
            transition: box-shadow 0.2s $curve, transform 0.2s $curve;

            > img {
              display: block;
              width: 100%;
              height: auto;
            }

            .logotest {
              width: 0;
              height: 0;
              position: absolute;
            }

            .thumb {
              display: block;
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
              border-radius: 12px;
              overflow: hidden;

              &:before {
                padding: 0 0 63%;
                display: block;
                content: '';
              }
            }
          }

          > h4 {
            font-size: 16px;
            text-align: center;
            color: $body-color;
            margin: 15px 0 0;
          }

          &:hover {
            > .thumbnail {
              box-shadow: rgba($body-color, 0.08) 0 8px 15px;
              transform: translateY(-2px);
            }
          }
        }
      }
    }
  }
}
</style>