import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './scss/app.scss'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';

import UserProfilePicture from '@/components/User/ProfilePicture'
Vue.component('user-profile-picture', UserProfilePicture)

import UserName from '@/components/User/Name'
Vue.component('user-name', UserName)

import UserCard from '@/components/User/Card'
Vue.component('user-card', UserCard)

import UserPreview from '@/components/User/Preview'
Vue.component('user-preview', UserPreview)

/* Billing components */
import PaymentMethod from '@/components/Billing/PaymentMethod'
Vue.component('payment-method', PaymentMethod)

import LoadingIndicator from '@/components/LoadingIndicator'
Vue.component('loading-indicator', LoadingIndicator)

import ChannelSelector from '@/components/ChannelSelector'
Vue.component('channel-selector', ChannelSelector)

Vue.config.productionTip = false

import utils from './utils'
Vue.use( utils )

import vSelect from "vue-select";
Vue.component('v-select', vSelect);

import VueToast from 'vue-toast-notification';
Vue.use(VueToast, {position: 'bottom'});

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import PortalVue from 'portal-vue'
Vue.use(PortalVue)

import HiThriveSwitch from '@/components/Switch';
Vue.component('ht-switch', HiThriveSwitch)

import HiThriveSortableTable from '@/components/SortableTable';
Vue.component('ht-sortable-table', HiThriveSortableTable)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
