<template>
  <div>
    <img src="@/assets/hithrive-logo-dark.svg" height="22" class="logo" />
    <h1>Hey there HiThriver<small>Let's get you logged in.</small></h1>

    <div class="alert alert-danger" v-if="error">
      {{error}}
    </div>

    <div class="doodle-wrap">
      <img src="@/assets/people-doodle.png" />

      <a :href="login_uri" class="signin-with-slack">
        <img alt="Sign in with Slack" height="40" width="172" src="https://platform.slack-edge.com/img/sign_in_with_slack.png" srcset="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x" />
      </a>
    </div>

    <div class="install">
      <h4>Not using HiThrive yet?</h4>
      <p>Try free for 30 days, no credit card required.</p>

      <router-link :to="{name: 'install'}" class="btn btn-sm btn-primary">Start for free</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  components: {
    
  },
  computed: {
    login_uri() {
      let redirectURI = process.env.VUE_APP_SLACK_LOGIN_REDIRECT_URI;

      return `https://slack.com/openid/connect/authorize?response_type=code&scope=openid,profile,email&client_id=${process.env.VUE_APP_SLACK_CLIENT_ID}&redirect_uri=${encodeURIComponent(redirectURI)}&state=${this.login_uri_state}`;
    }
  },
  data() {
    return {
      login_uri_state: '',
      error: null
    }
  },
  created() {
    const state = this.$rand();
    this.login_uri_state = state;
    localStorage.setItem('hithrive_login_state', state);

    localStorage.removeItem('hithrive_continue_uri')
    if(this.$route.query.redirect) {
      localStorage.setItem('hithrive_continue_uri', this.$route.query.redirect)
    }

    const error = this.$route.params.error;
    if(!!error) this.error = error;
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
img.logo {
  display: block;
  margin: 0 auto;
}

.doodle-wrap {
  position: relative;
  > img {
    width: 100%;
    height: auto;
    //max-width: 302px;
    margin: 15px auto -15px;
    display: block;
    opacity: 0.3;
    pointer-events: none;
  }

  .signin-with-slack {
    position: absolute;
    margin: 0 auto 0;
    top: 50%;
    margin-top: -20px;
    display: block;
    left: 0;
    right: 0;
    width: 172px;
    height: 40px;
    border-radius: 10px;
    box-shadow: rgba(0,0,0,0.1) 0 5px 10px;
  }
}


.install {
  background: $light;
  border-radius: 10px;
  z-index: 9;
  position: relative;
  padding: 15px;
  margin: -35px 0 0;

  > h4 {
    font-weight: 700;
    font-size: 16px;
    margin: 0;
  }

  p {
    font-size: 14px;
    color: $muted-text;
    margin: 0 0 10px;
  }
}

h1 {
  font-weight: 700;
  margin: 25px 0;
  font-size: 22px;

  > small {
    font-size: 16px;
    display: block;
    margin: 5px 0 0;
    color: $muted-text;
  }
}
</style>