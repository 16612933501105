<template>
  <div class="payment-method">
    <div v-if="paymentMethod !== null && addCard" class="add-card">
      <h4>Add new credit card</h4>
      <p>This will be used for your active subscription and point top-ups.</p>
      <div class="form-group">
        <label class="sr-only" for="cardholder-name">Name of cardholder</label>
        <input type="text" v-model="cardDetails.name" id="cardholder-name" placeholder="Cardholder name..." class="form-control" />
      </div>
      <div class="form-group">
        <div ref="stripeWrapper" class="stripe-wrapper faux-field"></div>
      </div>
      
      <button :class="['btn', 'btn-green', 'btn-block', {'loading': loading}]" @click="saveCard">Save credit card</button>
    </div>
    <div v-else-if="paymentMethod !== null && !addCard" class="selected-card">
      <svg viewBox="0 0 128 82" class="card-icon"><use :xlink:href="`#${paymentMethod.card.brand}-icon`"></use></svg>
      <div>
        <div>x{{paymentMethod.card.last4}} <small>({{paymentMethod.card.exp_month}}/{{paymentMethod.card.exp_year}})</small></div>
        <a href="#" @click.prevent="addCard=true">(change)</a>
      </div>
    </div>
    <div class="loading-placeholder" v-else><i></i></div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'PaymentMethod',
  data() {
    return {
      paymentMethod: null,
      addCard: false,
      stripeCard: null,
      setupIntent: null,
      loading: false,
      renderStripeElement: _.debounce( this._renderStripe, 250 ),
      cardDetails: {
        name: ''
      },
    }
  },
  validations: {
    cardDetails: {
      name: {
        required
      }
    }
  },
  watch: {
    addCard(v) {
      if(v === true) {
        this.renderStripeElement();
      }
    }
  },
  created() {
    this.populatePaymentMethod()
  },
  methods: {
    async populatePaymentMethod() {
      this.addCard = false;
      this.paymentMethod = null;
      const resp = await this.$api.Billing.get_payment_method();
      if( resp.sources.length == 0 ) {
        this.paymentMethod = [];
        this.addCard = true;
      }
      else {
        this.paymentMethod = resp.sources[0];
        this.$emit('input', this.paymentMethod)
      }
    },
    _renderStripe() {
      const elements = this.$stripe.elements();

      this.stripeCard = elements.create("card")
      this.stripeCard.mount( this.$refs.stripeWrapper )

      this.setupCard()
    },
    async setupCard() {
      if(this.setupIntent === null) {
        const resp = await this.$api.Billing.init_new_card()
        this.paymentMethod = [];
        this.setupIntent = resp;
      }
    },
    async saveCard() {
      this.$v.$touch()
      if(this.$v.$invalid) return;

      this.loading = true;
      const {setupIntent, error} = await this.$stripe.confirmCardSetup(this.setupIntent.client_secret, {
        payment_method: {
          card: this.stripeCard,
          billing_details: {
            name: this.cardDetails.name
          }
        }
      })

      if( error ) {
        this.loading = false;
        this.$toast.error(error.message);
        return false;
      }

      this.setupIntent = null;
      setTimeout(() => {
        this.populatePaymentMethod();
        this.$emit('done', 'SUCCESS', setupIntent.payment_method);
      }, 4000);
    }
  }
}
</script>

<style lang="scss" scoped>
.loading-placeholder {
  max-width: 120px;
  > i {
    background-image: url("data:image/svg+xml,%3Csvg width='172' height='36' viewBox='0 0 172 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='53' height='36' rx='4' fill='%23E4E1DF'/%3E%3Crect x='72' y='11' width='55' height='15' rx='4' fill='%23E4E1DF'/%3E%3Crect x='138' y='11' width='34' height='15' rx='4' fill='%23E4E1DF'/%3E%3C/svg%3E%0A");
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    
    &:before {
      content: '';
      display: block;
      padding: 0 0 20%;
    }
  }
}

.add-card {
  background: #fff;
  box-shadow: rgba(0,0,0,0.05) 0 5px 10px;
  border-radius: 10px;
  padding: 25px;
  max-width: 450px;

  > h4 {
    font-size: 16px;
    font-weight: 700;
    margin: 0 0;
  }

  > p {
    margin: 0 0 15px;
    color: $muted-text;
    font-size: 14px;
  }
}

.selected-card {
  display: flex;
  align-items: flex-start;

  > svg {
    flex: 0 0 38px;
    border-radius: 4px;
    margin: 0 10px 0 0;
  }

  > div {
    > div {
      padding: 6px 0 0;
      line-height: 12px;
      > small {
        color: $muted-text;
      }
    }

    > a {
      font-size: 12px;
      display: inline-block;
      line-height: 12px;
    }
  }
}
</style>