import { render, staticRenderFns } from "./CustomCatalog_Item.vue?vue&type=template&id=34044925&scoped=true"
import script from "./CustomCatalog_Item.vue?vue&type=script&lang=js"
export * from "./CustomCatalog_Item.vue?vue&type=script&lang=js"
import style0 from "./CustomCatalog_Item.vue?vue&type=style&index=0&id=34044925&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34044925",
  null
  
)

export default component.exports