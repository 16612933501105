<template>
  <main class="app-view birthdays birthday-dashboard">
    <header>
      <div class="title">
        <ul class="crumbs">
          <li>
            <router-link :to="{name: 'recognition'}">Anniversaries</router-link>
          </li>
        </ul>
        <h1>Settings</h1>
      </div>
    </header>

    <section class="main-section">
      <header>
        <h2>Celebration Settings</h2>
      </header>

      <div class="content settings">
        
        <div class="setting-row">
          <i class="fas fa-birthday-cake"></i>
          <div class="title">
            <h4>Enable anniversary celebrations</h4>
            <p>Turn anniversary celebrations on/off.</p>
          </div>
          <div class="fields">
            <ht-switch v-model="enable_award" />
          </div>
        </div>

        <div class="setting-row">
          <i class="fas fa-coins"></i>
          <div class="title">
            <h4>Reward</h4>
            <p>Set how many points a user receives when they earn this award. Optionally, attach specific gifts from your custom reward catalog.</p>
          </div>
          <div class="fields">
            <div class="input-group" :class="{'is-invalid': $v.anniversary_award.points.$error}">
              <input type="text" v-model.number="$v.anniversary_award.points.$model" class="form-control" />
              <div class="input-group-append">
                <span class="input-group-text">points per award</span>
              </div>
            </div>
            <span class="help-text">{{$conversionRate($v.anniversary_award.points.$model)}}</span>

            <div class="invalid-feedback">A point amount is required, can be 0.</div>

            <award-gift-rewards v-model="anniversary_award.rewards" />
          </div>
        </div>

        <div class="setting-row">
          <i class="fas fa-calendar"></i>
          <div class="title">
            <h4>Weekend behavior</h4>
            <p>Choose when to celebrate anniversaries that land on Saturdays and Sundays.</p>
          </div>
          <div class="fields">
            <div class="form-group">
              <select v-model="anniversary_award.weekend_mode" class="form-control">
                <option value="DAY_OF">Celebrate on day of anniversary</option>
                <option value="FRIDAY">Celebrate on Friday before weekend</option>
                <option value="MONDAY">Celebrate on Monday after weekend</option>
              </select>
            </div>
          </div>
        </div>

        <div class="setting-row">
          <i class="fas fa-calendar"></i>
          <div class="title">
            <h4>Announcement Channels</h4>
            <p>Choose which channels HiThrive uses to announce anniversary awards.</p>
          </div>
          <div class="fields">
            <div class="form-group">

                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" name="announcement-channels" id="announcementChannelsDefault" value="DEFAULT" class="custom-control-input" v-model="announcement_channel_behavior">
                  <label class="custom-control-label" for="announcementChannelsDefault">All relevant channels</label>
                </div>

                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" name="announcement-channels" id="announcementChannelsSpecific" value="OVERRIDE" class="custom-control-input" v-model="announcement_channel_behavior">
                  <label class="custom-control-label" for="announcementChannelsSpecific">Set specific channels</label>
                </div>

            </div>
            <multi-channel-selector v-if="announcement_channel_behavior === 'OVERRIDE'" :multiple="true" v-model="$v.announcement_channel_values.$model" :class="{'is-invalid': $v.announcement_channel_values.$error}" />
          </div>
        </div>

        <div class="setting-row">
          <i class="fas fa-brush"></i>
          <div class="title">
            <h4>Banner design</h4>
            <p>Customize the award banner that is attached to messages in Slack.</p>
          </div>
          <div class="fields">
            <div class="banner-preview" v-if="banners.length > 0">
              <img v-for="banner in banners" :key="`banner_preview_${banner}`" :src="`https://img-cdn.hithrive.com/teams-award-skins/${banner}.png`" height="50" />
            </div>
            <button class="btn btn-xs btn-light" :class="{'is-invalid': $v.banners.$error}" @click="selectBanner()">Select a banner</button>

            <div class="invalid-feedback">At least one banner is required.</div>
          </div>
        </div>

        <div class="setting-row">
          <i class="fas fa-comment"></i>
          <div class="title">
            <h4>Celebration message</h4>
            <p>Message to be posted publicly or sent privately.</p>
          </div>
          <div class="fields">
            <div class="form-group">
              <textarea class="form-control" rows="4" v-model="anniversary_award.message"></textarea>
              <small class="form-text text-muted">Use <strong>{name}</strong> anywhere in the message to @mention the user. Use <strong>{award_years}</strong> to include the number of years this celebration is for.</small>
            </div>
          </div>
        </div>

      </div>

    </section>

    <section class="main-section milestone-awards">
      <header>
        <div>
          <h2>Additional Milestones</h2>
          <p>Customize celebrations for specific milestones.</p>
        </div>

        <div class="btns">
          <button v-if="can_add_milestone" class="btn btn-secondary btn-circle" @click="addMilestone"><i class="fas fa-plus"></i></button>
        </div>
      </header>

      <div class="content settings">
        <ul>
          <li v-for="(award, index) in $v.milestones.$each.$iter" :class="{'has-error': award.$error}" :key="`milestoneaward_${index}_${award.id}`">
            <div>
              <h4>
                <input type="text" v-model.number="award.anniversary_interval.$model" @input="$v.$touch" maxlength="2" />
                <span>Year Anniversary</span>

                <button class="btn btn-danger btn-circle btn-xs" @click="removeMilestone(index)"><i class="fas fa-trash"></i></button>
              </h4>
              <div class="point-amount">
                <div class="input-group input-group-sm">
                  <input type="text" class="form-control" @input="$v.$touch" v-model.number="award.points.$model" />
                  <div class="input-group-append">
                    <span class="input-group-text">points</span>
                  </div>
                </div>
                <span class="help-text">{{$conversionRate(award.points.$model)}}</span>

                <award-gift-rewards v-model="award.rewards.$model" />
              </div>
              <div class="banner-selector">
                <div class="banner-preview" v-if="award.award_skins.$model.length > 0">
                  <img v-for="banner in award.award_skins.$model" :key="`banner_preview_${banner}`" :src="`https://img-cdn.hithrive.com/teams-award-skins/${banner}.png`" height="50" />
                </div>
                <button class="btn btn-xs btn-light" :class="{'is-invalid': award.award_skins.$error}" @click="selectBanner(award.award_skins)">Select a banner</button>

                <div class="invalid-feedback">A banner is required.</div>
              </div>
              <div class="message-wrap">
                <textarea v-model="award.message.$model" class="form-control" placeholder="Customize celebration message..."></textarea>
              </div>
            </div>
          </li>
        </ul>
      </div>

    </section>

    <footer>
      <button class="btn btn-green btn-sm" :class="{'loading': loading}" @click="save">Save</button>
    </footer>
  </main>
</template>

<script>
import {required, numeric, requiredIf, minLength, minValue} from 'vuelidate/lib/validators'
import AwardGiftRewards from '@/components/Awards/GiftBuilder'
import MultiChannelSelector from '@/components/MultiChannelSelector'

export default {
  name: 'AnniversarySettings',
  components: {
    AwardGiftRewards,
    MultiChannelSelector
  },
  computed: {
    groups() {
      return this.$store.state.groups;
    },
    awards() {
      return this.$store.state.awards;
    },
    milestone_awards() {
      return _.filter(this.awards, a => a.is_bot === true && a.is_archived === false && a.bot_type === 'ANNIVERSARY' && a.anniversary_interval !== -1);
    },
    can_add_milestone() {
      return true;
      //return _.findIndex(this.milestones, {id: 'NEW'}) === - 1 ? true : false;
    },
    loading() {
      return this.$store.state.loading;
    },
    announcement_channel: {
      get() {
        return this.announcement_channel_value;
      },
      set(value) {
        this.announcement_channel_value = value.id;
      }
    }
  },
  data() {
    return {
      enable_award: false,
      banners: [],
      anniversary_award: {
        points: 0,
        message: '',
        rewards: [],
        weekend_mode: 'DAY_OF'
      },
      milestones: [],
      announcement_channel_behavior: 'DEFAULT',
      announcement_channel_values: []
    }
  },
  validations: {
    announcement_channel_values: {
      requiredIf: requiredIf(function() {
        return this.announcement_channel_behavior === 'OVERRIDE';
      })
    },
    anniversary_award: {
      points: {
        required,
        numeric
      }
    },
    banners: {
      minLength: minLength(1)
    },
    milestones: {
      $each: {
        no_dupe: function(val) {
          return _.findIndex(
            _.filter(this.milestones, (m) => m.id !== val.id),
            (m) => (m.anniversary_interval === val.anniversary_interval)
          ) > -1 ? false : true;
        },
        anniversary_interval: {
          required,
          numeric,
          minValue: minValue(1)
        },
        points: {
          required,
          numeric
        },
        message: {
          
        },
        rewards: {
          
        },
        award_skins: {
          minLength: minLength(1)
        }
      }
    }
  },
  created() {
    this.populateAwardData();
  },
  methods: {
    selectBanner(model=null) {
      this.$root.$emit(
        'openModal',
        'Awards/BannerSelector',
        model ? [...model.$model] : [...this.banners],
        (result, banner) => {
          if(result === 'BANNER_SELECTED') {
            if(model !== null) {
              model.$model = banner;
            }
            else {
              this.$v.banners.$model = banner;
            }
          }
        }
      )
    },
    async populateAwardData() {
      const _populateAwards = () => {
        this.milestones = _.orderBy(this.milestone_awards, m => m.anniversary_interval);
        const award = _.find(this.awards, {bot_type: 'ANNIVERSARY', anniversary_interval: -1})
        if(!!award) {
          this.anniversary_award = award;
          this.enable_award = !this.anniversary_award.is_archived;
          this.banners = award.award_skins;
          this.announcement_channel_values = award.channels.length === 0 ? [] : award.channels;
          this.announcement_channel_behavior = award.channels.length === 0 ? 'DEFAULT' : 'OVERRIDE';
        }
      };
      
      if(this.awards) {
        _populateAwards();
      }
      else {
        const unwatch = this.$watch('awards', (val) => {
          _populateAwards();
          unwatch();
        })
      }
    },
    removeMilestone(index) {
      this.milestones.splice(index, 1);
    },
    addMilestone() {
      this.$v.milestones.$model.push(
        {
          id: `NEW_${this.$rand()}`,
          anniversary_interval: '',
          points: 0,
          message: '',
          award_skins: [],
          rewards: []
        }
      )

      this.$nextTick( () => window.scrollTo(0,document.body.scrollHeight) )
    },
    async save() {
      this.$v.$touch()
      
      if(this.$v.$error) {
        this.$toast.error('Your award has errors, please fix before saving.');
        return;
      }

      this.$store.commit('loading', true)

      const milestones = _.map(this.milestones, m => Object.assign({}, m, {
        award_skin: m.award_skins,
        rewards: m.rewards.filter(r => r.length > 0)
      }))

      await this.$api.Awards.update_anniversary_award({
        disable: !this.enable_award,
        points: this.anniversary_award.points,
        message: this.anniversary_award.message,
        weekend_mode: this.anniversary_award.weekend_mode,
        award_skin: this.banners,
        channels: this.announcement_channel_behavior === 'OVERRIDE' ? this.announcement_channel_values.map(({id}) => id) : null,
        rewards: this.anniversary_award.rewards.filter(r => r.length > 0)
      }, milestones)
      this.$toast.success('Anniversary award has been updated.')
      this.$store.dispatch('populateAwards')
      this.$store.commit('loading', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-preview {
  margin: 0 0 10px;
  > img {
    border-radius: 6px;
  }
}

.milestone-awards {
  ::v-deep .award-gift-builder {
    > ul {
      > li {
        padding: 5px 0;
      }
    }
  }
  .content {
    > ul {
      display: flex;
      flex-wrap: wrap;

      padding: 0;
      margin: -10px -10px;

      > li {
        display: block;
        flex: 0 0 (100%/3);
        width: (100%/3);
        padding: 10px 10px;

        > div {
          height: 100%;
          padding: 15px;
          background: $light;
          border-radius: 10px;

          > h4 {
            font-size: 14px;
            font-weight: 700;
            display: flex;
            align-items: center;
            position: relative;

            > input {
              flex: 0 0 30px;
              display: block;
              width: 30px;
              background: none;
              border: none;
              border-bottom: 1px dashed $primary;
              outline: none;
              text-align: center;
            }

            > span {
              display: block;
              flex: 0 0 auto;
              white-space: nowrap;
              margin: 0 0 0 7px;
            }

            > button {
              font-size: 9px;
              width: 20px;
              height: 20px;
              line-height: 20px;
              padding: 0 1px 0 0;
              display: block;
              position: absolute;
              right: 0;
              top: -2px;
              opacity: 0;
              transition: opacity 0.2s $curve, transform 0.2s $curve;
              transform: translateX(15px);
            }
          }

          .point-amount {
            .help-text {
              font-size: 12px;
              display: block;
              color: $muted-text;
            }
          }

          .banner-selector {
            margin: 0.5rem 0 0;

            .banner-preview {
              margin: 0 0 5px;
              > img {
                border-radius: 6px;
              }
            }
          }

          .message-wrap {
            margin: 0.5rem 0 0;

            > textarea {
              font-size: 14px;
              resize: none;
            }
          }

          &:hover {
            > h4 {
              > button {
                transform: none;
                opacity: 1;
              }
            }
          }
        }
        &.has-error {
          > div {
            box-shadow: $red 0 0 0 1px, rgba($red, 0.3) 0 0 0 4px;
          }
        }
      }
    }
  }
}
</style>