<template>
  <div class="dropdown">
    <a href="#" class="dropdown-toggle" data-toggle="dropdown">{{label}}</a>

    <div class="dropdown-menu">
      <a class="dropdown-item" @click.prevent.stop="selectItem(segment)" :class="{'active': value.indexOf(segment.id.toString()) > -1}" href="#" v-for="segment in segments" :key="`segment_filter_${segment.id}`">{{segment.name}}</a>
    </div>
  </div>
</template>

<script>
export default {
  props: ['label', 'value'],
  computed: {
    segments() {
      return this.$store.state.segments;
    }
  },
  methods: {
    selectItem(obj) {
      if(this.value.indexOf(obj.id.toString()) > -1) {
        const newVal = [...this.value];
        newVal.splice(
          newVal.indexOf(obj.id.toString()),
          1
        )
        this.$emit('input', newVal)
      }
      else {
        this.$emit('input', [
          ...this.value,
          obj.id.toString()
        ])
      }
    }
  }
}
</script>