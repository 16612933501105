<template>
  <div class="reward-view container-fluid" id="claims">
    <main>
      <header>
        <div class="title">
          <ul class="crumbs">
            <li>
              <router-link :to="{name: 'rewards_home'}">Rewards</router-link>
            </li>
          </ul>
          <h1>Gifts to Claim</h1>
        </div>
      </header>

      <section class="main-section" id="claim-list">
        <template v-if="claims !== null && claims.length > 0">
          <ul>
            <li v-for="claim in claims" :key="`claim_${claim.id}`">
              <div class="thumbnails">
                <span v-for="(thumb, thumbIndex) in thumbPreviews(claim)" :key="`claim_${claim.id}_tn_${thumbIndex}`" :style="{'background-image': `url('${$catalogThumbnail(thumb)}')`}"></span>
              </div>
              <h4>Gift for <strong>{{claim.earned_award.award.name}}</strong> award</h4>

              <div class="ml-auto">
                <button class="btn btn-sm btn-success" @click.prevent="openClaimModal(claim.id)">Claim gift</button>
              </div>
            </li>
          </ul>
        </template>
        <div class="empty-state" v-else-if="claims !== null">You don't have any gifts to claim.</div>
        <div class="loading-placeholder" v-else><i></i><i></i><i></i></div>
      </section>
    </main>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  computed: {
    claims() {
      return this.$store.state.reward_claims;
    }
  },
  data() {
    return {
      
    }
  },
  watch: {

  },
  mounted() {
    this.populateClaims()
  },
  methods: {
    thumbPreviews(claim) {
      return _(claim.earned_award.rewards.map((gift_row) => gift_row.filter((gift_opt) => gift_opt.type === 'CUSTOM_REWARD').map((gift_opt) => gift_opt.reward.images[0]))).flatten().uniq().value().slice(0, 3).map((id) => `https://img-cdn.hithrive.com/storefront-images/${id}.jpg`);
    },
    async populateClaims() {
      await this.$store.dispatch('populateClaims')

      if(this.$route.params.claim_id) {
        const has_claim = this.claims.findIndex(o => o.id == this.$route.params.claim_id) > -1;
        if(has_claim) {
          this.openClaimModal(this.$route.params.claim_id)
        }
        this.$router.replace({name: 'rewards_claims', params: {}})
      }
    },
    openClaimModal(claim_id) {
      const claim = _.cloneDeep(this.claims.find(c => c.id == claim_id));
      this.$root.$emit('openModal', 'RewardsPortal/ClaimGift', claim, (resp) => {
        if(resp === 'CLAIMED_GIFT') {
          this.$store.dispatch('populateClaims')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#claim-list {
  margin: 15px 0 0;

  > ul {
    display: block;
    padding: 0;
    margin: 0;

    > li {
      display: block;
      background: $light;
      border-radius: 10px;
      padding: 15px;
      display: flex;
      align-items: center;

      h4 {
        margin: 0;
        font-size: 16px;
      }

      .thumbnails {
        display: flex;
        margin: 0 15px 0 0;

        > span {
          display: block;
          flex: 0 0 35px;
          height: 35px;
          width: 35px;
          border-radius: 100%;
          overflow: hidden;
          background-color: #fff;
          background-size: cover;
          background-position: center center;
          box-shadow: rgba($body-color, 0.05) 0 5px 10px;
          z-index: 2;

          + span {
            margin: 0 0 0 -15px;
            z-index: 1;

            + span {
              z-index: 0;
            }
          }
        }
      }

      +li {
        margin: 15px 0 0;
      }

      &.active {
        box-shadow: $green 0 0 0 1px, rgba($green, 0.3) 0 0 0 3px;
      }
    }
  }
}
.loading-placeholder {
  //display: flex;
  margin: -10px 0;
  > i {
    margin: 10px 0;
    //flex: 1 0 auto;
    display: block;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    background-image: url("data:image/svg+xml,%3Csvg width='951' height='50' preserveAspectRatio='none' viewBox='0 0 951 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='951' height='50' rx='4' fill='%23E4E1DF'/%3E%3C/svg%3E%0A");
    
    &:before {
      content: '';
      display: block;
      height: 50px;
    }
  }
}
</style>