<template>
  <div class="reward-view container-fluid" id="gift-cards">
    <main>
      <header>
        <div class="title">
          <ul class="crumbs">
            <li>
              <router-link :to="{name: 'rewards_home'}">Rewards</router-link>
            </li>
          </ul>
          <h1><img src="@/assets/amazon-logo.svg#amazon-color" height="22" /></h1>
        </div>

        <form class="search-wrap" @submit.prevent="searchAmazon">
          <input type="text" class="form-control" placeholder="type and press enter to search..." v-model="query" />
          <button type="submit" class="btn btn-sm btn-light btn-circle"><i class="fas fa-search"></i></button>
        </form>
      </header>

      <section class="main-section">
        <header>
          <h2>Trending Home &amp; Kitchen</h2>
        </header>

        <featured-amazon category="HOME_KITCHEN" />
      </section>

      <section class="main-section">
        <header>
          <h2>Trending Clothing</h2>
        </header>

        <featured-amazon category="WOMENS_CLOTHING" />
        <featured-amazon category="MENS_CLOTHING" />
      </section>

      <section class="main-section">
        <header>
          <h2>Trending Pet Supplies</h2>
        </header>

        <featured-amazon category="PETS" />
      </section>

      <section class="main-section">
        <header>
          <h2>Trending Shoes</h2>
        </header>

        <featured-amazon category="WOMENS_SHOES" />
        <featured-amazon category="MENS_SHOES" />
      </section>

      <section class="main-section">
        <header>
          <h2>Trending Electronics</h2>
        </header>

        <featured-amazon category="ELECTRONICS" />
      </section>

      <section class="main-section">
        <header>
          <h2>Trending Toys &amp; Games</h2>
        </header>

        <featured-amazon category="TOYS_GAMES" />
      </section>

      <section class="main-section">
        <header>
          <h2>Trending Books</h2>
        </header>

        <featured-amazon category="BOOKS" />
      </section>
    </main>
  </div>
</template>

<script>
import FeaturedAmazon from '@/components/RewardsPortal/FeaturedAmazon';

export default {
  components: {
    FeaturedAmazon
  },
  computed: {
  },
  data() {
    return {
      query: ''
    }
  },
  watch: {

  },
  created() {
    
  },
  methods: {
    async searchAmazon() {
      if(this.query.trim().length === 0) return;

      this.$router.push({
        name: 'rewards_amazon_search',
        query: {
          q: this.query
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.search-wrap {
  margin: 0 0 4px auto;
  display: flex;

  > input {
    height: 28px;
    font-size: 14px;
    border-radius: 100em;
    width: 256px;
    max-width: 100%;
    margin: 0 5px 0 0;
  }

  > button {
    width: 28px;
    height: 28px;
    line-height: 28px;
    padding: 0;
    font-size: 12px;
  }
}

.main-section {
  .featured-amazon {
    +.featured-amazon {
      margin-top: 25px;
    }
  }
}

#search-results {
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 10px -10px 0;
    padding: 0;

    > li {
      display: block;
      flex: 0 0 20%;
      padding: 15px 10px;
      > a {
        display: block;
        text-decoration: none;
        color: $body-color;

        > .thumbnail {
          display: block;
          padding: 0 0 100%;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          border-radius: 10px;
          background-color: #fff;
          overflow: hidden;
          box-shadow: rgba($body-color, 0.05) 0 3px 5px;
          transition: box-shadow 0.2s $curve, transform 0.2s $curve;
        }

        > h4 {
          //font-weight: 700;
          font-size: 14px;
          margin: 10px 0 0;
          line-height: 1.25em;
          max-height: 3.6em;
          padding-bottom: 1.25em;
          overflow: hidden;
          position: relative;

          &:after {
            content: '';
            display: block;
            position: absolute;
            height: 1.25em;
            right: 0;
            bottom: 0;
            width: 50%;
            background: linear-gradient(to right, rgba($page-background, 0), rgba($page-background, 1) 50%);
          }
        }

        > span {
          display: inline-block;

          margin: 5px 0 0;
          color: #fff;
          background: $green;
          line-height: 12px;
          font-size: 12px;
          padding: 3px 4px;
          border-radius: 3px;
        }

        &:hover {
          > .thumbnail {
            box-shadow: rgba($body-color, 0.08) 0 8px 15px;
            transform: translateY(-2px);
          }
        }
      }
    }
  }
}

.loading-placeholder {
  &.search-results {
    display: flex;
    margin: 15px -10px 0;
    flex-wrap: wrap;
    > i {
      margin: 10px 10px;
      flex: 0 0 calc(20% - 20px);
      display: block;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center center;
      background-image: url("data:image/svg+xml,%3Csvg width='178' height='125' preserveAspectRatio='none' viewBox='0 0 178 125' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='178' height='125' rx='4' fill='%23E4E1DF'/%3E%3C/svg%3E%0A");
      
      &:before {
        content: '';
        display: block;
        padding: 0 0 60%;
      }
    }
  }
}
</style>