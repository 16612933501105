import Vue from 'vue';
import Vuex from 'vuex';
import { api } from '../api';
import axios from 'redaxios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user_token: localStorage.getItem('hithrive_token') || null,
    app_loading: true,
    user: null,
    team: null,
    groups: null,
    segments: null,
    featured_amazon: null,
    currency_rates: null,
    awards: null,
    loading: false,
    pending_redemptions: 0,
    prismic_ref: null,
    geo_ip: null,
    amazon_marketplace: null,
    integrations: null,
    reward_claims: null,
  },
  mutations: {
    user(state, val) {
      state.user = val;
    },
    team(state, val) {
      state.team = val;
    },
    groups(state, val) {
      state.groups = val;
    },
    segments(state, val) {
      state.segments = val;
    },
    awards(state, val) {
      state.awards = val;
    },
    app_loading(state, val) {
      state.app_loading = val === true ? true : false;
    },
    currency_rates(state, val) {
      state.currency_rates = val;
    },
    geo_ip(state, val) {
      state.geo_ip = val;
    },
    user_token(state, val) {
      if (val === null) {
        localStorage.removeItem('hithrive_token');
        state.user_token = null;
      } else {
        localStorage.setItem('hithrive_token', val);
        state.user_token = val;
      }
    },
    featured_amazon(state, val) {
      state.featured_amazon = val;
    },
    reward_claims(state, val) {
      state.reward_claims = val;
    },
    loading(state, val) {
      state.loading = val === true ? true : false;
    },
    pending_redemptions(state, val) {
      state.pending_redemptions = val;
    },
    prismic_ref(state, val) {
      state.prismic_ref = val;
    },
    amazon_marketplace(state, val) {
      state.amazon_marketplace = val;
    },
    integrations(state, val) {
      state.integrations = val;
    },
  },
  actions: {
    adjustRedeemablePoints({ commit }, adjustment_amount) {
      const current_balance = this.state.user.redeemable_points;
      const user = _.cloneDeep(this.state.user);
      user.redeemable_points = current_balance + adjustment_amount;
      commit('user', user);
    },
    async populateFeaturedAmazon({ commit }) {
      if (this.state.featured_amazon !== null) return;

      commit('featured_amazon', -1);
      const resp = await api.RewardPortal.Amazon.get_featured();
      commit('featured_amazon', resp);
    },
    updateUser({ commit }, change) {
      commit('user', Object.assign(this.state.user, change));
    },
    async userMetadata({ commit }, val = {}) {
      const user = _.cloneDeep(this.state.user);
      user.metadata = Object.assign(user.metadata, val);
      commit('user', user);
    },
    async populateGroups({ commit }) {
      const resp = await api.Groups.list(true);
      commit('groups', resp);
    },
    async populateClaims({ commit }) {
      const resp = await api.RewardPortal.get_claims();
      commit('reward_claims', resp);
    },
    async populateSegments({ commit }) {
      const resp = await api.Segments.list(true);
      commit('segments', resp);
    },
    async populateAwards({ commit }) {
      const resp = await api.Awards.list();
      commit('awards', resp);
    },
    async populatePendingRedemptions({ commit }) {
      const { pending_count } = await api.Team.get_pending_redemptions();
      commit('pending_redemptions', pending_count);
    },
    async populatePrismicRef({ commit }) {
      const resp = await axios.get('https://hithrive.cdn.prismic.io/api/v2');
      const masterRef = _.find(resp.data.refs, { id: 'master' });
      commit('prismic_ref', masterRef.ref);
    },
    async populateGeoIP({ commit }) {
      const resp = await axios.get(`https://get.geojs.io/v1/ip/geo.json`);
      commit('geo_ip', resp.data);
    },
    async populateIntegrations({ commit }) {
      const integrations = await api.Integrations.get_all();

      commit('integrations', integrations);
    },
    async populateUser({ commit }) {
      try {
        const user = await api.User.me();

        commit('user', _.cloneDeep(user.user));

        commit('amazon_marketplace', user.amazon_marketplace);

        // commit('groups',
        //   _.cloneDeep(
        //     user.team.groups
        //   )
        // )
        delete user.team.groups;

        commit('team', _.cloneDeep(user.team));

        if (user.user.is_admin) {
          this.dispatch('populateGroups');
          this.dispatch('populateSegments');
          this.dispatch('populatePendingRedemptions');
          this.dispatch('populateAwards');
          this.dispatch('populateIntegrations');
        } else if (user.user.is_manager) {
          console.log('is_manager');
          commit('segments', _.cloneDeep(user.user.segments_managed));
        }

        commit('currency_rates', user.currency_rates[user.team.base_currency]);

        return user;
      } catch (e) {
        commit('user', false);
        return false;
      }
    },
    logout({ commit }) {
      commit('user', null);
      commit('user_token', null);
      commit('groups', null);
      commit('team', null);
    },
    updateTeam({ commit }, update = {}) {
      const team = _.cloneDeep(this.state.team);
      commit('team', Object.assign(team, update));
    },
    async teamMetadata({ commit }, val = {}) {
      const team = _.cloneDeep(this.state.team);
      team.metadata = Object.assign(team.metadata, val);
      commit('team', team);
      await api.Team.update_metadata(val);
    },
  },
  getters: {
    user_status: (state) => () => {
      if (state.user === false) return 'ERROR';
      return state.user !== null ? 'AUTHED' : 'UNAUTHED';
    },
    custom_catalog_name: (state) => {
      const metadata = state.team.metadata;
      if (!!metadata.custom_catalog_name) return metadata.custom_catalog_name;

      return `Company Store`;
    },
  },
  modules: {},
});
