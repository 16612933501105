<template>
  <div class="dropdown">
    <a href="#" class="dropdown-toggle" data-toggle="dropdown">{{label}}</a>

    <div class="dropdown-menu">
      <a class="dropdown-item" @click.prevent.stop="selectItem(group)" :class="{'active': value.indexOf(group.id.toString()) > -1}" href="#" v-for="group in groups" :key="`group_filter_${group.id}`">{{group.title}}</a>
    </div>
  </div>
</template>

<script>
export default {
  props: ['label', 'value'],
  computed: {
    groups() {
      return this.$store.state.groups;
    }
  },
  methods: {
    selectItem(obj) {
      if(this.value.indexOf(obj.id.toString()) > -1) {
        const newVal = [...this.value];
        newVal.splice(
          newVal.indexOf(obj.id.toString()),
          1
        )
        this.$emit('input', newVal)
      }
      else {
        this.$emit('input', [
          ...this.value,
          obj.id.toString()
        ])
      }
    }
  }
}
</script>