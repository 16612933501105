<template>
  <li :class="{'open': campaign.status === 'OPEN', 'pending': campaign.status === 'PENDING'}">
    <div class="title">
      <h4>{{campaign.title}}</h4>
      <div>
        <span><i class="fas fa-users"></i> {{campaign.user_group.title}}</span>
      </div>
    </div>

    <div class="timestamps">
      <span><strong>Start: </strong>{{$formatDate(campaign.created_at)}}</span>
      <span v-if="campaign.end_date"><strong>End: </strong>{{$formatDate(campaign.end_date)}}</span>
    </div>

    <div class="nomination-count">
      <span>{{$formatNumber(campaign.nomination_count)}}</span>
    </div>

    <div class="engagement">
      <span>{{campaign.user_group.member_count > 0 ? Math.round((campaign.users_engaged/campaign.user_group.member_count)*100) : 0}}% <small>({{$formatNumber(campaign.users_engaged)}})</small></span>
      <div class="progress">
        <div class="progress-bar" role="progressbar" :style="{'width': Math.round((campaign.users_engaged/campaign.user_group.member_count)*100)+'%'}"></div>
      </div>
    </div>

    <div class="actions">
      <router-link :to="{name: 'reporting-activity', query: {type: 'nominations', campaign_id: campaign.id}}" class="btn btn-light btn-xs">View activity</router-link>
      <button v-if="campaign.status === 'OPEN' || campaign.status === 'PENDING'" class="btn btn-success btn-xs" @click="$emit('end-campaign')">End campaign</button>
    </div>
  </li>
</template>

<script>
export default {
  props: ['campaign']
}
</script>