<template>
  <div class="user--preview">
    <div class="profile-images">
      <user-profile-picture
        v-for="(pic, index) in preview"
        height="22"
        :user="pic"
        :key="`previewpic_${_uid}_${index}`"
        :style="{'z-index': (preview.length - index), '--previewIndex': (index)}"
      />
    </div>
    <div class="preview-text">
      <user-name :user="preview[0]" />
      <span class="extra" v-if="users.length > 1">{{extra_text}}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['users'],
  computed: {
    preview() {
      return this.users.slice(0, 3);
    },
    extra_text() {
      return `+ ${this.$formatNumber(this.users.length - 1)} other${this.users.length - 1 === 1 ? '' : 's'}`
    }
  }
}
</script>