<template>
  <main class="setup setup-home">
    <section>
      <div class="left">
        <img src="@/assets/people-doodle.png" class="bg" />
        <img src="@/assets/hithrive-logo-dark.svg" height="22" />
      </div>

      <div class="right">
        <h1>Setup HiThrive for your team</h1>
        <p>Try free for 30 days, no credit card required. {{$emoji(':tada:')}}</p>

        <div class="install-btns" :class="{'disabled': !can_continue}">
          <a :href="install_uri" rel="noopener">
            <img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" />
          </a>
        </div>

        <div class="policies">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" v-model="accept_policy" id="policy-check" class="custom-control-input" />
            <label class="custom-control-label" for="policy-check">I've read and accept the <a href="https://www.hithrive.com/privacy-policy" target="_blank">privacy policy</a> and <a href="https://www.hithrive.com/terms-of-service" target="_blank">terms of service</a>.</label>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  computed: {
    install_uri() {
      return `https://slack.com/oauth/v2/authorize?scope=channels:join,channels:manage,channels:read,chat:write,commands,reactions:read,team:read,groups:read,usergroups:read,users.profile:read,users:read,users:read.email&client_id=${process.env.VUE_APP_SLACK_CLIENT_ID}&redirect_uri=${encodeURIComponent(process.env.VUE_APP_SLACK_INSTALL_REDIRECT_URI)}&state=${this.uri_state}`;
    },
    can_continue() {
      return this.accept_policy;
    }
  },
  data() {
    return {
      uri_state: '',
      accept_policy: false
    }
  },
  created() {
    const state = this.$rand();
    this.uri_state = state;
    localStorage.setItem('hithrive_install_state', state);
  }
}
</script>

<style lang="scss" scoped>
.setup {
  min-height: 100vh;
  background: linear-gradient(45deg, rgba(45,31,64,1) 0%, rgba(71,52,97,1) 100%);
  display: flex;
  justify-content: center;
  align-items: center;

  > section {
    background: #F9F6F4;
    border-radius: 10px;
    width: 100%;
    max-width: 780px;
    position: relative;
    box-shadow: rgba(0,0,0,0.2) 0 10px 20px;
    padding: 50px 0 50px 0;
    display: flex;
    //justify-content: center;
    flex-wrap: nowrap;
    overflow-x: hidden;
    overflow-y: auto;
    align-items: center;

    > .left {
      flex: 0 0 40%;
      min-width: 0;
      padding: 0 35px;
      position: relative;

      > .bg {
        width: 100%;
        height: auto;
        opacity: 0.2;

        + img {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    > .right {
      border-left: 1px solid $light;
      padding: 35px 35px;

      .install-btns {
        display: flex;

        &.disabled {
          opacity: 0.5;
          pointer-events: none;
          user-select: none;
          filter: grayscale(1);
        }
      }

      .policies {
        margin: 25px 0 0;
        font-size: 13px;
        line-height: 1.35em;

        label {
          padding: 4px 0 0;
        }
      }

      > h1 {
        font-weight: 700;
        font-size: 20px;
        margin: 0 0 5px;
      }
      > p {
        color: $muted-text;
      }
    }
  }
}
</style>