import Vue from 'vue';
import VueRouter from 'vue-router';
import Admin from '../views/Admin.vue';
import Store from '../store';
import { api } from '../api';

import GetStarted from '../views/GetStarted';

/* Login views */
import Login from '../views/Login';
import Login_Home from '../views/Login/Home';
import Login_Slack from '../views/Login/Slack';
import Login_AssertSingleUseToken from '../views/Login/AssertSingleUseToken';

/* Setup views */
import Install from '../views/Install';
import Install_Home from '../views/Install/Home';
import Install_Slack from '../views/Install/Slack';

/* Trial ended views */
import AdminTrialEnded from '../views/AdminTrialEnded';
import TrialEnded from '../views/TrialEnded';
import AdminInactiveSubscription from '../views/AdminInactiveSubscription';
import InactiveSubscription from '../views/InactiveSubscription';

/* Shoutout views */
import Recognition from '../views/Recognition';
import Recognition_Dashboard from '../views/Recognition/Dashboard';
import Recognition_GroupEdit from '../views/Recognition/GroupEdit';
import Recognition_Settings from '../views/Recognition/Settings';

/* Birthday views */
import Birthdays from '../views/Birthdays';
import Birthdays_Dashboard from '../views/Birthdays/Dashboard';
import Birthdays_Settings from '../views/Birthdays/Settings';

/* Anniversaries views */
import Anniversaries from '../views/Anniversaries';
import Anniversaries_Dashboard from '../views/Anniversaries/Dashboard';
import Anniversaries_Settings from '../views/Anniversaries/Settings';

/* New Hires views */
import NewHires from '../views/NewHires';
import NewHires_Dashboard from '../views/NewHires/Dashboard';
import NewHires_Settings from '../views/NewHires/Settings';

/* Awards views */
import Awards from '../views/Awards';
import Awards_Awards from '../views/Awards/Awards';
import Awards_Edit from '../views/Awards/AwardEdit';
import Awards_Competitions from '../views/Awards/Competitions';
import Awards_Nominations from '../views/Awards/Nominations';

/* Rewards views */
import Rewards from '../views/Rewards';
import Rewards_Dashboard from '../views/Rewards/Dashboard';
import Rewards_Settings from '../views/Rewards/Settings';
import Rewards_CustomCatalog from '../views/Rewards/CustomCatalog';
import Rewards_Payments from '../views/Rewards/Payments';

/* Reporting views */
import Reporting from '../views/Reports';
import Reporting_Dashboard from '../views/Reports/Dashboard';
import Reporting_Activity from '../views/Reports/Activity';
import Reporting_Summary from '../views/Reports/Summary';

/* Billing views */
import Billing from '../views/Billing';
import Billing_Home from '../views/Billing/Home';
import Billing_Rewards from '../views/Billing/Rewards';
import Billing_Invoices from '../views/Billing/Invoices';

/* Users views */
import Users from '../views/Users';
import Users_Home from '../views/Users/Home';
import Users_Profile from '../views/Users/Profile';
import Users_Profile_Edit from '../views/Users/Profile/Edit';
import Users_UserSegments from '../views/Users/UserSegments';
import Users_UserSegmentEdit from '../views/Users/UserSegmentDetails';

/* Setting views */
import Settings from '../views/Settings';
import Settings_Home from '../views/Settings/Home';
import Settings_SecurityLog from '../views/Settings/SecurityLog';

//integrations
import Settings_Integrations from '../views/Settings/Integrations';
import Settings_Integrations_Home from '../views/Settings/Integrations/Home';
import Settings_Integrations_AuthBamboo from '../views/Settings/Integrations/AuthBamboo';
import Settings_Integrations_AuthSendoso from '../views/Settings/Integrations/AuthSendoso';
import Settings_Integrations_AuthGusto from '../views/Settings/Integrations/AuthGusto';

/* Rewards Portal views */
import RewardsPortal from '../views/RewardsPortal';
import RewardsPortal_Home from '../views/RewardsPortal/Home';
import RewardsPortal_Redemptions from '../views/RewardsPortal/Redemptions';
import RewardsPortal_Claims from '../views/RewardsPortal/Claims';
import RewardsPortal_GiftCards from '../views/RewardsPortal/GiftCards';
import RewardsPortal_GiftCardDetails from '../views/RewardsPortal/GiftCardDetails';

import RewardsPortal_CustomCatalog from '../views/RewardsPortal/CustomCatalog';
import RewardsPortal_CustomCatalogItem from '../views/RewardsPortal/CustomCatalog_Item';

import RewardsPortal_Amazon from '../views/RewardsPortal/Amazon';
import RewardsPortal_AmazonProduct from '../views/RewardsPortal/AmazonProduct';
import RewardsPortal_AmazonSearch from '../views/RewardsPortal/AmazonSearch';

import RewardsPortal_EditorialPost from '../views/RewardsPortal/EditorialPost';

import RewardsPortal_Storefront_Home from '../views/RewardsPortal/Storefront';
import RewardsPortal_Storefront_Product from '../views/RewardsPortal/StorefrontProduct';

import RewardsPortal_Charities_Home from '../views/RewardsPortal/Charities';
import RewardsPortal_Charities_Charity from '../views/RewardsPortal/CharityDetails';

import RewardsPortal_PayPal from '../views/RewardsPortal/PayPal';
import RewardsPortal_Venmo from '../views/RewardsPortal/Venmo';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    component: Login,
    meta: {
      isAuth: true,
    },
    children: [
      {
        name: 'login',
        component: Login_Home,
        path: '',
      },
      {
        name: 'login_slack',
        component: Login_Slack,
        path: 'slack',
      },
      {
        name: 'login_assert_single_use_token',
        component: Login_AssertSingleUseToken,
        path: 'assert_single_use_token',
      },
    ],
  },
  {
    path: '/setup',
    component: Install,
    children: [
      {
        name: 'install',
        component: Install_Home,
        path: '',
      },
      {
        name: 'install-slack',
        component: Install_Slack,
        path: 'slack',
      },
    ],
  },
  {
    name: 'admin_inactive_subscription',
    path: '/come-back',
    component: AdminInactiveSubscription,
    meta: {
      requireAdmin: true,
    },
  },
  {
    name: 'inactive_subscription',
    path: '/inactive',
    component: InactiveSubscription,
    meta: {
      requireAuth: true,
    },
  },
  {
    name: 'admin_trial_ended',
    path: '/%E2%8F%B0',
    component: AdminTrialEnded,
    meta: {
      requireAdmin: true,
    },
  },
  {
    name: 'trial_ended',
    path: '/%E2%8F%B0',
    component: TrialEnded,
    meta: {
      requireAuth: true,
    },
  },
  // Reward portal routes
  {
    path: '/redeem',
    component: RewardsPortal,
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: '',
        name: 'rewards_home',
        component: RewardsPortal_Home,
      },
      {
        path: 'redemptions',
        name: 'rewards_redemptions',
        component: RewardsPortal_Redemptions,
      },
      {
        path: 'claims/:claim_id?',
        name: 'rewards_claims',
        component: RewardsPortal_Claims,
      },
      {
        path: 'paypal',
        name: 'rewards_paypal',
        component: RewardsPortal_PayPal,
      },
      {
        path: 'venmo',
        name: 'rewards_venmo',
        component: RewardsPortal_Venmo,
      },
      //Custom catalog routes
      {
        path: 'custom',
        name: 'rewards_custom_catalog',
        component: RewardsPortal_CustomCatalog,
      },
      {
        path: 'custom/:id',
        name: 'rewards_custom_catalog_item',
        component: RewardsPortal_CustomCatalogItem,
      },
      // Gift card routes
      {
        path: 'gift-cards',
        name: 'rewards_gift_cards',
        component: RewardsPortal_GiftCards,
      },
      {
        path: 'gift-cards/:gift_card_id',
        name: 'rewards_gift_card_details',
        component: RewardsPortal_GiftCardDetails,
      },
      // Amazon routes
      {
        path: 'amazon',
        name: 'rewards_amazon',
        component: RewardsPortal_Amazon,
      },
      {
        path: 'amazon/search',
        name: 'rewards_amazon_search',
        component: RewardsPortal_AmazonSearch,
      },
      {
        path: 'amazon/:asin',
        name: 'rewards_amazon_product',
        component: RewardsPortal_AmazonProduct,
      },
      {
        path: 'post/:id',
        name: 'rewards_editorial_post',
        component: RewardsPortal_EditorialPost,
      },
      //Storefront routes
      {
        path: 'store',
        name: 'rewards_storefront_home',
        component: RewardsPortal_Storefront_Home,
      },
      {
        path: 'store/:product_slug',
        name: 'rewards_storefront_product',
        component: RewardsPortal_Storefront_Product,
      },
      //Charity routes
      {
        path: 'charities',
        name: 'rewards_charities_home',
        component: RewardsPortal_Charities_Home,
      },
      {
        path: 'charities/:ein',
        name: 'rewards_charities_charity',
        component: RewardsPortal_Charities_Charity,
      },
    ],
  },
  // Reporting routes
  {
    path: '/reporting',
    component: Admin,
    children: [
      {
        path: '',
        component: Reporting,
        children: [
          {
            name: 'reporting',
            path: '',
            component: Reporting_Dashboard,
            meta: {
              requireManager: true,
            },
          },
          {
            name: 'reporting-summary',
            path: 'summary',
            component: Reporting_Summary,
          },
          {
            name: 'reporting-activity',
            path: 'activity',
            component: Reporting_Activity,
            meta: {
              requireManager: true,
            },
          },
        ],
      },
    ],
  },
  //Admin routes
  {
    path: '/',
    component: Admin,
    meta: {
      requireAdmin: true,
    },
    children: [
      // Birthdays
      {
        path: 'get-started',
        component: GetStarted,
        name: 'get-started',
      },
      // Recognition
      {
        path: 'recognition',
        component: Recognition,
        children: [
          {
            name: 'recognition',
            path: '',
            component: Recognition_Dashboard,
          },
          {
            name: 'recognition-settings',
            path: 'settings',
            component: Recognition_Settings,
          },
          {
            name: 'recognition-group-edit',
            path: ':group_id',
            component: Recognition_GroupEdit,
          },
        ],
      },
      // Birthdays
      {
        path: 'birthdays',
        component: Birthdays,
        meta: {
          requirePro: true,
        },
        children: [
          {
            name: 'birthdays',
            path: '',
            component: Birthdays_Dashboard,
          },
          {
            name: 'birthdays-settings',
            path: 'settings',
            component: Birthdays_Settings,
          },
        ],
      },
      // Anniversaries
      {
        path: 'anniversaries',
        component: Anniversaries,
        meta: {
          requirePro: true,
        },
        children: [
          {
            name: 'anniversaries',
            path: '',
            component: Anniversaries_Dashboard,
          },
          {
            name: 'anniversaries-settings',
            path: 'settings',
            component: Anniversaries_Settings,
          },
        ],
      },
      // New hires
      {
        path: 'new-hires',
        component: NewHires,
        meta: {
          requirePro: true,
        },
        children: [
          {
            name: 'new-hires',
            path: '',
            component: NewHires_Dashboard,
          },
          {
            name: 'new-hires-settings',
            path: 'settings',
            component: NewHires_Settings,
          },
        ],
      },
      // Awards
      {
        path: 'awards',
        component: Awards,
        meta: {
          requirePro: true,
        },
        children: [
          {
            name: 'awards',
            path: '',
            component: Awards_Awards,
          },
          {
            name: 'awards-competitions',
            path: 'competitions',
            component: Awards_Competitions,
          },
          {
            name: 'awards-nominations',
            path: 'nominations',
            component: Awards_Nominations,
          },
          {
            name: 'awards-edit',
            path: ':award_id',
            component: Awards_Edit,
          },
        ],
      },
      // Rewards
      {
        path: 'rewards',
        component: Rewards,
        children: [
          {
            name: 'rewards',
            path: '',
            component: Rewards_Dashboard,
          },
          {
            name: 'rewards-settings',
            path: 'settings',
            component: Rewards_Settings,
          },
          {
            name: 'rewards-custom-catalog',
            path: 'custom-catalog',
            component: Rewards_CustomCatalog,
          },
          {
            name: 'rewards-payments',
            path: 'payments',
            component: Rewards_Payments,
          },
        ],
      },
      // Billing
      {
        path: 'billing',
        component: Billing,
        children: [
          {
            name: 'billing',
            path: '',
            component: Billing_Home,
          },
          {
            name: 'billing-rewards',
            path: 'rewards',
            component: Billing_Rewards,
          },
          {
            name: 'billing-invoices',
            path: 'invoices',
            component: Billing_Invoices,
          },
        ],
      },
      // Users
      {
        path: 'users',
        component: Users,
        children: [
          {
            name: 'users',
            path: '',
            component: Users_Home,
          },
          {
            name: 'users-user-segments',
            path: 'segments',
            component: Users_UserSegments,
          },
          {
            name: 'users-user-segment-edit',
            path: 'segments/:id',
            component: Users_UserSegmentEdit,
          },
          {
            path: ':id',
            component: Users_Profile,
            children: [
              {
                name: 'users-profile',
                path: 'profile',
                component: Users_Profile_Edit,
              },
            ],
          },
        ],
      },
      // Settings
      {
        path: 'settings',
        component: Settings,
        children: [
          {
            name: 'settings',
            path: '',
            component: Settings_Home,
          },
          {
            name: 'settings-securitylog',
            path: 'security-log',
            component: Settings_SecurityLog,
          },
          {
            path: 'integrations',
            component: Settings_Integrations,
            children: [
              {
                name: 'integrations-home',
                path: '',
                component: Settings_Integrations_Home,
              },
              {
                name: 'integrations-bamboohr-auth',
                path: 'auth-bamboohr',
                component: Settings_Integrations_AuthBamboo,
              },
              {
                name: 'integrations-sendoso-auth',
                path: 'auth-sendoso',
                component: Settings_Integrations_AuthSendoso,
              },
              {
                name: 'integrations-gusto-auth',
                path: 'auth-gusto',
                component: Settings_Integrations_AuthGusto,
              },
            ],
          },
        ],
      },
      {
        path: '',
        redirect: {
          name: 'recognition',
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => {
    return { x: 0, y: 0 };
  },
});

router.afterEach(() => {
  try {
    zE('webWidget', 'helpCenter:setSuggestions', { url: true });
  } catch (e) {
    console.warn(e);
  }
});

router.beforeResolve((to, from, next) => {
  const requirePro = to.matched.some((r) =>
    r.meta ? r.meta.requirePro : false
  );
  const requireAdmin = to.matched.some((r) =>
    r.meta ? r.meta.requireAdmin : false
  );
  const requireManager = to.matched.some((r) =>
    r.meta ? r.meta.requireManager : false
  );
  const requireAuth = to.matched.some((r) =>
    r.meta
      ? r.meta.requireAdmin || r.meta.requireAuth || r.meta.requireManager
      : false
  );
  const isAuth = to.matched.some((r) =>
    r.meta ? r.meta.isAuth || r.meta.isAuth : false
  );

  const _handleInactiveTeamNav = () => {
    const team = Store.state.team;
    const user = Store.state.user;

    if (user.is_admin === true) {
      if (team.plan_type === -1) {
        const isTrialEnded = to.matched.some(
          (r) => r.name === 'admin_trial_ended'
        );
        if (isTrialEnded) next();
        else next({ name: 'admin_trial_ended' });
      } else if (team.plan_type === -2) {
        //former subscription, but inactive
        const isInactiveSub = to.matched.some(
          (r) => r.name === 'admin_inactive_subscription'
        );
        if (isInactiveSub) next();
        else next({ name: 'admin_inactive_subscription' });
      }
    } else {
      if (team.plan_type === -1) {
        const isTrialEnded = to.matched.some((r) => r.name === 'trial_ended');
        if (isTrialEnded) next();
        else next({ name: 'trial_ended' });
      } else if (team.plan_type === -2) {
        //former subscription, but inactive
        const isInactiveSub = to.matched.some(
          (r) => r.name === 'inactive_subscription'
        );
        if (isInactiveSub) next();
        else next({ name: 'inactive_subscription' });
      }
    }
  };

  const _handleNav = () => {
    const user = Store.state.user;
    const team = Store.state.team;
    //we have a user, enforce nav
    if (!requireAuth) return next();

    if (requireAdmin || requireManager) {
      const _continue = encodeURIComponent(to.fullPath);
      if (user === null)
        return next({ name: 'login', query: { redirect: _continue } });
      //must be an admin or manager
      if (requireAdmin && user.is_admin === false) {
        //not admin, redirect to redeem view
        if (user.is_manager) return next({ name: 'reporting' });
        else if (user.subordinate_users && user.subordinate_users.length > 0)
          return next({ name: 'reporting-summary' });
        return next({ name: 'rewards_home' });
      } else if (
        requireManager &&
        user.is_manager === false &&
        user.is_admin === false
      ) {
        if (user.subordinate_users && user.subordinate_users.length > 0)
          return next({ name: 'reporting-summary' });
        return next({ name: 'rewards_home' });
      } else {
        //is admin, allow
        //some trial guarding
        if (team.plan_type <= -1) {
          _handleInactiveTeamNav();
          return;
        }

        if (requirePro && team.plan_type !== 1) {
          return next({ name: 'billing', params: { locked_feature: true } });
        }

        return next();
      }
    } else {
      //admin not required, auth is required though
      const _continue = encodeURIComponent(to.fullPath);
      if (user === null)
        return next({ name: 'login', query: { redirect: _continue } });

      //is authed
      if (team.plan_type <= -1) {
        _handleInactiveTeamNav();
        return;
      }

      next();
    }
  };

  if (isAuth === true) {
    Store.dispatch('logout');
  }

  if (Store.state.user_token !== null) {
    //We have a user token, make sure user model is loaded
    const user = Store.state.user;
    if (user === null) {
      const unwatch = Store.watch(Store.getters.user_status, (val, prev) => {
        Store.commit('app_loading', false);
        unwatch();

        if (val === 'AUTHED') return _handleNav();

        //error?
        if (val === 'ERROR') {
          //clear token
          Store.dispatch('logout');

          const _continue = encodeURIComponent(to.fullPath);
          next({
            name: 'login',
            params: { error: `Your session has expired, please login again.` },
            query: { continue: _continue },
          });
        }
      });

      //Refresh current token before doing anything
      api.Auth.refresh_token()
        .then(({ token }) => {
          Store.commit('user_token', token);
          Store.dispatch('populateUser');
        })
        .catch(() => {
          Store.dispatch('logout');

          const _continue = encodeURIComponent(to.fullPath);
          next({
            name: 'login',
            params: { error: `Your session has expired, please login again.` },
            query: { continue: _continue },
          });
        });
    } else {
      //has user model
      Store.commit('app_loading', false);
      _handleNav();
    }
  } else {
    Store.commit('app_loading', false);
    _handleNav();
  }
});

export default router;
