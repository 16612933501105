<template>
  <main class="app-view birthdays birthday-dashboard">
    <header>
      <div class="title">
        <h1>Welcome to HiThrive</h1>
      </div>

      <div class="btns">
        <button class="btn btn-light" @click="dismiss">Dismiss setup</button>
      </div>
    </header>

    <div class="welcome-hero">
      <img src="@/assets/get-started-doodle.jpg" />
    </div>

    <div class="content">
      <h2>Getting started with HiThrive</h2>
      <p>Thank you for installing HiThrive to Slack. Here are a few things you can do to get started:</p>

      <ul>
        <li>
          <router-link :to="{name: 'recognition'}">
            <i :class="[is_done_class('setup_first_group')]"></i>
            <div>
              <h4>Add HiThrive to a channel on Slack</h4>
              <p>Before anyone can use HiThrive, you'll need to pick a channel to use. You can add as many as you'd like.</p>
            </div>
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'recognition-settings'}">
            <i :class="[is_done_class('added_tag_values')]"></i>
            <div>
              <h4>Setup taggable corporate values</h4>
              <p>Let your team tag moments they believe match your corporate values.</p>
            </div>
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'rewards-settings'}">
            <i :class="[is_done_class('setup_rewards')]"></i>
            <div>
              <h4>Enable reward redemptions</h4>
              <p>Setup culture rewards and choose to enable Amazon and gift card redemptions.</p>
            </div>
          </router-link>
        </li>
        <li>
          <a href="#" @click.prevent="openIntroductionModal">
            <i :class="[is_done_class('introduction')]"></i>
            <div>
              <h4>Launch HiThrive</h4>
              <p>It's time to turn everything on so your team can use HiThrive.</p>
            </div>
          </a>
        </li>
        <li>
          <router-link :to="{name: 'billing'}">
            <i :class="[is_done_class('have_payment_method')]"></i>
            <div>
              <h4>Add a payment method</h4>
              <p>Enjoy your free trial, but it's a good idea to add a credit card now to avoid any interruptions.</p>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </main>
</template>

<script>
export default {
  computed: {
    team() {
      return this.$store.state.team;
    }
  },
  data() {
    return {

    }
  },
  methods: {
    is_done_class(k) {
      const val = this.team.metadata[k];
      const done = val !== true ? false : true;
      return done === true ? `fas fa-check-circle` : `far fa-circle`;
    },
    dismiss() {
      this.$store.dispatch('teamMetadata', {show_get_started: false})
      this.$router.push({
        name: 'recognition'
      })
    },
    openIntroductionModal() {
      this.$root.$emit(
        'openModal',
        'Introduction',
        {},
        () => {

        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.welcome-hero {
  margin: 25px 0 0;
  > img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
}

.content {
  z-index: 9;
  position: relative;
  background: #fff;
  border-radius: 10px;
  box-shadow: rgba(0,0,0,0.1) 0 10px 20px;
  padding: 25px;
  margin: -50px 50px 35px;
  min-height: 100px;

  > h2 {
    font-weight: 700;
    font-size: 22px;
    margin: 0 0 10px;
  }

  ul {
    display: block;
    padding: 0;
    margin: 0;

    > li {
      display: block;
      padding: 0;

      + li {
        margin-top: 15px;
      }

      > a {
        display: flex;
        text-decoration: none;
        color: $body-color;
        padding: 15px 50px 15px 15px;
        background: rgba($light, 0.5);
        border-radius: 10px;
        position: relative;

        &:hover {
          background: rgba($light, 0.8);
        }

        &:after {
          content: '\f054';
          display: block;
          position: absolute;
          right: 15px;
          top: 50%;
          height: 24px;
          font-size: 20px;
          margin-top: -12px;
          line-height: 25px;
          color: rgba($muted-text, 0.3);
          font-family: 'Font Awesome 5 Pro';
        }

        > i {
          font-size: 22px;
          flex: 0 0 40px;
          color: $muted-text;

          &.fa-check-circle {
            color: $green;
          }
        }

        h4 {
          font-weight: 700;
          font-size: 16px;
          margin: 0 0 2px;
        }

        p {
          font-size: 14px;
          margin: 0;
        }
      }
    }
  }
}
</style>