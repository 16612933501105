<template>
  <div class="reward-view" id="charity-details">
    <main>
      <header>
        <div class="title">
          <ul class="crumbs">
            <li>
              <router-link :to="{ name: 'rewards_home' }">Rewards</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'rewards_charities_home' }"
                >Charities</router-link
              >
            </li>
          </ul>

          <h1 v-if="charity !== null">{{ charity.name }}</h1>
          <div class="loading-placeholder" v-else><i></i></div>
        </div>
      </header>

      <section class="main-section" id="details-section">
        <template v-if="charity !== null">
          <div class="row align-items-center">
            <div class="col-md-4">
              <div class="thumbnail">
                <img
                  :src="
                    `https://hithrive-giving.herokuapp.com/nonprofits/${charity.ein}/logo`
                  "
                  @load="$set(charity, 'has_logo', true)"
                  class="logotest"
                />
                <div
                  class="thumb"
                  :style="{ 'background-image': charity_logo }"
                ></div>
              </div>
            </div>
            <div class="col-md-5">
              <h4>Redeem your {{ team.points_name }}</h4>

              <div class="form-group">
                <div
                  :class="[
                    'input-group',
                    'input-group-lg',
                    { 'is-invalid': !allow_redemption },
                  ]"
                >
                  <input
                    type="text"
                    v-model.number="points"
                    class="form-control"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">{{ team.points_name }}</span>
                  </div>
                </div>
                <div class="invalid-feedback">{{ warning }}</div>

                <div class="point-cost">
                  {{ $formatPoints(points) }} =
                  {{ $formatCurrency(points / 100) }}
                </div>
              </div>

              <button
                :class="['btn', 'btn-block', 'btn-green', { loading: loading }]"
                :disabled="!allow_redemption"
                @click="donatePoints"
              >
                Donate <strong>{{ $formatPoints(points) }}</strong>
              </button>
            </div>
          </div>

          <div class="info-section">
            <div class="d-flex info-row" v-if="charity.mission">
              <div class="title">
                Mission
              </div>
              <div class="content">
                <p class="mission">
                  {{ charity.mission }}
                </p>
              </div>
            </div>
            <div class="d-flex info-row">
              <div class="title">
                Address
              </div>
              <div class="content">
                {{ address }}
              </div>
            </div>
            <div class="d-flex info-row" v-if="charity.url">
              <div class="title">
                Website
              </div>
              <div class="content">
                <a :href="url" target="_blank">{{ url }}</a>
              </div>
            </div>
            <div class="d-flex info-row" v-if="charity.receipts">
              <div class="title">
                Annual Donations
              </div>
              <div class="content">
                {{ $formatCurrency(charity.receipts) }}
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="row align-items-end">
            <div class="col-md-4">
              <div class="loading-placeholder image"><i></i></div>
            </div>
            <div class="col-md-4">
              <div class="loading-placeholder redeem"><i></i></div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-10">
              <div class="loading-placeholder description"><i></i></div>
            </div>
          </div>
        </template>
      </section>
    </main>
  </div>
</template>

<script>
import urlparse from 'url-parse';

export default {
  computed: {
    team() {
      return this.$store.state.team;
    },
    url() {
      let url = this.charity.url.toLowerCase();
      if (url.indexOf('http') == -1) url = 'http://' + url;

      return urlparse(url).toString();
    },
    address() {
      let addr = ``;

      if (this.charity.street1) addr += this.charity.street1 + '\n';
      if (this.charity.street2) addr += this.charity.street2 + '\n';
      if (this.charity.city)
        addr += this.charity.city + ', ' + this.charity.state;

      return addr;
    },
    enough_points() {
      return this.$store.state.user.redeemable_points >= this.points;
    },
    allow_redemption() {
      const currencyAmt = this.points * 0.01;

      if (!this.enough_points) return false;
      if (currencyAmt < 1) return false;

      return true;
    },
    loading() {
      return this.$store.state.loading;
    },
    warning() {
      const currencyAmt = this.points * 0.01;

      if (currencyAmt < 1) {
        return 'Donation must be at least $1';
      }

      if (currencyAmt > 1000) {
        return `Donation can't be more than $1,000`;
      }

      if (!this.enough_points) {
        return `You don't have enough ${this.team.points_name}.`;
      }

      return null;
    },
    charity_logo() {
      if (this.charity.has_logo === true)
        return `url('https://hithrive-giving.herokuapp.com/nonprofits/${this.charity.ein}/logo')`;
      return `url('${require('@/assets/logo-placeholder.jpg')}')`;
    },
  },
  data() {
    return {
      charity: null,
      points: 100,
    };
  },
  created() {
    this.populateCharity();
  },
  methods: {
    async donatePoints() {
      this.$store.commit('loading', true);
      const resp = await this.$api.RewardPortal.Charities.redeem({
        ein: this.charity.ein,
        points: this.points,
      });
      this.$store.dispatch('adjustRedeemablePoints', -Math.abs(this.points));
      this.$store.commit('loading', false);

      this.$router.push({
        name: 'rewards_redemptions',
        params: {
          redemption_id: resp.redemption_id,
        },
      });
    },
    async populateCharity() {
      const resp = await this.$api.RewardPortal.Charities.get_by_ein(
        this.$route.params.ein
      );

      this.charity = resp;
    },
  },
};
</script>

<style lang="scss" scoped>
#charity-details {
  > main {
    > header {
      .egift-badge {
        display: inline-block;
        margin: 0 0 0 15px;
        vertical-align: -3px;
      }
      .loading-placeholder {
        width: 256px;
        max-width: 100%;
        > i {
          display: block;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: center center;
          background-image: url("data:image/svg+xml,%3Csvg width='951' height='50' preserveAspectRatio='none' viewBox='0 0 951 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='951' height='50' rx='4' fill='%23E4E1DF'/%3E%3C/svg%3E%0A");

          &:before {
            content: '';
            display: block;
            height: 28px;
          }
        }
      }
    }
  }
}

.point-cost {
  color: $muted-text;
  font-size: 12px;
  margin: 5px 0 0;
  display: block;
}

#details-section {
  margin: 25px 0 0;
  .loading-placeholder {
    &.description {
      margin: 25px 0 0;
      > i {
        &:before {
          content: '';
          display: block;
          padding: 0 0 11%;
        }

        display: block;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center center;
        background-image: url("data:image/svg+xml,%3Csvg width='591' height='65' viewBox='0 0 591 65' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4 0C1.79086 0 0 1.79086 0 4V11C0 13.2091 1.79086 15 4 15H100C102.209 15 104 13.2091 104 11V4C104 1.79086 102.209 0 100 0H4ZM4 50C1.79086 50 0 51.7909 0 54V61C0 63.2091 1.79086 65 4 65H100C102.209 65 104 63.2091 104 61V54C104 51.7909 102.209 50 100 50H4ZM0 29C0 26.7909 1.79086 25 4 25H138C140.209 25 142 26.7909 142 29V36C142 38.2091 140.209 40 138 40H4C1.79086 40 0 38.2091 0 36V29ZM155 25C152.791 25 151 26.7909 151 29V36C151 38.2091 152.791 40 155 40H244C246.209 40 248 38.2091 248 36V29C248 26.7909 246.209 25 244 25H155ZM257 29C257 26.7909 258.791 25 261 25H409C411.209 25 413 26.7909 413 29V36C413 38.2091 411.209 40 409 40H261C258.791 40 257 38.2091 257 36V29ZM426 25C423.791 25 422 26.7909 422 29V36C422 38.2091 423.791 40 426 40H587C589.209 40 591 38.2091 591 36V29C591 26.7909 589.209 25 587 25H426ZM113 4C113 1.79086 114.791 0 117 0H197C199.209 0 201 1.79086 201 4V11C201 13.2091 199.209 15 197 15H117C114.791 15 113 13.2091 113 11V4ZM117 50C114.791 50 113 51.7909 113 54V61C113 63.2091 114.791 65 117 65H197C199.209 65 201 63.2091 201 61V54C201 51.7909 199.209 50 197 50H117ZM210 4C210 1.79086 211.791 0 214 0H385C387.209 0 389 1.79086 389 4V11C389 13.2091 387.209 15 385 15H214C211.791 15 210 13.2091 210 11V4ZM214 50C211.791 50 210 51.7909 210 54V61C210 63.2091 211.791 65 214 65H385C387.209 65 389 63.2091 389 61V54C389 51.7909 387.209 50 385 50H214ZM398 4C398 1.79086 399.791 0 402 0H587C589.209 0 591 1.79086 591 4V11C591 13.2091 589.209 15 587 15H402C399.791 15 398 13.2091 398 11V4ZM402 50C399.791 50 398 51.7909 398 54V61C398 63.2091 399.791 65 402 65H587C589.209 65 591 63.2091 591 61V54C591 51.7909 589.209 50 587 50H402Z' fill='%23E4E1DF'/%3E%3C/svg%3E%0A");
      }
    }
    &.redeem {
      > i {
        &:before {
          content: '';
          display: block;
          padding: 0 0 38%;
        }

        display: block;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center center;
        background-image: url("data:image/svg+xml,%3Csvg width='271' height='104' viewBox='0 0 271 104' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='271' height='48' rx='4' fill='%23E4E1DF'/%3E%3Crect y='71' width='271' height='33' rx='4' fill='%23E4E1DF'/%3E%3C/svg%3E%0A");
      }
    }
    &.image {
      > i {
        &:before {
          content: '';
          display: block;
          padding: 0 0 63%;
        }

        display: block;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center center;
        background-image: url("data:image/svg+xml,%3Csvg width='258' height='164' viewBox='0 0 258 164' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='258' height='164' rx='4' fill='%23E4E1DF'/%3E%3C/svg%3E%0A");
      }
    }
  }

  .info-section {
    margin: 35px 0 0;
  }

  .info-row {
    padding: 10px 0;
    .title {
      width: 200px;
      font-weight: 700;
      flex: 0 0 200px;
    }
    .content {
      flex: 1;
    }

    border-bottom: 1px solid $light;
  }

  h4 {
    font-weight: 700;
    font-size: 16px;
    margin: 0 0 15px;

    & + p {
      font-size: 14px;
    }
  }

  .thumbnail {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background: #fff;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.05) 0 5px 10px;

    > .logotest {
      width: 0;
      height: 0;
      position: absolute;
    }

    > .thumb {
      display: block;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      border-radius: 12px;
      overflow: hidden;

      &:before {
        padding: 0 0 62%;
        display: block;
        content: '';
      }
    }
  }

  .description-row {
    margin-top: 25px;
    margin-bottom: -1rem;
  }
}
</style>
