<template>
  <div class="reward-view container-fluid" id="redemptions">
    <main>
      <header>
        <div class="title">
          <ul class="crumbs">
            <li>
              <router-link :to="{name: 'rewards_home'}">Rewards</router-link>
            </li>
          </ul>
          <h1>Redemptions</h1>
        </div>
      </header>

      <section class="main-section" id="redemption-list">
        <template v-if="redemptions !== null && redemptions.length > 0">
          <div class="alert alert-success" v-if="active_redemption">
            <strong>Your redemption is processing.</strong> {{redemption_success_msg}}
          </div>

          <ul>
            <li v-for="redemption in redemptions" :class="[{'active': active_redemption === redemption[0].id}]" :key="`redemptiongroup_${redemption[0].groupById}`">
              <component :is="redemption_component(redemption)" :redemption="redemption" />
            </li>
          </ul>
        </template>
        <div class="empty-state" v-else-if="redemptions !== null">You haven't made any redemptions yet.</div>
        <div class="loading-placeholder" v-else><i></i><i></i><i></i></div>
      </section>
    </main>
  </div>
</template>

<script>
import GiftCardRedemption from '@/components/RewardsPortal/Redemptions/GiftCard';
import AmazonRedemption from '@/components/RewardsPortal/Redemptions/Amazon';
import CustomRedemption from '@/components/RewardsPortal/Redemptions/Custom';
import DonationRedemption from '@/components/RewardsPortal/Redemptions/Donation';
import StorefrontRedemption from '@/components/RewardsPortal/Redemptions/Storefront';
import PayPalRedemption from '@/components/RewardsPortal/Redemptions/PayPal';
import IntegrationCustom from '@/components/RewardsPortal/Redemptions/IntegrationCustom';

export default {
  components: {
    GiftCardRedemption,
    AmazonRedemption,
    CustomRedemption,
    DonationRedemption,
    StorefrontRedemption,
    PayPalRedemption,
    IntegrationCustom
  },
  computed: {
    active_redemption() {
      return this.$route.params.redemption_id;
    },
    redemption_success_msg() {
      const type = _.find(this.redemptions, r => r[0].id === this.$route.params.redemption_id)[0].type;
      switch(type) {
        case 'PAYPAL':
          return `Your funds will be sent in the next hour.`;
        case 'GIFT_CARD':
          return 'Your gift card will be sent in the next hour.';
        case 'DONATION':
          return 'Funds will be sent to the non-profit within 30 days.';
        case 'AMAZON':
          return `We'll send you an update when your order ships. Multiple redemptions may be shipped together.`;
        case 'CUSTOM':
          return `An administrator has been notified to fulfill this redemption.`;
        case 'INTEGRATION_CUSTOM':
          return `Your order is being processed and will ship shortly.`;
        case 'CUSTOM_REDEMPTION':
          return `Your redemption has been processed.`;
        case 'STOREFRONT':
          return `An administrator has been notified to fulfill this redemption.`;
      }
    }
  },
  data() {
    return {
      redemptions: null
    }
  },
  watch: {

  },
  created() {
    this.populateRedemptions()
  },
  methods: {
    async populateRedemptions() {
      const resp = await this.$api.RewardPortal.get_redemptions();
      this.redemptions = resp;
    },
    redemption_component(redemption) {
      let comp_name = '';
      switch(redemption[0].type) {
        case 'CUSTOM':
          comp_name = 'CustomRedemption';
        break;
        case 'INTEGRATION_CUSTOM':
          comp_name = 'IntegrationCustom';
        break;
        case 'CUSTOM_CATALOG':
          comp_name = 'IntegrationCustom';
        break;
        case 'AMAZON':
          comp_name = 'AmazonRedemption';
        break;
        case 'GIFT_CARD':
          comp_name = 'GiftCardRedemption';
        break;
        case 'DONATION':
          comp_name = 'DonationRedemption';
        break;
        case 'STOREFRONT':
          comp_name = 'StorefrontRedemption';
        break;
        case 'PAYPAL':
          comp_name = 'PayPalRedemption';
        break;
      }

      return comp_name;
    }
  }
}
</script>

<style lang="scss" scoped>
#redemption-list {
  margin: 25px 0 0;

  > ul {
    display: block;
    padding: 0;
    margin: 0;

    > li {
      display: block;
      background: $light;
      border-radius: 10px;
      padding: 15px;

      +li {
        margin: 15px 0 0;
      }

      &.active {
        box-shadow: $green 0 0 0 1px, rgba($green, 0.3) 0 0 0 3px;
      }
    }
  }
}
.loading-placeholder {
  //display: flex;
  margin: -10px 0;
  > i {
    margin: 10px 0;
    //flex: 1 0 auto;
    display: block;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    background-image: url("data:image/svg+xml,%3Csvg width='951' height='50' preserveAspectRatio='none' viewBox='0 0 951 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='951' height='50' rx='4' fill='%23E4E1DF'/%3E%3C/svg%3E%0A");
    
    &:before {
      content: '';
      display: block;
      height: 50px;
    }
  }
}
</style>