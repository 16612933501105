<template>
  <main class="app-view reporting reporting-activity">
    <header>
      <div class="title">
        <h1>
          Activity
          <span>
            <div class="dropdown">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown"
                ><span>{{ report_type_title }}</span></a
              >
              <div class="dropdown-menu">
                <a
                  class="dropdown-item"
                  href="#"
                  @click.prevent="changeReport('shoutouts')"
                  >Shoutouts</a
                >
                <a
                  class="dropdown-item"
                  href="#"
                  @click.prevent="changeReport('shoutouts-by-recipient')"
                  >Shoutouts (by Recipient)</a
                >
                <a
                  class="dropdown-item"
                  href="#"
                  @click.prevent="changeReport('awards')"
                  >Awards</a
                >
                <a
                  class="dropdown-item"
                  href="#"
                  @click.prevent="changeReport('awards-by-recipient')"
                  >Awards (by Recipient)</a
                >
                <a
                  class="dropdown-item"
                  href="#"
                  @click.prevent="changeReport('birthdays')"
                  >Birthdays</a
                >
                <a
                  class="dropdown-item"
                  href="#"
                  @click.prevent="changeReport('anniversaries')"
                  >Anniversaries</a
                >
                <a
                  class="dropdown-item"
                  href="#"
                  @click.prevent="changeReport('new-hires')"
                  >New Hires</a
                >
                <a
                  class="dropdown-item"
                  href="#"
                  @click.prevent="changeReport('nominations')"
                  >Nominations</a
                >
                <a
                  class="dropdown-item"
                  href="#"
                  @click.prevent="changeReport('redemptions')"
                  >Redemptions</a
                >
                <a
                  class="dropdown-item"
                  href="#"
                  @click.prevent="changeReport('points')"
                  >Points earned</a
                >
                <a
                  class="dropdown-item"
                  href="#"
                  @click.prevent="changeReport('point-totals')"
                  >Point totals</a
                >
                <a
                  v-if="me.is_admin === true"
                  class="dropdown-item"
                  href="#"
                  @click.prevent="changeReport('users')"
                  >Users</a
                >
              </div>
            </div>
          </span>
        </h1>
      </div>

      <div class="filters">
        <button class="btn btn-circle btn-sm btn-light" @click="exportTable">
          <i class="fas fa-cloud-download"></i>
        </button>
        <date-range-picker
          ref="datePicker"
          v-model="date_range"
          :max-date="$DateTime.local().toJSDate()"
          opens="left"
          :ranges="date_options"
          v-if="has_date_range"
        >
        </date-range-picker>
      </div>
    </header>

    <component
      v-if="component !== null"
      :is="component"
      :date-range="date_range"
    />
  </main>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
export default {
  components: {
    DateRangePicker,
  },
  computed: {
    has_date_range() {
      if (this.report_type === 'users') return false;

      return true;
    },
    me() {
      return this.$store.state.user;
    },
    report_type_title() {
      switch (this.report_type) {
        case 'shoutouts':
          return 'Shoutouts';
        case 'shoutouts-by-recipient':
          return 'Shoutouts (by Recipient)';
        case 'awards':
          return 'Awards';
        case 'awards-by-recipient':
          return 'Awards (by Recipient)';
        case 'birthdays':
          return 'Birthdays';
        case 'redemptions':
          return 'Redemptions';
        case 'anniversaries':
          return 'Anniversaries';
        case 'new-hires':
          return 'New Hires';
        case 'nominations':
          return 'Nominations';
        case 'points':
          return 'Points earned';
        case 'point-totals':
          return 'Point totals';
        case 'users':
          return 'Users';
      }
    },
  },
  watch: {
    '$route.query': function(val) {
      this.renderActivityComponent();
    },
  },
  data() {
    return {
      component: null,
      date_options: {
        'Last 7 days': [
          this.$today.minus({ days: 7 }).toJSDate(),
          this.$today.toJSDate(),
        ],
        'This week': [
          this.$today.startOf('week').toJSDate(),
          this.$today.endOf('week').toJSDate(),
        ],
        'Last week': [
          this.$today
            .minus({ days: 7 })
            .startOf('week')
            .toJSDate(),
          this.$today
            .minus({ days: 7 })
            .endOf('week')
            .toJSDate(),
        ],
        'This month': [
          this.$today.startOf('month').toJSDate(),
          this.$today.toJSDate(),
        ],
        'Last 30 days': [
          this.$today.minus({ days: 30 }).toJSDate(),
          this.$today.toJSDate(),
        ],
        'Last month': [
          this.$today
            .minus({ months: 1 })
            .startOf('month')
            .toJSDate(),
          this.$today
            .minus({ months: 1 })
            .endOf('month')
            .toJSDate(),
        ],
        'Last 6 months': [
          this.$today
            .minus({ months: 6 })
            .startOf('month')
            .toJSDate(),
          this.$today.toJSDate(),
        ],
        'Last year': [
          this.$today
            .minus({ years: 1 })
            .startOf('year')
            .toJSDate(),
          this.$today
            .minus({ years: 1 })
            .endOf('year')
            .toJSDate(),
        ],
      },
      date_range: {
        startDate: this.$today.startOf('month').toJSDate(),
        endDate: this.$today.toJSDate(),
      },
      report_type: null,
    };
  },
  created() {
    this.renderActivityComponent();
  },
  methods: {
    exportTable() {
      this.$emit('exportTable');
    },
    changeReport(type) {
      this.$router.push({
        name: 'reporting-activity',
        query: {
          type,
        },
      });
    },
    renderActivityComponent() {
      const { type } = this.$route.query;
      if (!type) {
        this.$router.replace({
          name: 'reporting-activity',
          query: { type: 'shoutouts' },
        });
        return;
      }
      this.report_type = type;
      this.component = () => import(`./_${type}.vue`);
    },
  },
};
</script>

<style lang="scss" scoped>
.app-view.reporting {
  > header {
    > .filters {
      margin: 0 0 0 auto;

      button {
        margin: 0 10px 0 0;
      }
    }

    > .title {
      > h1 {
        > span {
          display: inline-block;
          font-size: 0.7em;
          color: $muted-text;

          > .dropdown {
            display: inline-block;

            > a {
              text-decoration: none;
              color: $muted-text;

              > span {
                border-bottom: 1px $muted-text dashed;
              }
            }
          }
        }
      }
    }
  }
}
</style>
