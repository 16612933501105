<template>
  <div id="trial-ended">
    <header>
      <img src="@/assets/hithrive-logo-dark.svg" height="20" />
    </header>

    <section class="intro">
      <h1>Your subscription has ended.</h1>
      <p>Thank you for using HiThrive. You don't currently have an active subscription, but we've made it easy to start using HiThrive again. Pick up right where you left off by choosing a plan below.</p>
    </section>

    <section class="choose-plan">
      <plan-selector @refresh="handleSubRefresh" />
    </section>
  </div>
</template>

<script>
import PlanSelector from '../components/Billing/PlanSelector.vue'
export default {
  components: {
    PlanSelector
  },
  computed: {
    team() {
      return this.$store.state.team;
    }
  },
  mounted() {
    if(this.team.plan_type !== -1) {
      this.$router.replace({
        name: 'recognition'
      })
    }
  },
  methods: {
    async handleSubRefresh() {
      await this.$store.dispatch('populateUser')
      this.$router.push({
        name: 'recognition'
      })
    }
  }
}
</script>

<style lang="scss" 
PlanSelectorscoped>
#trial-ended {
  max-width: 600px;
  margin: 35px auto;

  header {
    padding-bottom: 50px;
  }

  h1 {
    font-weight: 700;
    font-size: 24px;
  }

  .choose-plan {
    margin: 50px 0 0;
  }
}
</style>