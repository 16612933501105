<template>
  <li :class="[invoice_status_class]">
    <div class="date">
      <h4>{{$formatTimestamp(invoice.created)}}</h4>
    </div>

    <div class="amount">
      <span>{{$formatCurrency(invoice.total/100, invoice.currency)}}</span>
    </div>

    <div class="number">
      <span>#{{invoice.number}}</span>
    </div>

    <div class="actions">
      <a :href="invoice.invoice_pdf" target="_blank" class="btn btn-light btn-xs">View invoice</a>
    </div>
  </li>
</template>

<script>
export default {
  props: ['invoice'],
  computed: {
    invoice_status_class() {
      return this.invoice.paid === true ? 'status--paid' : 'status--open';
    }
  }
}
</script>

<style lang="scss" scoped>
li {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 10px 0 10px 6px;
  border-radius: 10px;
  background: $light;
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 6px;
    background: $secondary;
  }

  &.status--paid {
    &:before {
      background: $green;
    }
  }

  &.status--open {
    &:before {
      background: $red;
    }
  }

  + li {
    margin: 15px 0 0;
  }

  > div {
    padding: 0 10px;
  }

  .date {
    > h4 {
      font-weight: 700;
      margin: 0;
      font-size: 14px;
      line-height: 14px;
    }
  }

  .amount, .number {
    > span {
      font-size: 14px;
      line-height: 14px;
      display: block;
      color: $muted-text;
    }
  }

  .actions {
    margin: 0 0 0 auto;
  }

  .points {
    background: $green;
    font-size: 12px;
    line-height: 12px;
    padding: 2px 4px;
    border-radius: 3px;
    display: block;
    color: #fff;
    margin: 0 5px 0 0;
  }

  &.success {
    box-shadow: $green 0 0 0 1px, rgba($green, 0.3) 0 0 0 4px;

    &:before {
      content: '\f00c';
      font-family: 'Font Awesome 5 Pro';
      font-weight: 700;
      display: block;
      border-radius: 100%;
      background: $green;
      color: $light;
      font-size: 12px;
      text-align: center;
      line-height: 24px;
      width: 22px;
      height: 22px;
      margin: 0 10px 0 0;
    }
  }

  &.loading-placeholder {
    height: 44px;
  }
}
</style>