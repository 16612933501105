<template>
  <div class="paypal-redemption">
    <div class="top-info">
      <img :src="paypal_logo" height="12" />

      <div class="status ml-auto">
        <span :class="[status_class]">{{status_text}}</span>
      </div>
      <div class="point-cost">
        <span>{{$formatPoints(points)}}</span>
      </div>
    </div>

    <div class="d-flex">
      <div class="title">
        <div class="timestamp">{{$formatDate(date)}}</div>
        <h4>{{paypal.rail === 'VENMO' ? 'Venmo' : 'PayPal'}} Cash Out <small v-if="paypal.sent_amount">{{$formatCurrency(paypal.sent_amount, paypal.currency || 'USD', true)}}</small></h4>
      </div>
      <div class="order-actions">
        <a class="btn btn-light btn-kinda-sm" :href="support_email">Get redemption help</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['redemption'],
  computed: {
    user() {
      return this.$store.state.user;
    },
    team() {
      return this.$store.state.team;
    },
    status() {
      return this.redemption[0].status;
    },
    status_text() {
      switch( this.status ) {
        case 'PENDING':
          return 'Sending soon';
        case 'COMPLETE':
          return 'Funds sent';
        case 'CANCELLED':
          return 'Cancelled';
      }
    },
    status_class() {
      return 'status-' + this.status.toLowerCase();
    },
    date() {
      return this.redemption[0].created_at;
    },
    points() {
      return this.redemption[0].points;
    },
    paypal() {
      return this.redemption[0].redemption;
    },
    support_email() {
      return `mailto:help@hithrive.com?subject=Redemption%20Support%3A%20%23${this.team.id}-${this.user.id}-${this.redemption[0].id}&body=Please%20describe%20how%20we%20can%20assist%20you%20with%20your%20redemption%20below%3A%0D%0A%0D%0A`;
    },
    paypal_logo() {
      return this.paypal.rail === 'VENMO' ? require('@/assets/venmo-logo.svg') : require('@/assets/paypal-logo.svg');
    }
  }
}
</script>