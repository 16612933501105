<template>
  <main class="app-view settings settings-dashboard">
    <header>
      <div class="title">
        <ul class="crumbs">
          <li>
            <router-link :to="{name: 'settings'}">Settings</router-link>
          </li>
        </ul>
        <h1>Security Log</h1>
      </div>
    </header>

    <section class="main-section">
      <header>
        <h2>Recent Activity</h2>
      </header>
      <template v-if="activity !== null">
        <ul class="security-log" v-if="activity.length > 0">
          <li v-for="(e, index) in activity" :key="`sec_log_${index}`">
            <div>
              <span class="timestamp">{{$formatDate(e.created_at, $DateTime.DATETIME_SHORT)}}</span>
              <span class="type">{{activityLabel(e)}}</span>
            </div>
            <div>
              <user-card :user="e.user" :height="24" />
            </div>
            <div>
              <span>{{e.metadata.ip_addr}}</span>
              <span>{{e.metadata.user_agent}}</span>
            </div>
          </li>
        </ul>
        <p class="empty-state" v-else>No activity in the last 30 days.</p>
        <div class="load-more" ref="load_more" v-if="activity.length > 0">
          <loading-indicator v-if="loading" />
        </div>
      </template>
      <div v-else class="loading-placeholder"><i></i><i></i><i></i><i></i><i></i></div>
    </section>
  </main>
</template>

<script>
import {debounce} from 'lodash'

export default {
  computed: {
    loading() {
      return this.$store.state.loading;
    }
  },
  components: {
    
  },
  data() {
    return {
      has_more_pages: true,
      page: 1,
      activity: null
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scroll_handler);
  },
  mounted() {
    window.addEventListener('scroll', this.scroll_handler);
  },
  created() {
    this.populateSecurityLog()
  },
  methods: {
    populateSecurityLog: debounce(async function() {
      this.$store.commit('loading', true)
      const res = await this.$api.Team.get_security_activity(this.page)
      this.$store.commit('loading', false)
      
      if(res.length === 100) this.has_more_pages = true;
      else if(res.length < 100) this.has_more_pages = false;

      if(this.activity === null) this.activity = [];

      this.activity.push(...res)
    }, 250),
    scroll_handler() {
      if(this.loading) return;
      if(!this.has_more_pages) return;
      
      const el = this.$refs.load_more;
      if(!el) return;

      const rect = el.getBoundingClientRect();
      
      if(rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
        this.$store.commit('loading', true)
        this.page ++;
        this.populateSecurityLog();
      }
    },
    activityLabel({activity_type}) {
      switch(activity_type) {
        case 'REFRESH_TOKEN':
          return 'Refreshed token';
        case 'AUTHENTICATED_WITH_SLACK_TOKEN':
          return 'Login from Slack';
        case 'HITHRIVE_TOKEN_EXPIRED':
          return 'Attempted access with expired token';
        case 'AUTHENTICATED_WITH_HITHRIVE_TOKEN':
          return 'Login with single use token';
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.security-log {
  padding: 0;
  margin: 0;
  
  > li {
    display: flex;
    background: $light;
    padding: 10px 0;
    border-radius: 10px;
    font-size: 12px;
    align-items: center;

    > div {
      display: block;
      flex: 1 0 auto;
      min-width: 0;
      white-space: nowrap;
      //max-width: 60%;
      padding: 0 10px;

      &:last-child {
        flex: 0 1 auto;
      }

      > span {
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    + li {
      margin: 10px 0 0;
    }
  }
}
.loading-placeholder {
  display: block;
  margin: 0 0;
  > i {
    margin: 0 0;
    +i { 
      margin-top: 15px;
    }
    //flex: 1 0 auto;
    display: block;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    background-image: url("data:image/svg+xml,%3Csvg width='951' height='50' preserveAspectRatio='none' viewBox='0 0 951 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='951' height='50' rx='4' fill='%23E4E1DF'/%3E%3C/svg%3E%0A");
    
    &:before {
      content: '';
      display: block;
      height: 50px;
    }
  }
}
</style>