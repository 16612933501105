<template>
  <main class="app-view reporting reporting-summary">
    <header>
      <div class="title">
        <h1>
          Summary
        </h1>
      </div>

      <div class="filters">
        <date-range-picker
          ref="datePicker"
          v-model="date_range"
          :max-date="$DateTime.local().toJSDate()"
          opens="left"
          :ranges="date_options"
        >
        </date-range-picker>
      </div>
    </header>

    <section class="main-section">
      <div class="content widgets" v-if="stat_data">
        <div class="row">
          <widget class="col-4" widget="Stat" title="Shoutouts" :data="{current: stat_data.shoutouts_given, previous: stat_data.prev_shoutouts_given}" />
          <widget class="col-4" widget="Stat" title="Awards" :data="{current: stat_data.awards_earned, previous: stat_data.prev_awards_earned}" />
          <widget class="col-4" widget="Stat" title="Points Earned" :data="{current: stat_data.points_earned, previous: stat_data.prev_points_earned}" />
        </div>
        <div class="row">
          <widget class="col-6" widget="Engagement" title="Engagement" :data="{hideStats: true, engagement: stat_data.engagement, shoutouts: stat_data.shoutouts_given, awards: stat_data.awards_given}" />
          <widget class="col-6" widget="TopValues" title="Top Values" :data="{current_period: stat_data.top_values}" />
        </div>
      </div>
    </section>

    <section class="main-section">
      <header>
        <h2>Activity Feed</h2>
      </header>

      <div class="content settings">
        <div class="feed-wrap" v-if="feed !== null">
          <component v-for="obj in feed" :key="`${obj.type}_${obj.id}_feed`" :is="obj.type === 'SHOUTOUT_SENT' ? 'ShoutoutCard' : 'AwardCard'" :data="obj.type === 'SHOUTOUT_SENT' ? obj.shoutout : obj.award_earned" />
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Widget from '@/components/Reporting/Widget';
import Muuri from 'muuri'
import DateRangePicker from 'vue2-daterange-picker'
import {debounce} from 'lodash'
import ShoutoutCard from '@/components/ShoutoutCard'
import AwardCard from '@/components/AwardCard'

export default {
  components: {
    Widget,
    DateRangePicker,
    ShoutoutCard,
    AwardCard
  },
  computed: {
    me() {
      return this.$store.state.user;
    },
    groups() {
      return this.$store.state.groups;
    },
    segments() {
      return this.$store.state.segments;
    },
    segment_filter_label() {
      if(this.segment_filter === 'ALL') return 'all of your teams';
      return _.find(this.segments, {id: this.segment_filter}).name;
    }
  },
  watch: {
    date_range(val) {
      this.$router.replace({name: 'reporting-summary', query: {dateRange: `${this.$DateTime.fromJSDate(val.startDate).toJSDate()},${this.$DateTime.fromJSDate(val.endDate).toJSDate()}`}})
      this.populateStatData()
      this.populateFeed()
    },
    group_filter(val) {
      this.populateStatData()
    },
    segment_filter(val) {
      this.populateStatData()
      this.populateFeed()
    }
  },
  data() {
    return {
      segment_filter: 'ALL',
      stat_data: null,
      connection_data: null,
      segment_activity: null,
      grid: null,
      feed: null,
      date_options: {
        'Yesterday': [ this.$today.minus({days: 1}).toJSDate(), this.$today.toJSDate() ],
        'Last 7 days': [ this.$today.minus({days: 7}).toJSDate(), this.$today.toJSDate() ],
        'This week': [ this.$today.startOf('week').toJSDate(), this.$today.endOf('week').toJSDate() ],
        'Last week': [ this.$today.minus({days: 7}).startOf('week').toJSDate(), this.$today.minus({days: 7}).endOf('week').toJSDate() ],
        'This month': [ this.$today.startOf('month').toJSDate(), this.$today.toJSDate() ],
        'Last 30 days': [ this.$today.minus({days: 30}).toJSDate(), this.$today.toJSDate() ],
        'Last month': [ this.$today.minus({months: 1}).startOf('month').toJSDate(), this.$today.minus({months: 1}).endOf('month').toJSDate() ],
        'Last 6 months': [ this.$today.minus({months: 6}).startOf('month').toJSDate(), this.$today.toJSDate() ]
      },
      date_range: {
        startDate: this.$today.startOf('week').toJSDate(),
        endDate: this.$today.toJSDate()
      }
    }
  },
  created() {
    this.populateStatData();
    this.populateFeed()

    if(!!this.$route.query.dateRange) {
      const [start, end] = this.$route.query.dateRange.split(',')
      this.date_range.startDate = this.$DateTime.fromISO(start)
      this.date_range.endDate = this.$DateTime.fromISO(end)
    }
  },
  methods: {
    populateFeed: debounce( async function() {
      this.feed = null;

      const feed_data = await this.$api.Reporting.get_feed(
        this.date_range.startDate,
        this.date_range.endDate,
        this.segment_filter
      )

      this.feed = feed_data;
    }, 250 ),
    populateStatData: debounce( async function() {
      this.stat_data = null;

      const _loadStats = this.$api.Reporting.get_dr_stats(
        this.date_range.startDate,
        this.date_range.endDate
      )
        .then(d => {
          this.stat_data = d;
        })

      const promises = [
        _loadStats,
      ];

      await Promise.all(promises)
    }, 250 ),
    renderGrid() {
      
    },
    renderLayout() {
      if(this.grid === null) return this.renderGrid();

      this.grid.refreshItems();
      this.grid.layout(true);
    }
  }
}
</script>

<style lang="scss" scoped>
.app-view.reporting-summary {
  > header {
    > .filters {
      margin: 0 0 0 auto;
    }
    > .title {
      > h1 {
        > span {
          display: inline-block;
          font-size: 0.7em;
          color: $muted-text;

          > .dropdown {
            display: inline-block;

            > a {
              text-decoration: none;
              color: $muted-text;

              > span {
                border-bottom: 1px $muted-text dashed;
              }
            }
          }
        }
      }
    }
  }
}

.feed-wrap {
  >.shoutout-card,
  >.award-card {
    background: #fff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: rgba($body-color, 0.05) 0 5px 10px;

    + div {
      margin-top: 15px;
    }
  }
}

.content.widgets {
  padding: 20px 0 0;

  > .row {
    + .row {
      margin-top: 30px;
    }
  }

  > .widget-grid {
    position: relative;
    margin: 0 -15px;

    > .reporting-widget {
      display: block;
      position: absolute;
      z-index: 1;
      margin: 15px 0;
    }
  }
}
</style>