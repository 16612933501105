<template>
  <main class="app-view rewards rewards-dashboard">
    <header>
      <div class="title">
        <ul class="crumbs">
          <li>
            <router-link :to="{name: 'rewards'}">Rewards</router-link>
          </li>
        </ul>
        <h1>Settings</h1>
      </div>
    </header>

    <section class="main-section">
      <header>
        <h2>Redemption Payments</h2>
      </header>

      <div class="content settings">
        
      </div>
    </section>

  </main>
</template>

<script>
export default {
  data() {
    return {
      payments: null
    }
  },
  mounted() {
    this.populatePayments()
  },
  methods: {
    async populatePayments() {
      const resp = await this.$api.Billing.get_reward_payments()

      this.payments = resp;
    }
  }
}
</script>

<style lang="scss" scoped>
ul.payments {
  display: block;
  padding: 0;
  margin: 0;

  > li {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 10px;
    background: $light;
    border-radius: 10px;

    .info {
      padding: 0 10px 0 0;
      flex: 0 0 auto;
    }
    .user {
      padding: 0 10px;
      flex: 0 0 auto;
    }

    .reward {
      padding: 0 10px;
      flex: 0 0 auto;
    }

    .amount {
      padding: 0 10px;
      flex: 0 0 auto;
    }

    .amount {
      > span {
        background: $green;
        display: inline-block;
        line-height: 1em;
        font-size: 12px;
        border-radius: 3px;
        padding: 3px 4px;
        color: #fff;
      }
    }

    +li {
      margin-top: 15px;
    }
  }
}
</style>