import { ajaxPrefilter } from 'jquery';
import axios from 'redaxios';
import Store from './store';

const api = {
  client: axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    json: true,
  }),
  charityClient: axios.create({
    baseURL: `https://hithrive-giving.herokuapp.com`,
    json: true,
  }),
  execute: function(method, resource, data, addlHeaders = {}) {
    if (Store.state.user_token) {
      addlHeaders['Authorization'] = `Bearer ${Store.state.user_token}`;
    }

    return this.client({
      method,
      url: resource,
      data,
      headers: {
        ...addlHeaders,
      },
    }).then((req) => {
      return req.data;
    });
  },
  charityExec: function(method, resource, data, addlHeaders = {}) {
    if (Store.state.user_token) {
      addlHeaders['Authorization'] = `Bearer ${Store.state.user_token}`;
    }

    return this.charityClient({
      method,
      url: resource,
      data,
      headers: {
        ...addlHeaders,
      },
    }).then((req) => {
      return req.data;
    });
  },

  /* API Methods */
  User: {
    me: () => api.execute('GET', '/user/me'),
    ping: () => api.execute('GET', '/user/ping'),
    update_locale: (country, language = 'EN') =>
      api.execute('PATCH', '/user/locale', { country, language }),
    ShippingAddresses: {
      add: (address) => api.execute('POST', '/user/addresses', { ...address }),
      remove: (id) => api.execute('DELETE', '/user/addresses/' + id),
      make_default: (id) =>
        api.execute('PATCH', '/user/addresses/make_default/' + id),
    },
  },
  Reporting: {
    get_stats: (start_date, end_date, group_id, segment_id = null) => {
      if (!!start_date)
        return api.execute(
          'GET',
          `/team/reporting/stats?start_date=${encodeURIComponent(
            start_date
          )}&end_date=${encodeURIComponent(
            end_date
          )}&group=${group_id}&segment=${segment_id}`
        );
      return api.execute(
        'GET',
        `/team/reporting/stats?group=${group_id}&segment=${segment_id}`
      );
    },
    get_dr_stats: (start_date, end_date) => {
      return api.execute(
        'GET',
        `/team/reporting/dr_stats?start_date=${encodeURIComponent(
          start_date
        )}&end_date=${encodeURIComponent(end_date)}`
      );
    },
    get_feed: (start_date, end_date, segment_id = null) => {
      if (!!start_date)
        return api.execute(
          'GET',
          `/team/reporting/feed?start_date=${encodeURIComponent(
            start_date
          )}&end_date=${encodeURIComponent(end_date)}&segment=${segment_id}`
        );
    },
    get_connections: (start_date, end_date, group_id, segments) => {
      if (!!start_date)
        return api.execute(
          'GET',
          `/team/reporting/connections?start_date=${encodeURIComponent(
            start_date
          )}&end_date=${encodeURIComponent(end_date)}&group=${group_id}`
        );
      return api.execute(
        'GET',
        `/team/reporting/connections?group=${group_id}`
      );
    },
    get_segment_activity: (start_date, end_date, group_id) => {
      if (!!start_date)
        return api.execute(
          'GET',
          `/team/reporting/count_by_segment?start_date=${encodeURIComponent(
            start_date
          )}&end_date=${encodeURIComponent(end_date)}&group=${group_id}`
        );
      return api.execute(
        'GET',
        `/team/reporting/count_by_segment?group=${group_id}`
      );
    },
    get_recognition: (filters) =>
      api.execute(
        'GET',
        '/team/reporting/recognition?filters=' + JSON.stringify(filters)
      ),
    get_shoutouts: (filters, by_recipient = false) =>
      api.execute(
        'GET',
        '/team/reporting/shoutouts?filters=' +
          JSON.stringify(filters) +
          '&by_recipient=' +
          by_recipient
      ),
    get_birthdays: (filters) =>
      api.execute(
        'GET',
        '/team/reporting/birthdays?filters=' + JSON.stringify(filters)
      ),
    get_anniversaries: (filters) =>
      api.execute(
        'GET',
        '/team/reporting/anniversaries?filters=' + JSON.stringify(filters)
      ),
    get_newhires: (filters) =>
      api.execute(
        'GET',
        '/team/reporting/newhires?filters=' + JSON.stringify(filters)
      ),
    get_redemptions: (filters) =>
      api.execute(
        'GET',
        '/team/reporting/redemptions?filters=' + JSON.stringify(filters)
      ),
    get_nominations: (filters) =>
      api.execute(
        'GET',
        '/team/reporting/nominations?filters=' + JSON.stringify(filters)
      ),
    get_awards: (filters, by_recipient = false) =>
      api.execute(
        'GET',
        '/team/reporting/awards?filters=' +
          JSON.stringify(filters) +
          '&by_recipient=' +
          by_recipient
      ),
    get_points_earned: (filters) =>
      api.execute(
        'GET',
        '/team/reporting/points_earned?filters=' + JSON.stringify(filters)
      ),
    get_point_totals: (filters) =>
      api.execute(
        'GET',
        '/team/reporting/point_totals?filters=' + JSON.stringify(filters)
      ),
    get_users: (filters, return_groups_segments) =>
      api.execute(
        'GET',
        '/team/reporting/users?return_groups_segments=' +
          (return_groups_segments === true ? 'true' : 'false') +
          '&filters=' +
          JSON.stringify(filters)
      ),
  },
  Auth: {
    exchange_slack_token: (token, extra = {}) =>
      api.execute('POST', '/user/exchange_slack_token', { token, ...extra }),
    exchange_single_use_token: (token) =>
      api.execute('POST', '/user/exchange_token', { token }),
    refresh_token: () => api.execute('GET', '/user/refresh_token'),
  },
  Team: {
    install_slack: (code) =>
      api.execute('POST', '/setup/from_slack', { oauth_code: code }),
    search_users: (query, group_id = null, segment_id = null) =>
      api.execute(
        'GET',
        `/team/users?q=${query}&group_id=${group_id}&segment_id=${segment_id}`
      ),
    update_metadata: (meta = {}) =>
      api.execute('PATCH', '/team/metadata', meta),
    update_values: (values = []) =>
      api.execute('PATCH', '/team/values', values),
    get_point_stats: () => api.execute('GET', `/team/point_stats`),
    get_icons: () => api.execute('GET', '/team/icons'),
    get_pending_redemptions: () =>
      api.execute('GET', '/team/pending_redemption_count'),
    get_admins: () => api.execute('GET', '/team/administrators'),
    set_admins: (admins) =>
      api.execute('PATCH', '/team/administrators', { admins }),
    get_profile_fields: () => api.execute('GET', '/team/custom_fields'),
    list_users: (page = 1, query = '', filters = {}) =>
      api.execute(
        'GET',
        '/team/users/' +
          page +
          '?q=' +
          query +
          '&filters=' +
          JSON.stringify(filters)
      ),
    get_user_by_id: (id) => api.execute('GET', '/team/user/' + id),
    update_user: (id, data) => api.execute('PATCH', '/team/user/' + id, data),
    upload_logo: (form) => api.execute('POST', '/team/logo', form),
    import_user_fields: (d) =>
      api.execute('POST', '/team/import_user_fields', d),
    get_security_activity: (page = 1) =>
      api.execute('GET', '/team/activity_log/' + page),
    send_onboarding_message: (include_message = false, custom_msg = '') =>
      api.execute('POST', '/team/send_onboarding_messages', {
        include_message,
        custom_msg,
      }),
    set_active_status: (status = false) =>
      api.execute('POST', '/team/active_status', { status }),
    get_reward_notifications: () =>
      api.execute('GET', '/team/reward_notifications'),
    set_reward_notifications: (to_notify = []) =>
      api.execute('PATCH', '/team/reward_notifications', { to_notify }),
  },
  Groups: {
    list: (with_preview = false) =>
      api.execute('GET', '/team/groups?with_preview=' + with_preview),
    update: (id, data) => api.execute('PATCH', `/team/groups/${id}`, data),
    create: (group) => api.execute('POST', `/team/groups`, group),
    delete: (id) => api.execute('DELETE', '/team/groups/' + id),
    list_members: (id, search = '', return_all) => {
      if (return_all === true)
        return api.execute(
          'GET',
          '/team/groups/' + id + '/members?q=' + search + '&fetchAll=true'
        );
      return api.execute('GET', '/team/groups/' + id + '/members?q=' + search);
    },
  },
  Segments: {
    list: (with_preview = false) =>
      api.execute('GET', '/team/segments?with_preview=' + with_preview),
    update: (id, data) => api.execute('PATCH', `/team/segments/${id}`, data),
    create: (group) => api.execute('POST', `/team/segments`, group),
    get: (id) => api.execute('GET', '/team/segments/' + id),
    delete: (id) => api.execute('DELETE', '/team/segments/' + id),
    update_members: (id, users = []) =>
      api.execute('PATCH', `/team/segments/${id}/members`, { users }),
    list_members: (id, search = '', return_all) => {
      if (return_all === true)
        return api.execute(
          'GET',
          '/team/segments/' + id + '/members?q=' + search + '&fetchAll=true'
        );
      return api.execute(
        'GET',
        '/team/segments/' + id + '/members?q=' + search
      );
    },
  },
  Shoutouts: {},
  Awards: {
    list: () => api.execute('GET', '/team/awards'),
    update_birthday_award: (award) =>
      api.execute('PATCH', '/team/date_awards/birthday', award),
    update_anniversary_award: (award, addl_years = []) =>
      api.execute('PATCH', `/team/date_awards/anniversary`, {
        ...award,
        addl_years,
      }),
    update_newhire_award: (award) =>
      api.execute('PATCH', '/team/date_awards/new_hire', award),
    create: (award) =>
      api.execute('PATCH', '/team/awards', { to_create: [award] }),
    archive: (award) =>
      api.execute('DELETE', '/team/awards', { to_delete: [award] }),
    update: (award) =>
      api.execute('PATCH', '/team/awards', { to_update: [award] }),
    get_upcoming_anniversaries: () =>
      api.execute('GET', '/team/upcoming_anniversaries'),
    get_upcoming_birthdays: () =>
      api.execute('GET', '/team/upcoming_birthdays'),
    get_upcoming_newhires: () => api.execute('GET', '/team/upcoming_newhires'),
    get_birthday_stats: () => api.execute('GET', '/team/birthday_stats'),
    get_anniversary_stats: () => api.execute('GET', '/team/anniversary_stats'),
    get_newhire_stats: () => api.execute('GET', '/team/newhire_stats'),
  },
  Competitions: {
    get_active: () => api.execute('GET', '/team/competitions'),
    get_results: () => api.execute('GET', '/team/competitions/results'),
    get_leaderboard: (id) =>
      api.execute('GET', `/team/competitions/${id}/leaderboard`),
    get_result_history: (competition_id) =>
      api.execute('GET', `/team/competitions/${competition_id}/results`),
    create: (competition) =>
      api.execute('POST', `/team/competitions`, competition),
    archive: (competition_id) =>
      api.execute('DELETE', '/team/competitions/' + competition_id),
  },
  Nominations: {
    list: () => api.execute('GET', `/team/nominations`),
    create: (campaign) => api.execute('POST', '/team/nominations', campaign),
    get_campaign: (id) => api.execute('GET', `/team/nominations/${id}`),
    end_campaign: (id, users, message = '') =>
      api.execute('POST', `/team/nominations/${id}/complete`, {
        for_users: users,
        message,
      }),
  },
  Billing: {
    get_payment_method: () => api.execute('GET', '/team/billing'),
    get_payment_intent: (pi_id) =>
      api.execute('GET', '/team/billing/payment_intent/' + pi_id),
    init_new_card: () => api.execute('GET', '/team/billing/setup_card'),
    get_subscription: () => api.execute('GET', '/team/billing/subscription'),
    preview_subscription: (plan_type, billing_interval) =>
      api.execute(
        'GET',
        `/team/billing/preview_plan?plan_type=${plan_type}&billing_interval=${billing_interval}`
      ),
    set_subscription: (plan_type, billing_interval) =>
      api.execute('POST', '/team/billing/select_plan', {
        plan_type,
        billing_interval,
      }),
    get_topups: (limit = 20) =>
      api.execute('GET', '/team/topups?limit=' + limit),
    get_topup_intent: (amount, intent_id) => {
      if (!!intent_id)
        return api.execute(
          'GET',
          `/team/billing/topup_intent?intent_id=${intent_id}&point_amount=${amount}`
        );

      return api.execute(
        'GET',
        `/team/billing/topup_intent?point_amount=${amount}`
      );
    },
    get_invoices: () => api.execute('GET', '/team/invoices'),
    set_reward_payment_method: (payment_method) =>
      api.execute('POST', '/team/billing/rewards_payment_method', {
        payment_method,
      }),
    get_reward_payments: () =>
      api.execute('GET', '/team/billing/rewards_payments'),
    get_reward_payment: (id) =>
      api.execute('GET', '/team/billing/rewards_payments/' + id),
  },
  Rewards: {
    refresh_images: (id) =>
      api.execute('POST', '/team/rewards/refresh_images/' + id),
    get_redemptions: (page = 1) =>
      api.execute('GET', `/team/redemptions/${page}`),
    get_rewards: () => api.execute('GET', `/team/rewards`),
    set_catalog_status: (statuses) =>
      api.execute('PATCH', `/team/catalog_status`, statuses),
    update_rewards: (rewards) =>
      api.execute('PATCH', '/team/rewards', { rewards }),
    get_pending_culture: () =>
      api.execute('GET', `/team/redemptions?type=culture`),
    mark_complete: (id) =>
      api.execute('PATCH', `/team/redemption/${id}/complete`),
    get_redemption_stats: () => api.execute('GET', '/team/redemption_stats'),
    get_custom_rewards: (ids = []) => {
      if (ids.length > 0)
        return api.execute('GET', `/team/rewards/custom?q=${ids.join(',')}`);
      return api.execute('GET', `/team/rewards/custom`);
    },
    add_custom_item: (item) =>
      api.execute('POST', '/team/rewards/custom/add_item', item),
    update_custom_item: (item, images) =>
      api.execute('PATCH', '/team/rewards/custom/' + item.id, { item, images }),
    upload_custom_item_images: (id, images) =>
      api.execute('PUT', '/team/rewards/custom/' + id + '/images', images),
    delete_custom_item: (id) =>
      api.execute('DELETE', '/team/rewards/custom/' + id),
  },
  Slack: {
    get_channels: (query) =>
      api.execute('GET', '/team/slack/channels?q=' + query),
    get_channel_by_id: (id) => api.execute('GET', '/team/slack/channel/' + id),
  },
  Integrations: {
    get_all: () => api.execute('GET', '/team/integrations'),
    get_logs: (id) => api.execute('GET', '/team/integrations/' + id + '/logs'),
    get_log: (int_id, log_id) =>
      api.execute('GET', `/team/integrations/${int_id}/logs/${log_id}`),
    BambooHR: {
      exchange_code: (code) =>
        api.execute('POST', '/team/integrations/bamboohr/exchange_code', {
          code,
        }),
      set_subdomain: (subdomain) =>
        api.execute('POST', '/team/integrations/bamboohr/set_subdomain', {
          subdomain,
        }),
    },
    Gusto: {
      exchange_code: (code) =>
        api.execute('POST', '/team/integrations/gusto/exchange_code', { code }),
    },
    Sendoso: {
      exchange_code: (code) =>
        api.execute('POST', '/team/integrations/sendoso/exchange_code', {
          code,
        }),
    },
    Zenefits: {
      set_credentials: (apikey) =>
        api.execute('POST', '/team/integrations/zenefits/set_credentials', {
          apikey,
        }),
    },
    Namely: {
      set_credentials: (subdomain, pat) =>
        api.execute('POST', '/team/integrations/namely/set_credentials', {
          subdomain,
          pat,
        }),
    },
    Printful: {
      set_credentials: (apikey) =>
        api.execute('POST', '/team/integrations/printful/set_credentials', {
          apikey,
        }),
    },
    FTP: {
      roll_credentials: () =>
        api.execute('POST', '/team/integrations/ftp/roll_credentials'),
      set_field_map: (mapping) =>
        api.execute('POST', '/team/integrations/ftp/update_fields', {
          mapping,
        }),
    },
    TriNet: {
      set_credentials: (companyId, clientId, clientSecret) =>
        api.execute('POST', '/team/integrations/trinet/set_credentials', {
          clientId,
          clientSecret,
          companyId,
        }),
    },
    Paylocity: {
      set_credentials: (companyId, clientId, clientSecret) =>
        api.execute('POST', '/team/integrations/paylocity/set_credentials', {
          clientId,
          clientSecret,
          companyId,
        }),
    },
    SagePeople: {
      set_credentials: (client_id, client_secret, username, password) =>
        api.execute('POST', '/team/integrations/sagepeople/set_credentials', {
          client_id,
          client_secret,
          username,
          password,
        }),
    },
  },
  RewardPortal: {
    get_redemptions: () => api.execute('GET', '/rewards/redemptions'),
    get_claims: () => api.execute('GET', '/rewards/claims/pending'),
    claim_gift: (claim_id, claims, claim_data) =>
      api.execute('POST', '/rewards/claims/' + claim_id + '/claim', {
        claims,
        claim_data,
      }),
    GiftCards: {
      list: (page = 1, query = '') =>
        api.execute('GET', `/gift_cards/${page}?query=${query}`),
      get_featured: () => api.execute('GET', '/gift_cards/?featured=true'),
      get_details: (id) => api.execute('GET', '/gift_cards/details/' + id),
      redeem: (id, variant_id, value) =>
        api.execute('POST', `/gift_cards/redeem/${id}`, { variant_id, value }),
    },
    CustomCatalog: {
      list: (page = 1, query = '') =>
        api.execute('GET', `/rewards/custom/${page}?query=${query}`),
      get_item: (id) => api.execute('GET', '/rewards/custom/item/' + id),
      redeem: (id, reward) =>
        api.execute('POST', '/rewards/redeem/' + id, reward),
    },
    Amazon: {
      get_featured: () => api.execute('GET', '/amazon/trending'),
      get_product: (asin, addr_id) =>
        api.execute('GET', `/amazon/product/${asin}?addr=${addr_id}`),
      get_variant: (asin, addr_id) =>
        api.execute('GET', `/amazon/variant/${asin}?addr=${addr_id}`),
      redeem: (asin, addr) =>
        api.execute('POST', `/amazon/redeem/${asin}`, { addr }),
      search: (query, page = 1) =>
        api.execute(
          'GET',
          '/amazon/search/' + page + '?q=' + encodeURIComponent(query)
        ),
    },
    CompanyRewards: {
      list: () => api.execute('GET', '/rewards'),
      redeem: (id) => api.execute('POST', '/rewards/redeem/' + id),
    },
    Storefront: {
      redeem: (reward) =>
        api.execute('POST', '/rewards/redeem/storefront', { reward }),
    },
    Charities: {
      get_by_ein: (ein) => api.charityExec('GET', '/nonprofits/' + ein),
      search: (query) =>
        api.charityExec('GET', '/nonprofits/search?q=' + query),
      redeem: (donation) =>
        api.execute('POST', '/rewards/donation', { donation }),
    },
    PayPal: {
      verify_destination: (destination, code) =>
        api.execute('POST', '/paypal/verify_destination', {
          destination,
          code,
        }),
      redeem_venmo: (amount, destination) =>
        api.execute('POST', '/paypal/redeem', {
          rail: 'VENMO',
          amount,
          destination,
          currency: 'USD',
        }),
      redeem_paypal: (amount, destination, currency = 'USD') =>
        api.execute('POST', '/paypal/redeem', {
          rail: 'PAYPAL',
          amount,
          destination,
          currency,
        }),
    },
  },
};

export { api };
