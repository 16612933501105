var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reward-view",attrs:{"id":"reward-home"}},[_c('main',[(_vm.claims.length > 0)?_c('div',{staticClass:"alert alert-info d-sm-flex align-items-center"},[_c('div',[_c('strong',[_vm._v("You have a gift to claim!")]),_vm._v(" Head on over to your "),_c('router-link',{staticClass:"alert-link",attrs:{"to":{ name: 'rewards_claims' }}},[_vm._v("pending gifts")]),_vm._v(" to claim your gift. ")],1),_c('router-link',{staticClass:"btn btn-info btn-sm ml-auto",attrs:{"to":{ name: 'rewards_claims' }}},[_vm._v("Claim my gift")])],1):_vm._e(),(_vm.$hasCatalog('AMAZON') && _vm.article_list.length > 0)?_c('section',{staticClass:"featured-carousel"},[_c('vue-slick-carousel',{attrs:{"dots":true,"adaptiveHeight":true}},[_c('router-link',{attrs:{"to":{ name: 'rewards_amazon' }}},[_c('img',{attrs:{"src":require("@/assets/amazon-banner.jpg")}})]),_vm._l((_vm.article_list),function(article,index){return _c('router-link',{key:`featured_article_${index}`,attrs:{"to":{
            name: 'rewards_editorial_post',
            params: { id: article.uid },
          }}},[_c('img',{attrs:{"src":article.data.post_image.url}})])})],2)],1):_vm._e(),(_vm.$hasCatalog('GIFT_CARDS'))?_c('section',{staticClass:"featured-gift-cards main-section"},[_c('header',[_c('h2',[_vm._v("Featured gift cards")]),_c('div',{staticClass:"btns"},[_c('router-link',{staticClass:"btn btn-dark btn-xs btn-rounded",attrs:{"to":{ name: 'rewards_gift_cards' }}},[_vm._v("View all gift cards "),_c('i',{staticClass:"fal fa-long-arrow-right"})])],1)]),_c('div',{staticClass:"content"},[_c('featured-gift-cards')],1)]):_vm._e(),(_vm.$hasCatalog('AMAZON'))?_c('section',{staticClass:"featured-amazon main-section"},[_c('header',[_vm._m(0),_c('div',{staticClass:"btns"},[_c('router-link',{staticClass:"btn btn-dark btn-xs btn-rounded",attrs:{"to":{ name: 'rewards_amazon' }}},[_vm._v("Shop more categories "),_c('i',{staticClass:"fal fa-long-arrow-right"})])],1)]),_c('div',{staticClass:"content"},[_c('featured-amazon')],1)]):_vm._e(),(
        _vm.company_rewards === null ||
          (_vm.company_rewards !== null && _vm.company_rewards.length > 0)
      )?_c('section',{staticClass:"company-rewards main-section"},[_vm._m(1),_c('div',{staticClass:"content"},[(_vm.company_rewards !== null)?[(_vm.company_rewards.length > 0)?_c('ul',_vm._l((_vm.company_rewards),function(reward){return _c('li',{key:`cr_${reward.id}`},[_c('div',{staticClass:"icon"},[_c('i',{domProps:{"innerHTML":_vm._s(_vm.$emoji(reward.emoji))}})]),_c('div',{staticClass:"details"},[_c('div',{staticClass:"title"},[_c('h4',[_vm._v(_vm._s(reward.name))])]),_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(reward.description)+" ")])]),_c('div',{staticClass:"redeem"},[_c('button',{staticClass:"btn btn-xs btn-green",class:{ loading: reward.loading === true },attrs:{"disabled":_vm.point_balance < reward.point_cost},on:{"click":function($event){return _vm.redeemCompanyReward(reward)}}},[_vm._v(" Redeem for "),_c('strong',[_vm._v(_vm._s(_vm.$formatPoints(reward.point_cost)))])])])])}),0):_vm._e()]:_c('div',{staticClass:"loading-placeholder"},[_c('i'),_c('i'),_c('i')])],2)]):(!_vm.$hasCatalog('GIFT_CARDS') && !_vm.$hasCatalog('AMAZON'))?_c('section',[_vm._m(2),_c('div',{staticClass:"empty-state"},[_vm._v(" Your team hasn't setup any rewards yet, check back soon! ")])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_vm._v("Trending on "),_c('img',{attrs:{"src":require("@/assets/amazon.svg"),"height":"16"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('header',[_c('h2',[_vm._v("Company rewards")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('header',[_c('h2',[_vm._v("No rewards yet!")])])
}]

export { render, staticRenderFns }