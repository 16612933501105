<template>
  <div class="plans">

    <div class="info-box" v-if="subscription && subscription !== 'NO_PLAN'">You currently have {{$formatNumber(plan.quantity)}} active user{{plan.quantity === 1 ? '' : 's'}} at ${{$formatNumber(plan.price.unit_amount/100, '0.00')}}/{{plan.price.recurring.interval}}.</div>

    <div class="interval-toggle">
      <span :class="{'active': !show_annual}">Monthly</span>
      <label class="switch">
        <input type="checkbox" v-model="show_annual" />
        <span></span>
      </label>
      <span :class="{'active': show_annual}">Annual</span>
    </div>

    <div class="side-by-side" v-if="subscription !== null">
      <div :class="{'active': (current_plan === '0_MONTH' || current_plan === `2_${show_interval}`)}">
        <i>&#128512;</i>
        <h3>HiThrive Core</h3>
        <h4 v-html="`$${show_annual ? '1.00' : '1.50'}/mo<small>per active user</small>`"></h4>

        <div class="info" v-if="team.plan_type === 0"><strong>You've got HiThrive Core for free!</strong><br>Thanks for being an early adopter.</div>
        <template v-else-if="can_switch && current_plan !== `2_${show_interval}`">
          <button class="btn btn-block btn-green" @click="choosePlan(2)">Select this plan</button>
          <div class="help-text">You'll be able to preview your changes on the next page.</div>
        </template>
        <div class="info" v-else-if="!can_switch">To switch to monthly billing, please email help@hithrive.com</div>
        <div class="info" v-else><strong>This is your current plan.</strong></div>

        <ul>
          <li>Peer-to-peer recognition with shoutouts.</li>
          <li>Customizable monthly point allowances.</li>
          <li>Unlimited recognition channels.</li>
          <li>Redeem points for gift cards, custom rewards, and <img src="@/assets/amazon-business.svg" height="14" />.</li>
        </ul>
      </div>
      <div :class="{'active': (current_plan === `1_${show_interval}`)}">
        <i>&#128526;</i>
        <h3>HiThrive Pro</h3>
        <h4 v-html="`$${show_annual ? '3.00' : '4.00'}/mo<small>per active user</small>`"></h4>

        <template v-if="can_switch && current_plan !== `1_${show_interval}`">
          <button class="btn btn-block btn-green" @click="choosePlan(1)">Select this plan</button>
          <div class="help-text">You'll be able to preview your changes on the next page.</div>
        </template>
        <div class="info" v-else-if="!can_switch">To switch to monthly billing, please email help@hithrive.com</div>
        <div class="info" v-else><strong>This is your current plan.</strong></div>

        <ul>
          <li><strong>Everything from Core.</strong></li>
          <li>Celebrate birthdays and service anniversaries with customizable awards.</li>
          <li>Manager-to-peer spot awards.</li>
          <li>Award nomination campaigns.</li>
          <li>Leaderboard competitions.</li>
        </ul>
      </div>
    </div>
    <div class="loading-placeholder" v-else><i></i><i></i></div>

    <p class="help-text">Need help? Email help@hithrive.com with any questions.</p>
    <p class="help-text">All prices are in USD unless explicitly stated otherwise.</p>
  </div>
</template>

<script>
export default {
  props: ['paymentMethod'],
  computed: {
    team() {
      return this.$store.state.team;
    },
    can_switch() {
      if(this.subscription === 'NO_PLAN') return true;
      if(this.current_interval === 'YEAR' && this.show_interval === 'MONTH') return false;
      return true;
    },
    plan() {
      return this.subscription.items.data.find(si => ('HITHRIVE_PLAN_ID' in si.price.metadata) === true)
    },
    current_interval() {
      return this.plan.price.recurring.interval.toUpperCase()
    },
    current_plan() {
      if(this.team.plan_type === 0) return '0_MONTH';
      if(this.subscription === 'NO_PLAN') return 'NO_PLAN';
      return `${this.plan.price.metadata.HITHRIVE_PLAN_ID}_${this.plan.price.recurring.interval.toUpperCase()}`;
    },
    show_interval() {
      return this.show_annual ? 'YEAR' : 'MONTH';
    },
    payment_method() {
      return this.paymentMethod || this.populated_payment;
    }
  },
  data() {
    return {
      show_annual: false,
      subscription: null,
      populated_payment: null
    }
  },
  created() {
    this.populateSubscription()
    this.populatePaymentMethod();
  },
  methods: {
    async populatePaymentMethod() {
      if(!!this.payment_method) return;

      this.populated_payment = null;
      const resp = await this.$api.Billing.get_payment_method();
      if( resp.sources.length == 0 ) {
        this.populated_payment = null;
      }
      else {
        this.populated_payment = resp.sources[0];
      }
    },
    async populateSubscription() {
      this.subscription = null;
      const resp = await this.$api.Billing.get_subscription();
      if(resp === null) this.subscription = {};
      else this.subscription = resp;

      if(this.subscription !== 'NO_PLAN') {
        this.show_annual = this.plan.price.recurring.interval === 'month' ? false : true;
      }
    },
    async choosePlan(plan) {
      this.$root.$emit(
        'openModal',
        'Billing/SelectSubscription',
        {
          plan_type: plan,
          billing_interval: this.show_interval,
          payment_method: this.payment_method || null
        },
        (result) => {
          console.log(result)
          // handle sub result
          if(result === 'UPDATED_SUBSCRIPTION') {
            this.$emit('refresh');
            this.populateSubscription();
          }
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.plans {
  max-width: 700px;
  padding-bottom: 4px;

  .info-box {
    margin: 0 0 25px;
  }

  .loading-placeholder {
    display: flex;
    margin: 35px -15px 0;
    > i {
      margin: 0 15px;
      flex: 1 0 auto;
      display: block;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      background-image: url("data:image/svg+xml,%3Csvg width='178' height='125' viewBox='0 0 178 125' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='178' height='125' rx='4' fill='%23E4E1DF'/%3E%3C/svg%3E%0A");
      
      &:before {
        content: '';
        display: block;
        padding: 0 0 70%;
      }
    }
  }

  > .help-text {
    text-align: center;
    margin: 20px 0 0;
    font-size: 14px;
    color: $muted-text;
  }
  
  .side-by-side {
    display: flex;
    align-items: flex-start;
    margin: 35px -15px 0;

    > div {
      flex: 0 0 calc(50% - 30px);
      border-radius: 10px;
      background: #fff;
      min-height: 50px;
      box-shadow: rgba(0,0,0,0.05) 0 5px 10px;
      margin: 0 15px;
      padding: 25px;

      &.active {
        box-shadow: $green 0 0 0 4px;
      }

      .help-text {
        font-size: 10px;
        color: $muted-text;
        margin: 5px 0 0;
        text-align: center;
      }
      
      > h3 {
        text-align: center;
        font-weight: 700;
        margin: 0;
        font-size: 22px;
      }

      > h4 {
        text-align: center;
        font-size: 16px;
        margin: 5px 0 25px;
        color: $muted-text;

        ::v-deep > small {
          display: block;
          font-size: 12px;
          margin: 0 0;
        }
      }

      > i {
        font-style: normal;
        font-size: 28px;
        width: 36px;
        text-align: center;
        margin: 0 auto 5px;
        display: block;
      }

      > .info {
        background: $light;
        border-radius: 10px;
        padding: 10px;
        font-size: 12px;
        text-align: center;
        color: $muted-text;
      }

      > ul {
        display: block;
        padding: 0;
        margin: 15px 0 0;

        > li {
          display: block;
          font-size: 14px;
          padding: 5px 0 5px 14px;
          position: relative;

          &:before {
            display: block;
            position: absolute;
            content: '';
            left: 0;
            top: 13px;
            width: 5px;
            height: 5px;
            background: $purple;
            border-radius: 100%;
          }
        }
      }
    }
  }
}

.interval-toggle {
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    display: block;
    font-size: 16px;
    width: 70px;
    text-align: center;
    color: $muted-text;

    &.active {
      font-weight: 700;
      color: $body-color;
    }
  }
  .switch {
    flex: 0 0 auto;
    display: block;
    position: relative;
    width: 60px;
    height: 30px;
    background: $green;
    border-radius: 100em;
    overflow: hidden;
    cursor: pointer;
    margin: 0 15px;

    &:hover {
      background: darken($green, 6%);
    }

    > span {
      background: #fff;
      border-radius: 100em;
      //width: 20px;
      height: 20px;
      position: absolute;
      display: block;
      left: 5px;
      right: calc(100% - 25px);
      top: 5px;
      box-shadow: rgba(0,0,0,0.2) 0 1px 3px;
      transition: left 0.2s $curve, right 0.2s $curve;
    }

    &:active {
      > span {
        left: 5px;
        right: calc(100% - 30px);
      }

      > input {
        &:checked + span {
          left: 30px;
          right: 5px;
        }
      }
    }

    > input {
      width: 1px;
      height: 1px;
      position: absolute;
      left: -999px;

      &:checked + span {
        left: 35px;
        right: 5px;
      }
    }
  }
}
</style>