<template>
  <table class="sortable-table table table-sm">
    <thead>
      <tr>
        <th v-for="(col, index) in columns" :key="`sortabletable_${_compID}_col_${index}`">
          <span :class="[{'sortable': sortable_cols.includes(index+1)}, sort_col_class(index)]" @click.prevent="handleSort(index)">{{col}}</span>
        </th>
      </tr>
    </thead>
    <tbody>
      <slot name="rows" v-bind="{rows: sorted_rows, sort_by}"></slot>
    </tbody>
  </table>
</template>

<script>
import objectPath from 'object-path';

export default {
  name: 'SortableTable',
  props: ['columns', 'sortable', 'rows'],
  computed: {
    _compID() {
      return this.$rand()
    },
    sorted_rows() {
      return this.sort_by === -1 ? [...this.rows] : [...this.rows].sort((a, b) => this.comp_fnc(a, b));
    },
    sortable_cols() {
      return Object.keys(this.sortable).map( v => parseInt(v) )
    }
  },
  data() {
    return {
      sort_by: -1,
      sort_dir: 'ASC'
    }
  },
  methods: {
    comp_fnc(a, b) {
      const sort_field_path = this.sortable[ (this.sort_by + 1).toString() ];

      let _aVal, _bVal;

      if(typeof sort_field_path === 'function') {
        _aVal = sort_field_path(a);
        _bVal = sort_field_path(b);
      }
      else {
        _aVal = objectPath.get(a, sort_field_path) || '';
        _bVal = objectPath.get(b, sort_field_path) || '';
      }

      const _DateComp = () => {
        const _aDate = this.$DateTime.fromISO(_aVal);
        const _bDate = this.$DateTime.fromISO(_bVal);
        return this.sort_dir === 'ASC' ? _aDate - _bDate : _bDate - _aDate;
      };

      const _NatStrComp = () => {
        return this.sort_dir === 'ASC' ? _aVal.localeCompare(_bVal) : _bVal.localeCompare(_aVal);
      };

      const _NumComp = () => {
        console.log(_aVal, _bVal)
        const _a = isNaN(_aVal) ? parseInt( _aVal.split(' ')[0].replace(/[^0-9.]/g, '') ) : _aVal;
        const _b = isNaN(_bVal) ? parseInt( _bVal.split(' ')[0].replace(/[^0-9.]/g, '') ) : _bVal;

        return this.sort_dir === 'ASC' ? _a - _b : _b - _a;
      };

      const field_name = typeof sort_field_path === 'function' ? sort_field_path.name.replace(/^bound\s/, '') : sort_field_path.split('.')[0];
      switch(field_name) {
        case "created_at":
          return _DateComp();
        case "created_by":
          return _NatStrComp();
        case "points":
          return _NumComp();
        case "award":
          return _NatStrComp();
        case "for_users":
          return _NatStrComp();
        case "nominated_award":
          return _NatStrComp();
        case "currency_value":
          return _NumComp();
      }

      return _NatStrComp();
    },
    sort_col_class(index) {
      if(this.sort_by === index) return `sort--${this.sort_dir.toLowerCase()}`;

      return '';
    },
    handleSort(index) {
      if(!this.sortable_cols.includes(index + 1)) return;

      if(this.sort_by !== index) this.sort_dir = 'ASC';
      else if(this.sort_dir === 'DESC') {
        this.sort_by = -1;
        this.sort_dir = 'ASC';
        return;
      }
      else this.sort_dir = 'DESC';

      this.sort_by = index;
    }
  }
}
</script>