<template>
  <div id="featured-gift-cards">
    <template v-if="featured_gift_cards !== null">
      <vue-slick-carousel v-bind="slickSettings">
        <router-link class="gift-card" v-for="gift_card in featured_gift_cards" :key="`fgc_${gift_card.id}`" tag="div" :to="{name: 'rewards_gift_card_details', params: {gift_card_id: gift_card.id}}">
          <a>
            <div class="thumbnail" :style="{'background-image': `url('${gift_card.thumbnail}')`}"></div>
            <h4>{{gift_card.title}}</h4>
          </a>
        </router-link>
      </vue-slick-carousel>
    </template>
    <template v-else>
      <div class="loading-placeholder"><i></i><i></i><i></i><i></i><i></i></div>
    </template>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';

export default {
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      featured_gift_cards: null,
      slickSettings: {
        arrows: true,
        dots: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        speed: 250,
        initialSlide: -1,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3
            }
          }
        ]
      }
    }
  },
  created() {
    this.populateFeaturedGiftCards();
  },
  methods: {
    async populateFeaturedGiftCards() {
      const resp = await this.$api.RewardPortal.GiftCards.get_featured();
      this.featured_gift_cards = resp;
    }
  }
}
</script>

<style lang="scss" scoped>
#featured-gift-cards {
  ::v-deep .slick-slider {
    .slick-arrow {
      position: absolute;
      top: 0;
      bottom: 0;
      background: linear-gradient(90deg, rgba(249,246,244,1) 0%, rgba(249,246,244,0) 100%);
      width: 100px;
      z-index: 99;
      appearance: none;
      border: none;
      outline: none;
      text-indent: -999px;
      overflow: hidden;

      &:before {
        content: '\f054';
        font-family: 'Font Awesome 5 Pro';
        display: block;
        position: absolute;
        top: 30px;
        width: 40px;
        height: 40px;
        background: $secondary;
        box-shadow: rgba(0,0,0,0.1) 0 5px 10px;
        border-radius: 100%;
        line-height: 42px;
        text-align: center;
        font-size: 14px;
        color: #fff;
        text-indent: 0;
        padding: 0 2px 0 0;
        transition: transform 0.2s $curve;
      }

      &:hover {
        &:before {
          transform: scale(1.1);
        }
      }

      &:active {
        &:before {
          transform: scale(0.99);
        }
      }

      &.slick-prev {
        left: -10px;

        &:before {
          content: '\f053';
          left: 10px;
        }
      }

      &.slick-next {
        &:before {
          right: 10px;
          padding: 0 0 0 2px;
        }
        
        right: -10px;
        background: linear-gradient(270deg, rgba(249,246,244,1) 0%, rgba(249,246,244,0) 100%);
      }
    }
  }

  ::v-deep .slick-list {
    margin: -2px -10px 0;
    .slick-slide {
      padding: 2px 10px 0;
    }
    .gift-card {
      display: block;
      text-align: center;

      > a {
        display: block;
        text-decoration: none;
        color: $body-color;

        > .thumbnail {
          display: block;
          padding: 0 0 63%;
          background-size: cover;
          background-position: center center;
          border-radius: 6px;
          overflow: hidden;
          box-shadow: rgba($body-color, 0.05) 0 3px 5px;
          transition: box-shadow 0.2s $curve, transform 0.2s $curve;
        }

        > h4 {
          //font-weight: 700;
          font-size: 14px;
          margin: 10px 0 0;
        }

        &:hover {
          > .thumbnail {
            box-shadow: rgba($body-color, 0.08) 0 8px 15px;
            transform: translateY(-2px);
          }
        }
      }
    }
  }
  
  .loading-placeholder {
    display: flex;
    margin: 0 -10px;
    > i {
      margin: 0 10px;
      flex: 1 0 auto;
      display: block;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center center;
      background-image: url("data:image/svg+xml,%3Csvg width='178' height='125' preserveAspectRatio='none' viewBox='0 0 178 125' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='178' height='125' rx='4' fill='%23E4E1DF'/%3E%3C/svg%3E%0A");
      
      &:before {
        content: '';
        display: block;
        padding: 0 0 60%;
      }
    }
  }
}
</style>