<template>
  <main class="app-view recognition recognition-group-edit" v-if="group">
    <header>
      <div class="title">
        <ul class="crumbs">
          <li>
            <router-link :to="{name: 'recognition'}">Recognition</router-link>
          </li>
        </ul>
        <h1>{{group.title}}</h1>
      </div>

      <a href="#" @click.prevent="showUserList" class="user-count">
        <ul v-if="group.users.length > 0">
          <li v-for="user in group.users" :key="`groupuserpreview_${user.id}`">
            <user-profile-picture :user="user" height="22" />
          </li>
        </ul>
        <span>{{$formatNumber(group.user_count)}} user{{group.user_count == 1 ? '' : 's'}}</span>
      </a>

      <div class="btns">
        <router-link class="btn btn-light" :to="{name: 'reporting-activity', query: {type: 'shoutouts', group: group.id}}"><i class="fas fa-chart-pie"></i> Latest shoutouts</router-link>
        <router-link class="btn btn-light" :to="{name: 'reporting-activity', query: {type: 'awards', group: group.id}}"><i class="fas fa-chart-pie"></i> Latest awards</router-link>
      </div>
    </header>

    <!-- Channel settings -->
    <section class="main-section" v-if="group.channel_id !== null">
      <header>
        <h2>Channel Settings</h2>
      </header>

      <div class="content settings">
        
        <div class="setting-row">
          <i class="fab fa-slack"></i>
          <div class="title">
            <h4>Slack Channel</h4>
            <p>A channel in Slack to sync this group with.</p>
          </div>
          <div class="fields">
            <v-select v-if="group.channel_id !== null && initial_channels.length > 0" v-model="group.channel_id" @search="fetchChannelOptions" @option:selected="handleChannelSelect" placeholder="Search for a channel..." :getOptionLabel="formatChannelLabel" :clearable="false" :filterable="false" label="name" :options="channels" :reduce="opt => opt.id" />
            <loading-indicator small v-else />
          </div>
        </div>

      </div>
    </section>

    <!-- Shoutout settings -->
    <section class="main-section shoutout-settings">
      <header>
        <h2>Shoutout Settings</h2>
      </header>

      <div class="content settings">

        <div class="setting-row">
          <i class="fas fa-coins"></i>
          <div class="title">
            <h4>Point Allowance</h4>
            <p>Monthly shoutout point allowance for each member of this channel.</p>
            <a href="https://help.hithrive.com/hc/en-us/articles/360058923311-How-do-points-work-" target="_blank" class="help-link">How do point budgets work?</a>
          </div>
          <div class="fields">
            <div class="input-group">
              <input type="text" v-model.number="group.shoutout_allowance" class="form-control" />
              <div class="input-group-append">
                <span class="input-group-text">points per {{team.allowance_interval}}</span>
              </div>
            </div>
            <span class="help-text">{{$conversionRate(group.shoutout_allowance)}}</span>
          </div>
        </div>
        
        <div class="setting-row">
          <i class="fas fa-filter"></i>
          <div class="title">
            <h4>Point Amount Options</h4>
            <p>Define the amount of points a user can give with each shoutout.</p>
          </div>
          <div class="fields">
            <textarea rows="4" class="form-control" v-model="point_options" placeholder="Enter one per line, or leave blank..."></textarea>
            <span class="help-text">Leave blank for freeform point amounts.</span>
          </div>
        </div>
        

      </div>
    </section>

    <!-- Award settings -->
    <section class="main-section award-settings" :class="{'pro-locked': !is_pro}">
      <div class="pro-upgrade">
        <span><i class="fas fa-lock"></i> This feature is only available on HiThrive Pro. </span>
        <router-link :to="{name: 'billing'}">Learn more</router-link>
      </div>

      <header>
        <h2>Award Settings</h2>
      </header>

      <div class="content settings">

        <div class="setting-row">
          <i class="fas fa-coins"></i>
          <div class="title">
            <h4>Point Allowance</h4>
            <p>Monthly spot award point allowance for each manager of this channel.</p>
            <a href="https://help.hithrive.com/hc/en-us/articles/360058923311-How-do-points-work-" target="_blank" class="help-link">How do point budgets work?</a>
          </div>
          <div class="fields">
            <div class="input-group">
              <input type="text" v-model.number="group.point_allowance" class="form-control" />
              <div class="input-group-append">
                <span class="input-group-text">points per {{team.allowance_interval}}</span>
              </div>
            </div>
            <span class="help-text">{{$conversionRate(group.point_allowance)}}</span>
          </div>
        </div>

        <div class="setting-row">
          <i class="fas fa-user-crown"></i>
          <div class="title">
            <h4>Channel Managers</h4>
            <p>Users who can give spot awards using the <strong>/award</strong> command.</p>
          </div>
          <div class="fields">
            <user-picker v-model="group.managers" :group="group.id" placeholder="search to add a manager..." />
          </div>
        </div>

      </div>
    </section>

    <footer>
      <button class="btn btn-muted-link btn-sm" @click="deleteGroup">Delete</button>
      <button class="btn btn-green btn-sm" @click="saveGroup">Save</button>
    </footer>
  </main>
</template>

<script>
import { debounce } from 'lodash';
import UserPicker from '@/components/UserPicker';

export default {
  components: { UserPicker },
  computed: {
    groups() {
      return this.$store.state.groups;
    },
    is_pro() {
      return this.$store.state.team.plan_type === 1;
    },
    team() {
      return this.$store.state.team;
    }
  },
  data() {
    return {
      group: null,
      initial_channels: [],
      channels: [],
      nudges: {
        inactivity_nudges: true
      },
      point_options: ''
    }
  },
  watch: {
    'group.channel_id': function(val) {
      if(this.channels.length === 0) return;

      this.$nextTick( () => {
        this.group.title = `#${_.find(this.channels, {id: val}).name}`;
      } )
    }
  },
  validations: {
    
  },
  created() {
    this.populateGroup(this.$route.params.group_id);
  },
  methods: {
    formatChannelLabel(option) {
      return `#${option.name}`;
    },
    fetchChannelOptions(query, loading) {
      if(query.length > 0) {
        this.searchChannels(loading, query, this);
      }
      else {
        this.channels = this.initial_channels || [];
      }
    },
    handleChannelSelect(opt) {
      this.$nextTick( () => {
        this.channels = [opt];
        this.initial_channels = [opt];
      } );
    },
    searchChannels: debounce(
      async (loading, search, vm) => {
        loading(true);

        const resp = await vm.$api.Slack.get_channels(search);
        vm.channels = resp;

        loading(false);
      },
      250
    ),
    async populateChannelInfo() {
      if(this.group.channel_id === null) return;
      
      const resp = await this.$api.Slack.get_channel_by_id(this.group.channel_id)
      this.channels = resp;
      this.initial_channels = resp;
    },
    async populateGroup(group_id) {
      const _cloneGroup = () => {
        this.group = _.cloneDeep(
          _.find(this.groups, (g) => g.id.toString() === group_id.toString())
        );

        this.point_options = this.group.shoutout_point_options.join('\n')

        // this.nudges = {
        //   inactivity_nudges: this.group.metadata.inactivity_nudges === false ? false : true
        // };

        this.populateChannelInfo();
      };
      
      if(this.groups) {
        _cloneGroup();
      }
      else {
        const unwatch = this.$watch('groups', (val) => {
          _cloneGroup();
          unwatch();
        })
      }
    },
    async saveGroup() {
      this.$store.commit('loading', true)

      const update_data = _.cloneDeep(this.group)
      update_data.managers = _.map(this.group.managers, 'id');
      update_data.point_options = this.point_options.split('\n');

      //update_data.nudges = this.nudges;

      await this.$api.Groups.update(this.group.id, update_data)
      this.$store.dispatch('populateGroups')

      this.$toast.success('Channel settings have been saved.');

      this.$store.commit('loading', false)
    },
    async deleteGroup() {
      if(!confirm('Are you sure you want to delete this group?')) return;

      this.$store.commit('loading', true)
      const resp = await this.$api.Groups.delete(this.group.id)
      if(resp.error) {
        this.$store.commit('loading', false)
        this.$toast.error(resp.message);
        return;
      }

      await this.$store.dispatch('populateGroups')
      this.$store.commit('loading', false)
      this.$toast.success('Recognition group has been deleted.');
      this.$router.replace({name: 'recognition'});
    },
    async showUserList() {
      const resp = await this.$api.Groups.list_members(this.group.id, '', true)
      this.$root.$emit(
        'openModal',
        'UserList/index',
        {
          modal_title: `${this.group.title} Members`,
          list: resp
        },
        () => {
          
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.app-view {
  > header {
    .user-count {
      display: flex;
      align-items: center;
      height: 26px;
      margin: 0 0 0 15px;

      > ul {
        display: flex;
        flex: 0 0 auto;
        padding: 0 5px;
        margin: 0 5px 0 0;

        > li {
          display: block;
          margin: 0 0 0 -5px;
          
          ::v-deep >.user--profile-picture  {
            box-shadow: #fff 0 0 0 2px;
          }
        }
      }

      > span {
        display: block;
        font-size: 12px;
        margin: 0 0;
        color: $muted-text;
      }
    }
  }
}

</style>