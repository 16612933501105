<template>
  <main class="app-view birthdays birthday-dashboard">
    <header>
      <div class="title">
        <ul class="crumbs">
          <li>
            <router-link :to="{name: 'recognition'}">Birthdays</router-link>
          </li>
        </ul>
        <h1>Settings</h1>
      </div>
    </header>

    <section class="main-section">
      <header>
        <h2>Celebration Settings</h2>
      </header>

      <div class="content settings">
        
        <div class="setting-row">
          <i class="fas fa-birthday-cake"></i>
          <div class="title">
            <h4>Enable birthday celebrations</h4>
            <p>Turn birthday celebrations on/off.</p>
          </div>
          <div class="fields">
            <ht-switch v-model="enable_award" />
          </div>
        </div>

        <div class="setting-row">
          <i class="fas fa-coins"></i>
          <div class="title">
            <h4>Reward</h4>
            <p>Set how many points a user receives when they earn this award. Optionally, attach specific gifts from your custom reward catalog.</p>
          </div>
          <div class="fields">
            <div class="input-group" :class="{'is-invalid': $v.birthday_award.points.$error}">
              <input type="text" v-model.number="$v.birthday_award.points.$model" class="form-control" />
              <div class="input-group-append">
                <span class="input-group-text">points per birthday</span>
              </div>
            </div>
            <span class="help-text">{{$conversionRate($v.birthday_award.points.$model)}}</span>

            <div class="invalid-feedback">A point amount is required, can be 0.</div>

            <award-gift-rewards v-model="birthday_award.rewards" />
          </div>
        </div>

        <div class="setting-row">
          <i class="fas fa-calendar"></i>
          <div class="title">
            <h4>Weekend behavior</h4>
            <p>Choose when to celebrate birthdays that land on Saturdays and Sundays.</p>
          </div>
          <div class="fields">
            <div class="form-group">
              <select v-model="birthday_award.weekend_mode" class="form-control">
                <option value="DAY_OF">Celebrate on day of birthday</option>
                <option value="FRIDAY">Celebrate on Friday before weekend</option>
                <option value="MONDAY">Celebrate on Monday after weekend</option>
              </select>
            </div>
          </div>
        </div>

        <div class="setting-row">
          <i class="fas fa-calendar"></i>
          <div class="title">
            <h4>Announcement Channels</h4>
            <p>Choose which channels HiThrive uses to announce birthday awards.</p>
          </div>
          <div class="fields">
            <div class="form-group">

                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" name="announcement-channels" id="announcementChannelsDefault" value="DEFAULT" class="custom-control-input" v-model="announcement_channel_behavior">
                  <label class="custom-control-label" for="announcementChannelsDefault">All relevant channels</label>
                </div>

                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" name="announcement-channels" id="announcementChannelsSpecific" value="OVERRIDE" class="custom-control-input" v-model="announcement_channel_behavior">
                  <label class="custom-control-label" for="announcementChannelsSpecific">Set specific channels</label>
                </div>

            </div>
            
            <multi-channel-selector v-if="announcement_channel_behavior === 'OVERRIDE'" :multiple="true" v-model="$v.announcement_channel_values.$model" :class="{'is-invalid': $v.announcement_channel_values.$error}" />
          </div>
        </div>

        <div class="setting-row">
          <i class="fas fa-brush"></i>
          <div class="title">
            <h4>Banner design</h4>
            <p>Customize the award banner that is attached to messages in Slack.</p>
          </div>
          <div class="fields">
            <div class="banner-preview" v-if="banners.length > 0">
              <img v-for="banner in banners" :key="`banner_preview_${banner}`" :src="`https://img-cdn.hithrive.com/teams-award-skins/${banner}.png`" height="50" />
            </div>
            <button class="btn btn-xs btn-light" :class="{'is-invalid': $v.banners.$error}" @click="selectBanner()">Select a banner</button>

            <div class="invalid-feedback">At least one banner is required.</div>
          </div>
        </div>

        <div class="setting-row">
          <i class="fas fa-comment"></i>
          <div class="title">
            <h4>Celebration message</h4>
            <p>Message to be posted publicly or sent privately.</p>
          </div>
          <div class="fields">
            <div class="form-group">
              <textarea class="form-control" rows="4" v-model="birthday_award.message"></textarea>
              <small class="form-text text-muted">Use <strong>{name}</strong> anywhere in the message to @mention the user.</small>
            </div>
          </div>
        </div>

        <div class="setting-row">
          <i class="fas fa-comments"></i>
          <div class="title">
            <h4>Collect birthdates automatically</h4>
            <p>HiThrive will DM users asking them to set their birthdate. Turn this off if you'd rather import these with a CSV.</p>
          </div>
          <div class="fields">
            <ht-switch v-model="ask_birthdays" />
          </div>
        </div>

      </div>

    </section>

    <footer>
      <button class="btn btn-green btn-sm" :class="{'loading': loading}" @click="save">Save</button>
    </footer>
  </main>
</template>

<script>
import AwardGiftRewards from '@/components/Awards/GiftBuilder'
import MultiChannelSelector from '@/components/MultiChannelSelector'
import {required, numeric, minLength, requiredIf} from 'vuelidate/lib/validators'

export default {
  name: 'BirthdayDashboard',
  components: {
    MultiChannelSelector,
    AwardGiftRewards
  },
  computed: {
    team() {
      return this.$store.state.team;
    },
    groups() {
      return this.$store.state.groups;
    },
    awards() {
      return this.$store.state.awards;
    },
    loading() {
      return this.$store.state.loading;
    },
    announcement_channels: {
      get() {
        return this.announcement_channel_values;
      },
      set(value) {
        console.log(value)
        if(!value) return;
        this.announcement_channel_values = value.map(({id}) => id);
      }
    }
  },
  data() {
    return {
      enable_award: false,
      ask_birthdays: true,
      banners: [],
      birthday_award: {
        points: 0,
        rewards: null,
        message: '',
        weekend_mode: 'DAY_OF'
      },
      announcement_channel_behavior: 'DEFAULT',
      announcement_channel_values: []
    }
  },
  validations: {
    announcement_channel_values: {
      requiredIf: requiredIf(function() {
        return this.announcement_channel_behavior === 'OVERRIDE';
      })
    },
    birthday_award: {
      points: {
        required,
        numeric
      }
    },
    banners: {
      minLength: minLength(1)
    }
  },
  created() {
    this.populateAwardData();
  },
  methods: {
    selectBanner(model=null) {
      this.$root.$emit(
        'openModal',
        'Awards/BannerSelector',
        [...this.banners],
        (result, banner) => {
          if(result === 'BANNER_SELECTED') {
            if(model !== null) {
              model.$model = banner;
            }
            else {
              this.$v.banners.$model = banner;
            }
          }
        }
      )
    },
    async populateAwardData() {
      const _populateAwards = () => {
        const award = _.find(this.awards, {bot_type: 'BIRTHDAY'})
        if(!!award) {
          this.birthday_award = award;
          this.enable_award = !this.birthday_award.is_archived;

          this.ask_birthdays = this.team.metadata.ask_birthdays === false ? false : true;
          this.banners = award.award_skins;

          this.announcement_channel_values = award.channels.length === 0 ? [] : award.channels;
          this.announcement_channel_behavior = award.channels.length === 0 ? 'DEFAULT' : 'OVERRIDE';
        }
        else {
          this.birthday_award.rewards = [];
        }
      };
      
      if(this.awards) {
        _populateAwards();
      }
      else {
        const unwatch = this.$watch('awards', (val) => {
          _populateAwards();
          unwatch();
        })
      }
    },
    async save() {
      this.$v.$touch()
      if(this.$v.$invalid) return;

      this.$store.commit('loading', true)
      await Promise.all([
        this.$api.Awards.update_birthday_award({
          disable: !this.enable_award,
          points: this.birthday_award.points,
          message: this.birthday_award.message,
          weekend_mode: this.birthday_award.weekend_mode,
          award_skin: this.banners,
          channels: this.announcement_channel_behavior === 'OVERRIDE' ? this.announcement_channel_values.map(({id}) => id) : null,
          rewards: this.birthday_award.rewards.filter(r => r.length > 0)
        }),
        this.$store.dispatch('teamMetadata', {ask_birthdays: this.ask_birthdays})
      ]);
      this.$store.dispatch('populateAwards')
      this.$toast.success('Birthday award has been updated.');
      this.$store.commit('loading', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-preview {
  margin: 0 0 10px;
  > img {
    border-radius: 6px;
  }
}

.content.groups {
  > ul {
    display: flex;
    flex-wrap: wrap;

    padding: 0;
    margin: -10px -10px;

    > li {
      display: block;
      flex: 0 0 (100%/3);
      width: (100%/3);
      padding: 10px 10px;

      > a {
        height: 100%;

        h4 {
          font-size: 14px;
          font-weight: 700;
        }
      }
    }
  }

  .loading-placeholder {
    display: flex;
    margin: 0 -10px;
    > i {
      margin: 0 10px;
      flex: 1 0 auto;
      display: block;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      background-image: url("data:image/svg+xml,%3Csvg width='178' height='125' viewBox='0 0 178 125' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='178' height='125' rx='4' fill='%23E4E1DF'/%3E%3C/svg%3E%0A");
      
      &:before {
        content: '';
        display: block;
        padding: 0 0 70%;
      }
    }
  }
}
</style>