<template>
  <main class="app-view awards awards-nominations">
    <header>
      <div class="title">
        <ul class="crumbs">
          <li>
            <router-link :to="{name: 'awards'}">Awards</router-link>
          </li>
        </ul>
        <h1>Nominations</h1>
      </div>
    </header>

    <section class="main-section">
      <header>
        <h2>All Campaigns</h2>

        <div class="btns">
          <button class="btn btn-circle btn-secondary" @click="newCampaign"><i class="fas fa-plus"></i></button>
        </div>
      </header>

      <div class="content campaigns">
        <template v-if="nominations && nominations.length > 0">
          <div class="table-header">
            <span class="title">Campaign</span>
            <span class="timestamps">Start / end date</span>
            <span class="nomination-count">Nominations</span>
            <span class="engagement">User Engagement</span>
          </div>
          <ul>
            <campaign-li v-for="campaign in active_campaigns" @end-campaign="endCampaign(campaign)" :campaign="campaign" :key="`nominationcampaign_${campaign.id}`" />
          </ul>
        </template>
        <div class="empty-state" v-else-if="nominations && nominations.length == 0">
          <p>No active nomination campaigns.</p>
        </div>
        <div class="loading-placeholder" v-else-if="!nominations"><i></i><i></i><i></i></div>
      </div>
    </section>

    <section class="main-section" v-if="nominations === null || (nominations && nominations.length > 0)">
      <header>
        <h2>Ended Campaigns</h2>
      </header>

      <div class="content campaigns">
        <div class="table-header">
          <span class="title">Campaign</span>
          <span class="timestamps">Start / end date</span>
          <span class="nomination-count">Nominations</span>
          <span class="engagement">User Engagement</span>
        </div>
        <ul>
          <campaign-li v-for="campaign in ended_campaigns" :campaign="campaign" :key="`nominationcampaign_${campaign.id}`" />
        </ul>
        <div class="loading-placeholder" v-if="!nominations"><i></i><i></i><i></i></div>
      </div>
    </section>
  </main>
</template>

<script>
import CampaignListElementVue from '@/components/Awards/Nominations/Campaign_ListElement'

export default {
  name: 'Nomination',
  components: {
    'campaign-li': CampaignListElementVue, 
  },
  computed: {
    active_campaigns() {
      return _.filter(
        this.nominations,
        n => (n.status === 'OPEN' || n .status === 'PENDING')
      )
    },
    ended_campaigns() {
      return _.filter(
        this.nominations,
        n => (n.status === 'COMPLETE')
      )
    }
  },
  data() {
    return {
      nominations: null
    }
  },
  created() {
    this.populateNominations()
  },
  methods: {
    async populateNominations() {
      this.nominations = null;
      const resp = await this.$api.Nominations.list();
      this.nominations = resp;
    },
    newCampaign() {
      this.$root.$emit(
        'openModal',
        'Nominations/CreateCampaign',
        {},
        (result) => {
          if(result === 'CAMPAIGN_CREATED') {
            this.populateNominations();
          }
        }
      )
    },
    endCampaign(campaign) {
      if(campaign.metadata.multi_nominations === true) {
        this.$root.$emit(
          'openModal',
          'Nominations/EndMultiCampaign',
          campaign,
          (result) => {
            if(result === 'CAMPAIGN_ENDED') {
              this.populateNominations();
            }
          }
        )
      }
      else {
        this.$root.$emit(
          'openModal',
          'Nominations/EndCampaign',
          campaign,
          (result) => {
            if(result === 'CAMPAIGN_ENDED') {
              this.populateNominations();
            }
          }
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.empty-state {
  p {
    margin: 0 0 0;
    color: $muted-text;
  }
}

.content.campaigns {
  .table-header {
    margin: 0 0;
    padding: 0 10px 10px 16px;
    display: flex;
    align-items: flex-end;

    > span {
      padding: 0 15px;
      min-width: 0;
      font-size: 12px;
      color: $muted-text;
      font-weight: 700;
      display: block;
      line-height: 1.2em;

      &.title {
        flex: 0 1 25%;
        padding-left: 0;
      }

      &.nomination-count {
        flex: 0 1 100px;
      }

      &.engagement {
        flex: 0 1 20%;
      }

      &.timestamps {
        flex: 0 1 150px;
      }
    }
  }
  > ul {
    padding: 0;
    margin: 0;

    ::v-deep > li {
      display: flex;
      background: $light;
      border-radius: 10px;
      padding: 10px 10px 10px 16px;
      font-size: 14px;
      align-items: center;
      overflow: hidden;
      position: relative;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 6px;
        background: $secondary;
      }

      &.open {
        &:before {
          background: $green;
        }
      }

      &.pending {
        &:before {
          background: $red;
        }
      }

      +li {
        margin-top: 15px;
      }

      > div {
        min-width: 0;
      }

      .actions {
        margin: 0 0 0 auto;

        >.btn {
          display: block;

          &+.btn {
            margin: 2px 0 0;
          }
        }
      }

      .title {
        padding: 0 15px 0 0;
        flex: 0 1 25%;

        > h4 {
          font-size: 14px;
          font-weight: 700;
          margin: 0 0 3px;
          line-height: 1em;
        }

        > div {
          display: flex;
          font-size: 12px;
          color: $muted-text;

          > span {
            display: block;
          }
        }
      }

      .timestamps {
        flex: 0 1 150px;
        padding: 0 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        > span {
          display: block;
          font-size: 12px;
          line-height: 1.35em;

          > strong {
            color: $muted-text;
          }
        }
      }

      .nomination-count {
        padding: 0 15px;
        flex: 0 1 100px;

        > span {
          line-height: 1em;
          display: block;
        }
      }

      .engagement {
        padding: 0 15px;
        flex: 0 1 20%;
        > span {
          display: block;
          line-height: 1em;
          
          > small {
            color: $muted-text;
          }
        }
        .progress {
          height: 0.5em;
          border-radius: 100em;
          box-shadow: rgba($green, 0.3) 0 0 0 2px;
          margin: 7px 2px 0;

          .progress-bar {
            background-color: $green;
          }
        }
      }
    }
  }

  .loading-placeholder {
    display: block;
    margin: 0 0;
    > i {
      margin: 0 0;
      +i { 
        margin-top: 15px;
      }
      //flex: 1 0 auto;
      display: block;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center center;
      background-image: url("data:image/svg+xml,%3Csvg width='951' height='50' preserveAspectRatio='none' viewBox='0 0 951 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='951' height='50' rx='4' fill='%23E4E1DF'/%3E%3C/svg%3E%0A");
      
      &:before {
        content: '';
        display: block;
        height: 50px;
      }
    }
  }
}
</style>