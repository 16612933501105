<template>
  <div class="reward-view container-fluid" id="gift-cards">
    <main>
      <header>
        <div class="title">
          <ul class="crumbs">
            <li>
              <router-link :to="{ name: 'rewards_home' }">Rewards</router-link>
            </li>
          </ul>
          <h1>Gift Cards</h1>
        </div>

        <div class="search-wrap" v-if="show_search">
          <input
            type="text"
            class="form-control"
            placeholder="start typing to search..."
            v-model="query"
          />
        </div>
      </header>

      <section class="main-section" id="gift-card-list">
        <template v-if="gift_cards !== null">
          <ul v-if="gift_cards.length > 0">
            <router-link
              v-for="gc in gift_cards"
              :key="`gc_${gc.id}`"
              tag="li"
              :to="{
                name: 'rewards_gift_card_details',
                params: { gift_card_id: gc.id },
              }"
            >
              <a>
                <div
                  class="thumbnail"
                  :style="{ 'background-image': `url('${gc.thumbnail}')` }"
                ></div>
                <h4>{{ gc.title }}</h4>
              </a>
            </router-link>
          </ul>
          <div
            class="manual-gift-cards"
            v-else-if="show_search === false && gift_cards.length === 0"
          >
            <form
              action="https://submit-form.com/cT7RdvmL"
              @submit.prevent="sendManualGiftCardForm"
            >
              <p>
                <strong
                  >We're working hard to make more reward options available in
                  your region.</strong
                >
                In the meantime, please allow our rewards support team to assist
                in redeeming your {{ team.points_name }}.
              </p>

              <template v-if="!sent_manual_request">
                <div class="form-group">
                  <label for=""
                    >How many {{ team.points_name }} would you like to
                    redeem?</label
                  >
                  <div class="input-group input-group-lg">
                    <input
                      class="form-control"
                      type="text"
                      name="point_amount"
                      v-model.number="$v.manual_points_value.$model"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">{{
                        team.points_name
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <button type="submit" class="btn btn-primary btn-block">
                    Redeem {{ $formatPoints(manual_points) }} ({{
                      $formatCurrency(local_currency, currency, true)
                    }}*)
                  </button>
                </div>

                <small
                  >* Monetary value is just an estimate and may fluctuate due to
                  currency conversion rates.</small
                >
              </template>
              <template v-else>
                <div class="alert alert-success">
                  Thank you, our team will contact you soon.
                </div>
              </template>
            </form>
          </div>
          <div class="empty-state" v-else>
            No gift cards were found that match your search.
          </div>

          <div class="load-more" ref="load_more" v-if="gift_cards.length > 0">
            <loading-indicator v-if="loading_more" />
          </div>
        </template>
        <div class="loading-placeholder" v-else>
          <i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i
          ><i></i><i></i>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import { getParamByISO } from 'iso-country-currency';
import { required, minValue } from 'vuelidate/lib/validators';
export default {
  computed: {
    team() {
      return this.$store.state.team;
    },
  },
  data() {
    return {
      query: '',
      page: 1,
      has_more_pages: true,
      gift_cards: null,
      loading_more: false,
      manual_points_value: 100,
      sent_manual_request: false,
      show_search: true,
      initial_load: true,
    };
  },
  watch: {
    query() {
      this.page = 1;
      this.has_more_pages = true;
      this.populateGiftCards();
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scroll_handler);
  },
  mounted() {
    window.addEventListener('scroll', this.scroll_handler);
  },
  created() {
    this.populateGiftCards();
  },
  validations: {
    manual_points_value: {
      required,
      minValue: minValue(100),
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    team() {
      return this.$store.state.team;
    },
    currency_rates() {
      return this.$store.state.currency_rates;
    },
    manual_points() {
      return this.manual_points_value === null ? 0 : this.manual_points_value;
    },
    currency() {
      return getParamByISO(this.user_country, 'currency');
    },
    user_country() {
      const locale = this.$store.state.user.locale;
      const separator = locale.indexOf('-') > -1 ? '-' : '_';
      const splitLocale = locale.split(separator);

      const country = splitLocale[1].toUpperCase();

      return country;
    },
    local_currency() {
      const conversion_rate = this.currency_rates[this.currency];
      const to_local = (this.manual_points / 100) * conversion_rate;

      return to_local;
    },
  },
  methods: {
    scroll_handler() {
      if (this.loading_more) return;
      if (!this.has_more_pages) return;

      const el = this.$refs.load_more;
      if (!el) return;

      const rect = el.getBoundingClientRect();

      if (
        rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight)
      ) {
        this.page++;
        this.loading_more = true;
        this.populateGiftCards();
      }
    },
    populateGiftCards: _.debounce(async function() {
      if (!this.loading_more) this.gift_cards = null;

      const resp = await this.$api.RewardPortal.GiftCards.list(
        this.page,
        this.query
      );
      if (this.gift_cards === null) this.gift_cards = [];
      this.gift_cards.push(...resp);
      this.loading_more = false;
      if (resp.length < 20) this.has_more_pages = false;

      if (this.initial_load === true && resp.length === 0)
        this.show_search = false;
      this.initial_load = false;
    }, 250),
    async sendManualGiftCardForm($e) {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      const form = $e.target;
      this.$store.commit('loading', true);
      await fetch(form.getAttribute('action'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          email: this.user.email,
          name: this.user.name,
          country: this.user_country,
          user_id: this.user.id,
          team_id: this.team.id,
          team_name: this.team.name,
          point_amount: this.$formatPoints(this.manual_points),
          currency_amount: this.$formatCurrency(
            this.local_currency,
            this.currency,
            true
          ),
          _email: {
            subject: `${this.user.name} would like to redeem points`,
            from: this.user.name,
          },
        }),
      });
      this.sent_manual_request = true;
      this.$store.commit('loading', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.manual-gift-cards {
  background: #fff;
  box-shadow: $box-shadow;
  border-radius: 12px;
  padding: 25px;
  max-width: 512px;
}
.search-wrap {
  margin: 0 0 4px auto;

  > input {
    height: 28px;
    font-size: 14px;
    border-radius: 100em;
    width: 256px;
    max-width: 100%;
  }
}

#gift-card-list {
  margin: 15px 0 0;

  .empty-state {
    text-align: center;
    margin: 35px 0 0;
    font-size: 18px;
    color: $muted-text;
  }

  > ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px 0;
    padding: 0;

    > li {
      flex: 0 0 33.333%;
      width: 33.333%;
      @include media-breakpoint-up(md) {
        flex: 0 0 25%;
        width: 25%;
      }
      display: block;
      padding: 15px 10px;
      margin: 0;
      text-align: center;

      > a {
        display: block;
        text-decoration: none;
        color: $body-color;

        > .thumbnail {
          display: block;
          padding: 0 0 63%;
          background-size: contain;
          background-color: #fff;
          background-repeat: no-repeat;
          background-position: center center;
          border-radius: 6px;
          overflow: hidden;
          box-shadow: rgba($body-color, 0.05) 0 3px 5px;
          transition: box-shadow 0.2s $curve, transform 0.2s $curve;
        }

        > h4 {
          //font-weight: 700;
          font-size: 14px;
          margin: 10px 0 0;
        }

        &:hover {
          > .thumbnail {
            box-shadow: rgba($body-color, 0.08) 0 8px 15px;
            transform: translateY(-2px);
          }
        }
      }
    }
  }
  .loading-placeholder {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    > i {
      margin: 10px 10px;
      flex: 0 0 calc(20% + 20px);
      display: block;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center center;
      background-image: url("data:image/svg+xml,%3Csvg width='178' height='125' preserveAspectRatio='none' viewBox='0 0 178 125' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='178' height='125' rx='4' fill='%23E4E1DF'/%3E%3C/svg%3E%0A");

      &:before {
        content: '';
        display: block;
        padding: 0 0 60%;
      }
    }
  }
}
</style>
