<template>
  <v-select v-model="channel_id" @search="fetchChannelOptions" @option:selected="handleChannelSelect" placeholder="Search for a channel..." :multiple="multiple" :getOptionLabel="formatChannelLabel" :clearable="false" :filterable="false" label="name" :options="channels" :reduce="opt => opt.id" />
</template>

<script>
import {debounce} from 'lodash'

export default {
  props: {
    value: {
      type: null,
      required: false
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      channel_id: null,
      channels: [],
      initial_channels: []
    }
  },
  watch: {
    channel_id(val) {
      this.$nextTick( () => {
        this.$emit('input', _.find(this.channels, {id: val}))
      } );
    }
  },
  mounted() {
    if(this.value !== null) {
      this.channel_id = this.value;
      this.populateChannelInfo();
    }
    else {
      this.prepopulateChannels()
    }
  },
  methods: {
    async prepopulateChannels() {
      const resp = await this.$api.Slack.get_channels('');
      this.channels = resp;
      this.initial_channels = this.channels;
    },
    formatChannelLabel(option) {
      return `#${option.name}`;
    },
    fetchChannelOptions(query, loading) {
      if(query.length > 0) {
        this.searchChannels(loading, query, this);
      }
      else {
        this.channels = this.initial_channels || [];
      }
    },
    handleChannelSelect(opt) {
      this.$nextTick( () => {
        this.channels = [opt];
        this.initial_channels = this.channels;
      } );
    },
    searchChannels: debounce(
      async (loading, search, vm) => {
        loading(true);

        const resp = await vm.$api.Slack.get_channels(search);
        vm.channels = resp;

        loading(false);
      },
      250
    ),
    async populateChannelInfo() {
      const resp = await this.$api.Slack.get_channel_by_id(this.channel_id)
      this.channels = resp;
      this.initial_channels = resp;
    },
  }
}
</script>