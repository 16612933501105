<template>
  <aside>
    <div>
      <a href="#" class="nav-toggle" @click.prevent="showNav = !showNav"><i :class="['fas', (showNav === false ? 'fa-bars' : 'fa-times')]"></i></a>
      <img src="../assets/hithrive-logo.svg" height="18" class="logo" />

      <ul :class="['nav', {'visible-nav': showNav}]">
        <template v-if="requireAdmin">
        
          <router-link tag="li" :to="{name: 'get-started'}" v-if="getting_started > -1">
            <a>
              <div class="indicator" v-if="getting_started > 0">{{getting_started}}</div>
              <i><img src="@/assets/tada.png" height="16" /></i>
              <span>Getting started</span>
            </a>
          </router-link>

          <!-- Recognition nav -->
          <router-link tag="li" :to="{name: 'recognition'}">
            <a>
              <i class="fas fa-megaphone"></i>
              <span>Recognition</span>
            </a>

            <!-- Birthdays nested nav -->
            <ul>
              <router-link tag="li" exact :to="{name: 'recognition'}">
                <a>All Channels</a>
              </router-link>
              <router-link tag="li" exact :to="{name: 'recognition-settings'}">
                <a>Settings</a>
              </router-link>
            </ul>
          </router-link>

          <!-- Birthdays nav -->
          <router-link tag="li" :to="{name: 'birthdays'}" :class="[{'locked': !has_pro}]">
            <a>
              <i class="fas fa-birthday-cake"></i>
              <span>Birthdays</span>
            </a>

            <!-- Birthdays nested nav -->
            <ul>
              <router-link tag="li" exact :to="{name: 'birthdays'}">
                <a>Activity</a>
              </router-link>
              <router-link tag="li" exact :to="{name: 'birthdays-settings'}">
                <a>Settings</a>
              </router-link>
            </ul>

          </router-link>

          <!-- Anniversaries nav -->
          <router-link tag="li" :to="{name: 'anniversaries'}" :class="[{'locked': !has_pro}]">
            <a>
              <i class="fas fa-glass-cheers"></i>
              <span>Anniversaries</span>
            </a>

            <ul>
              <router-link tag="li" exact :to="{name: 'anniversaries'}">
                <a>Activity</a>
              </router-link>
              <router-link tag="li" exact :to="{name: 'anniversaries-settings'}">
                <a>Settings</a>
              </router-link>
            </ul>

          </router-link>

          <!-- Anniversaries nav -->
          <router-link tag="li" :to="{name: 'new-hires'}" :class="[{'locked': !has_pro}]">
            <a>
              <i class="fas fa-user-plus"></i>
              <span>New Hires</span>
            </a>

            <ul>
              <router-link tag="li" exact :to="{name: 'new-hires'}">
                <a>Activity</a>
              </router-link>
              <router-link tag="li" exact :to="{name: 'new-hires-settings'}">
                <a>Settings</a>
              </router-link>
            </ul>

          </router-link>

          <!-- Awards nav -->
          <router-link tag="li" :to="{name: 'awards'}" :class="[{'locked': !has_pro}]">
            <a>
              <i class="fas fa-trophy"></i>
              <span>Awards</span>
            </a>

            <!-- Awards nested nav -->
            <ul>
              <router-link tag="li" exact :to="{name: 'awards'}">
                <a>All Awards</a>
              </router-link>
              <router-link tag="li" exact :to="{name: 'awards-competitions'}">
                <a>Competitions</a>
              </router-link>
              <router-link tag="li" :to="{name: 'awards-nominations'}">
                <a>Nominations</a>
              </router-link>
            </ul>

          </router-link>

          <!-- Rewards nav -->
          <router-link tag="li" :to="{name: 'rewards'}">
            <a>
              <div class="indicator" v-if="pending_redemptions > 0">{{$formatNumber(pending_redemptions)}}</div>
              <i class="fas fa-gift"></i>
              <span>Rewards</span>
            </a>

            <!-- reporting nested nav -->
            <ul>
              <router-link tag="li" exact :to="{name: 'rewards'}">
                <a>Activity</a>
              </router-link>
              <router-link tag="li" :to="{name: 'rewards-custom-catalog'}">
                <a>Custom Catalog</a>
              </router-link>
              <router-link tag="li" :to="{name: 'rewards-settings'}">
                <a>Settings</a>
              </router-link>
            </ul>

          </router-link>
        </template>

        <!-- Reporting nav -->
        <router-link tag="li" :to="{name: 'reporting'}">
          <a>
            <i class="fas fa-chart-pie"></i>
            <span>Reports</span>
          </a>
          
          <!-- reporting nested nav -->
          <ul>
            <router-link tag="li" exact :to="{name: 'reporting'}" v-if="requireAdmin">
              <a>Insights</a>
            </router-link>
            <router-link tag="li" exact :to="{name: 'reporting-summary'}" v-if="hasDirectReports">
              <a>Summary</a>
            </router-link>
            <router-link tag="li" :to="{name: 'reporting-activity'}" v-if="requireAdmin">
              <a>Activity</a>
            </router-link>
          </ul>

        </router-link>

        <template v-if="requireAdmin">

          <hr />

          <!-- Billing nav -->
          <router-link tag="li" :to="{name: 'billing'}">
            <a>
              <i class="fas fa-wallet"></i>
              <span>Billing</span>
            </a>

            <!-- Awards nested nav -->
            <ul>
              <router-link tag="li" exact :to="{name: 'billing'}">
                <a>Subscription</a>
              </router-link>
              <router-link tag="li" exact :to="{name: 'billing-rewards'}">
                <a>Rewards</a>
              </router-link>
              <router-link tag="li" exact :to="{name: 'billing-invoices'}">
                <a>Invoices</a>
              </router-link>
            </ul>

          </router-link>

          <router-link tag="li" :to="{name: 'users'}">
            <a>
              <i class="fas fa-user"></i>
              <span>Users</span>
            </a>

            <!-- Users nested nav -->
            <ul>
              <router-link tag="li" exact :to="{name: 'users'}">
                <a>All Users</a>
              </router-link>
              <router-link tag="li" exact :to="{name: 'users-user-segments'}">
                <a>User Segments</a>
              </router-link>
            </ul>
          </router-link>

          <router-link tag="li" :to="{name: 'settings'}">
            <a>
              <i class="fas fa-cog"></i>
              <span>Settings</span>
            </a>

            <!-- Settings nested nav -->
            <ul>
              <router-link tag="li" exact :to="{name: 'settings'}">
                <a>Team Settings</a>
              </router-link>
              <router-link tag="li" exact :to="{name: 'settings-securitylog'}">
                <a>Security Log</a>
              </router-link>
              <router-link tag="li" exact :to="{name: 'integrations-home'}">
                <a>Integrations</a>
              </router-link>
            </ul>

          </router-link>

        </template>

      </ul>

      <footer>
        {{version}}
      </footer>
    </div>
  </aside>
</template>

<script>
export default {
  computed: {
    requireAdmin() {
      return this.$store.state.user.is_admin;
    },
    version() {
      let env_name = `DEV`;
      switch(process.env.VUE_APP_ENV_NAME) {
        case 'STAGING':
          env_name = 'STAGING';
        break;
        case 'PRODUCTION':
          env_name = 'PROD';
        break;
      }
      return `${process.env.VUE_APP_GIT_TAG} ${env_name}`;
    },
    team() {
      return this.$store.state.team;
    },
    has_pro() {
      return this.team.plan_type === 1;
    },
    pending_redemptions() {
      return this.$store.state.pending_redemptions;
    },
    hasDirectReports() {
      const subordinate_users = this.$store.state.user.subordinate_users || [];
      return subordinate_users.length > 0 ? true : false;
    },
    getting_started() {
      let count = 0;
      const {
        show_get_started,
        setup_first_group,
        added_tag_values,
        setup_rewards,
        have_payment_method,
        introduction
      } = this.team.metadata;
      
      if(show_get_started === false || this.team.done_setup === true) return -1;

      if(setup_first_group !== true) count ++;
      if(added_tag_values !== true) count ++;
      if(setup_rewards !== true) count ++;
      if(have_payment_method !== true) count ++;
      if(introduction !== true) count ++;

      return count;
    }
  },
  data() {
    return {
      showNav: false
    }
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
aside { 
  .logo {
    margin: 0 0 0 5px;
    @include media-breakpoint-up(sm) {
      margin: 10px 0 0 15px;
    }
  }

  .nav-toggle {
    color: #fff;
    width: 40px;
    height: 40px;
    line-height: 42px;
    font-size: 20px;
    display: block;
    text-align: center;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

  ul.nav {
    display: none;
    &.visible-nav {
      display: block;
    }
    position: fixed;
    z-index: 9999;
    background: $purple;
    left: 0;
    right: 0;
    top: 50px;
    bottom: 0;
    overflow-y: scroll;
    @include media-breakpoint-up(sm) {
      display: block;
      margin: 35px 0 25px;
      position: relative;
      background: none;
      overflow: inherit;
      top: 0;
    }
    padding: 0;
    width: 100%;

    > hr {
      border-top: none;
      height: 1px;
    }

    > li {
      display: block;
      padding: 13px 15px;
      position: relative;

      > a {
        display: block;
        padding: 0 15px 0 45px;
        color: #ffffff;
        font-weight: 700;
        font-size: 15px;
        text-decoration: none;
        position: relative;
        height: 28px;
        line-height: 30px;

        > .indicator {
          position: absolute;
          left: 4px;
          background: $red;
          border-radius: 100em;
          padding: 2px 3px;
          text-align: center;
          color: #fff;
          font-size: 10px;
          font-weight: 700;
          display: block;
          line-height: 10px;
          z-index: 9;
          top: 4px;
          height: 13px;
          min-width: 13px;
          transform: translate(-50%, -50%);
          box-shadow: #2D1F40 0 0 0 2px;
        }

        > i {
          border-radius: 100%;
          width: 28px;
          height: 28px;
          font-size: 11.5px;
          background: linear-gradient(180deg, #4E3C67 0%, #332646 100%);
          box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25), inset 0px 1px 0px rgba(255, 255, 255, 0.05);
          text-align: center;
          line-height: 28px;
          color: #ffffff;
          text-shadow: rgba(0,0,0,0.2) 0 -1px 0;
          position: absolute;
          left: 0;
          top: 0;
          transition: all 0.2s $curve;

          > img {
            vertical-align: -3px;
          }
        }
      }

      &.locked {
        > a {
          opacity: 0.5;
        }

        &:before {
          content: 'PRO';
          font-weight: 700;
          font-size: 8px;
          position: absolute;
          z-index: 5;
          background: rgba(255,255,255,0.9);
          width: 20px;
          height: 12px;
          border-radius: 3px;
          box-shadow: rgba(0,0,0,0.2) 0 3px 7px;
          color: #2D1F40;
          text-align: center;
          line-height: 13px;
          left: 3px;
          top: 50%;
          margin-top: -6px;
        }
      }

      //Nested nav
      > ul {
        padding: 3px 0 0 45px;
        margin: 0;
        display: none;

        > li {
          display: block;
          padding: 2px 0;
          
          > a {
            display: block;
            color: rgba(255,255,255,0.5);
            text-decoration: none;
            font-size: 13px;

            &:hover {
              font-weight: 700;
              color: #fff;
            }
          }

          &.router-link-active {
            > a {
              font-weight: 700;
              color: #fff;
            }
          }
        }
      }

      &.router-link-active {
        background: rgba(0,0,0,0.2);
        border-radius: 10px;

        > ul {
          display: block;
        }
      }
    }
  }
}
</style>