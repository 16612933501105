<template>
  <div class="reward-view container-fluid" id="custom-catalog">
    <main>
      <header>
        <div class="title">
          <ul class="crumbs">
            <li>
              <router-link :to="{name: 'rewards_home'}">Rewards</router-link>
            </li>
          </ul>
          <h1>{{custom_catalog_name}}</h1>
        </div>
      </header>

      <section class="main-section" id="items-list">
        <template v-if="items !== null">
          <ul>
            <router-link v-for="item in items" :key="`customcatalog_${item.id}`" tag="li" :to="{name: 'rewards_custom_catalog_item', params: {id: item.id}}">
              <a>
                <div class="thumbnail" :style="{'background-image': `url('${$catalogThumbnail(item.images[0])}')`}"></div>
                <h4>{{item.name}}</h4>
                <div class="point-cost"><span>{{$formatPoints(item.point_cost)}}</span></div>
              </a>
            </router-link>
          </ul>
        </template>
        <div class="loading-placeholder" v-else><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i></div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  computed: {
    custom_catalog_name() {
      return this.$store.getters.custom_catalog_name;
    }
  },
  data() {
    return {
      items: null
    }
  },
  mounted() {
    this.populateRewards()
  },
  methods: {
    async populateRewards() {
      const resp = await this.$api.RewardPortal.CustomCatalog.list()
      this.items = resp;
    }
  }
}
</script>

<style lang="scss" scoped>
#items-list {
  > ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px 0;
    padding: 0;

    > li {
      flex: 0 0 33.333%;
      width: 33.333%;
      @include media-breakpoint-up(md) {
        flex: 0 0 25%;
        width: 25%;
      }
      display: block;
      padding: 15px 10px;
      margin: 0;
      text-align: center;

      > a {
        display: block;
        text-decoration: none;
        color: $body-color;

        > .point-cost {
          margin: 3px 0 0;
          > span {
            display: inline-block;
            background: $green;
            color: #fff;
            border-radius: 3px;
            line-height: 14px;
            font-size: 12px;
            padding: 2px 5px;
          }
        }

        > .thumbnail {
          display: block;
          padding: 0 0 100%;
          background-size: cover;
          background-position: center center;
          border-radius: 6px;
          overflow: hidden;
          box-shadow: rgba($body-color, 0.05) 0 3px 5px;
          transition: box-shadow 0.2s $curve, transform 0.2s $curve;
          background-color: #fff;
        }

        > h4 {
          //font-weight: 700;
          font-size: 14px;
          margin: 10px 0 0;
        }

        &:hover {
          > .thumbnail {
            box-shadow: rgba($body-color, 0.08) 0 8px 15px;
            transform: translateY(-2px);
          }
        }
      }
    }
  }
}
.loading-placeholder {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  > i {
    margin: 10px 10px;
    flex: 0 0 calc(20% + 20px);
    display: block;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    background-image: url("data:image/svg+xml,%3Csvg width='178' height='125' preserveAspectRatio='none' viewBox='0 0 178 125' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='178' height='125' rx='4' fill='%23E4E1DF'/%3E%3C/svg%3E%0A");
    
    &:before {
      content: '';
      display: block;
      padding: 0 0 100%;
    }
  }
}
</style>