<template>
  <div class="reporting-widget">
    <div class="widget-content">
      <header>
        <h2>{{widget_title}}</h2>
      </header>
      <component :is="widget_component" :data="data" @loaded="widgetLoaded" />
    </div>
  </div>
</template>

<script>
import WidgetLoading from './WidgetLoading.vue'
import WidgetError from './WidgetError.vue'

export default {
  props: {
    title: {
      type: String
    },
    widget: {
      type: String
    },
    data: {
      type: [Object, Array]
    }
  },
  computed: {
    widget_title() {
      return this.title;
    },
    widget_component() {
      return () => ({
        component: import(`./Widgets/${this.widget}`),
        loading: WidgetLoading,
        error: WidgetError,
        delay: 0,
        timeout: 3000
      });
    }
  },
  data() {
    return {

    }
  },
  created() {

  },
  methods: {
    widgetLoaded() {
      this.$nextTick( () => {
        this.$emit('loaded')
      } );
    }
  }
}
</script>