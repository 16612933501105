<template>
  <div class="award-gift-builder">
    <ul>
      <li v-for="(gift, index) in gifts" :key="`gift_row_${index}`">
        <ul>
          <li v-for="(option, optIndex) in gift" :class="[{'gift-option': option.type !== 'PLACEHOLDER'}]" :key="`gift_opt_${index}_${optIndex}`">
            <template v-if="option.type === 'PLACEHOLDER'">
              <a href="#" @click.prevent="openGiftSelector(gift, optIndex)">
                <i class="fas fa-gift"></i>
                <span>Select a gift</span>
              </a>
            </template>
            <template v-else>
              <a href="#" @click.prevent="deleteGiftOption(gift, optIndex)">
                <template v-if="option.type === 'CUSTOM_REWARD'">
                  <div class="thumbnail" :style="{'background-image': `url('https://img-cdn.hithrive.com/storefront-images/${option.reward.images[0]}.jpg')`}"></div>
                  <h4>{{option.reward.name}}</h4>
                  <div class="availability">{{option.reward.countries.length === 0 ? 'All countries' : option.reward.countries.join(', ')}}</div>
                </template>
                <template v-else-if="option.type === 'POINTS'">
                  <div class="points">{{$formatPoints(option.points, true)}}</div>
                  <h4>Points</h4>
                </template>
              </a>
            </template>
          </li>
        </ul>
        <button class="btn btn-xs btn-circle btn-secondary" :disabled="gift.findIndex(g=>g.type === 'PLACEHOLDER') > -1" @click.prevent="addGiftOption(gift)"><i class="fas fa-plus"></i></button>
      </li>
    </ul>

    <button class="btn btn-xs btn-secondary" @click.prevent="addGiftRow"><i class="fas fa-plus"></i> Attach a gift</button>
  </div>
</template>

<script>
export default {
  name: 'AwardGiftBuilder',
  props: ['value'],
  computed: {
    value_hash() {
      if(_.isEmpty(this.value) || this.value === null) return null;
      return this.$md5(JSON.stringify(this.value))
    }
  },
  watch: {
    gifts(newVal, oldVal) {
      const reduced_val = newVal.map((row) => row.map(
        (giftOpt) => {
     
          if(giftOpt.type === 'CUSTOM_REWARD') {
            return {
              type: 'CUSTOM_REWARD',
              reward_id: giftOpt.reward.id
            };
          }

          return giftOpt;
        }
        )
      )

      this.$emit('input', reduced_val)
    },
    value_hash(newVal, oldVal) {
      if(oldVal === null && newVal !== null && this.init_value === null) {
        this.init_value = this.value;

        this.populateValue(newVal, oldVal)
      }
    }
  },
  data() {
    return {
      init_value: null,
      initial_load: false,
      gifts: [],
      loading: false
    }
  },
  mounted() {
    if(this.value_hash !== null) {
      this.init_value = _.cloneDeep(this.value);
      this.populateValue(this.value, null)
    }
  },
  methods: {
    populateValue(newVal, oldVal) {
      const has_rewards = this.init_value.some((row) => row.filter((opt) => opt.type === 'CUSTOM_REWARD').length > 0 )
      if(has_rewards) {
        this.populateGifts()
      }

      if(oldVal === null && (newVal !== null && newVal.length > 0)) this.initial_load = true;
    },
    async populateGifts() {
      this.initial_load = true;
      this.loading = true;
      
      const gift_ids = _.uniq(this.init_value.reduce((agg, val) => {
        //get options in row
     
        const row_ids = val.filter(opt => opt.type === 'CUSTOM_REWARD').map(opt => opt.reward_id)
        agg.push(...row_ids);

        return agg;
      }, []))
      if(gift_ids.length > 0) {
        const resp = await this.$api.Rewards.get_custom_rewards(gift_ids)
      
        //map response to gifts model
        this.gifts = this.init_value.map((row) => row.map(
          (giftOpt) => {
            if(giftOpt.type === 'CUSTOM_REWARD') {
              return {
                type: 'CUSTOM_REWARD',
                reward: resp.find(o => o.id === giftOpt.reward_id)
              };
            }

            return giftOpt;
          }
          )
        )
      }

      this.loading = false;
    },
    addGiftRow() {
      this.gifts.push([
        {
          type: 'PLACEHOLDER'
        }
      ]);
    },
    addGiftOption(row) {
      row.push({
        type: 'PLACEHOLDER'
      })
    },
    deleteGiftOption(gift_row, opt_index) {
      if(!confirm('Are you sure you want to delete this gift option?')) return;

      gift_row.splice(opt_index, 1);

      //clear empty rows
      this.clearEmptyRows()
    },
    clearEmptyRows() {
      this.gifts = this.gifts.filter(g => g.length > 0);
    },
    openGiftSelector(gift_row, opt_index) {
      this.$root.$emit('openModal', 'Awards/GiftSelector', {}, (selected) => {
        if(selected === null) return;

        console.log(selected)

        //check if exists
        if(selected.type === 'POINTS' && gift_row.findIndex(g => g.type === 'POINTS') > -1) {
          this.$toast.error('This gift row already has a point option.')
          return;
        }
        if(gift_row.filter(g => (g.type === 'CUSTOM_REWARD')).findIndex(g => g.reward.id === selected.id) > -1) {
          this.$toast.error('This product is already in this gift row.')
          return;
        }

        //has gift selection
        let gift = selected;
        if(selected.type === 'CUSTOM_REWARD') {
          gift = {
            type: 'CUSTOM_REWARD',
            reward: _.cloneDeep(selected)
          };
        }
        gift_row.splice(opt_index, 1, gift);
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.award-gift-builder {
  margin: 15px 0 0;
  > ul {
    display: block;
    padding: 0;
    margin: 0 0 10px;

    > li {
      display: flex;
      background: $light;
      border-radius: 10px;
      padding: 15px;
      align-items: center;
      position: relative;
      
      + li {
        margin-top: 15px;

        &:before {
          content: 'AND';
          background: $light;
          border-radius: 100em;
          position: absolute;
          left: 10px;
          top: -15px;
          width: 30px;
          text-align: center;
          z-index: 9;
          height: 15px;
          line-height: 15px;
          font-size: 9px;
          font-weight: 700;
          display: block;
        }
      }

      > button {
        margin: 0 0 0 10px;
      }

      > ul {
        display: flex;
        padding: 0;
        margin: 0 -5px;

        > li {
          display: block;
          padding: 0 5px;
          flex: 0 0 100px;
          position: relative;

          +.gift-option {
            &:before {
              content: 'OR';
              background: $light;
              border-radius: 100em;
              position: absolute;
              left: -15px;
              top: 50%;
              margin-top: -7px;
              width: 30px;
              text-align: center;
              z-index: 9;
              height: 14px;
              line-height: 15px;
              font-size: 9px;
              font-weight: 700;
              display: block;
            }
          }

          > a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-decoration: none;
            text-align: center;
            background: #fff;
            box-shadow: rgba(0,0,0,0.05) 0 5px 10px;
            border-radius: 4px;
            padding: 10px;
            width: 90px;
            height: 100%;
            position: relative;
            overflow: hidden;
            color: $body-color;

            > i {
              font-size: 22px;
            }

            > span {
              display: block;
              font-size: 12px;
              font-weight: 700;
              margin: 4px 0 0;
            }

            h4 {
              margin: 0;
              font-weight: 700;
              font-size: 12px;
              margin: 4px 0 0;
            }

            .availability {
              font-size: 10px;
              text-align: center;
              color: $muted-text;
            }

            > .thumbnail {
              display: block;
              width: 50px;
              height: 50px;
              background-size: cover;
              background-position: center center;
              border-radius: 6px;
              margin: 0 auto;
            }
          }

          &.gift-option {
            > a { 
              &:hover {
                &:before {
                  content: '';
                  display: block;
                  position: absolute;
                  left: 0;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  background: rgba($body-color, 0.5);
                  backdrop-filter: blur(1px);
                }
                &:after {
                  content: '\f1f8';
                  display: block;
                  position: absolute;
                  font-family: 'Font Awesome 5 Pro';
                  left: 0;
                  right: 0;
                  top: 50%;
                  transform: translateY(-50%);
                  color: #fff;
                  font-size: 18px;
                  font-weight: 700;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>