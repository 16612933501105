<template>
  <main class="app-view settings settings-dashboard">
    <header>
      <div class="title">
        <h1>Settings</h1>
      </div>
    </header>

    <section class="main-section">
      <header>
        <h2>Team Settings</h2>
      </header>

      <div class="content settings">
        <div class="setting-row">
          <i class="fas fa-power-off"></i>
          <div class="title">
            <h4>Activate HiThrive</h4>
            <p>Suspend or enable HiThrive for your team. Users won't be able to use HiThrive if this is disabled.</p>
          </div>
          <div class="fields">
            <ht-switch v-model="is_active" />
          </div>
        </div>

        <div class="setting-row">
          <i class="fas fa-users-crown"></i>
          <div class="title">
            <h4>Administrators</h4>
            <p>Users who have access to this admin dashboard.</p>
          </div>
          <div class="fields">
            <user-picker v-model="admins" :required-users="owner" placeholder="search for users to make admins..." />
          </div>
        </div>
      </div>

    </section>

    <section class="main-section nudges">
      <header>
        <h2>Nudge Settings</h2>
      </header>

      <div class="content settings">
        <div class="setting-row">
          <i class="fas fa-bell"></i>
          <div class="title">
            <h4>Active Nudges</h4>
            <p>Smart reminders designed to boost team participation.</p>
          </div>
          <div class="fields">
            <ul>
              <li>
                <span>Points about to expire (End of month)</span>
                <ht-switch v-model="nudges.eom_point_reminder" />
              </li>
              <li>
                <span>Inactivity reminders</span>
                <ht-switch v-model="nudges.inactivity_nudge" />
              </li>
              <li>
                <span>Allowance reloaded (Start of month)</span>
                <ht-switch v-model="nudges.point_reload_nudge" />
              </li>
              <li>
                <span>Points available to redeem</span>
                <ht-switch v-model="nudges.redeemable_points_nudge" />
              </li>
            </ul>
          </div>
        </div>
      </div>

    </section>

    <section class="main-section">
      <header>
        <h2>Redemption Portal Branding</h2>
      </header>

      <div class="content settings">
        <div class="setting-row">
          <i class="fas fa-expand-wide"></i>
          <div class="title">
            <h4>Logo</h4>
            <p>Header logo in PNG format.</p>
          </div>
          <div class="fields">
            <div class="logo-picker">
              <input ref="logo-input" type="file" @change="selectedFile" id="logo-input" accept="image/*">
              <div class="logo-preview" v-if="logo !== null">
                <img :src="logo" height="50" />
              </div>
              <button class="btn btn-light btn-xs" @click="chooseFile">Upload logo</button>
              <button class="btn btn-link btn-xs" @click="logo = null" v-if="logo !== null">Clear logo</button>
            </div>
          </div>
        </div>

        <div class="setting-row">
          <i class="fas fa-eye-dropper"></i>
          <div class="title">
            <h4>Primary Color</h4>
            <p>HEX value of your custom brand color.</p>
          </div>
          <div class="fields">
            <div class="color-picker">
              <input type="text" class="form-control" v-model="primary_color" placeholder="#000000">
            </div>
          </div>
        </div>
      </div>

    </section>

    <footer>
      <button class="btn btn-green btn-sm" :class="{'loading': loading}" @click="save">Save</button>
    </footer>
  </main>
</template>

<script>
import UserPicker from '@/components/UserPicker';

export default {
  computed: {
    owner() {
      if(this.admins.length === 0) return [];

      return [_.find(this.admins, {is_owner: true})];
    },
    loading() {
      return this.$store.state.loading;
    },
    team() {
      return this.$store.state.team;
    }
  },
  components: {
    UserPicker
  },
  data() {
    return {
      admins: [],
      is_active: false,
      primary_color: null,
      logo: null,
      nudges: {
        eom_point_reminder: false,
        inactivity_nudge: false,
        point_reload_nudge: false,
        redeemable_points_nudge: false
      }
    }
  },
  created() {
    this.populateAdmins()

    this.primary_color = this.team.metadata.primary_color ? `#${this.team.metadata.primary_color}` : null;
    this.logo = this.team.metadata.logo || null;

    this.nudges.eom_point_reminder = this.team.metadata.eom_point_reminder === false ? false : true;
    this.nudges.inactivity_nudge = this.team.metadata.inactivity_nudge === false ? false : true;
    this.nudges.point_reload_nudge = this.team.metadata.point_reload_nudge === false ? false : true;
    this.nudges.redeemable_points_nudge = this.team.metadata.redeemable_points_nudge === false ? false : true;

    this.is_active = this.team.is_active;
  },
  methods: {
    selectedFile() {
      const $input = this.$refs['logo-input'];

      if($input.files && $input.files[0]) {
        const reader = new FileReader();

        reader.addEventListener('load', (e) => {
          this.logo = e.target.result;
        })

        reader.readAsDataURL( $input.files[0] );
      }
    },
    chooseFile() {
      const $input = this.$refs['logo-input'];

      $input.value = null;
      $input.click();
    },
    async populateAdmins() {
      const resp = await this.$api.Team.get_admins();
      this.admins = resp;
    },
    async save() {
      this.$store.commit('loading', true)

      //see if we need to upload logo first before posting updates
      if(this.logo !== null && this.logo.substr(0, 5) == 'data:') {
        //upload logo and swap out result URL
        const iconData = new FormData();
        iconData.append('logo', this.$refs['logo-input'].files[0])

        const resp = await this.$api.Team.upload_logo(iconData)
        this.logo = resp.logo_url;
      }

      await Promise.all([
        this.$api.Team.set_active_status(this.is_active),
        this.$api.Team.set_admins(_.map(this.admins, 'id')),
        this.$store.dispatch('teamMetadata', {
          primary_color: this.primary_color ? this.primary_color.replace('#', '') : null,
          logo: this.logo,
          eom_point_reminder: this.nudges.eom_point_reminder,
          inactivity_nudge: this.nudges.inactivity_nudge,
          point_reload_nudge: this.nudges.point_reload_nudge,
          redeemable_points_nudge: this.nudges.redeemable_points_nudge
        })
      ])
      this.$store.commit('loading', false)

      this.$toast.success('Settings saved successfully.')
    }
  }
}
</script>

<style lang="scss" scoped>
.nudges {
  ul {
    display: block;
    margin: 0;
    padding: 0;

    > li {
      display: flex;
      border-bottom: 1px solid $light;
      align-items: center;
      padding: 5px 0;
      > span {
        font-size: 14px;
        display: block;
        margin: 0;
        flex: 1;
      }
    }
  }
}
.logo-picker {
  overflow: hidden;
  position: relative;
  padding: 5px;
  margin: -5px -5px;

  #logo-input {
    width: 1px;
    height: 1px;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    left: -9999px;
  }

  .logo-preview {
    background: $primary;
    border-radius: 10px;
    padding: 10px 15px;
    display: block;
    margin: 0 0 10px;

    > img {
      display: block;
      margin: 0 auto;
    }
  }
}
</style>