<template>
  <main class="app-view rewards rewards-dashboard">
    <header>
      <div class="title">
        <h1>Rewards</h1>
      </div>

      <div class="btns">
        <router-link class="btn btn-light" :to="{name: 'reporting-activity', query: {type: 'redemptions'}}"><i class="fas fa-chart-pie"></i> Latest redemptions</router-link>
      </div>
    </header>

    <section class="main-section">
      <header>
        <h2>Insights</h2>
      </header>

      <div class="content">
        <ul class="stats" v-if="stats">

          <li>
            <div>
              <h4>Paid redemptions this month</h4>
              <span>{{$formatNumber(stats.paid_redemptions_count)}}</span>
            </div>
          </li>

          <li>
            <div>
              <h4>Paid redemption cost</h4>
              <span>{{$formatPoints(stats.paid_redemptions)}}</span>
            </div>
          </li>

          <li>
            <div>
              <h4>Culture redemptions this month</h4>
              <span>{{$formatNumber(stats.custom_redemptions)}}</span>
            </div>
          </li>

        </ul>
      </div>

    </section>

    <section class="main-section pending-culture" v-if="pending_culture === null || (pending_culture && pending_culture.length > 0)">
      <header>
        <h2>Pending Culture Redemptions</h2>
      </header>

      <div class="redemptions">
        <ul>
          <li v-for="(redemption, index) in pending_culture" :key="`pcr_${index}`">
            <div class="info">
              <span class="timestamp">{{$formatDate(redemption.created_at)}}</span>
            </div>
            <div class="user">
              <user-card :user="redemption.user" :height="22" />
            </div>
            <div class="reward">
              <div class="reward-name">
                <i v-html="$emoji(redemption.redemption.emoji)"></i>
                <span>{{redemption.redemption.name}}</span>
              </div>
            </div>

            <div class="points">
              <span class="point-cost">{{$formatPoints(redemption.points)}}</span>
            </div>

            <div class="actions">
              <!--<button class="btn btn-danger btn-xs btn-circle"><i class="fas fa-thumbs-down"></i></button>-->
              <button class="btn btn-success btn-xs btn-circle" @click="approveRedemption(redemption, index)"><i class="fas fa-thumbs-up"></i></button>
            </div>
          </li>
        </ul>

        <div class="loading-placeholder" v-if="!pending_culture"><i></i><i></i><i></i></div>
      </div>

    </section>
  </main>
</template>

<script>
export default {
  name: 'RewardsDashboard',
  computed: {
    
  },
  data() {
    return {
      pending_culture: null,
      stats: null
    }
  },
  created() {
    this.populateStats()
    this.populatePendingRedemptions()
  },
  methods: {
    async populatePendingRedemptions() {
      const resp = await this.$api.Rewards.get_pending_culture()
      this.pending_culture = resp;
    },
    async populateStats() {
      const resp = await this.$api.Rewards.get_redemption_stats()
      this.stats = resp;
    },
    async approveRedemption(redemption, index) {
      this.pending_culture.splice(index, 1)
      this.$toast.success('Redemption marked as complete.');
      await this.$api.Rewards.mark_complete(redemption.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.stats {
  display: flex;
  padding: 0;
  margin: 0 -10px;

  > li {
    display: block;
    padding: 0 10px;

    > div {
      background: $light;
      padding: 15px;
      border-radius: 10px;

      > h4 {
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        color: $muted-text;
        margin: 0 0 5px;
      }

      > span {
        text-align: center;
        display: block;
      }
    }
  }
}

.pending-culture {

  .loading-placeholder {
    display: block;
    margin: 0 0;
    > i {
      margin: 0 0;
      +i { 
        margin-top: 15px;
      }
      //flex: 1 0 auto;
      display: block;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center center;
      background-image: url("data:image/svg+xml,%3Csvg width='951' height='50' preserveAspectRatio='none' viewBox='0 0 951 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='951' height='50' rx='4' fill='%23E4E1DF'/%3E%3C/svg%3E%0A");
      
      &:before {
        content: '';
        display: block;
        height: 50px;
      }
    }
  }

  .info {
    padding: 0 10px 0 0;
    flex: 0 0 auto;
  }
  .user {
    padding: 0 10px;
    flex: 0 0 auto;
  }

  .reward {
    padding: 0 10px;
    flex: 0 0 auto;
  }

  .points {
    padding: 0 10px;
    flex: 0 0 auto;
  }

  .actions {
    margin: 0 0 0 auto;
  }

  ul {
    display: block;
    padding: 0;
    margin: 0;

    > li {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 10px;
      background: $light;
      border-radius: 10px;

      .info {
        .timestamp {
          font-size: 12px;
          display: block;
          color: $muted-text;
        }
      }

      .reward {
        .reward-name {
          > i {
            display: inline-block;
            font-style: normal;
            font-size: 22px;
            margin: 0 5px 0 0;
            vertical-align: middle;
          }
          > span {
            font-size: 16px;
            font-weight: 700;
          }
        }
      }

      .points {
        > span {
          background: $green;
          display: inline-block;
          line-height: 1em;
          font-size: 12px;
          border-radius: 3px;
          padding: 3px 4px;
          color: #fff;
        }
      }

      +li {
        margin-top: 15px;
      }
    }
  }
}
</style>