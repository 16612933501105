<template>
  <div>
    <img src="@/assets/hithrive-logo-dark.svg" height="22" class="logo" />

    <loading-indicator />
  </div>
</template>

<script>
export default {
  created() {
    this.exchangeTokenForJWT( this.$route.query.token );
  },
  methods: {
    async exchangeTokenForJWT(token) {
      this.$store.dispatch('logout');

      const redirect = this.$route.query.redirect ? decodeURIComponent(this.$route.query.redirect) : '/';

      try {
        const resp = await this.$api.Auth.exchange_single_use_token( token );

        if( 'error' in resp ) {
          return this.$router.replace({
            name: 'login',
            params: {
              error: `That link has expired, please try again.`
            },
            query: {
              redirect
            }
          });
        }

        this.$store.commit('user_token', resp.token);
        await this.$store.dispatch('populateUser');

        this.$router.replace(redirect)
      } catch(e) {
        return this.$router.replace({
            name: 'login',
            params: {
              error: `That link has expired, please try again.`
            },
            query: {
              redirect
            }
          });
      }
    }
  }
}
</script>