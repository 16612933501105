<template>
  <div class="reward-view container-fluid" id="storefront" v-if="product">
    <main>
      <header>
        <div class="title">
          <ul class="crumbs">
            <li>
              <router-link :to="{ name: 'rewards_home' }">Rewards</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'rewards_storefront_home' }"
                >Swag Shop</router-link
              >
            </li>
          </ul>
          <h1>{{ product.name }}</h1>
        </div>
      </header>

      <section class="main-section" id="storefront-product">
        <div class="row">
          <div id="image-col" class="col-md-5">
            <div class="img-wrap">
              <img :src="`${current_variant.images[img_index]}?width=320`" />
            </div>
            <div class="gallery-swatches">
              <ul>
                <li
                  v-for="(img, index) in current_variant.images"
                  :class="[{ active: img_index == index }]"
                  :key="`thumbnail_${current_variant.sku}_${index}`"
                >
                  <a
                    href="#"
                    @click.prevent="img_index = index"
                    :style="{ 'background-image': `url(${img}?width=80)` }"
                  ></a>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-md-7">
            <div class="filters">
              <div
                class="filter"
                v-for="(options, label) in filters"
                :key="`storefront_product_filter_${label}`"
              >
                <label
                  ><strong>{{ $storefrontData.filter_labels[label] }}:</strong>
                  {{ filter_by[label] }}</label
                >
                <v-select
                  v-model="filter_by[label]"
                  @option:selected="changeFilter(label)"
                  :clearable="false"
                  placeholder="Select..."
                  :filterable="false"
                  :searchable="false"
                  :options="options"
                >
                  <template #option="option">
                    <span>{{ option.label }}</span>
                  </template>
                </v-select>
              </div>
            </div>

            <div class="description" v-html="product.description"></div>

            <footer class="redeem-banner">
              <div>
                <div class="price">
                  <small>Price:</small>
                  <strong>{{
                    $formatPoints(current_variant.price * 100)
                  }}</strong>
                </div>
                <div class="spacer"></div>
                <div class="redeem">
                  <div class="ship-to">
                    <div class="dropdown">
                      <a
                        href="#"
                        id="shipToDropdownLink"
                        class="dropdown-toggle"
                        data-toggle="dropdown"
                        >{{
                          selected_addr
                            ? `Ship to: ${selected_addr.street1}`
                            : addresses.length > 0
                            ? `Select shipping address...`
                            : `Add new shipping address...`
                        }}</a
                      >

                      <div class="dropdown-menu" @click.prevent="changeAddress">
                        <a
                          class="dropdown-item"
                          href="#"
                          v-for="addr in addresses"
                          :data-value="addr.id"
                          :key="`addr_${addr.id}`"
                          >{{ addr.street1 }}</a
                        >
                        <div
                          class="dropdown-divider"
                          v-if="addresses.length > 0"
                        ></div>
                        <a
                          class="dropdown-item"
                          href="#"
                          data-value="manage_addresses"
                          >Manage addresses</a
                        >
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    class="btn btn-green"
                    @click.prevent="submitOrder"
                    :disabled="!can_buy"
                  >
                    Buy Now
                  </button>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  computed: {
    addresses() {
      return this.$store.state.user.addresses;
    },
    current_variant() {
      return _.find(this.product.variants, { sku: this.variant_sku });
    },
    team() {
      return this.$store.state.team;
    },
    filters() {
      if (this.product.variants.length === 1) return {};

      return this.product.variants.reduce((agg, val) => {
        //loop over each variant
        Object.keys(val.options).forEach((filterKey) => {
          if (!Object.keys(agg).includes(filterKey)) agg[filterKey] = [];

          if (!agg[filterKey].includes(val.options[filterKey]))
            agg[filterKey].push(val.options[filterKey]);
        });

        return agg;
      }, {});
    },
    can_buy() {
      return (
        Object.keys(this.filter_by).length === Object.keys(this.filters).length
      );
    },
  },
  data() {
    return {
      product: null,
      variant_sku: null,
      img_index: 0,
      filter_by: {},
      selected_addr: null,
    };
  },
  created() {
    this.populateProduct();
  },
  methods: {
    async submitOrder() {
      const resp = await this.$api.RewardPortal.Storefront.redeem({
        product_name: this.product.name,
        sku: this.variant_sku,
        thumbnail: this.current_variant.images[0] + '?width=80',
        point_cost: this.current_variant.price * 100,
      });

      if ('error' in resp) {
        this.$store.dispatch('loading', false);

        if (resp.error == 'INSUFFICIENT_POINTS')
          this.$toast.error(
            `You don't have enough ${this.team.points_name} to buy this product.`
          );

        if (resp.error == 'INSUFFICIENT_FUNDS')
          this.$toast.error(
            `Amazon redemptions are temporarily unavailable. An administrator has been notified.`
          );

        return;
      }

      this.$store.dispatch(
        'adjustRedeemablePoints',
        -Math.abs(this.current_variant.price * 100)
      );
      this.$router.push({
        name: 'rewards_redemptions',
        params: {
          redemption_id: resp.id,
        },
      });
    },
    addAddress() {
      this.$root.$emit(
        'openModal',
        'RewardsPortal/ShippingAddresses',
        {},
        (result) => {}
      );
    },
    changeAddress(e) {
      const elem = e.target || e.srcElement;
      const value = elem.getAttribute('data-value');

      if (value === 'manage_addresses') {
        this.addAddress();
      } else {
        this.selected_addr = _.find(this.addresses, { id: value });
      }
    },
    populateProduct() {
      this.selected_addr = _.find(this.addresses, { default: true });

      const slug = this.$route.params.product_slug;
      this.product = _.find(this.$storefrontData.products, { slug });
      this.variant_sku = this.product.variants[0].sku;
    },
    changeFilter() {
      this.variant_sku = _.find(this.product.variants, ({ options }) =>
        _.every(
          Object.keys(this.filter_by),
          (k) => options[k] === this.filter_by[k]
        )
      ).sku;
    },
  },
};
</script>

<style lang="scss" scoped>
#storefront-product {
  margin: 25px 0 0;

  #image-col {
    .img-wrap {
      padding: 0 0 100%;
      position: relative;
      border-radius: 10px;
      overflow: hidden;
      box-shadow: rgba(0, 0, 0, 0.05) 0 5px 10px;
      background: #fff;

      img {
        display: block;
        position: absolute;
        max-width: 100%;
        max-height: 100%;
        height: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .gallery-swatches {
      margin: 15px -5px 0;
      ul {
        display: flex;
        padding: 0;
        margin: 0;
        flex-wrap: wrap;

        > li {
          display: block;
          margin: 0;
          padding: 5px;

          width: (100%/6);
          flex: 0 0 (100%/6);

          > a {
            border-radius: 10px;
            overflow: hidden;
            position: relative;
            display: block;
            background-size: cover;
            background-position: center center;
            box-shadow: rgba($body-color, 0.1) 0 1px 3px;
            transition: box-shadow 0.1s $curve, transform 0.2s $curve;

            &:hover {
              box-shadow: rgba($body-color, 0.1) 0 2px 5px;
              transform: translateY(-2px);
            }

            &:before {
              content: '';
              display: block;
              padding: 0 0 100%;
            }

            > img {
              display: block;
              height: 100%;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }

          &.active {
            > a {
              transform: none;
              box-shadow: $primary 0 0 0 1px, rgba($primary, 0.3) 0 0 0 3px;
            }
          }
        }
      }
    }
  }

  .filter {
    display: block;
    margin: 0 0 25px;

    > label {
      display: block;
      font-weight: normal;

      > strong {
        font-weight: 700;
      }
    }

    ::v-deep .v-select {
      display: flex;
      .vs__dropdown-toggle {
        .vs__selected-options {
          flex: 0 1 auto;
          min-width: 80px;

          .vs__selected {
            position: relative;
          }
        }
      }

      .vs__dropdown-menu {
        width: auto;
        border-radius: 0 4px 4px 4px;
        border-top: 1px solid #e3e0de;
      }
    }
  }

  footer.redeem-banner {
    background: $page-background;
    @include media-breakpoint-up(md) {
      position: sticky;
      bottom: 0;
      padding: 10px 0 15px;
      margin: 35px 0 0;
    }

    > div {
      background: $light;
      border-radius: 10px;
      min-height: 30px;
      padding: 15px 15px;
      display: flex;
      align-items: center;
      flex-direction: column;

      margin: 0 -15px -15px;
      @include media-breakpoint-up(md) {
        margin: 0;
        padding: 10px 15px;
        flex-direction: row;
      }

      .spacer {
        flex: 1;
      }

      .price {
        margin: 0 0 15px;
        text-align: center;
        @include media-breakpoint-up(md) {
          margin: 0;
          text-align: left;
        }

        > small {
          color: $muted-text;
          display: inline-block;
          margin: 0 4px 0 0;
        }

        > .tax-disclaimer {
          font-size: 10px;
          margin: 0;
          display: block;
        }

        > strong {
          color: $red;
          > small {
            font-weight: 700;
            font-size: 12px;
          }
        }
      }

      .redeem {
        width: 100%;
        @include media-breakpoint-up(xl) {
          display: flex;
          align-items: center;
          width: auto;
        }

        .ship-to {
          margin: 0 0 15px;
          @include media-breakpoint-up(xl) {
            margin: 0 15px 0 0;
          }

          .dropdown-toggle {
            font-size: 14px;
            color: $muted-text;

            &:after {
              vertical-align: 1px;
            }
          }
        }

        > button {
          @include media-breakpoint-up(xl) {
            width: auto;
            height: auto;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
