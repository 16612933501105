<template>
  <div class="reward-view container-fluid" id="storefront">
    <main>
      <header>
        <div class="title">
          <ul class="crumbs">
            <li>
              <router-link :to="{name: 'rewards_home'}">Rewards</router-link>
            </li>
          </ul>
          <h1>Swag Shop</h1>
        </div>
      </header>

      <section class="main-section" id="storefront-products">
        <aside>
          <ul>
            <li v-for="(category, index) in categories" :key="`storefront_category_${index}`" :class="{'active': current_category === $slugify(category)}">
              <a href="#" @click.prevent="current_category=$slugify(category)">{{category}}</a>
            </li>
          </ul>
        </aside>

        <div>
          <ul>
            <li v-for="(product, index) in products" :key="`storefront_product_${index}`">
              <router-link class="content-card" :to="{name: 'rewards_storefront_product', params: {product_slug: product.slug}}">
                <img :src="`${product.variants[0].images[0]}?width=160`" alt="" />

                <h5>{{product.name}}</h5>
                <div class="point-cost">
                  <span>{{$formatPoints(product.variants[0].price*100)}}</span>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  computed: {
    categories() {
      return [
        'All Items', 
        ...this.$storefrontData.categories
      ];
    },
    products() {
      if(this.current_category === 'all-items') return this.$storefrontData.products;
      return _.filter(this.$storefrontData.products, {category: this.current_category})
    }
  },
  data() {
    return {
      current_category: 'all-items',
    }
  },
  methods: {
    viewProduct(product) {
      this.$root.$emit(
        'openModal',
        'RewardsPortal/StorefrontProduct',
        product,
        () => {

        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
#storefront {
  #storefront-products {
    margin: 25px 0 0;
    display: flex;
    align-items: flex-start;

    > aside {
      flex: 0 0 256px;
      background: $light;
      border-radius: 10px;
      padding: 15px 25px;

      > ul {
        padding: 0;
        margin: 0;
        > li {
          display: block;
          padding: 5px 0;

          > a {
            display: inline-block;
            color: $primary;
          }

          &.active {
            > a {
              font-weight: 700;
            }
          }
        }
      }
    }

    > div {
      flex: 1;

      > ul {
        display: flex;
        flex-wrap: wrap;
        padding: 0 25px;
        margin: -15px -25px -15px -15px;

        > li {
          flex: 0 0 (100%/3);
          display: block;
          padding: 15px 15px;

          > a {
            display: block;
            padding: 0 10px 15px;
            text-align: center;

            > img {
              display: block;
              width: 100%;
              height: auto;
            }

            > h5 {
              text-align: center;
              font-size: 14px;
              font-weight: 700;
              margin: 10px 0 0;
            }

            > .point-cost {
              margin: 3px 0 0;
              > span {
                display: inline-block;
                background: $green;
                color: #fff;
                border-radius: 3px;
                line-height: 14px;
                font-size: 12px;
                padding: 2px 5px;
              }
            }
          }
        }
      }
    }
  }
}
</style>