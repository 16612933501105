<template>
  <div id="trial-ended">
    <header>
      <img src="@/assets/hithrive-logo-dark.svg" height="20" />
    </header>

    <section class="intro">
      <h1>Your trial has ended</h1>
      <p>We hope you enjoyed your free trial of HiThrive. To continue using HiThrive, please setup a subscription.</p>
    </section>

    <section class="choose-plan">
      <plan-selector @refresh="handleSubRefresh" />
    </section>
  </div>
</template>

<script>
import PlanSelector from '../components/Billing/PlanSelector.vue'
export default {
  components: {
    PlanSelector
  },
  computed: {
    team() {
      return this.$store.state.team;
    }
  },
  mounted() {
    if(this.team.plan_type !== -1) {
      this.$router.replace({
        name: 'recognition'
      })
    }
  },
  methods: {
    async handleSubRefresh() {
      await this.$store.dispatch('populateUser')
      this.$router.push({
        name: 'recognition'
      })
    }
  }
}
</script>

<style lang="scss" 
PlanSelectorscoped>
#trial-ended {
  max-width: 600px;
  margin: 35px auto;

  header {
    padding-bottom: 50px;
  }

  h1 {
    font-weight: 700;
    font-size: 24px;
  }

  .choose-plan {
    margin: 50px 0 0;
  }
}
</style>