<template>
  <span class="user--name">
    {{name}}
  </span>
</template>

<script>
export default {
  name: 'UserName',
  props: [
    'user'
  ],
  computed: {
    name() {
      let user = null;
      if( typeof this.user == 'undefined' ) {
        //use current user
        user = this.$store.state.user;
      }
      else if( typeof this.user == 'object' ) {
        user = this.user;
      }

      let name = user.name;
      if(name.trim().length === 0) {
        name = user.email;
      }

      return `${name}`;
    }
  },
  methods: {
    
  }
}
</script>