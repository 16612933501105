<template>
  <router-view />
</template>

<script>
export default {
  created() {
    this.populateSegments()
  },
  methods: {
    populateSegments() {
      this.$store.dispatch('populateSegments')
    }
  }
}
</script>