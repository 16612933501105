<template>
  <div class="reward-view container-fluid" id="gift-card-details">
    <main v-if="post">
      <header>
        <div class="title">
          <ul class="crumbs">
            <li>
              <router-link :to="{name: 'rewards_home'}">Rewards</router-link>
            </li>
          </ul>

          <h1>{{post.data.post_title[0].text}}</h1>
        </div>
      </header>

      <section class="main-section" id="article-section">
        <cite>By Team HiThrive</cite>
        <div class="post-body" v-html="post_body"></div>

        <template v-if="product_list">
          <ol class="product-list amazon">
            <li v-for="reward in product_list" v-bind:key="`amazonproduct_${reward.asin}`">
              <router-link v-bind:to="{name: 'rewards_amazon_product', params: { asin: reward.asin }}">
                <div class="image" v-bind:style="{'background-image': `url('${reward.image.url}')`}"></div>
                <div class="meta">
                  <h3>{{reward.title[0].text}}</h3>
                  <div v-html="parseHTML(reward.description)"></div>
                </div>
              </router-link>
            </li>
          </ol>
        </template>
      </section>
    </main>
  </div>
</template>

<script>
import PrismicDOM from 'prismic-dom'
import axios from 'redaxios'

export default {
  computed: {
    prismic_ref() {
      return this.$store.state.prismic_ref;
    }
  },
  data() {
    return {
      post: null,
      post_body: null,
      product_list: null
    }
  },
  created() {
    this.loadPost(this.$route.params.id)
  },
  methods: {
    parseHTML(elements) {
      return PrismicDOM.RichText.asHtml(elements)
    },
    async loadPost(id) {
      if(this.prismic_ref === null) {
        await this.$store.dispatch('populatePrismicRef')
      }

      const resp = await axios.get(`https://hithrive.cdn.prismic.io/api/v2/documents/search?ref=${this.prismic_ref}&q=[[at(my.reward_content.uid,"${id}")]]`);
      const result = resp.data.results[0];

      this.post = result;
      this.post_body = PrismicDOM.RichText.asHtml( result.data.post_body )

      this.$emit('updateHead')

      if( result.data.body.length > 0 ) {
        if( result.data.body[0].slice_type == "amazon_products" ) {
          //this.rewardListType = 'AMAZON';
          this.product_list = result.data.body[0].items;
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#article-section {
  > cite {
    font-size: 14px;
    color: $muted-text;
  }
}
.product-list {
  display: block;
  padding: 0;
  margin: 35px 0 0;

  > li {
    display: block;
    padding: 0;
    margin: 0 0 25px;
    >a {
      display: flex;
      background: #fff;
      overflow: hidden;
      color: $body-color;
      text-decoration: none;

      background: #fff;
      border-radius: 10px;
      padding: 20px;
      min-height: 25px;
      transition: box-shadow 0.2s $curve, transform 0.2s $curve;
      box-shadow: rgba($body-color, 0.05) 0 3px 5px;

      &:hover {
        box-shadow: rgba($body-color, 0.08) 0 8px 15px;
        transform: translateY(-2px);
      }
      &:active {
        box-shadow: rgba($body-color, 0.05) 0 3px 5px;
        transform: translateY(0);
      }

      .image {
        margin: 0 25px 0 0;
        width: 25%;
        flex: 0 0 25%;
        min-height: 150px;
        background-size: cover;
        background-position: center center;

        &:before {
          content: '';
          display: block;
          padding: 0 0 100%;
        }
      }

      .meta {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 14px;

        h3 {
          font-size: 22px;
          font-weight: 700;
        }


      }
    }
  }
}
</style>