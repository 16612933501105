<template>
  <router-view />
</template>

<script>
export default {
  created() {
    this.populateGroups();
  },
  methods: {
    async populateGroups() {
      this.$store.dispatch('populateGroups')
    }
  }
}
</script>