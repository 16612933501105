<template>
  <div class="segment-conditions">
    <template  v-if="profile_fields !== null && value !== null">
      <div class="condition-group" v-for="(group, group_index) in value.$each.$iter" :key="`condition_group_${group_index}`">
        <ul>
          <li v-for="(condition, index) in group.$each.$iter" :key="`segmentcondition_${index}`">
            <button class="btn btn-danger btn-circle btn-xs" @click="removeCondition(group_index, index)"><i class="fas fa-trash"></i></button>
            <div class="form-group">
              <label>Profile field</label>
              <select class="form-control form-control-sm" :class="{'is-invalid': condition.field_id.$error}" v-model="condition.field_id.$model">
                <option disabled value="">Select a field...</option>
                <option v-for="field in available_fields(condition.field_id.$model, group_index)" :key="`fieldselector_${index}_${field.id}`" :value="field.id">
                  {{field.label}}
                </option>
              </select>
            </div>
            <div class="form-group operator">
              <select class="form-control form-control-sm" v-model="condition.operator.$model">
                <option value="=">=</option>
                <option value="BEGINS_WITH">Begins with</option>
                <option value="CONTAINS">Contains</option>
              </select>
            </div>
            <div class="form-group">
              <label>Field value</label>
              <select v-if="condition.operator.$model === '='" class="form-control form-control-sm" :class="{'is-invalid': condition.field_value.$error}" v-model="condition.field_value.$model">
                <option disabled value="">Select a value...</option>
                <option v-for="(value, val_index) in field_values[condition.field_id.$model]" :key="`field_${index}_value_opt_${val_index}`" :value="value">{{value}}</option>
              </select>
              <input type="text" class="form-control form-control-sm" v-else placeholder="enter value here..." v-model="condition.field_value.$model" />
            </div>
          </li>
        </ul>

        <button class="btn btn-xs" @click="addConditionToGroup(group_index)"><i class="fas fa-plus"></i> AND</button>
      </div>

      <button class="btn btn-secondary btn-circle btn-xs" @click="addConditionGroup"><i class="fas fa-plus"></i></button>
    </template>
    <loading-indicator v-else small />
  </div>
</template>

<script>
export default {
  props: ['value'],
  data() {
    return {
      profile_fields: null,
      field_values: null,
      conditions: null
    }
  },
  watch: {
    value: function(val) {
      //this.$emit('input', val)
    }
  },
  mounted() {
    this.populateProfileFields()
  },
  methods: {
    available_fields(field, group_index) {
      const used_fields = _.map(this.value.$model[group_index], 'field_id')
      return _.filter(this.profile_fields, (f) => f.id === field || !used_fields.includes(f.id))
    },
    addConditionToGroup(group_index) {
      this.value.$model[group_index].push({
        field_id: '',
        field_value: '',
        operator: '=',
      })
    },
    addConditionGroup() {
      this.value.$model.push([{
        field_id: '',
        field_value: '',
        operator: '='
      }])
    },
    removeCondition(group_index, index) {
      this.value.$model[group_index].splice(index, 1);
      if(this.value.$model[group_index].length === 0) this.value.$model.splice(group_index, 1);
    },
    async populateProfileFields() {
      const {fields, field_values} = await this.$api.Team.get_profile_fields()
      this.profile_fields = fields;
      this.field_values = field_values;
    },
  }
}
</script>

<style lang="scss" scoped>
.segment-conditions {
  .condition-group {
    background: $light;
    border-radius: 10px;
    padding: 5px 15px 5px 40px;
    position: relative;

    +.condition-group {
      margin: 30px 0 0;

      &:before {
        content: 'OR';
        display: block;
        position: absolute;
        left: 5px;
        top: -22px;
        font-size: 10px;
        color: $muted-text;
      }
    }

    +.btn {
      margin: 15px 0 0;
    }
  }
  ul {
    display: block;
    padding: 0;
    margin: 0;

    +.btn {
      margin: 10px 0 0;
    }

    > li {
      display: flex;
      margin: 0 -5px 0 -30px;
      position: relative;
      align-items: flex-end;
      padding: 10px 0 0 25px; 

      + li {
        margin-top: 15px;
      }

      > button {
        display: none;
        position: absolute;
        top: 44px;
        left: 0;
      }

      &:hover {
        > button {
          display: block;
        }
      }

      > div {
        flex: 1;
        padding: 0 5px 0;

        > label {
          font-size: 12px;
          font-weight: 700;
          color: $muted-text;
        }
      }

      .form-group {
        margin: 0;

        &.operator {
          flex: 0 0 130px;
        }
      }

      &:before {
        content: 'AND';
        display: block;
        position: absolute;
        left: 5px;
        top: -6px;
        font-size: 10px;
        color: $muted-text;
      }

      &:first-child {
        &:before {
          content: none;
        }
      }
    }
  }
}
</style>