<template>
  <main class="app-view billing billing-invoices">
    <header>
      <div class="title">
        <ul class="crumbs">
          <li>
            <router-link :to="{name: 'billing'}">Billing</router-link>
          </li>
        </ul>
        <h1>Invoices</h1>
      </div>
    </header>

    <section class="main-section" v-if="open_invoices === null || open_invoices.length > 0">
      <header>
        <h2>Open Invoices</h2>
      </header>

      <div class="content">
        <ul v-if="open_invoices !== null" class="invoices">
          <invoice :invoice="invoice" v-for="invoice in open_invoices" :key="`open_invoice_${invoice.id}`" />
        </ul>
        <div class="loading-placeholder" v-else><i></i><i></i><i></i><i></i></div>
      </div>
    </section>

    <section class="main-section" v-if="paid_invoices === null || paid_invoices.length > 0">
      <header>
        <h2>Paid Invoices</h2>
      </header>

      <div class="content">
        <ul v-if="paid_invoices !== null" class="invoices">
          <invoice :invoice="invoice" v-for="invoice in paid_invoices" :key="`paid_invoice_${invoice.id}`" />
        </ul>
        <div class="loading-placeholder" v-else><i></i><i></i><i></i><i></i></div>
      </div>
    </section>
  </main>
</template>

<script>
import Invoice from '@/components/Billing/Invoice'

export default {
  components: {
    Invoice
  },
  computed: {
    team() {
      return this.$store.state.team;
    },
    locked_feature() {
      return this.$route.params.locked_feature === true;
    }
  },
  data() {
    return {
      paid_invoices: null,
      open_invoices: null
    }
  },
  created() {
    this.populateInvoices()
  },
  methods: {
    async populateInvoices() {
      const resp = await this.$api.Billing.get_invoices()
      this.open_invoices = resp.open_invoices;
      this.paid_invoices = resp.paid_invoices;
    }
  }
}
</script>

<style lang="scss" scoped>
.invoices {
  display: block;
  padding: 0;
  margin: 0;
}
.loading-placeholder {
  display: block;
  margin: 0 0;
  > i {
    margin: 0 0;
    +i { 
      margin-top: 15px;
    }
    //flex: 1 0 auto;
    display: block;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    background-image: url("data:image/svg+xml,%3Csvg width='951' height='50' preserveAspectRatio='none' viewBox='0 0 951 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='951' height='50' rx='4' fill='%23E4E1DF'/%3E%3C/svg%3E%0A");
    
    &:before {
      content: '';
      display: block;
      height: 50px;
    }
  }
}
</style>