<template>
  <div class="amazon-redemption">
    <div class="top-info">
      <div class="title">
        <h4><img src="@/assets/amazon-logo.svg#amazon-color" height="18" /></h4>
      </div>
      <div class="ml-auto"></div>
      <div class="status">
        <span :class="[status_class]">{{status_text}}</span>
      </div>
      <div class="point-cost">
        <span>{{$formatPoints(points)}}</span>
      </div>
    </div>
    <div class="d-flex">
      <ul class="items">
        <li v-for="(item, index) in redemption" :key="`amazonitem_${item.groupById}_${item.redemption.asin}_${index}`">
          <div class="thumbnail" :style="{'background-image': `url('${item.redemption.thumbnail}')`}"></div>
          <div class="title">
            <div class="timestamp">{{$formatDate(item.created_at)}}</div>
            <h5>{{item.redemption.title}}</h5>
            <div class="item-cost">
              <span>{{$formatPoints(item.points)}}</span>
            </div>
          </div>
        </li>
      </ul>
      <div class="order-actions">
        <a class="btn btn-light btn-kinda-sm" target="_blank" :href="`https://hithrive.aftership.com/${tracking_number}`" v-if="status === 'COMPLETE'">Track package</a>
        <a class="btn btn-light btn-kinda-sm" :href="support_email">Get redemption help</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['redemption'],
  computed: {
    user() {
      return this.$store.state.user;
    },
    team() {
      return this.$store.state.team;
    },
    status() {
      return this.redemption[0].status;
    },
    tracking_number() {
      if(!('shipping_details' in this.redemption[0].redemption)) return '';
      return this.redemption[0].redemption.shipping_details.tracking_number;
    },
    status_text() {
      switch(this.status) {
        case 'CANCELLED':
          return 'Item cancelled';
        case 'RETURNED':
          return 'Item returned';
        case 'PENDING':
          return 'Order received';
        case 'PROCESSING':
          return 'Preparing to ship';
        case 'COMPLETE':
          return 'Shipped';
      }
    },
    status_class() {
      return 'status-' + this.status.toLowerCase();
    },
    points() {
      return _.sumBy(this.redemption, 'points');
    },
    support_email() {
      return `mailto:help@hithrive.com?subject=Redemption%20Support%3A%20%23${this.team.id}-${this.user.id}-${this.redemption[0].id}&body=Please%20describe%20how%20we%20can%20assist%20you%20with%20your%20redemption%20below%3A%0D%0A%0D%0A`;
    }
  }
}
</script>