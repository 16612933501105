<template>
  <div id="app-wrap">
    <div class="trial-banner" v-if="has_trial && me.is_admin">
      &#x23F0; Your trial ends {{ trial_ends }}.
      <template v-if="!has_payment_method"
        ><router-link :to="{ name: 'billing' }"
          >Add a credit card now to avoid interruptions.
          <i class="far fa-long-arrow-right"></i></router-link
      ></template>
      <template v-else
        ><router-link :to="{ name: 'billing' }"
          >Manage your billing settings
          <i class="far fa-long-arrow-right"></i></router-link
      ></template>
    </div>

    <app-sidebar />

    <div id="content-wrap">
      <header>
        <ul class="stats">
          <li v-if="me.is_admin">
            <router-link :to="{ name: 'billing-rewards' }">
              <label>Your team's balance</label>
              <span>{{ $formatPoints(team.purchased_points) }}</span>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'rewards_home' }">
              <label>Redeem your {{ team.points_name }}</label>
              <span>{{ $formatPoints(me.redeemable_points) }}</span>
            </router-link>
          </li>
        </ul>

        <nav>
          <button @click="openHelpWidget">
            <i class="fas fa-question"></i>
          </button>
          <button v-if="false">
            <i class="fas fa-bell"></i>
          </button>
        </nav>

        <div class="dropdown">
          <a
            href="#"
            class="dropdown-toggle"
            data-display="static"
            role="button"
            data-toggle="dropdown"
          >
            <user-profile-picture />
          </a>

          <div class="dropdown-menu dropdown-menu-right">
            <h6 class="dropdown-header">Hey, {{ me.name }}</h6>
            <a class="dropdown-item" href="#" @click.prevent="logout">Logout</a>
          </div>
        </div>
      </header>

      <router-view />
    </div>
  </div>
</template>

<script>
import AppSidebar from '@/components/AppSidebar';
import { loadVGSCollect } from '@vgs/collect-js';
import Vue from 'vue';

export default {
  components: {
    AppSidebar,
  },
  computed: {
    me() {
      return this.$store.state.user;
    },
    team() {
      return this.$store.state.team;
    },
    has_trial() {
      return this.team.trial_end !== null;
    },
    trial_ends() {
      const end_time = this.$DateTime.fromISO(this.team.trial_end);
      const cur_time = this.$DateTime.local();

      if (end_time < cur_time) return `today`;
      if (end_time.hasSame(cur_time, 'day')) return `today`;
      if (end_time - cur_time < 86400000) return `tomorrow`;

      return end_time.toRelative({ unit: 'days' });
    },
    has_payment_method() {
      return this.team.metadata.have_payment_method === true;
    },
  },
  watch: {},
  created() {
    this.mountVGS();
  },
  methods: {
    async mountVGS() {
      Vue.prototype.$vgs = await loadVGSCollect({
        vaultId: 'tntibenaqyo',
        environment: 'live',
        version: '2.8.1',
      }).catch((e) => {
        console.error(e);
      });
    },
    openHelpWidget() {
      zE('webWidget', 'open');
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.replace({ name: 'login' });
    },
  },
};
</script>

<style lang="scss" scoped>
#content-wrap {
  > header {
    height: 80px;
    padding: 10px 0 15px;
    border-bottom: 1px solid #e3e0de;
    box-shadow: #f9f6f4 -15px 0 0, #f9f6f4 15px 0 0;
    position: sticky;
    top: 0;
    background: #f9f6f4;
    z-index: 99;
    transition: box-shadow 0.2s $curve;

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    > .stats {
      display: flex;
      padding: 0;
      margin: 0 auto 0 -7px;
      align-items: flex-end;

      > li {
        display: block;
        padding: 0 7px;

        > a {
          display: block;
          padding: 7px 30px 7px 10px;
          border-radius: 10px;
          background: rgba($body-color, 0.05);
          //background: linear-gradient(180deg, rgba(60,110,113,1) 0%, rgba(53,99,102,1) 100%);
          color: $body-color;
          //text-shadow: rgba(0,0,0,0.2) 0 -1px 0;
          //box-shadow: inset rgba(255,255,255,0.3) 0 1px 0;
          text-decoration: none;
          position: relative;

          &:after {
            content: '\f054';
            font-family: 'Font Awesome 5 Pro';
            color: rgba($body-color, 0.3);
            font-size: 12px;
            position: absolute;
            right: 10px;
            display: block;
            top: 50%;
            transform: translateY(-50%);
          }

          > label {
            display: block;
            margin: 0 0 1px;
            font-size: 10px;
            line-height: 1.2em;
            //text-transform: uppercase;
            color: rgba($body-color, 0.65);
            pointer-events: none;
          }

          > span {
            display: block;
            font-size: 12px;
            line-height: 1.2em;
          }

          &:hover {
            background: rgba($body-color, 0.1);
          }
        }
      }
    }

    > nav {
      display: flex;
      align-items: center;
      padding: 0 10px;
      > button {
        appearance: none;
        border: none;
        background: none;
        padding: 5px 7px;
        margin: 0 3px;
        display: block;
        font-size: 19px;
        color: $muted-text;
        text-shadow: #ffffff 0 1px 0;
      }
    }

    .dropdown {
      position: relative;

      .dropdown-toggle {
        padding: 0 12px 3px 0;
        display: block;
        &:after {
          content: '\f0d7';
          font-weight: 700;
          font-family: 'Font Awesome 5 Pro';
          position: absolute;
          top: 14px;
          color: rgba($muted-text, 0.5);
          vertical-align: auto;
          font-size: 13px;
          line-height: 11px;
          margin: 0;
          right: 0;
          border: none;
        }

        &:hover {
          &:after {
            color: $muted-text;
          }
        }
      }
    }
  }
}
#app-wrap {
  > .trial-banner {
    height: 40px;
    background: linear-gradient(
      180deg,
      rgba(34, 26, 50, 1) 80%,
      rgba(30, 23, 46, 1) 100%
    );
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 99;
    color: #fff;
    padding: 9px 10px;
    text-align: center;
    font-size: 14px;

    > a {
      color: #fff;
      text-decoration: underline;
    }

    + aside {
      top: 40px;
    }

    ~ #content-wrap {
      padding-top: 40px;
      > header {
        top: 40px;
      }
    }
  }
}
</style>
