<template>
  <main class="app-view rewards rewards-dashboard">
    <header>
      <div class="title">
        <ul class="crumbs">
          <li>
            <router-link :to="{name: 'rewards'}">Rewards</router-link>
          </li>
        </ul>
        <h1>Settings</h1>
      </div>
    </header>

    <section class="topup-alert" v-if="need_topup">
      <div>
        <div>
          <h2>You need a top-up!</h2>
          <p>Before your team can redeem any points on paid reward catalogs, you'll need to buy some points.</p>
        </div>
        <div class="actions">
          <button class="btn btn-success" @click="topupPoints"><i class="fas fa-coins"></i> Top-up points</button>
        </div>
      </div>
    </section>

    <section class="main-section">
      <header>
        <h2>Reward Catalogs</h2>

        <div class="btns">
          <a href="https://help.hithrive.com/hc/en-us/articles/360058955171-Paying-for-rewards-points-and-top-ups" target="_blank" class="help-link">Paying for rewards</a>
        </div>
      </header>

      <div class="content settings">
        
        <ul class="catalogs">
          <li>
            <div class="content-card">
              <div class="d-flex">
                <i class="fas fa-tshirt"></i>
                <ht-switch v-model="catalogs.custom_catalog" />
              </div>
              <h4>Custom Catalog</h4>
              <p>Redeem points for any manually fulfilled items and catalog items from your integrations.</p>
            </div>
          </li>
          <li>
            <div class="content-card">
              <div class="d-flex">
                <i class="fab fa-amazon"></i>
                <ht-switch v-model="catalogs.amazon" />
              </div>
              <h4>Amazon</h4>
              <p>Shop for any product on Amazon using your HiThrive points with Amazon Prime included.</p>
            </div>
          </li>
          <li>
            <div class="content-card">
              <div class="d-flex">
                <i class="fas fa-gift-card"></i>
                <ht-switch v-model="catalogs.gift_cards" />
              </div>
              <h4>Gift Cards</h4>
              <p>Redeem your HiThrive points for gift cards from popular retailers around the world.</p>
            </div>
          </li>
          <li>
            <div class="content-card">
              <div class="d-flex">
                <i class="fas fa-hands-usd"></i>
                <ht-switch v-model="catalogs.charities" />
              </div>
              <h4>Charitable Donations</h4>
              <p>Make charitable contributions using your HiThrive points to 1.5m+ US non-profits.</p>
            </div>
          </li>
          <li>
            <div class="content-card">
              <div class="d-flex">
                <i class="fab fa-paypal"></i>
                <ht-switch v-model="catalogs.paypal" />
              </div>
              <h4>PayPal/Venmo</h4>
              <p>Cash out your HiThrive points to your local currency through PayPal or Venmo.</p>
            </div>
          </li>
        </ul>

      </div>

    </section>

    <section class="main-section culture-rewards">
      <header>
        <h2>Settings</h2>
      </header>

      <div class="content settings">
        <div class="setting-row taggable-values">
          <i class="fas fa-users"></i>
          <div class="title">
            <h4>Custom Catalog Notifications</h4>
            <p>Who should we notify when a custom catalog (non-integration) reward is redeemed?</p>
          </div>
          <div class="fields">
            <user-picker v-model="custom_catalog_notifications" />
          </div>
        </div>
      </div>

    </section>

    

    <footer>
      <button class="btn btn-green btn-sm" :class="{'loading': loading}" @click="save">Save</button>
    </footer>
    
    <emoji-picker v-bind:data="emoji_index" v-on:select="chooseEmoji" id="emoji_picker_reward" native />
    <div v-html="emoji_picker_css"></div>
  </main>
</template>

<script>
import emojiData from "emoji-mart-vue-fast/data/all.json";
import { Picker as EmojiPicker, EmojiIndex } from "emoji-mart-vue-fast";
const emojiIndex = new EmojiIndex(emojiData);

import UserPicker from '@/components/UserPicker'

import {required, numeric} from 'vuelidate/lib/validators'

export default {
  name: 'RewardsSetings',
  components: {
    EmojiPicker,
    UserPicker
  },
  computed: {
    emoji_picker_css() {
      return `<style>
      .emoji-mart {
        top: ${this.emoji_picker_pos.y + 31}px;
        left: ${this.emoji_picker_pos.x - 1}px;
        display: ${this.emoji_display === true ? 'flex' : 'none'};
      }
      </style>`;
    },
    team() {
      return this.$store.state.team;
    },
    loading() {
      return this.$store.state.loading;
    },
    reward_catalog_array() {
      return Object.keys(this.catalogs).reduce((arr, val) => {
        if(this.catalogs[val] === true) arr.push(val.toUpperCase());
        return arr;
      }, [])
    },
    need_topup() {
      return this.team.purchased_points === 0
      && this.team.reward_payment_type === 0
      && [
        'AMAZON',
        'GIFT_CARDS',
        'CHARITIES'
      ].some( c => this.team.reward_catalogs.includes(c) );
    }
  },
  data() {
    return {
      emoji_display: false,
      emoji_index: emojiIndex,
      //culture_rewards: null,
      use_postpay: null,
      check_postpay: false,
      custom_catalog_notifications: [],
      catalogs: {
        amazon: false,
        gift_cards: false,
        charities: false,
        paypal: false,
        custom_catalog: false
      },
      emoji_picker_pos: {
        x: 0,
        y: 0
      },
      editing_index: -1
    }
  },
  validations: {
    culture_rewards: {
      $each: {
        emoji: {
          required
        },
        name: {
          required
        },
        description: {
          required
        },
        point_cost: {
          required,
          numeric
        }
      }
    }
  },
  created() {
    //this.populateRewards()
    this.populateRewardNotifications()

    this.catalogs.amazon = this.team.reward_catalogs.includes('AMAZON');
    this.catalogs.gift_cards = this.team.reward_catalogs.includes('GIFT_CARDS');
    this.catalogs.charities = this.team.reward_catalogs.includes('CHARITIES');
    this.catalogs.paypal = this.team.reward_catalogs.includes('PAYPAL');
    this.catalogs.custom_catalog = this.team.reward_catalogs.includes('CUSTOM_CATALOG');
  },
  watch: {
    emoji_display(val) {
      setTimeout( () => {
        if( val === false ) {
          //remove listener
          document.body.removeEventListener('mousedown', this.handleOuterEmojiClick)
        }
        else {
          //add listener
          document.body.addEventListener('mousedown', this.handleOuterEmojiClick)
        }
      }, 50 );
    },
    use_postpay(newVal, oldVal) {
      if(oldVal === false && newVal === true) {
        //see if we can enable post pay
        this.togglePostpay()
      }
    }
  },
  methods: {
    async populateRewardNotifications() {
      const notify = await this.$api.Team.get_reward_notifications()

      this.custom_catalog_notifications = notify;
    },
    topupPoints() {
      this.$root.$emit(
        'openModal',
        'Billing/PointTopup',
        {},
        () => {}
      )
    },
    async togglePostpay() {
      //check payment method
      this.check_postpay = true;

      const _checkPaymentMethod = async () => {
        const resp = await this.$api.Billing.get_payment_method();
        if( resp.sources.length == 0 ) {
          return false;
        }
        else {
          return resp.sources[0];
        }
      };

      const paymentMethod = await _checkPaymentMethod()
      if(paymentMethod === false) {
        //Prompt to add credit card
        // TODO: open credit card modal
        this.$root.$emit(
        'openModal',
        'Billing/SetPaymentMethod',
        {},
        (resp) => {
          this.check_postpay = false;
          if(resp === 'PAYMENT_METHOD_SAVED') {

          }
          else {
            this.use_postpay = false;
          }
        }
      )
      }
      else {
        this.check_postpay = false;
      }
    },
    async populateRewards() {
      const resp = await this.$api.Rewards.get_rewards();
      this.culture_rewards = resp;
    },
    async save() {
      this.$v.$touch()
      if(this.$v.$invalid) return;

      this.$store.commit('loading', true)
      await Promise.all([
        this.$api.Rewards.set_catalog_status(this.catalogs),
        //this.$api.Rewards.update_rewards(this.culture_rewards),
        this.$api.Team.set_reward_notifications(this.custom_catalog_notifications.map(({id}) => id))
      ])
      this.$store.dispatch('teamMetadata', {setup_rewards: true})
      this.$store.dispatch('updateTeam', {
        reward_catalogs: this.reward_catalog_array
      })
      this.$toast.success('Reward settings have been saved.');
      this.$store.commit('loading', false)
    },
    addReward() {
      this.culture_rewards.push({
        emoji: ':wave:',
        name: '',
        description: '',
        point_cost: 0
      })
    },
    deleteReward(index) {
      this.culture_rewards.splice(index, 1)
    },
    openEmojiPicker($e, index) {
      this.emoji_display = true;
      this.emoji_picker_pos.x = $e.target.offsetLeft;
      this.emoji_picker_pos.y = $e.target.offsetTop;
      this.editing_index = index;
    },
    chooseEmoji(emoji) {
      this.emoji_display = false;
      this.culture_rewards[this.editing_index].emoji = emoji.colons;
    },
    handleOuterEmojiClick($e) {
      if( _.some(this.$getMousePath($e), p => {return p.id == 'emoji_picker_reward'}) ) return;

      this.emoji_display = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.emoji-mart {
  position: absolute;
  z-index: 99;
}

.topup-alert {
  border-radius: 10px;
  background: $light;
  padding: 15px;
  margin: 25px 0 0;

  > div {
    display: flex;
    align-items: center;

    h2 {
      font-weight: 700;
      font-size: 16px;
      margin: 0;
    }

    p {
      font-size: 14px;
      margin: 0;
    }

    .actions {
      padding: 0 0 0 15px;
      margin: 0 0 0 auto;
    }
  }
}

.culture-rewards {

  .table-header {
    margin: 0 0;
    padding: 0 10px 10px;
    display: flex;
    align-items: flex-end;

    > span {
      min-width: 0;
      font-size: 12px;
      color: $muted-text;
      font-weight: 700;
      display: block;
      line-height: 1.2em;
    }
  }

  .emoji {
    padding: 0 10px 0 0;
    flex: 0 0 55px;
  }

  .name {
    padding: 0 10px;
    flex: 1 0 25%;
  }
  .description {
    padding: 0 10px;
    flex: 1 0 30%;
  }

  .points {
    padding: 0 10px;
    flex: 0 0 100px;
  }

  .delete {
    padding: 0 0 0 10px;
    flex: 0 0 30px;
    text-align: right;
  }

  ul {
    display: block;
    padding: 0;
    margin: 0;

    > li {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 10px;
      background: $light;
      border-radius: 10px;

      +li {
        margin-top: 15px;
      }
    }
  }
}

.catalogs {
  display: flex;
  flex-wrap: wrap;

  padding: 0;
  margin: -10px -10px;

  > li {
    display: block;
    flex: 0 0 (100%/3);
    width: (100%/3);
    padding: 10px 10px;

    > div {
      height: 100%;
      display: flex;
      flex-direction: column;

      align-items: flex-start;

      .d-flex {
        width: 100%;
      }

      i {
        font-size: 22px;
        color: $muted-text;
        line-height: 28px;
        display: block;
        flex: 0 0 auto;
      }

      .ht-switch {
        margin: 0 0 0 auto;
      }

      h4 {
        font-weight: 700;
        margin: 10px 0 0;
        font-size: 14px;
      }
      
      p {
        margin: 0;
        font-size: 12px;
        color: $muted-text;
      }
    }
  }
}
</style>